import { useUser } from 'app/stores/user';
import { useNodeDisconnectMutation } from 'shared/api/user/useNodeDisconnectMutation';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';
import { toaster } from 'shared/ui/Toast';

import emptyBoxSrc from '../assets/box.svg';

type Props = {
  isOpen: boolean;

  onOpenChange: (value: boolean) => void;
  publicKey: string;
};

export const DisconnectPublicKeyModal = ({ isOpen, onOpenChange, publicKey }: Props) => {
  const { isPending, mutateAsync: disconnectPublicKey } = useNodeDisconnectMutation();
  const { updateUser } = useUser();

  const handleConfirm = async () => {
    try {
      const { user } = await disconnectPublicKey({ public_key: publicKey });

      updateUser(user);
      onOpenChange(false);
    } catch (e) {
      toaster.error('Something went wrong');
    }
  };
  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="!max-w-md">
        <Modal.CloseButton />

        <div className="flex flex-col items-center justify-center pt-8">
          <img className="mb-5 size-24" src={emptyBoxSrc} />
          <h1 className="mb-4 text-center text-2xl">Disconnect Public Key</h1>

          <div className="mb-4 text-center text-base font-light text-clay-500">
            Are you sure you want to disconnect your Public Key?Please confirm or cancel to proceed.
          </div>

          <div className="grid w-full grid-cols-2 gap-3">
            <Button color="secondary" onClick={() => onOpenChange(false)} variant="filled-light">
              Cancel
            </Button>
            <Button isLoading={isPending} onClick={handleConfirm}>
              Confirm
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

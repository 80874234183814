import { useMemo } from 'react';

import { getSizeCategories } from 'shared/api/datasets/useGetDatasetsQuery';
import { useGetDatasetsTagsByType } from 'shared/api/datasets/useGetDatasetsTagsByType';
import { useGetMatchingDatasetsTags } from 'shared/api/datasets/useGetMatchingDatasetsTags';
import { Spinner } from 'shared/ui/Spinner';

import type { FiltersProps } from './MainFilters';

import { Chip } from './Chip';

type Props = {
  filters: FiltersProps['filters'];
  onChange: FiltersProps['onChange'];
};

export const OtherFilters = ({ filters, onChange }: Props) => {
  const { data: availableTags, isPending } = useGetMatchingDatasetsTags({
    format:
      filters.format && filters.format?.length > 0
        ? filters.format.map((val) => `format:${val}`).join(',')
        : undefined,
    language: filters.language?.join(','),
    library: filters.library?.join(','),
    license: filters.license?.join(','),
    modality:
      filters.modality && filters.modality?.length > 0
        ? filters.modality.map((val) => `modality:${val}`).join(',')
        : undefined,
    other: filters.other?.join(','),
    size_categories: filters.size?.[0] && getSizeCategories(filters.size[0]),
    sort: filters.sort,
    task_categories: filters.tasks?.join(',') || undefined,
    type: 'other',
  });

  const { data: defaultData, isPending: isDefaultPending } = useGetDatasetsTagsByType(
    { type: 'other' },
    { staleTime: Infinity },
  );

  const available = useMemo(() => {
    return defaultData?.other?.filter((item) => availableTags?.matchingTags.includes(item.id));
  }, [defaultData, availableTags]);

  const hidden = useMemo(() => {
    return defaultData?.other?.filter((item) => !availableTags?.matchingTags.includes(item.id));
  }, [defaultData, availableTags]);

  return (
    <div>
      {isPending || isDefaultPending ? (
        <div className="flex flex-col items-center justify-center py-10">
          <Spinner className="size-4" />
        </div>
      ) : (
        <div className="flex flex-wrap items-center gap-2">
          {available?.map((item) => (
            <Chip
              isActive={filters.license?.includes(item.id)}
              key={item.id}
              onClick={() => {
                onChange({
                  other: filters.other?.includes(item.id)
                    ? filters.other?.filter((val) => val !== item.id)
                    : [...(filters.other || []), item.id],
                });
              }}
            >
              {item.label}
            </Chip>
          ))}
          {hidden?.map((item) => (
            <Chip isDisabled key={item.id}>
              {item.label}
            </Chip>
          ))}
        </div>
      )}
    </div>
  );
};

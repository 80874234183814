import { Suspense, lazy } from 'react';

import { PageLoader } from 'shared/ui/PageLoader';

export { Training } from './Training';

const TrainingNoCodeLazy = lazy(() =>
  import('./TrainingNoCode').then((module) => ({ default: module.TrainingNoCode })),
);
const TrainingCodeLazy = lazy(() =>
  import('./TrainingCode').then((module) => ({ default: module.TrainingCode })),
);

export const TrainingNoCode = () => (
  <Suspense fallback={<PageLoader />}>
    <TrainingNoCodeLazy />
  </Suspense>
);

export const TrainingCode = () => (
  <Suspense fallback={<PageLoader />}>
    <TrainingCodeLazy />
  </Suspense>
);

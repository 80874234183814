import { Icon } from 'shared/ui/Icon';

import curiousBg from '../assets/curious.svg';

type Props = {
  onClick?: () => void;
};
export const CreateDatasetBanner = ({ onClick }: Props) => {
  return (
    <div
      className="flex w-full cursor-pointer justify-between rounded-xl bg-cover bg-center bg-no-repeat px-6 py-5"
      onClick={onClick}
      style={{
        backgroundImage: `url(${curiousBg})`,
      }}
    >
      <div className="flex flex-col gap-3">
        <Icon className="size-7 stroke-white text-transparent" name="fileBox" />

        <div className="text-2xl font-light text-white">Upload a Dataset</div>
      </div>
      <div className="text-end text-base/5 font-light text-white">
        Get rewards every <br /> time {`it's`} used.
      </div>
    </div>
  );
};

import type { WillChangeEvent } from '@egjs/react-flicking';

import { useRef, useState } from 'react';

import Flicking from '@egjs/react-flicking';
import { twMerge } from 'tailwind-merge';

import { getTvl } from 'pages/DAI/helpers/getTvl';
import { useDAIListQuery } from 'shared/api/dai/useDAIListQuery';
import { Icon } from 'shared/ui/Icon';

import { DoxxedTeamCard } from './DoxxedTeamCard';

const teams = [
  {
    coinName: '$LIFE',
    price: 340231,
    title: 'Lifegen: Safe radiology AI recognition for bio.',
    userName: 'joshua-cryptoman',
  },
  {
    coinName: '$TOON',
    price: 253444,
    title: 'Lifegen: Safe radiology AI recognition for bio.',
    userName: 'joshua-cryptoman',
  },
  {
    coinName: '$TOON',
    price: 253444,
    title: 'Lifegen: Safe radiology AI recognition for bio.',
    userName: 'joshua-cryptoman',
  },
];
export const DoxxedTeamList = () => {
  const ref = useRef<Flicking>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const { data: daiList, isPending: isListLoading } = useDAIListQuery({ limit: 5, skip: 0, sort: 'newest' });

  return (
    <div className="flex flex-col overflow-hidden rounded-xl bg-clay-10 p-4 pb-0">
      <div className="mb-2 font-semibold tracking-tight">Top DAI Teams</div>

      <Flicking
        align="prev"
        bound
        cameraClass="flex"
        cameraTag="div"
        circular={false}
        defaultIndex={0}
        horizontal
        noPanelStyleOverride
        onWillChange={(e: WillChangeEvent) => {
          setActiveIndex(e.index);
        }}
        ref={ref}
        renderOnSameKey={false}
        viewportTag="div"
      >
        {isListLoading
          ? Array.from({ length: 4 })
              .fill('')
              .map((_, idx) => {
                return (
                  <div className="mr-2 min-h-[157px] w-64 min-w-64 max-w-64 overflow-hidden" key={idx}>
                    <DoxxedTeamCard isLoading />
                  </div>
                );
              })
          : daiList?.list.map((dai, idx) => {
              const member = dai.members.find((member) => member.title.toLowerCase() === 'founder');
              return (
                <div className="mr-2" key={idx}>
                  <DoxxedTeamCard
                    className="w-80 max-w-80"
                    coinName={dai.token?.ticker || '--'}
                    imageSrc={dai.profileImage}
                    isLoading={false}
                    price={getTvl(dai.tvl)}
                    title={dai.projectName}
                    userImageSrc={member?.avatar}
                    userName={member?.name || ''}
                  />
                </div>
              );
            })}
      </Flicking>

      <div className="ml-auto mt-2 flex items-center gap-2 pb-3">
        <Icon
          className={twMerge(
            'size-4 cursor-pointer rounded-md bg-clay-10 p-1 text-clay-500 transition-colors hover:bg-clay-20',
            (activeIndex === undefined || activeIndex <= 0) && 'cursor-not-allowed opacity-50',
          )}
          name="arrowLeftLong"
          onClick={() => {
            if (activeIndex > 0) {
              ref.current?.prev();
            }
          }}
        />
        <Icon
          className={twMerge(
            'size-4 rotate-180 cursor-pointer rounded-md bg-clay-10 p-1 text-clay-500 transition-colors hover:bg-clay-20',
            activeIndex >= teams.length - 1 && 'cursor-not-allowed opacity-50',
          )}
          name="arrowLeftLong"
          onClick={() => {
            if (activeIndex < teams.length - 1) {
              ref.current?.next();
            }
          }}
        />
      </div>
    </div>
  );
};

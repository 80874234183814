import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { Training } from 'shared/api/training';
import type { TableColumn } from 'shared/ui/Table';

import { useGetTrainingByUserQuery } from 'shared/api/training';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Pagination } from 'shared/ui/Pagination';
import { Spinner } from 'shared/ui/Spinner';
import { Table } from 'shared/ui/Table';

const TABLE_LIMIT = 3;

const getColumns = (navigate: ReturnType<typeof useNavigate>): TableColumn<string, Training>[] => [
  {
    key: 'name',
    renderTd: (row) => <span className=" text-base font-medium text-clay-500">{row.name}</span>,
    thClassName: 'border',
    title: 'Project Name',
  },
  {
    key: 'modelName',
    renderTd: (row) => <span className=" text-base font-medium text-clay-500">{row.model?.modelName}</span>,
    thClassName: 'border',
    title: 'Model Name',
  },
  {
    key: 'trainType',
    renderTd: (row) => <span className=" text-base font-medium text-clay-500">{row.type}</span>,
    thClassName: 'border',
    title: 'Training Method',
  },
  {
    key: 'actions',
    renderTd: (row) => (
      <div className="flex justify-center">
        <Button color="white" onClick={() => navigate(`/training/${row.type}/${row._id}`)}>
          Open Project
          <Icon className="size-3.5" name="arrowUpRight" />
        </Button>
      </div>
    ),
    tdClassName: 'w-48',
    thClassName: 'border',
    title: 'Open Project',
  },
];

export const TrainedModels = () => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const skip = TABLE_LIMIT * (currentPage - 1);

  const { data, isPending } = useGetTrainingByUserQuery();

  const list = data?.data.slice(skip, TABLE_LIMIT * currentPage);

  const columns = useMemo(() => getColumns(navigate), [navigate]);

  return (
    <div className="overflow-hidden rounded-2xl border border-clay-20 bg-white">
      {isPending && (
        <div className="flex justify-center py-6">
          <Spinner className="size-5" />
        </div>
      )}
      {!isPending && (
        <>
          <header className="mb-5 flex items-center justify-between gap-4 px-7 pt-4">
            <h3 className="text-2xl/none font-semibold text-clay-900">Trained Models</h3>
            <div className="w-full max-w-lg">
              <Input
                classNameInputWrapper="bg-gray-100"
                placeholder="Filter by name"
                size="medium"
                startSlot={
                  <Icon
                    className="mx-1 size-4 text-clay-900 transition-colors group-focus-within:text-clay-600 sm:size-4 sm:text-clay-300"
                    name="search"
                  />
                }
              />
            </div>
          </header>
          <div className="-mx-px">
            <Table
              className="border-collapse"
              columns={columns}
              data={list || []}
              theadTrClassName="shadow-none"
            />
          </div>
          {data?.data.length ? (
            <footer className="flex justify-center p-5">
              <Pagination
                currentPage={currentPage}
                onPageChange={setCurrentPage}
                totalPages={Math.ceil(data.count / TABLE_LIMIT)}
              />
            </footer>
          ) : (
            <div className=" py-14 text-center text-base font-medium text-clay-400">
              You have not started any projects yet
            </div>
          )}
        </>
      )}
    </div>
  );
};

import * as React from "react";
const SvgDocker = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null), /* @__PURE__ */ React.createElement("g", { id: "Docker" }, /* @__PURE__ */ React.createElement("g", { "data-name": "<Group>", id: "_Group_" }, /* @__PURE__ */ React.createElement("circle", { cx: 5.04, cy: 16, "data-name": "<Path>", id: "_Path_", r: 0.5, style: {
  fill: "none",
  stroke: "#303c42",
  strokeLinecap: "round",
  strokeLinejoin: "round"
} }), /* @__PURE__ */ React.createElement("rect", { "data-name": "<Rectangle>", height: 3, id: "_Rectangle_", style: {
  fill: "none",
  stroke: "#303c42",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, width: 3, x: 1.5, y: 9.5 }), /* @__PURE__ */ React.createElement("rect", { "data-name": "<Rectangle>", height: 3, id: "_Rectangle_2", style: {
  fill: "none",
  stroke: "#303c42",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, width: 3, x: 4.5, y: 9.5 }), /* @__PURE__ */ React.createElement("rect", { "data-name": "<Rectangle>", height: 3, id: "_Rectangle_3", style: {
  fill: "none",
  stroke: "#303c42",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, width: 3, x: 7.5, y: 9.5 }), /* @__PURE__ */ React.createElement("rect", { "data-name": "<Rectangle>", height: 3, id: "_Rectangle_4", style: {
  fill: "none",
  stroke: "#303c42",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, width: 3, x: 10.5, y: 9.5 }), /* @__PURE__ */ React.createElement("rect", { "data-name": "<Rectangle>", height: 3, id: "_Rectangle_5", style: {
  fill: "none",
  stroke: "#303c42",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, width: 3, x: 4.5, y: 6.5 }), /* @__PURE__ */ React.createElement("rect", { "data-name": "<Rectangle>", height: 3, id: "_Rectangle_6", style: {
  fill: "none",
  stroke: "#303c42",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, width: 3, x: 7.5, y: 6.5 }), /* @__PURE__ */ React.createElement("rect", { "data-name": "<Rectangle>", height: 3, id: "_Rectangle_7", style: {
  fill: "none",
  stroke: "#303c42",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, width: 3, x: 10.5, y: 6.5 }), /* @__PURE__ */ React.createElement("rect", { "data-name": "<Rectangle>", height: 3, id: "_Rectangle_8", style: {
  fill: "none",
  stroke: "#303c42",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, width: 3, x: 10.5, y: 3.5 }), /* @__PURE__ */ React.createElement("rect", { "data-name": "<Rectangle>", height: 3, id: "_Rectangle_9", style: {
  fill: "none",
  stroke: "#303c42",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, width: 3, x: 13.5, y: 9.5 }), /* @__PURE__ */ React.createElement("path", { d: "M23.5,11.5s-1.75-1.12-3-.5A3.45,3.45,0,0,0,19,8.5a3.64,3.64,0,0,0-.58,2.88,1,1,0,0,1-1,1.12H.5c0,6.25,3.83,8,7.5,8a13.76,13.76,0,0,0,12.06-7A4.68,4.68,0,0,0,23.5,11.5Z", "data-name": "<Path>", id: "_Path_2", style: {
  fill: "none",
  stroke: "#303c42",
  strokeLinecap: "round",
  strokeLinejoin: "round"
} }))));
export default SvgDocker;

import { useState } from 'react';

import { motion } from 'framer-motion';
import { useAccount, useBalance, useCosmWasmSigningClient, useExecuteContract, useQuerySmart } from 'graz';
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import type { DAI } from 'shared/api/dai/types';
import type { ClassName } from 'shared/types';

import { nesaTestnet } from 'shared/config/networks/nesaTestnet';
import { fromUnesToNes } from 'shared/helpers/fromUnesToNes';
import { catchError } from 'shared/helpers/parseAxiosError';
import { Button } from 'shared/ui/Button';
import { Spinner } from 'shared/ui/Spinner';
import { toaster } from 'shared/ui/Toast';

import type { Stake, StakesResponse } from '../types';

import { stakingContractAddress } from '../consts';

type Props = { dai: DAI } & ClassName;

export const Unstake = ({ className, dai }: Props) => {
  const { data: account } = useAccount();

  const { data: signingClient } = useCosmWasmSigningClient();
  const { executeContractAsync, isLoading: isUnstaking } = useExecuteContract({
    contractAddress: stakingContractAddress,
  });

  const [itemToUnstake, setItemToUnstake] = useState<Stake>();

  const {
    data: uBalance,
    isLoading,
    refetch: refetchBalance,
  } = useBalance({
    bech32Address: account?.bech32Address,
    chainId: nesaTestnet.chainId,
    denom: nesaTestnet.stakeCurrency.coinMinimalDenom,
  });

  const { data, refetch: refetchStakes } = useQuerySmart<StakesResponse, Error>({
    address: stakingContractAddress,
    queryMsg: {
      get_stakes: {
        owner: account?.bech32Address,
      },
    },
  });

  const currentDAIStakes = data?.stakes.filter((stake) => stake.company_id === dai._id) || [];
  const nowSeconds = DateTime.now().toSeconds();
  const sortedStakes = currentDAIStakes.sort((a, b) => {
    const leftTimeA = nowSeconds - a.start_time - a.lockup_seconds;
    const leftTimeB = nowSeconds - b.start_time - b.lockup_seconds;

    return leftTimeB - leftTimeA;
  });

  const balance = uBalance?.amount ? fromUnesToNes(uBalance.amount).toNumber() : undefined;

  const handleUnstake = async () => {
    try {
      if (!itemToUnstake) return;

      await executeContractAsync({
        fee: {
          amount: [{ amount: '1', denom: 'unes' }],
          gas: '200000',
        },
        funds: [{ amount: itemToUnstake.amount, denom: 'unes' }],
        msg: {
          unstake: { stake_ids: [itemToUnstake.id] },
        },

        signingClient,
      });

      refetchBalance();
      refetchStakes();

      toaster.success(`Success`);

      setItemToUnstake(undefined);
    } catch (e) {
      catchError(e);
    }
  };

  return (
    <motion.div
      animate={{ opacity: 1 }}
      className={twMerge('flex flex-col', className)}
      initial={{ opacity: 0 }}
    >
      <div className="mb-2 flex items-end justify-between text-sm">
        <div className="ml-auto flex items-center gap-2 font-medium">
          Balance: {isLoading ? <Spinner className="size-3" /> : balance} NES
        </div>
      </div>

      <div className="mb-3 flex flex-col">
        {sortedStakes.slice(0, 4).map((stake) => {
          const timeLeft = stake.start_time + stake.lockup_seconds - nowSeconds;
          return (
            <div
              className={twMerge(
                'flex cursor-pointer items-center justify-between border-b border-clay-20 px-2 py-4 last:border-none hover:bg-primary-30',
                itemToUnstake?.id === stake.id && 'bg-primary-50',
                timeLeft > 0 && 'cursor-not-allowed',
                isUnstaking && 'pointer-events-none',
              )}
              key={stake.id}
              onClick={() => {
                if (timeLeft > 0) return;

                setItemToUnstake(stake);
              }}
            >
              <div className="text-clay-400">
                {timeLeft <= 0
                  ? 'Available now'
                  : `Locked for ${Math.ceil((stake.start_time + stake.lockup_seconds - nowSeconds) / (60 * 60 * 24))} days`}
              </div>
              <div className="flex flex-col items-end">
                <div className="font-medium">{fromUnesToNes(stake.amount).toString()} NES</div>
              </div>
            </div>
          );
        })}
      </div>

      <Button
        className="text-base font-medium"
        color="red"
        disabled={!itemToUnstake}
        isLoading={isUnstaking}
        onClick={handleUnstake}
        size="medium"
      >
        Unstake Asset
      </Button>
    </motion.div>
  );
};

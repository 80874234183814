import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

type Props = {
  description?: string;
  stepNumber: number | string;
  title: string;
} & ClassName;
export const StepHeader = ({ className, description, stepNumber, title }: Props) => {
  return (
    <div className={twMerge('flex w-full items-center gap-3 border-b border-clay-20 px-4 py-3', className)}>
      <div className="flex size-8 items-center justify-center rounded-lg bg-clay-1000 text-sm/none font-semibold text-white">
        {stepNumber}
      </div>

      <div className="flex flex-col gap-0">
        <div className="text-lg font-medium tracking-tight text-clay-1000">{title}</div>
        {description && <div className="text-sm tracking-tight text-clay-500">{description}</div>}
      </div>
    </div>
  );
};

import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { CreateTrainingRequest, Training } from './types';

export const useCreateTrainingMutation = (
  options: UseMutationOptions<Training, unknown, CreateTrainingRequest> = {},
) => {
  return useMutation({
    mutationFn: async (params) => {
      const { data } = await axiosInstance.post<Training>(`/training/create`, params);

      return data;
    },
    ...options,
  });
};

import { useCallback, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useController, useForm } from 'react-hook-form';

import { useLocalStorage } from '@uidotdev/usehooks';
import { ImagePlus, Plus, Search, Trash2 } from 'lucide-react';
import { twMerge } from 'tailwind-merge';
import { v4 as uuidv4 } from 'uuid';

import { useDAIUploadFileMutation } from 'shared/api/dai/useDAIUploadImageMutation';
import { useUsersByNameQuery } from 'shared/api/user/useUsersByNameQuery';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { Label } from 'shared/ui/Label';
import { Modal } from 'shared/ui/Modal';
import { Popover } from 'shared/ui/Popover';
import { Select } from 'shared/ui/Select';
import { Spinner } from 'shared/ui/Spinner';
import { TextArea } from 'shared/ui/TextArea';
import { toaster } from 'shared/ui/Toast';

import type { Member, SocialLinkType, UserPreview } from '../types';

import { socialLinkTypes } from './Description';

type FormValues = {
  about?: string;
  avatar: File | string;
  name: string;
  nesaUser?: UserPreview;
  nesaUserId?: string;
  socialLinks: { href: string; type: SocialLinkType }[];
  title: string;
};

type Props = {
  isOpen: boolean;
  member?: Member;
  onOpenChange: (isOpen: boolean) => void;
  onSuccess?: (member?: Member, edit?: boolean) => void;
};

const urlRegex = /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/;

export const NewMemberModal = ({ isOpen, member, onOpenChange, onSuccess }: Props) => {
  const [, setMembers] = useLocalStorage<Member[]>('dai-members', []);

  const {
    control,
    formState: { isValid, touchedFields },
    handleSubmit,
    register,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      about: member?.about,
      avatar: member?.avatar,
      name: member?.name || '',
      socialLinks: member?.socialLinks || [],
      title: member?.title || '',
    },
  });

  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const triggerRef = useRef<HTMLDivElement>(null);

  const { field: avatarField } = useController({
    control,
    defaultValue: member?.avatar || undefined,
    name: 'avatar',
    rules: { required: true },
  });
  const { field: socialLinksField } = useController({ control, name: 'socialLinks' });
  const { field: nesaUserField } = useController({ control, name: 'nesaUser' });

  const { data, isPending } = useUsersByNameQuery(
    { limit: 4, name: search, skip: 0 },
    { enabled: !!search && search.length > 2 },
  );

  // const { mutateAsync: uploadImage } = useUploadImageMutation();
  const { isPending: isUploadingImage, mutateAsync: uploadImage } = useDAIUploadFileMutation();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    console.log('acceptedFiles', acceptedFiles);
    avatarField.onChange(acceptedFiles[0]);
    // setUploadedFiles(acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getInputProps, getRootProps, isDragActive } = useDropzone({ onDrop });

  const handleFormSubmit = async ({ avatar, ...formValues }: FormValues) => {
    try {
      setIsLoading(true);
      if (!avatar) return;

      let uploadRes = undefined;

      if (typeof avatar !== 'string') {
        uploadRes = await uploadImage({ file: avatar });
        console.log('formValues', formValues);
      }

      setMembers((prev) => {
        return member
          ? prev.map((item) =>
              item.id === member.id
                ? { ...formValues, avatar: uploadRes?.imageUrl || member?.avatar, id: member?.id }
                : item,
            )
          : [...prev, { avatar: uploadRes?.imageUrl, id: uuidv4(), ...formValues }];
      });

      onOpenChange(false);

      reset();
      onSuccess?.(
        member
          ? { ...formValues, avatar: uploadRes?.imageUrl || member?.avatar, id: member?.id }
          : { avatar: uploadRes?.imageUrl, id: uuidv4(), ...formValues },
        !!member,
      );
    } catch (e) {
      toaster.error('Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content>
        <Modal.CloseButton />

        <h1 className="mb-1 text-xl font-semibold">{member ? 'Edit' : 'New'} Member</h1>

        <p className="mb-4 text-sm font-light text-clay-500">
          {member ? 'Edit a member for this team' : 'Add a new member for this team'}
        </p>

        <div className="flex flex-col gap-4">
          <div>
            <Label className="text-xs">
              Profile Image <span className="ml-1 text-red-900">*</span>
            </Label>

            <div className="flex flex-col gap-4">
              <div
                {...getRootProps()}
                className={twMerge(
                  'flex cursor-pointer flex-col rounded-lg border-[1.2px] border-dashed border-clay-40 p-3',
                  'font-normal text-clay-400 transition-all duration-200',
                  isDragActive && 'border-primary-800',
                )}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                      {avatarField.value ? (
                        <img
                          className="size-12 rounded-lg object-cover"
                          src={
                            typeof avatarField.value !== 'string'
                              ? URL.createObjectURL(avatarField.value)
                              : avatarField.value
                          }
                        />
                      ) : (
                        <div className="flex size-12 items-center justify-center rounded-lg bg-clay-10">
                          <ImagePlus className="size-4 text-clay-350" />
                        </div>
                      )}

                      <div className="flex flex-col gap-0.5">
                        <div className="font-medium tracking-tight text-clay-800">
                          Upload DAI profile picture
                        </div>
                        <div className="text-sm font-light text-clay-350">
                          SVG, PNG, JPG or GIF (rec. 500 x 500px)
                        </div>
                      </div>
                    </div>

                    <Button color="white" size="extra-small">
                      Browse
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center gap-4 border-b border-clay-20 pb-6">
            <Input
              label={
                <span>
                  Name <span className="text-red-900">*</span>
                </span>
              }
              {...register('name', { required: true })}
            />
            <Input
              label={
                <span>
                  Title <span className="text-red-900">*</span>
                </span>
              }
              {...register('title', { required: true })}
            />
          </div>

          <div>
            <h2 className="mb-2 text-base font-semibold">Social Links</h2>

            <div className="mb-0 flex flex-col gap-0.5">
              {socialLinksField.value.map((value, idx) => {
                return (
                  <div className="flex items-end gap-4" key={idx}>
                    <Input
                      error={
                        touchedFields.socialLinks && (urlRegex.test(value.href) ? '' : 'Link should be valid')
                      }
                      errorSpacing
                      label={
                        <span className="text-xs/none font-normal text-clay-500 2xl:text-sm">
                          Product Link (beta) <span className="text-red-900">*</span>
                        </span>
                      }
                      onChange={(e) => {
                        socialLinksField.onChange(
                          socialLinksField.value.map((item, i) =>
                            i === idx ? { ...item, href: e.target.value || '' } : item,
                          ),
                        );
                      }}
                      startSlot={
                        <div className="relative -left-2 flex h-full grow items-center justify-center rounded-l-lg bg-clay-10 pl-2 pr-3 text-clay-350">
                          https://
                        </div>
                      }
                      startSlotClassName="m-0 h-full flex items-center justify-center"
                      value={value.href || ''}
                    />

                    <Select
                      className="min-w-32 border border-clay-20 bg-white pl-3"
                      onValueChange={(type) => {
                        socialLinksField.onChange(
                          socialLinksField.value.map((item, i) =>
                            i === idx ? { ...item, type: type } : item,
                          ),
                        );
                      }}
                      value={value.type}
                      wrapperClassName="pb-4"
                    >
                      <Select.Content>
                        {socialLinkTypes.map(({ label, value }) => {
                          return (
                            <Select.Item key={value} value={value}>
                              {label}
                            </Select.Item>
                          );
                        })}
                      </Select.Content>
                    </Select>

                    <Button
                      className="group mb-4 flex h-10 items-center justify-center px-3"
                      color="red"
                      onClick={() => {
                        socialLinksField.onChange(socialLinksField.value.filter((_, i) => i !== idx));
                      }}
                    >
                      <Trash2 className="size-4 " />
                    </Button>
                  </div>
                );
              })}
            </div>

            <Button
              color="secondary"
              onClick={() => {
                socialLinksField.onChange([...socialLinksField.value, { href: '', type: 'discord' }]);
              }}
              size="extra-small"
            >
              <Plus className="size-4" /> Add
            </Button>
          </div>

          <TextArea
            innerClassName="!text-sm"
            {...register('about')}
            label="About"
            labelClassName="text-xs text-clay-500"
          />

          <div>
            <Popover open={search.length > 2 && !nesaUserField.value}>
              <Popover.Trigger className="w-full outline-none">
                <div className="w-full" ref={triggerRef}>
                  <Input
                    className="w-full outline-none"
                    disabled={!!nesaUserField.value}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Link existing Nesa User"
                    startSlot={<Search className="size-4 text-clay-350" />}
                    value={search}
                  />
                </div>
              </Popover.Trigger>

              <Popover.Content
                className="w-full min-w-[var(--radix-popover-trigger-width)] px-1"
                onOpenAutoFocus={(e) => e.preventDefault()}
              >
                {isPending ? (
                  <div className="flex items-center justify-center py-4">
                    <Spinner className="size-4" />
                  </div>
                ) : (data?.list || []).length > 0 ? (
                  <div className="flex flex-col gap-1 overflow-scroll py-0">
                    {data?.list.map((user) => {
                      return (
                        <div
                          className="flex cursor-pointer items-center gap-3 rounded-lg px-2 py-1.5 text-sm font-medium transition-colors hover:bg-clay-10"
                          key={user._id}
                          onClick={() => {
                            nesaUserField.onChange(user);
                            setSearch('');
                          }}
                        >
                          <div className="size-8 rounded-full bg-clay-20"></div>
                          {user.first_name || ''} {user.last_name || ''} {user.email ? `(${user.email})` : ''}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="flex items-center justify-center py-4 text-center text-sm font-light text-clay-500">
                    No matches found
                  </div>
                )}
              </Popover.Content>
            </Popover>

            {nesaUserField.value?._id && (
              <div className="mt-3 flex items-center justify-between">
                <div className="flex items-center gap-3 text-sm">
                  <div className="size-8 rounded-full bg-clay-20"></div>
                  {nesaUserField.value.first_name || ''} {nesaUserField.value.last_name || ''}
                </div>

                <Button color="red" onClick={() => nesaUserField.onChange(null)} size="extra-small">
                  Unlink
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className="mt-6 flex items-center gap-3">
          <Button color="secondary" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>

          <Button
            disabled={!isValid}
            isLoading={isLoading || isUploadingImage}
            onClick={handleSubmit(handleFormSubmit)}
          >
            {member ? 'Edit' : 'Create'}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

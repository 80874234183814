import { Link } from 'react-router-dom';

import { Icon } from 'shared/ui/Icon';

type Props = {
  category: string;
  description: string;
  rows: string[][];
  title: string;
};

const columns = ['Name', 'input price ($)', 'output price ($)'];

export const Table = ({ category, description, rows, title }: Props) => {
  return (
    <div className="col-span-3 row-span-5 grid grid-cols-subgrid grid-rows-subgrid gap-0 overflow-hidden rounded-xl bg-white pt-5">
      <header className="col-span-3 mb-3 px-6">
        <div className="flex items-center gap-2">
          <span className="text-2xl/7 font-semibold text-clay-900">{title}</span>
          {category && (
            <Link
              className="size-6 cursor-pointer text-clay-900 duration-200 hover:text-primary-800"
              to={`/?type=${category}`}
            >
              <Icon className="size-6" name="arrowUpRight" safeArea="0" />
            </Link>
          )}
        </div>
        <p className="col-span-3 mt-1 text-sm/5 font-normal tracking-[-.02rem] text-clay-500">
          {description}
        </p>
      </header>
      <div className="relative col-span-3 grid grid-cols-subgrid">
        <div className="absolute inset-0 -top-2 bg-table-header-radial" />
        {columns.map((text, i) => (
          <div
            className="relative col-span-1 border-b border-r border-clay-20 px-6 py-2.5 text-sm font-semibold last-of-type:border-r-0"
            key={i}
          >
            {text}
          </div>
        ))}
      </div>
      {rows.map((cells, i) => (
        <div className="group/tr contents" key={i}>
          {cells.map((text, i) => (
            <div
              className="col-span-1 flex h-16 items-center overflow-hidden border-b border-r border-clay-20 bg-white px-6 py-1 text-sm text-clay-900 first-of-type:font-medium last-of-type:border-r-0 group-last-of-type/tr:border-b-0"
              key={i}
            >
              {text}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

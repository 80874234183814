import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { GetQueryHistoryByIdParams, QueryHistory } from './types';

import { queryHistoryKeys } from './queryKeys';

export const useGetQueryHistoryByIdQuery = (
  params: GetQueryHistoryByIdParams,
  options: UseQueryOptions<QueryHistory>,
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { id, ...remainingParams } = params;
      const { data } = await axiosInstance.get<QueryHistory>(`/query-history/${id}`, {
        params: remainingParams,
        signal,
      });

      return data;
    },
    queryKey: queryHistoryKeys.byId(params),
    ...options,
  });
};

import { CATEGORY_LABEL_MAP } from '../const';

export const getCategoryLabel = (category: string) => {
  if (!category) {
    return '';
  }

  return (
    CATEGORY_LABEL_MAP[category as keyof typeof CATEGORY_LABEL_MAP]?.title ||
    category
      .split('-')
      .reduce((acc, str, i) => `${acc}${i > 0 ? ' ' : ''}${str.charAt(0).toUpperCase()}${str.slice(1)}\n`, '')
  );
};

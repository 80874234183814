type Props = {
  currentPage: number;
  onPageChange: (page: number) => void;
  totalPages: number;
};

const drawPages = 4;

export const Pagination = ({ currentPage, onPageChange, totalPages }: Props) => {
  // const startPage = currentPage === 1 ? 1 : currentPage === totalPages ? totalPages - 2 : currentPage - 1;

  const pages = Array.from({
    length: Math.min(
      totalPages === 7 ? 5 : totalPages > drawPages ? drawPages : totalPages - 2,
      totalPages === 7
        ? 5
        : totalPages <= drawPages + 1 ||
            (currentPage - drawPages >= 0 && totalPages - currentPage >= drawPages)
          ? 3
          : drawPages,
    ),
  })
    .fill(null)
    .map((_, idx) => {
      let page = currentPage - 1 + idx;
      if (currentPage < drawPages) {
        page = 2 + idx;
      }
      if (totalPages - currentPage < drawPages) {
        page = totalPages - drawPages + idx;
      }

      if (totalPages <= drawPages + 1 || totalPages === 7) {
        page = 2 + idx;
      }

      return page;
    });

  return (
    <div className="flex items-center gap-1.5">
      <button
        className="h-7 px-3 py-1 text-sm/none font-medium text-clay-900 outline-none duration-200 hover:text-primary-800 disabled:text-clay-300"
        disabled={currentPage <= 1}
        onClick={() => onPageChange(currentPage - 1)}
      >
        Previous
      </button>

      <Page currentPage={currentPage} onPageChange={onPageChange} page={1} />

      {pages.length > 2 && pages[0] && pages[0] > 2 && <Dots />}

      {pages.map((page) => (
        <Page currentPage={currentPage} key={page} onPageChange={onPageChange} page={page} />
      ))}

      {pages.length > 2 && pages[pages.length - 1] < totalPages - 1 && <Dots />}

      {totalPages > 1 && <Page currentPage={currentPage} onPageChange={onPageChange} page={totalPages} />}

      <button
        className="h-7 px-3 py-1 text-sm/none font-medium text-clay-900 outline-none duration-200 hover:text-primary-800 disabled:text-clay-300"
        disabled={currentPage >= totalPages}
        onClick={() => onPageChange(currentPage + 1)}
      >
        Next
      </button>
    </div>
  );
};

type PageProps = {
  currentPage: number;
  onPageChange: (page: number) => void;
  page: number;
};

const Dots = () => {
  return (
    <div className="h-7 w-9 border border-transparent text-center">
      <span className="text-sm font-semibold text-clay-500">...</span>
    </div>
  );
};

const Page = ({ currentPage, onPageChange, page }: PageProps) => {
  return (
    <button
      aria-selected={currentPage === page}
      className="flex h-7 min-w-7 cursor-pointer items-center justify-center rounded border border-clay-20 px-0.5 text-clay-500 aria-selected:bg-clay-20 hover:text-primary-800"
      onClick={() => onPageChange(page)}
    >
      <span className="text-sm font-semibold">{page}</span>
    </button>
  );
};

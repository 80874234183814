import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { DatasetsResponse, GetDatasetsParams } from './types';

import { queryKeys } from './queryKeys';
// sizeNumberToString(val)
// or:(size_categories:n<1K,size_categories:1K<n<10K,size_categories:10K<n<100K,size_categories:100K<n<1M,size_categories:1M<n<10M,size_categories:10M<n<100M,size_categories:100M<n<1B,size_categories:1B<n<10B,size_categories:10B<n<100B,size_categories:100B<n<1T)
// or(size_categories:n<1K,size_categories:1K<n<10K,size_categories:10K<n<100K,size_categories:100K<n<1M,size_categories:1M<n<10M,size_categories:100M<n<1B,size_categories:1B<n<10B,size_categories:10B<n<100B,size_categories:100B<n<1T,size_categories:n>1T)
// const sizeStringToNumber = (val: string) => {
//   if (val === '<1K') return 0;
//   if (val === '1K') return 1;
//   if (val === '10K') return 2;
//   if (val === '100K') return 3;
//   if (val === '1M') return 4;
//   if (val === '10M') return 5;
//   if (val === '100M') return 6;
//   if (val === '1B') return 7;
//   if (val === '10B') return 8;
//   if (val === '100B') return 9;
//   if (val === '1T') return 10;
//   if (val === '>1T') return 11;
// };

const possibleSizes = ['<1K', '1K', '10K', '100K', '1M', '10M', '100M', '1B', '10B', '1T', '>1T'];
export const sizesCheckMap = {
  '<1K': 'n<1K',
  '>1T': 'n>1T',
  '1B': '1B<n<10B',
  '1K': '1K<n<10K',
  '1M': '1M<n<10M',
  '1T': '100B<n<1T',
  '10B': '10B<n<100B',
  '10K': '10K<n<100K',
  '10M': '10M<n<100M',
  '100B': '100B<n<1T',
  '100K': '100K<n<1M',
  '100M': '100M<n<1B',
};

export const sizesCheckMapRevert = {
  '1B<n<10B': '1B',
  '1K<n<10K': '1K',
  '1M<n<10M': '1M',
  '10B<n<100B': '10B',
  '10K<n<100K': '10K',
  '10M<n<100M': '10M',
  '100B<n<1T': '1T',
  '100K<n<1M': '100K',
  '100M<n<1B': '100M',
  'n<1K': '<1K',
  'n>1T': '>1T',
};

const getSizeCategoriesApi = (maxSize: string, minSize?: string) => {
  if (minSize) return '';

  const endIdx = possibleSizes.findIndex((i) => i === maxSize);

  if (endIdx === possibleSizes.length - 1) return undefined;

  const itemsToInclude = possibleSizes.slice(0, endIdx + 1);
  const res = itemsToInclude.reduce<string[]>((acc, val) => {
    if (!sizesCheckMap[val as keyof typeof sizesCheckMap]) return acc;
    acc.push(sizesCheckMap[val as keyof typeof sizesCheckMap]);

    return acc;
  }, []);

  return res;
};

export const getSizeCategories = (maxSize: string, minSize?: string) => {
  if (minSize) return '';

  const endIdx = possibleSizes.findIndex((i) => i === maxSize);

  if (endIdx === possibleSizes.length - 1) return undefined;

  const res = getSizeCategoriesApi(maxSize, minSize);

  if (!res) return res;

  return `or:(${res.join(',')})`;
};

export const useGetDatasetsQuery = (
  params: GetDatasetsParams,
  options: UseQueryOptions<DatasetsResponse> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { format, library, modality, size, tasks, ...remaining } = params;
      const { data } = await axiosInstance.get('/datasets/list', {
        params: {
          format: format && format?.length > 0 ? format : undefined,
          library: library && library?.length > 0 ? library : undefined,
          modality: modality && modality?.length > 0 ? modality : undefined,
          size_categories: (size?.[0] && getSizeCategoriesApi(size[0])) || undefined,
          task_categories:
            tasks && tasks?.length > 0 ? tasks.map((el) => el.replace('task_categories:', '')) : undefined,
          ...remaining,
        },
        signal,
        timeout: Number.POSITIVE_INFINITY,
      });

      return data;
    },
    queryKey: queryKeys.json(params),
    ...options,
  });

  // return useInfiniteQuery({
  //   getNextPageParam: (last: any[] = [], all: any[][]) => (last.length === 30 ? all.length : null),
  //   initialPageParam: 0,
  //   queryFn: async ({ pageParam = 0, signal }) => {
  //     const res = await axios.get(`https://datasets.nesa.ai/datasets-json`, { params, signal });

  //     return res.data;
  //   },
  //   queryKey: queryKeys.json(params),
  //   ...(options || {}),
  // });
};

import { useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { ArrowUpRight } from 'lucide-react';

type Props = {
  avatar?: string;
  name: string;
  onClick?: () => void;
};

export const TeamMember = ({ avatar, name, onClick }: Props) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className="flex cursor-pointer flex-col items-center justify-center gap-1.5"
      key={name}
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className="relative size-14 overflow-hidden rounded-full bg-clay-20">
        {avatar && <img className="size-full object-cover" src={avatar} />}
        <AnimatePresence>
          {isHover && (
            <motion.div
              animate={{ opacity: 1 }}
              className="absolute left-1/2 top-1/2 flex size-12 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-white/80"
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
            >
              <ArrowUpRight className="size-4" />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="text-center text-base font-medium">{name}</div>
    </div>
  );
};

import type { IconName } from '../ui/Icon';

export const CATEGORY_LABEL_MAP: Record<
  string,
  { description?: string; directions?: string; title: string }
> = {
  all: {
    description: '',
    directions: '',
    title: 'All Models',
  },
  'audio-classification': {
    description: 'Categorizing audio signals into predefined classes or labels.',
    directions: 'Upload audio to identify and categorize sounds.',
    title: 'Audio classification',
  },
  'automatic-speech-recognition': {
    description: 'Transcribing spoken language into text.',
    directions: 'Provide audio to convert spoken words into text.',
    title: 'Automatic Speech Rec',
  },
  'depth-estimation': {
    description: 'Predicting depth to build 3D scenes from images.',
    directions: 'Provide an image to estimate depth of different objects.',
    title: 'Depth\nEstimation',
  },
  'feature-extractor': {
    description: 'Extracting high-level features from diverse data types.',
    directions: 'Provide an image to extract embeddings for downstream tasks.',
    title: 'Feature\nExtraction',
  },
  'image-classification': {
    description: 'Categorizing and identifying objects or scenes in images.',
    directions: 'Provide an image to identify and classify its contents.',
    title: 'Image\nClassification',
  },
  'image-generation': {
    description: 'Generating images from textual descriptions to visualize concepts and ideas.',
    directions: 'Enter a text prompt to generate an image.',
    title: 'Image\nGeneration',
  },
  'image-segmentation': {
    description: 'Segmenting images into meaningful components.',
    directions: 'Provide an image to segment it into distinct regions.',
    title: 'Image\nSegmentation',
  },
  'instance-segmentation': { description: '', title: 'Instance\nSegmentation' },
  'metabolic-modeling': {
    description: 'Simulating metabolic pathways to predict biochemical behaviors in cells or organisms.',
    directions: 'Upload single-cell gene expressions to predict metabolic fluxes.',
    title: 'Metabolic modeling',
  },
  'named-entity-recognition': {
    description: 'Identifying and classifying key entities like names, organizations, and locations in text.',
    directions: 'Provide text to detect and classify named entities.',
    title: 'Named Entity Rec',
  },
  'object-detection': {
    description: 'Detecting and localizing objects in images.',
    directions: 'Provide an image or video for object identification and localization.',
    title: 'Object\nDetection',
  },
  ocr: {
    description: 'Extracting text from images and documents.',
    directions: 'Provide an image containing text for context extraction.',
    title: 'Optical Character Rec',
  },

  'panoptic-segmentation': { description: '', title: 'Panoptic\nSegmentation' },
  'question-answering': {
    description: 'Answering queries with contextual understanding.',
    directions: 'Input both the passage and the question to extract the answer.',
    title: 'Question\nAnswering',
  },
  'sentence-similarity': {
    description: 'Measuring the similarity between sentences based on semantic content.',
    directions: 'Input two sentences to evaluate their semantic similarity.',
    title: 'Sentence similarity',
  },
  'sentiment-analysis': {
    description: 'Determining the emotional tone or polarity expressed in text.',
    directions: 'Enter text to analyze and classify its sentiment.',
    title: 'Sentiment analysis',
  },
  'spatial-transcriptomics': {
    description: 'Analyzing gene expression to understand cellular organization and function.',
    directions: 'Upload RNA-seq from tissue samples for spatial domain identification. ',
    title: 'Spatial transcriptomics',
  },
  'speech-to-text': {
    description: 'Translating spoken words into text, often in real-time applications.',
    directions: 'Upload audio to transcribe spoken words into text.',
    title: 'Speech to Text',
  },
  'structural-bioinformatics': {
    description:
      'Studying biological macromolecular structures to elucidate their function and interactions.',
    directions: 'Upload an amino acid sequence to model 3D protein structures.  ',
    title: 'Structural Bioinformatics',
  },
  summarization: {
    description: 'Creating concise summaries of longer texts.',
    directions: 'Provide a body of text to generate a concise summary.',
    title: 'Content\nSummarization',
  },
  'super-resolution': {
    description:
      'Enhancing the resolution of images to produce high quality visuals from low-resolution inputs.',
    directions: 'Upload a low-resolution image to sharpen and upscale.',
    title: 'Super resolution',
  },
  'system-biology': {
    description:
      'Integrating complex biological data to model systemic interactions within biological systems.',
    directions: 'Upload single-cell gene expressions to predict cellular responses.',
    title: 'Systems biology',
  },
  'text-classification': {
    description: 'Categorizing text for tasks like sentiment or topic classification.',
    directions: 'Enter text to classify into predefined categories.',
    title: 'Text\nClassification',
  },
  'text-generation': {
    description: 'Generating coherent and relevant text across domains.',
    directions: 'Enter a text prompt to generate contextual responses.',
    title: 'Text\nGeneration',
  },
  'text-to-speech': {
    description: 'Converting written text into natural sounding spoken words using AI.',
    directions: 'Input text to synthesize as spoken audio.  ',
    title: 'Text to Speech',
  },
  'text-to-video': {
    description: 'Generating video content from textual descriptions using advanced AI models.',
    directions: 'Enter a text prompt to generate a video.',
    title: 'Text to Video',
  },
  'time-series-forecasting': {
    description: 'Predicting future data points in a sequence based on historical time-dependent data.',
    directions: 'Upload multivariate tabular data to forecast future values.',
    title: 'Time Series Forecasting',
  },
  'token-classification': {
    description: 'Labeling words and tokens for tasks like named entity recognition.',
    title: 'Token\nClassification',
  },
  translation: {
    description: 'Translating text across languages while maintaining meaning.',
    directions: 'Provide text to be translated into a specified language.',
    title: 'Language\nTranslation',
  },
  'unconditional-image-generation': {
    description: 'Generating new images from scratch without any conditional input using generative models.',
    directions: 'Click to automatically generate images without specific constraints.',
    title: 'Unconditional Image Gen',
  },
};

export const filterItems: {
  icon: IconName;
  value: string;
}[] = [
  { icon: 'box', value: 'all' },
  { icon: 'textGeneration', value: 'text-generation' },
  { icon: 'imageGeneration', value: 'image-generation' },
  { icon: 'videoGeneration', value: 'text-to-video' },
  { icon: 'text2Speech', value: 'text-to-speech' },
  { icon: 'qa', value: 'question-answering' },
  { icon: 'language', value: 'translation' },
  { icon: 'imageSegmentation', value: 'image-segmentation' },
  { icon: 'autoSpeech', value: 'automatic-speech-recognition' },
  { icon: 'textClassification', value: 'text-classification' },
  { icon: 'speech2Text', value: 'speech-to-text' },
  { icon: 'audioClassification', value: 'audio-classification' },
  { icon: 'sentenceSimilarity', value: 'sentence-similarity' },
  { icon: 'contentSummarization', value: 'summarization' },
  { icon: 'namedEntRec', value: 'named-entity-recognition' },
  { icon: 'objDetect', value: 'object-detection' },
  { icon: 'ocr', value: 'ocr' },
  { icon: 'imageClassification', value: 'image-classification' },
  { icon: 'metMod', value: 'metabolic-modeling' },
  { icon: 'systemsBio', value: 'system-biology' },
  { icon: 'spatialTrans', value: 'spatial-transcriptomics' },
  { icon: 'structBio', value: 'structural-bioinformatics' },
  { icon: 'unconImgGen', value: 'unconditional-image-generation' },
  { icon: 'depthEst', value: 'depth-estimation' },
  { icon: 'featureExtr', value: 'feature-extractor' },
  { icon: 'timeSeries', value: 'time-series-forecasting' },
  { icon: 'superRes', value: 'super-resolution' },
  { icon: 'centralized', value: 'sentiment-analysis' },
];

import { useInfiniteQuery } from '@tanstack/react-query';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { DAI } from './types';

import { daiKeys } from './queryKeys';

type Response = {
  limit: number;
  list: DAI[];
  skip: number;
  totalCount: number;
};

type Params = {
  category?: string;
  limit: number;
  search?: string;
  // skip: number;
  sort?: 'newest' | 'oldest';
  tags?: string[];
};

export const useDAIListInfiniteQuery = (
  { limit, ...params }: Params,
  options: { enabled?: boolean } = {},
) => {
  return useInfiniteQuery({
    getNextPageParam: (last: DAI[] = [], all: DAI[][]) => (last.length === limit ? all.length : null),
    initialPageParam: 0,
    queryFn: async ({ pageParam = 0, signal }) => {
      const res = await axiosInstance.get<Response>(`/dai/list`, {
        params: { limit, skip: pageParam * limit, ...params },
        signal,
      });

      return res.data.list;
    },
    queryKey: daiKeys.infiniteList({ limit, ...params }),
    ...(options || {}),
  });
};

import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { CATEGORY_LABEL_MAP, filterItems } from 'shared/const';

import audioClassificationSrc from './assets/audio-classification.svg';
import autoSpeechSrc from './assets/auto-speech.svg';
import centralizedSrc from './assets/centralized.svg';
import contentSummarizationSrc from './assets/content-sum.svg';
import depthSrc from './assets/depth-estimation.svg';
import featureExtractionSrc from './assets/feature-extraction.svg';
import imageClassificationSrc from './assets/image-classification.svg';
import imageGenSrc from './assets/image-gen.svg';
import imageSegmentationSrc from './assets/image-segmentation.svg';
import metModSrc from './assets/met-mod.svg';
import nerSrc from './assets/ner.svg';
import objDetectionSrc from './assets/obj-detection.svg';
import ocrSrc from './assets/ocr.svg';
import qaSrc from './assets/qa.svg';
import sentenceSimilaritySrc from './assets/sentence-sim.svg';
import spacialTransSrc from './assets/spatial-trans.svg';
import speechToTextSrc from './assets/speech-to-text.svg';
import structBioSrc from './assets/struct-bio.svg';
import superResSrc from './assets/super-res.svg';
import systemBioSrc from './assets/system-bio.svg';
import textClassificationSrc from './assets/text-classification.svg';
import textGenSrc from './assets/text-gen.svg';
import timeSeriesForecastSrc from './assets/time-series.svg';
import translationSrc from './assets/translation.svg';
import ttsSrc from './assets/tts.svg';
import uncondImageGenSrc from './assets/uncond-image-gen.svg';
import vidGenSrc from './assets/vid-gen.svg';

const gradient1 = 'bg-[#FFFFDB]'; //'bg-gradient-to-tr from-turquoise-45 from-35% to-blue-40 to-95%';
// const gradient2 = 'from-yellow-40 to-yellow-50 bg-gradient-to-tr from-35% to-95%';
// const gradient3 = 'from-random-linen to-random-dutchWhite bg-gradient-to-tr from-35% to-95%';
// const gradient4 = 'from-random-feta to-random-blueChalk bg-gradient-to-tr from-35% to-95%';
// const gradient5 = 'bg-gradient-to-tr from-pink-40 from-35% to-pink-50 to-95%';
// const gradient6 = 'from-random-amour to-random-pearl bg-gradient-to-tr from-35% to-95%';
// const gradient7 = 'from-random-aliceBlue to-random-hawkesBlue bg-gradient-to-tr from-35% to-95%';

type Props = { type: string } & ClassName;

const icons = {
  'audio-classification': audioClassificationSrc,
  'automatic-speech-recognition': autoSpeechSrc,
  'depth-estimation': depthSrc,
  'feature-extractor': featureExtractionSrc,
  'image-classification': imageClassificationSrc,
  'image-generation': imageGenSrc,
  'image-segmentation': imageSegmentationSrc,
  'metabolic-modeling': metModSrc,
  'named-entity-recognition': nerSrc,
  'object-detection': objDetectionSrc,
  ocr: ocrSrc,
  'question-answering': qaSrc,
  'sentence-similarity': sentenceSimilaritySrc,
  'sentiment-analysis': centralizedSrc,
  'spatial-transcriptomics': spacialTransSrc,
  'speech-to-text': speechToTextSrc,
  'structural-bioinformatics': structBioSrc,
  summarization: contentSummarizationSrc,
  'super-resolution': superResSrc,
  'system-biology': systemBioSrc,
  'text-classification': textClassificationSrc,
  'text-generation': textGenSrc,
  'text-to-speech': ttsSrc,
  'text-to-video': vidGenSrc,
  'time-series-forecasting': timeSeriesForecastSrc,
  translation: translationSrc,
  'unconditional-image-generation': uncondImageGenSrc,
};

export const PreviewBanner = ({ className, type }: Props) => {
  const data = filterItems.find(({ value }) => value === type);
  const descriptions = CATEGORY_LABEL_MAP[type as keyof typeof CATEGORY_LABEL_MAP];

  if (!data || !descriptions) return;

  return (
    <motion.div
      animate={{ opacity: 1 }}
      className={twMerge(
        'relative flex flex-col justify-between gap-4 rounded-xl p-7',
        gradient1,
        type === 'text-generation' && 'bg-[#FFFFDB]',
        type === 'image-generation' && 'bg-[#EAFFEA]',
        type === 'text-to-speech' && 'bg-[#F5FFFF]',
        type === 'question-answering' && 'bg-[#FFF0FF]',
        type === 'translation' && 'bg-[#FFFDF5]',
        type === 'image-segmentation' && 'bg-[#F5E7D7]',
        type === 'automatic-speech-recognition' && 'bg-[#FFEDED]',
        type === 'text-classification' && 'bg-[#F7F7F7]',
        type === 'speech-to-text' && 'bg-transparent',
        type === 'audio-classification' && 'bg-[#DCDEFF]',
        type === 'sentence-similarity' && 'bg-[#FFFFDB]',
        type === 'summarization' && 'bg-[#EAFFEA]',
        type === 'named-entity-recognition' && 'bg-[#E8FFFF]',
        type === 'object-detection' && 'bg-[#F5FFFF]',
        type === 'ocr' && 'bg-[#FFF0FF]',
        type === 'image-classification' && 'bg-[#FFFDF5]',
        type === 'metabolic-modeling' && 'bg-[#F5E7D7]',
        type === 'system-biology' && 'bg-[#FFEDED]',
        type === 'spatial-transcriptomics' && 'bg-[#F7F7F7]',
        type === 'structural-bioinformatics' && 'bg-transparent',
        type === 'unconditional-image-generation' && 'bg-[#DCDEFF]',
        type === 'depth-estimation' && 'bg-[#FFFFDB]',
        type === 'feature-extractor' && 'bg-[#EAFFEA]',
        type === 'time-series-forecasting' && 'bg-[#E8FFFF]',
        type === 'super-resolution' && 'bg-[#F5FFFF]',
        type === 'sentiment-analysis' && 'bg-[#FFF0FF]',
        type === 'text-to-video' && 'bg-[#F5FFFF]',

        className,
      )}
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
    >
      <div className="mr-5 flex flex-col pr-28 sm:pr-32 lg:pr-36">
        <h1 className="mb-1 text-3xl font-semibold tracking-tighter md:text-[2.5rem] md:leading-10">
          {descriptions.title}
        </h1>
        <div className="mb-4 text-balance text-base font-light text-clay-400 md:text-xl">
          {descriptions.description || ''}
        </div>
      </div>
      {descriptions.directions && (
        <div className="mt-auto flex flex-col items-start gap-1 xs:flex-row xs:items-center xs:gap-3">
          <div className="rounded-full border border-clay-100 bg-white px-3 py-1.5 text-sm/4 font-semibold text-clay-900 shadow-checkbox xs:px-[1.125rem] xs:py-2 md:text-base/5">
            Directions
          </div>
          <div className="text-sm font-semibold text-clay-900 md:text-base">
            {descriptions.directions || ''}
          </div>
        </div>
      )}

      {icons[type as keyof typeof icons] && (
        <div className="absolute right-7 top-7 lg:right-9 lg:top-9">
          <img
            className="-mt-6 size-28 min-w-28 sm:size-32 sm:min-w-32 lg:size-36 lg:min-w-36"
            src={icons[type as keyof typeof icons]}
          />
        </div>
      )}
    </motion.div>
  );
};

import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { TrainingByUserResponse } from './types';

import { trainingQueryKeys } from './queryKeys';

export const useGetTrainingByUserQuery = (options: UseQueryOptions<TrainingByUserResponse> = {}) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get<TrainingByUserResponse>(`/training/by-user`, {
        signal,
      });

      return data;
    },
    queryKey: trainingQueryKeys.byUser(),
    ...options,
  });
};

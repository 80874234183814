import { useRef, useState } from 'react';

import { useLocalStorage } from '@uidotdev/usehooks';

import type { UploadedModel } from 'features/UploadModelModal/UploadModelModal';

import { useUser } from 'app/stores/user';
import { UploadModelModal } from 'features/UploadModelModal';
import { useGetUserModelListQuery } from 'shared/api/models/useGetUserModelListQuery';
import { Button } from 'shared/ui/Button';
import { Gallery } from 'shared/ui/Gallery';
import { Spinner } from 'shared/ui/Spinner';

import { UploadingModelCardWrapper } from './UploadingCards';
import emptyImgSrc from './assets/empty-model.svg';

// const models: Model[] = [];
type Tag = 'Language Transition' | 'Mock tag';
export type UploadingModel = {
  name: string;
  status: string;
  step: number;
  tags: Tag[];
};
// const uploadingModels: UploadingModel[] = [
//   {
//     name: 'Gpt-3.5-turbo-0125',
//     status: 'Processing...',
//     step: 2,
//     tags: [
//       'Language Transition',
//       'Language Transition',
//       'Language Transition',
//       'Language Transition',
//       'Language Transition',
//     ],
//   },
//   { name: 'Gpt-3.5-turbo-0126', status: 'Finishing', step: 4, tags: ['Mock tag'] },
//   { name: 'Gpt-3.5-turbo-0126', status: 'Finishing', step: 4, tags: ['Mock tag'] },
//   { name: 'Gpt-3.5-turbo-0126', status: 'Finishing', step: 4, tags: ['Mock tag'] },
//   { name: 'Gpt-3.5-turbo-0126', status: 'Finishing', step: 4, tags: ['Mock tag'] },
//   { name: 'Gpt-3.5-turbo-0126', status: 'Finishing', step: 4, tags: ['Mock tag'] },
//   { name: 'Gpt-3.5-turbo-0126', status: 'Finishing', step: 4, tags: ['Mock tag'] },
//   { name: 'Gpt-3.5-turbo-0126', status: 'Finishing', step: 4, tags: ['Mock tag'] },
//   { name: 'Gpt-3.5-turbo-0126', status: 'Finishing', step: 4, tags: ['Mock tag'] },
//   { name: 'Gpt-3.5-turbo-0126', status: 'Finishing', step: 4, tags: ['Mock tag'] },
//   { name: 'Gpt-3.5-turbo-0126', status: 'Finishing', step: 4, tags: ['Mock tag'] },
//   { name: 'Gpt-3.5-turbo-0126', status: 'Finishing', step: 4, tags: ['Mock tag'] },
// ];
const uploadingModels: UploadingModel[] = [];
export const UserModelList = () => {
  const { user } = useUser();
  // const navigate = useNavigate();
  // const [filters] = useState<Omit<UserModelListRequestParams, 'userId'>>({ limit: 10, skip: 0 });
  // const { data: stats } = useGetModelStatistics({ ids: modelIds }, { enabled: modelIds.length > 0 });
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [uploadedModels] = useLocalStorage<UploadedModel[]>('uploaded-models', []);

  const { data: uploadedModesItems, isPending } = useGetUserModelListQuery(
    { limit: 1000, userId: user?._id || '' },
    { enabled: !!user?._id },
  );

  console.log('uploadedModesItems', uploadedModesItems);
  // const flatModelList = data?.pages.flat() || [];
  // const modelIds = flatModelList.map(({ _id }) => _id);
  const isUploadingPending = false;
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  if (!uploadingModels.length && !uploadedModels.length) {
    return (
      <div className="my-auto flex flex-col items-center justify-center gap-1.5 py-10 text-center">
        <img alt="img" className="mb-2 h-20 w-auto" src={emptyImgSrc} />

        <h1 className="text-center text-2xl ">No models uploaded yet</h1>
        <div className="mb-4 text-center text-base font-light text-clay-500">
          Your models that you have uploaded <br />
          to Nesa will appear here.
        </div>

        <Button onClick={() => setIsUploadOpen(true)}>Upload model</Button>

        <UploadModelModal isOpen={isUploadOpen} onOpenChange={setIsUploadOpen} />
      </div>
    );
  }

  return (
    <div className="flex flex-1 flex-col gap-6">
      <div>
        {isUploadingPending ? (
          <div className="flex w-full flex-col items-center justify-between py-14">
            <Spinner className="size-8" />
          </div>
        ) : uploadingModels?.length > 0 ? (
          <>
            <p className="mb-2">Uploading</p>
            <UploadingModelCardWrapper models={uploadingModels} />
          </>
        ) : null}
      </div>
      <div>
        {isPending ? (
          <div className="flex w-full flex-col items-center justify-between py-14">
            <Spinner className="size-8" />
          </div>
        ) : uploadedModels.length > 0 ? (
          <>
            <p className="mb-2">Uploaded Models</p>
            <Gallery
              fetchNextPage={() => void 0}
              hasNextPage={false}
              isFetchingNextPage={false}
              isInContainer={false}
              isPending={isPending}
              layout
              // @ts-expect-error mocked
              modelList={uploadedModels}
              scrollContainerRef={scrollContainerRef}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

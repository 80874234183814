import type { PropsWithChildren, ReactNode } from 'react';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { twMerge } from 'tailwind-merge';

type Props = {
  content?: ReactNode;
  header?: ReactNode;
  type?: 'dark' | 'light';
} & Omit<TooltipPrimitive.TooltipContentProps, 'content'> &
  Omit<TooltipPrimitive.TooltipProps, 'content'>;

export const Tooltip = ({
  children,
  className,
  content,
  defaultOpen,
  delayDuration = 500,
  disableHoverableContent,
  header,
  onOpenChange,
  open,
  type = 'dark',
  ...props
}: PropsWithChildren<Props>) => {
  if (!content) return children;

  return (
    <TooltipPrimitive.Root
      defaultOpen={defaultOpen}
      delayDuration={delayDuration}
      disableHoverableContent={disableHoverableContent}
      onOpenChange={onOpenChange}
      open={open}
    >
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal>
        <>
          <TooltipPrimitive.Content
            align="center"
            alignOffset={4}
            collisionPadding={4}
            side="right"
            sideOffset={4}
            {...props}
          >
            {header && (
              <div
                className={twMerge(
                  'z-50 rounded-t-lg bg-[#475166] px-2 py-0.5 text-sm font-medium shadow-sm',
                  type === 'dark' && 'px-3 py-1.5 text-clay-100',
                  className,
                )}
              >
                {header}
              </div>
            )}
            <div
              className={twMerge(
                'z-50 rounded-lg bg-clay-20 px-2 py-0.5 text-sm font-normal text-clay-800 shadow-sm',
                type === 'dark' && 'bg-clay-800 px-3 py-1.5 text-clay-100',
                header && 'rounded-t-none',
                className,
              )}
            >
              {content}
            </div>
            {type === 'dark' && <TooltipPrimitive.Arrow className="fill-clay-800" height={5} width={11} />}
          </TooltipPrimitive.Content>
        </>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  );
};

Tooltip.Provider = TooltipPrimitive.Provider;

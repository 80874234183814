import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useDebounce } from '@uidotdev/usehooks';
import { Wrench } from 'lucide-react';
import { twJoin } from 'tailwind-merge';

import { PageIsInAlphaModal } from 'features/PageIsInAlphaModal';
import { UpgradeToProModal } from 'features/UpgradeToProModal';
import { githubRegexp } from 'features/UploadModelModal/ui/GithubLink';
import { useGetModelByIdQuery } from 'shared/api/models/useGetModelByIdQuery';
import { useModelListQuery } from 'shared/api/models/useGetModelListQuery';
import { useGetModelsByNamesQuery } from 'shared/api/models/useGetModelsByNamesQuery';
import { trendingTextModelNames } from 'shared/const';
import { getCategoryLabel } from 'shared/helpers/getCategoryLabel';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Popover } from 'shared/ui/Popover';
import { Spinner } from 'shared/ui/Spinner';

import { CreateTrainingModal } from '../CreateTrainingModal';
import TrainedCardIcon1 from './ui/1.svg?react';
import TrainedCardIcon2 from './ui/2.svg?react';
import TrainedCardIcon3 from './ui/3.svg?react';
import { TrainCard } from './ui/TrainCard';

const mustRecently = [
  { id: '66ffbb2f43eb873115c9b26e', title: 'Llama-3.2-1B-Instruct' },
  { id: '66b7685cc55f370092aa7e6a', title: 'sdxl-flash' },
  { id: '663a05612c30d64ab6044c0a', title: 'Llama-3-8B-Lexi-Uncensored' },
  { id: '66ffbb2f43eb873115c9b270', title: 'BioMistral-7B' },
];

export const FindModel = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [githubLink, setGithubLink] = useState('');
  const [isShowBlocker, setIsShowBlocker] = useState(false);
  const [openUpgradeToPro, setOpenUpgradeToPro] = useState(false);

  const [createProjectType, setCreateProjectType] = useState<'code' | 'lora' | 'no-code'>();

  const modelId = searchParams.get('modelId') || '66ffbb2f43eb873115c9b26f';

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const debouncedValue = useDebounce(searchValue, 300);

  const { data, isPending } = useModelListQuery(
    { excludeOrg: ['OpenAI', 'Anthropic'], limit: 5, search: debouncedValue },
    { enabled: debouncedValue.length > 2 },
  );
  const { data: model, isLoading } = useGetModelByIdQuery(modelId!, { enabled: !!modelId });

  const { data: trendingModels = [], isPending: isTrendingPending } = useGetModelsByNamesQuery({
    names: trendingTextModelNames,
  });
  const modelsResult = searchValue.length <= 2 ? trendingModels : data?.pages.flat() || [];

  const resetValue = () => {
    setSearchValue('');
    setIsPopoverOpen(false);
  };

  const hasResults = modelsResult.length > 0 && (searchValue.length <= 2 ? !isTrendingPending : !isPending);

  return (
    <div className="rounded-2xl border border-clay-20 bg-white/85 p-9 backdrop-blur-2xl">
      <div className="flex items-center justify-between gap-12">
        <div className="w-full max-w-72">
          <h3 className="mb-4 text-4xl/none font-semibold text-clay-900">Train a Model</h3>
          <p className="text-base text-clay-500">
            Train a model with Nesa Train, either with code or with any other option listed below.
          </p>
        </div>
        <div className="w-full max-w-xl xl:max-w-2xl">
          <Popover onOpenChange={setIsPopoverOpen} open={isPopoverOpen}>
            <Popover.Trigger
              asChild
              className="flex w-full shrink-0 grow-0 cursor-pointer items-center gap-0 text-xs font-medium outline-none transition-colors hover:text-primary-900"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <div>
                <Input
                  classNameInputWrapper="h-14 rounded-xl px-3.5 shadow-card-large"
                  onChange={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setSearchValue(e.target.value);
                  }}
                  onFocusCapture={() => {
                    setIsPopoverOpen(true);
                  }}
                  placeholder="Search Models"
                  size="medium"
                  startSlot={
                    <Icon
                      className="mr-1 size-4 text-clay-900 transition-colors group-focus-within:text-clay-600 sm:size-4 sm:text-clay-300"
                      name="search"
                    />
                  }
                  value={searchValue}
                />
              </div>
            </Popover.Trigger>
            <Popover.Content
              alignOffset={0}
              className="min-w-[var(--radix-popover-trigger-width)] p-3"
              onOpenAutoFocus={(e) => e.preventDefault()}
              sideOffset={10}
            >
              {!isPending && !modelsResult.length ? (
                <div className="flex flex-col items-center py-3 ">
                  <div className="mx-auto mb-2 flex size-11 items-center justify-center rounded-full border border-clay-20 shadow-boxy">
                    <Icon className="size-4 text-clay-350" name="search" />
                  </div>
                  <h4 className="mb-2 text-lg">No Result Found</h4>

                  <div className="mb-3 text-center text-sm font-light text-clay-400">
                    <span className="font-normal text-clay-900">“{searchValue}”</span> did not match any
                    models or commands. Please try again.
                  </div>

                  <Button color="secondary" onClick={resetValue} variant="filled-light">
                    Clear search
                  </Button>
                </div>
              ) : (
                !hasResults && (
                  <div className="flex min-h-48 items-center justify-center py-4">
                    <Spinner className="size-5" />
                  </div>
                )
              )}
              {hasResults && (
                <div className="flex flex-col ">
                  <div className="mb-3 border-b border-clay-20 pb-3 text-base font-light text-clay-350">
                    {searchValue.length <= 2 ? 'Trending Results' : 'Search Results'}
                  </div>

                  {modelsResult.map((model) => {
                    return (
                      <div
                        className="flex h-8 cursor-pointer items-center gap-3 rounded-lg px-2.5 py-1 text-sm font-normal text-clay-700 transition-colors hover:bg-clay-20"
                        key={model._id}
                        onClick={() => {
                          const newSearch = new URLSearchParams(searchParams);
                          newSearch.set('modelId', model._id);
                          setIsPopoverOpen(false);
                          setSearchParams(newSearch);
                        }}
                      >
                        <div className="flex items-center gap-1.5 overflow-hidden">
                          <span className="flex items-center gap-2.5 truncate">
                            <Icon className="size-5 shrink-0 text-clay-350" name="box" />
                            <span className="truncate text-base">{model.modelName}</span>
                          </span>

                          <span className="size-[2px] rounded-full bg-clay-100"></span>

                          <span className="min-w-fit text-sm font-light text-clay-300">{model.org}</span>
                        </div>

                        <div className="ml-auto shrink-0 truncate rounded-[4px] border border-clay-20 bg-white px-1 py-0.5 text-xs uppercase text-clay-300">
                          {getCategoryLabel(model.type)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </Popover.Content>
          </Popover>
          <div className="mb-2.5 mt-7 text-sm font-medium text-clay-500">Recently trained</div>
          <div className="flex flex-wrap gap-1">
            {mustRecently.map((el, i) => (
              <div
                className="cursor-pointer rounded-lg bg-clay-20 px-2.5 py-1.5 text-sm/4 font-medium text-clay-500"
                key={i}
                onClick={() => {
                  const newSearch = new URLSearchParams(searchParams);
                  newSearch.set('modelId', el.id);
                  setIsPopoverOpen(false);
                  setSearchParams(newSearch);
                }}
              >
                {el.title}
              </div>
            ))}
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="mt-9 rounded-2xl border border-clay-20 shadow-card-medium">
          <div className="flex justify-center py-9">
            <Spinner className="size-6" />
          </div>
        </div>
      )}
      {model && (
        <div className="mt-9 rounded-2xl border border-clay-20 shadow-card-medium">
          <header className="flex items-center gap-5 border-b border-clay-20 bg-clay-10 px-3.5 py-4">
            <div className="flex items-center gap-3 text-clay-900">
              <Icon className="size-5" name="brain" safeArea="0" />
              <span className="text-lg font-medium">Train</span>
            </div>
            <div className="line-clamp-1 flex items-center gap-2 whitespace-nowrap rounded-xl border border-clay-20 bg-white px-2.5 py-2 text-clay-900">
              <Icon className="size-4" name="box" />
              <span className="text-sm font-medium">{model?.name || '--'}</span>
            </div>
          </header>
          <div className="flex flex-col gap-7 p-7">
            <div>
              <h3 className="text-lg font-semibold text-clay-900">How would you like to train?</h3>
              <p className="text-base text-clay-500">
                Choose which training method you{"'"}d like to start working with:
              </p>
            </div>
            <div className="grid grid-cols-3 gap-3.5">
              <TrainCard
                description="You can choose between Streamlit, Gradio and Static for your project, or pick Docker to host any other app. Use Nesa Train for the best experience."
                icon={<TrainedCardIcon1 />}
                onClick={() => {
                  // setIsShowBlocker(true);
                  setCreateProjectType(`code`);
                }}
                title="Train IDE"
              />
              <TrainCard
                description="Quickly begin training by importing data and setting parameters"
                icon={<TrainedCardIcon2 />}
                onClick={() => {
                  // setIsShowBlocker(true);
                  setCreateProjectType(`no-code`);
                }}
                title="Train (NoCode)"
              />
              <TrainCard
                description="You can choose between Streamlit, Gradio and Static for your project, or pick Docker to host any other app. Use Nesa Train for the best experience."
                icon={<TrainedCardIcon3 />}
                onClick={() => {
                  // setIsShowBlocker(true);
                  setCreateProjectType(`lora`);
                }}
                title="LoRA (NoCode)"
              />
            </div>
            <div className="flex items-end gap-4">
              <Input
                classNameLabel="text-sm mb-2.5 text-clay-500"
                error={githubLink && !githubRegexp.test(githubLink) ? 'Invalid github link' : ''}
                errorSpacing={!!githubLink && !githubRegexp.test(githubLink)}
                label="(Optional) Start by cloning a github repo:"
                onChange={(e) => {
                  const { value } = e.target;
                  setGithubLink(value);
                }}
                placeholder="github..."
                size="medium"
                value={githubLink}
              />
              <div className={twJoin(!!githubLink && !githubRegexp.test(githubLink) && 'mb-4')}>
                <Button onClick={() => setIsShowBlocker(true)} size="medium">
                  Continue
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {createProjectType && modelId && (
        <CreateTrainingModal
          modelId={modelId}
          onClose={() => setCreateProjectType(undefined)}
          open
          type={createProjectType}
        />
      )}

      <PageIsInAlphaModal
        description={
          <>
            <span className="text-clay-900">
              Access to <span className="font-semibold">Training</span> is being rolled out in phases to users
              on the early access list.
            </span>
            <p className="mt-2.5 text-sm text-clay-500">To join the access list, upgrade to Nesa Pro.</p>
          </>
        }
        icon={<Wrench className="size-5 text-clay-900" />}
        onBackClick={() => {
          setIsShowBlocker(false);
        }}
        onOpenChange={() => {
          setIsShowBlocker(false);
        }}
        onUpgradeToProClick={() => {
          setOpenUpgradeToPro(true);
        }}
        open={isShowBlocker}
        subtitle="Nesa Train facilitates a seamless training experience with subsidized costs on Nesa compute. You can train your model with code or without code."
        title="Nesa Train is in Alpha"
      />

      <UpgradeToProModal
        onOpenChange={() => {
          setOpenUpgradeToPro(false);
        }}
        open={openUpgradeToPro}
        redirectPath="/training"
      />
    </div>
  );
};

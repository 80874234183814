import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollableBody = window.document.getElementById('scrollable-body');
    scrollableBody?.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

type UploadImageParams = {
  file: File;
};
export const useDAIUploadFileMutation = (
  options: UseMutationOptions<{ imageUrl: string }, Error, UploadImageParams> = {},
) => {
  return useMutation({
    mutationFn: async ({ file }: UploadImageParams) => {
      const formData = new FormData();

      formData.append('file', file);

      const { data } = await axiosInstance.post<{ imageUrl: string }>(`/dai/upload/file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 5 * 60 * 1000,
      });

      return data;
    },
    ...options,
  });
};

import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useController, useFormContext } from 'react-hook-form';

import { AnimatePresence, motion } from 'framer-motion';
import { ImagePlus } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

// import { useUploadImageMutation } from 'shared/api/ipfs/useUploadImageMutation';
import { Button } from 'shared/ui/Button';
import { DatePicker } from 'shared/ui/DatePicker';
import { Input } from 'shared/ui/Input';
import { Label } from 'shared/ui/Label';
import { RadioGroup } from 'shared/ui/Radio';
import { Select } from 'shared/ui/Select';

import type { FormValues, TokenChain } from '../types';

import { getFileSrc } from '../helpers/getFileSrc';
import { StepHeader } from './StepHeader';

const chainOptions: { label: string; value: TokenChain }[] = [
  { label: 'Base', value: 'base' },
  { label: 'Arbitrum', value: 'arbitrum' },
  { label: 'BNB', value: 'bnb' },
  { label: 'Ethereum', value: 'ethereum' },
  { label: 'Optimism', value: 'optimism' },
  { label: 'Polygon', value: 'polygon' },
];

export const Tokenomics = () => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<FormValues>();
  const { field: tokenomicsTypeField } = useController({ control, name: 'tokenomicsType' });
  const { field: tokenImageField } = useController({ control, name: 'tokenImage' });
  const { field: tokenChainField } = useController({ control, name: 'tokenChain' });
  const { field: tokenStartDateField } = useController({ control, name: 'tokenStartDate' });

  console.log('err', errors);
  // const { mutateAsync: uploadImage } = useUploadImageMutation();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    console.log('acceptedFiles', acceptedFiles);
    tokenImageField.onChange(acceptedFiles[0]);
    // setUploadedFiles(acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getInputProps, getRootProps, isDragActive } = useDropzone({ onDrop });
  const {
    getInputProps: getInputProps2,
    getRootProps: getRootProps2,
    isDragActive: isDragActive2,
  } = useDropzone({ onDrop });

  return (
    <div className="m-auto flex w-full max-w-3xl flex-col rounded-2xl border border-clay-20  bg-white shadow-lg ">
      <StepHeader stepNumber={3} title="Tokenomics" />

      <div className="flex flex-col gap-3">
        <RadioGroup onValueChange={tokenomicsTypeField.onChange} value={tokenomicsTypeField.value}>
          <div className="border-b border-clay-20 p-4">
            <div className="cursor-pointer" onClick={() => tokenomicsTypeField.onChange('create')}>
              <RadioGroup.Item
                className="font-medium"
                labelClassName="text-lg tracking-tight cursor-pointer"
                value="create"
              >
                Create a New Token
              </RadioGroup.Item>
            </div>

            <AnimatePresence>
              {tokenomicsTypeField.value === 'create' && (
                <motion.div
                  animate={{ height: 'auto', opacity: 1 }}
                  className="overflow-hidden"
                  exit={{ height: 0, opacity: 0 }}
                  initial={{ height: 0, opacity: 0 }}
                >
                  <div className="py-4">
                    <div>
                      <Label className="text-xs">
                        Token Image <span className="ml-1 text-red-900">*</span>
                      </Label>

                      <div className="flex flex-col gap-4">
                        <div
                          {...getRootProps()}
                          className={twMerge(
                            'flex cursor-pointer flex-col rounded-lg border-[1.2px] border-dashed border-clay-40 p-3',
                            'font-normal text-clay-400 transition-all duration-200',
                            isDragActive && 'border-primary-800',
                          )}
                        >
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <p>Drop the files here ...</p>
                          ) : (
                            <div className="flex items-center justify-between">
                              <div className="flex items-center gap-4">
                                {tokenImageField.value ? (
                                  <img
                                    className="size-12 rounded-lg object-cover"
                                    src={getFileSrc(tokenImageField.value)}
                                  />
                                ) : (
                                  <div className="flex size-12 items-center justify-center rounded-lg bg-clay-10">
                                    <ImagePlus className="size-4 text-clay-350" />
                                  </div>
                                )}

                                <div className="flex flex-col gap-0.5">
                                  <div className="font-medium tracking-tight text-clay-800">
                                    Upload token logo
                                  </div>
                                  <div className="text-sm font-light text-clay-350">
                                    SVG, PNG, JPG or GIF (rec. 500 x 500px)
                                  </div>
                                </div>
                              </div>

                              <Button color="white" size="extra-small">
                                Browse
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="mt-3 grid grid-cols-4 items-end gap-3">
                      <Input
                        error={errors.ticker?.message || ''}
                        errorSpacing
                        {...register('ticker')}
                        label={
                          <span>
                            Ticker Symbol
                            <span className="ml-1 text-red-900">*</span>
                          </span>
                        }
                        placeholder="-"
                      />
                      <Input
                        className="col-span-2"
                        error={errors.tokenSupply?.message || ''}
                        errorSpacing
                        min={0}
                        type="number"
                        {...register('tokenSupply')}
                        label={
                          <span>
                            Total token supply
                            <span className="ml-1 text-red-900">*</span>
                          </span>
                        }
                        placeholder="-"
                      />
                      <Input
                        error={errors.stakersReserved?.message || ''}
                        errorSpacing
                        max={100}
                        min={0}
                        type="number"
                        {...register('stakersReserved', {
                          // max: 100,
                          // min: 0,
                          // required: true,
                          validate: (value, formValues) => {
                            if (formValues.tokenomicsType !== 'create') return undefined;

                            console.log('value', value);
                            return value === undefined ||
                              Number.isNaN(Number(value)) ||
                              Number(value) < 0 ||
                              Number(value) > 100
                              ? 'Value should be between 0 and 100'
                              : undefined;
                          },
                        })}
                        label={
                          <span>
                            % supply reserved for stakers
                            <span className="ml-1 text-red-900">*</span>
                          </span>
                        }
                        placeholder="-"
                      />
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="border-b border-clay-20 p-4">
            <div className="cursor-pointer" onClick={() => tokenomicsTypeField.onChange('import')}>
              <RadioGroup.Item
                className=" font-medium"
                labelClassName="text-lg tracking-tight cursor-pointer"
                value="import"
              >
                Import an Existing Token
              </RadioGroup.Item>
            </div>

            <AnimatePresence>
              {tokenomicsTypeField.value === 'import' && (
                <motion.div
                  animate={{ height: 'auto', opacity: 1 }}
                  className="overflow-hidden"
                  exit={{ height: 0, opacity: 0 }}
                  initial={{ height: 0, opacity: 0 }}
                >
                  <div className="pt-4">
                    <Select
                      className="mb-3 border border-clay-20 bg-white"
                      label={
                        <span className="text-sm font-normal text-clay-500">
                          Chain
                          <span className="ml-1 text-red-900">*</span>
                        </span>
                      }
                      onValueChange={tokenChainField.onChange}
                      placeholder="-"
                      value={tokenChainField.value}
                    >
                      <Select.Content>
                        {chainOptions.map(({ label, value }) => {
                          return (
                            <Select.Item key={value} value={value}>
                              {label}
                            </Select.Item>
                          );
                        })}
                      </Select.Content>
                    </Select>

                    <Input
                      label={
                        <span>
                          Token Contract Address
                          <span className="ml-1 text-red-900">*</span>
                        </span>
                      }
                      {...register('tokenContractAddress', {
                        validate: (value) =>
                          value && /^0x[a-fA-F0-9]{40}$/.test(value) ? undefined : 'Address is not valid',
                        // validate: (value) => (/value?.startsWith('0x') ? '' : 'Address is not valid'),
                      })}
                      error={errors.tokenContractAddress?.message || ''}
                      errorSpacing
                      maxLength={42}
                      placeholder="0x00000000000..."
                      // placeholder="0x0000000000000000000000000000000000000000"
                    />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="border-none border-clay-20 p-4">
            <div className="cursor-pointer" onClick={() => tokenomicsTypeField.onChange('later')}>
              <RadioGroup.Item
                className=" font-medium"
                labelClassName="text-lg tracking-tight cursor-pointer"
                value="later"
              >
                Create Token Later
              </RadioGroup.Item>
            </div>

            <AnimatePresence>
              {tokenomicsTypeField.value === 'later' && (
                <motion.div
                  animate={{ height: 'auto', opacity: 1 }}
                  className="overflow-hidden"
                  exit={{ height: 0, opacity: 0 }}
                  initial={{ height: 0, opacity: 0 }}
                >
                  <div className="py-4">
                    <div>
                      <Label className="text-xs">
                        Token Image <span className="ml-1 text-red-900">*</span>
                      </Label>

                      <div className="flex flex-col gap-4">
                        <div
                          {...getRootProps2()}
                          className={twMerge(
                            'flex cursor-pointer flex-col rounded-lg border-[1.2px] border-dashed border-clay-40 p-3',
                            'font-normal text-clay-400 transition-all duration-200',
                            isDragActive2 && 'border-primary-800',
                          )}
                        >
                          <input {...getInputProps2()} />
                          {isDragActive2 ? (
                            <p>Drop the files here ...</p>
                          ) : (
                            <div className="flex items-center justify-between">
                              <div className="flex items-center gap-4">
                                {tokenImageField.value ? (
                                  <img
                                    className="size-12 rounded-lg object-cover"
                                    src={getFileSrc(tokenImageField.value)}
                                  />
                                ) : (
                                  <div className="flex size-12 items-center justify-center rounded-lg bg-clay-10">
                                    <ImagePlus className="size-4 text-clay-350" />
                                  </div>
                                )}

                                <div className="flex flex-col gap-0.5">
                                  <div className="font-medium tracking-tight text-clay-800">
                                    Upload token logo
                                  </div>
                                  <div className="text-sm font-light text-clay-350">
                                    SVG, PNG, JPG or GIF (rec. 500 x 500px)
                                  </div>
                                </div>
                              </div>

                              <Button color="white" size="extra-small">
                                Browse
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="mt-3 grid grid-cols-1 gap-2">
                      <Input
                        error={errors.ticker?.message || ''}
                        errorSpacing
                        {...register('ticker')}
                        label={
                          <span>
                            Ticker Symbol
                            <span className="ml-1 text-red-900">*</span>
                          </span>
                        }
                        placeholder="-"
                      />

                      <div className="flex max-w-72 flex-col">
                        <Label className="mb-1 text-sm font-normal text-clay-500">
                          Estimated Launch Date
                        </Label>
                        <DatePicker
                          minDate={new Date()}
                          onDateChange={tokenStartDateField.onChange}
                          selectedDate={tokenStartDateField.value || null}
                        />
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </RadioGroup>
      </div>
    </div>
  );
};

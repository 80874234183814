import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useLocalStorage } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useAccount } from 'graz';
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import type { QueryParams } from 'features/ModelQueryParams/types';
import type { Section } from 'features/QueryMenu/QueryMenu';
import type { TransactionData } from 'pages/QueryPage/types';
import type { Model } from 'shared/api/models/types';
import type { QueryHistory as QueryHistoryItem } from 'shared/api/queryHistory/types';

import { queryClient } from 'app/App';
import { useUser } from 'app/stores/user';
import { ApiKeyModal } from 'features/ApiKeyModal/ApiKeyModal';
import { LimitReachedModal } from 'features/LimitReachedModal';
import { RequestContent } from 'features/ModelQuery/ui/RequestContent';
import { ResponseContent } from 'features/ModelQuery/ui/ResponseContent';
import { QueryHistory } from 'features/ModelQueryHistory/QueryHistory';
import { ModelQueryParams } from 'features/ModelQueryParams/ModelQueryParams';
import {
  getIsImageGeneration,
  getIsUnconditionalImageGen,
} from 'features/ModelQueryParams/helpers/modelParamsChecks';
import { ModelReviews } from 'features/ModelReviews';
import { ModelsFromOrg } from 'features/ModelsFromOrg';
import { QueryHeader } from 'features/QueryHeader';
import { QueryMenu } from 'features/QueryMenu';
import { chatClient, createChatClient } from 'pages/QueryPage/chatClientStore';
import { ERROR_CODES, defaultQueryParams, getDefaultParamsByModelType } from 'pages/QueryPage/config';
import { getErrorMessage } from 'pages/QueryPage/helpers/getErrorMessage';
import { getIsApiModel } from 'pages/QueryPage/helpers/getIsApiModel';
import { getIsImageModel } from 'pages/QueryPage/helpers/getIsImageModel';
import { getModelParams } from 'pages/QueryPage/helpers/getModelParams';
import { useChatStatus } from 'pages/QueryPage/hooks/useChatStatus';
import { InsufficientBalanceModal } from 'pages/QueryPage/ui/InsufficientBalanceModal';
import { Payments } from 'pages/QueryPage/ui/Payments';
import { ReviewModal } from 'pages/QueryPage/ui/ReviewModal';
import { useErrorSubmitMutation } from 'shared/api/errors/useErrorSubmitMutation';
import { ipfsKeys } from 'shared/api/ipfs/queryKeys';
import { useUploadImageMutation } from 'shared/api/ipfs/useUploadImageMutation';
import { getLlmSessionMessageQueryKey } from 'shared/api/messages/useGetLlmSessionMessagesQuery';
import { queryHistoryKeys } from 'shared/api/queryHistory/queryKeys';
import { useCreateQueryHistoryMutation } from 'shared/api/queryHistory/useCreateQueryHistoryMutation';
import { useGetUserReviewByModelQuery } from 'shared/api/reviews/useGetUserReviewByModelQuery';
import { useTokenCheckMutation } from 'shared/api/user/useTokenCheckMutation';
import { getPrice } from 'shared/helpers/getPrice';
import { isObjectEmpty } from 'shared/helpers/isObjectEmpty';
import { sleep } from 'shared/helpers/sleep';
import { useEvent } from 'shared/hooks/useEvent';
import { useLimitReached } from 'shared/hooks/useLimitReached';
import { useStateX } from 'shared/hooks/useStateX';
import { Accordion } from 'shared/ui/Accordion';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Card } from 'shared/ui/Card';
import { Icon } from 'shared/ui/Icon';
import { ModelDescription } from 'shared/ui/ModelDescription';
import { Spinner } from 'shared/ui/Spinner';
import { StretchedSkeleton } from 'shared/ui/StretchedSkeleton';
import { toaster } from 'shared/ui/Toast';

type Stream = { destroy: () => void; on: (event: string, fn: unknown) => void } & unknown;

const isProd = import.meta.env.MODE === 'production';

type Props = {
  model: Model;
};

export const ModelQuery = ({ model }: Props) => {
  const navigate = useNavigate();

  const { id: modelId, section } = useParams<{ id: string; section?: Section }>();

  const { isLimitReached, refetch: refetchLimits } = useLimitReached(modelId);
  const { mutateAsync: runTokenCheck } = useTokenCheckMutation();
  const [historyId, setHistoryId] = useState('');

  const [activeSection, setActiveSection] = useState<Section>(section || 'playground');

  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);
  const [isApiKeyModalOpen, setIsApiKeyModalOpen] = useState(false);

  const [blocksState, setBlocksState] = useStateX({
    isMoreFromOrgOpen: true,
    isParamOpen: true,
    isQueryHistoryOpen: false,
    isResponseOpen: false,
  });

  const [modelReviews, setModelReviews] = useLocalStorage<Record<string, boolean>>('nesaModelReviews', {});

  const { mutateAsync: logError } = useErrorSubmitMutation();

  const { data: account, walletType } = useAccount();
  const { user } = useUser();

  const { data: reviewData } = useGetUserReviewByModelQuery({
    modelId: model?._id || '',
    userId: user?._id || '',
  });
  const userReview = reviewData?.data;

  useEffect(() => {
    createChatClient({
      address: account?.bech32Address,
      isByPass: !isLimitReached,
      modelName: model?.name.toLowerCase(),
      walletType,
    });

    requestChatStatus();

    return () => {
      readableStream?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.bech32Address, isLimitReached, model?.name, walletType]);

  const { requestChatStatus, txDataCode, updateTxDataCode } = useChatStatus();

  const [responseCount, setResponseCount] = useState(0);
  const [queryParams, setQueryParams] = useStateX<QueryParams>(
    getDefaultParamsByModelType({ apiKeyService: model.api_key_service, modelType: model.type }),
  );

  const [txData, setTxData] = useStateX<TransactionData>({
    minerRates: [],
    paymentProgress: [],
    status: 'idle',
  });

  const [readableStream, setReadableStream] = useState<Stream>();

  const [txDataErrorMessage, setTxDataErrorMessage] = useState<null | string>(null);

  const [ipfsLinks, setIpfsLinks] = useState<string[]>([]);

  const isModelExpectImage = getIsImageModel(model);

  const { mutateAsync: createQueryHistoryItem } = useCreateQueryHistoryMutation();
  const { mutateAsync: uploadImage } = useUploadImageMutation();

  const [resultArr, setResultArr] = useState<string[]>([]);
  const [isRequestingSession, setIsRequestingSession] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useStateX({ insufficientBalance: false });

  const transactionHashRef = useRef('');

  const responseAccordionRef = useRef<HTMLDivElement>(null);

  const timeoutRef = useRef<NodeJS.Timeout | null | number>(null);
  const txTimeoutRef = useRef<NodeJS.Timeout | null | number>(null);
  const failedRequestRetries = useRef(0);

  const [errors, setErrors] = useStateX<{ inputError: boolean }>({ inputError: false });

  useEffect(() => {
    isLimitReached && setIsLimitModalOpen(true);
  }, [isLimitReached]);

  useEffect(() => {
    return () => {
      const historyQueryKey = getLlmSessionMessageQueryKey({
        modelName: model?.name,
        sessionId: 'default',
        userId: user?._id || '',
      });

      queryClient.setQueryData(historyQueryKey, {
        pageParams: [],
        pages: [],
      });
    };
  }, [model?.name, user?._id]);

  const resetAnimation = () => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    setResultArr([]);

    setTxData({ status: 'idle' });

    ipfsLinks && queryClient.setQueryData(ipfsKeys.file({ cid: ipfsLinks.join(',') }), null);

    setIpfsLinks([]);

    refetchLimits();
  };

  const requestSession = async (cb?: () => void) => {
    try {
      console.log('chatClient', chatClient?.['authToken']);
      if (!chatClient) {
        toaster.error('No client defined');
        return;
      }

      setIsRequestingSession(true);

      const sessionStream = await chatClient.requestSession();

      sessionStream.on('data', (data: { code: number; message: string }) => {
        //  Processing transmission data
        console.log('sessionStream', data);

        setTxDataErrorMessage(null);

        const { code } = data;
        const message = data.message || '';

        if (code === 200) {
          // Streaming data return for TransactionHash

          setTxData({ transactionHash: message || '' });
          transactionHashRef.current = message || '';

          if (cb && typeof cb === 'function') {
            cb();
          }
          // setIsRequestingSession(false);
        } else if (code === 302) {
          setIsRequestingSession(false);
        } else if (ERROR_CODES.includes(code)) {
          console.log('ERROR', { code, message });
          const { text: error, type } = getErrorMessage(message.toLowerCase());
          transactionHashRef.current = '';

          if (type === 'insufficientBalance') {
            setErrorModalOpen({ insufficientBalance: true });
          }

          toaster.error(error);
          setTxDataErrorMessage(error);

          isProd &&
            logError({
              error: getErrorMessage(message.toLowerCase()).text,
              modelId: model?._id || '',
              params: {
                ...txData,
                code,
                full: message,
                msg: error,
              },
              userId: user?._id || '',
            });

          setTxData({ transactionHash: '' });
          setIsRequestingSession(false);
        }
      });
      sessionStream.on('end', () => {
        // End of transmission
        // setIsRequestingSession(false);
      });
      // });
    } catch (e) {
      console.log('ERROR SESSION', e);
      console.error(e);

      const errorMessage = getErrorMessage(`${e}`).text;

      isProd &&
        logError({
          error: `${e}`,
          modelId: model?._id || '',
          params: {
            ...txData,
            msg: txDataErrorMessage,
          },
          userId: user?._id || '',
        });

      toaster.error(errorMessage);

      setIsRequestingSession(false);
      resetAnimation();
    }
  };

  const handleSessionError = async () => {
    await requestSession();

    await handleSendQuery();
  };

  const startTxTimeout = (stream: { destroy: () => void } & unknown) => {
    txTimeoutRef.current && clearTimeout(txTimeoutRef.current);

    txTimeoutRef.current = setTimeout(
      () => {
        console.log('timeout');
        resetAnimation();
        setTxData({ status: 'error' });

        logError({
          error: `model timeout`,
          modelId: model?._id || '',
          params: { ...txData },
          userId: user?._id || '',
        });

        stream?.destroy();

        txTimeoutRef.current && clearTimeout(txTimeoutRef.current);
      },
      model.type === 'spatial-transcriptomics' ? 8 * 60 * 1000 : 5 * 60 * 1000,
    );
  };

  const handleSendQuery = async (qParams: Partial<QueryParams> = {}) => {
    setIsRequestingSession(false);

    const params = { ...queryParams, ...qParams } as QueryParams;

    if (getIsImageGeneration(params, model.type) && !params.apiKey && model.org && getIsApiModel(model)) {
      setIsApiKeyModalOpen(true);

      return;
    }

    if (isModelExpectImage && 'file' in params && !params.file) {
      setQueryParams(defaultQueryParams);
      setErrors({ inputError: true });
      return;
    }

    ipfsLinks && queryClient.setQueryData(ipfsKeys.file({ cid: ipfsLinks.join(',') }), undefined);

    setIpfsLinks([]);

    setTxData({ status: 'progress' });
    setResultArr([]);

    const startTime = DateTime.now();

    if (!chatClient) {
      console.error('No client defined!');

      return;
    }

    let uploadRes: { cid: string; name?: string | undefined } | undefined = undefined;
    let audioUploadRes: { cid: string; name?: string | undefined } | undefined = undefined;

    if ('file' in params && params.file) {
      uploadRes = await uploadImage({ file: params.file });
    }

    if ('audioBlob' in params && params.audioBlob) {
      const audioFile = new File([params.audioBlob], 'audio', { type: params.audioBlob.type });
      audioUploadRes = await uploadImage({ file: audioFile });
    }

    const modelParams = getModelParams(params, model);

    const privacyStrategyParams = {};
    // params.private && (model.privacy_strategies || []).length > 0
    //   ? { privacy_strategy: model.privacy_strategies[0] }
    //   : {};

    const apiKeyParams = 'apiKey' in params && params.apiKey ? { api_key: params.apiKey } : {};

    const sessionIdParams = {
      ...privacyStrategyParams,
      ...apiKeyParams,
    };

    const getContentValue = () => {
      if (audioUploadRes) return audioUploadRes.cid;
      if (uploadRes) return uploadRes.cid;
      if ('question' in params) return params.question;
      if ('content' in params && typeof params.content === 'string') return params.content;

      return undefined;
    };

    const chatRequest = {
      messages: [
        {
          content: getContentValue(),
          context: 'context' in params ? params.context : undefined,
          role: 'user',
          // for sentence similarity
          // sentence_2: 'sentence2' in params ? params.sentence2 : undefined,
        },
      ],
      model: model?.name || '',
      model_params: modelParams,
      session_id: (isObjectEmpty(sessionIdParams) && sessionIdParams) || undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;

    console.log('client.current', chatClient);

    chatClient
      ?.requestChat(chatRequest)
      ?.then((readableStream1) => {
        const readableStream = readableStream1 as Stream;

        let res = '';
        let sessionId = '';
        let minerRates: { account: string; rate: number }[];
        let totalPayment = 0;
        let execTime: number | undefined = undefined;

        let isSessionReinitiated = false;

        startTxTimeout(readableStream);
        setReadableStream(readableStream);

        readableStream.on(
          'data',
          (data: {
            code: number;
            content?: string;
            message: string;
            session_id?: string;
            total_payment?: { amount: number; denom: string };
          }) => {
            console.log('readable stream', data);

            startTxTimeout(readableStream);

            // Processing transmission data
            if (data.code === 200) {
              if (!execTime) {
                execTime = Math.abs(startTime.diffNow('seconds').seconds);
              }

              failedRequestRetries.current = 0;

              setIpfsLinks((prev) => [...prev, data.message]);

              setBlocksState({
                isMoreFromOrgOpen: false,
                isParamOpen: false,
                isQueryHistoryOpen: true,
                isResponseOpen: true,
              });
              responseAccordionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

              if (data.message.includes('[DONE]') || data.message === '##') {
                return;
              }

              try {
                setTxData((prev) => ({
                  ...prev,
                  executionTime: execTime,
                  paymentProgress: [
                    ...(prev.paymentProgress || []),
                    { ...(data.total_payment || {}), date: DateTime.now().toISO() },
                  ],
                  sessionId: data.session_id,
                }));

                sessionId = data?.session_id || '';

                if (data.total_payment?.amount) {
                  totalPayment = data.total_payment?.amount;
                }

                res += data.message;
                setResultArr((prev) => [...prev, data.message]);
              } catch (e) {
                console.error('json parse error', e, ' --- ', data);
              }
            }

            if (data.code === 203) {
              setTxData({ minerRates: data.message ? JSON.parse(data.message) : [] });
              minerRates = JSON.parse(data.message);
            }

            if (
              data.code === 204 &&
              data.message === 'Error: Connection failed' &&
              failedRequestRetries.current < 3
            ) {
              failedRequestRetries.current += 1;
              setTimeout(
                () => {
                  handleSendQuery(qParams);
                },
                500 * (failedRequestRetries.current + 1),
              );

              return;
            }

            if (data.code === 205) {
              const parsed = data.message ? JSON.parse(data.message) : {};

              if (parsed.msg === 'session status mismatch') {
                isSessionReinitiated = true;
                setResultArr([]);

                setTxData({ sessionId: 'default', status: 'progress' });

                setTimeout(() => {
                  handleSessionError();
                }, 0);

                return;
              }

              if (
                parsed.detail?.toLowerCase() === 'Token is invalid'.toLowerCase() &&
                failedRequestRetries.current < 2
              ) {
                (async () => {
                  failedRequestRetries.current += 1;
                  console.log('localSendRetries', failedRequestRetries.current);
                  const accessTokenDefault = localStorage.getItem('user') || undefined;

                  console.log('accessTokenDefault', accessTokenDefault);

                  createChatClient({
                    address: account?.bech32Address,
                    authToken: accessTokenDefault,
                    isByPass: !isLimitReached,
                    modelName: model.name,
                    walletType,
                  });

                  requestChatStatus();

                  await sleep(1000);

                  await requestSession(() =>
                    setTimeout(
                      () => {
                        handleSendQuery(params);
                      },
                      isLimitReached ? 5000 : 400,
                    ),
                  );
                })();

                return;
              }

              if (
                parsed.detail?.toLowerCase() === 'session id is required'.toLowerCase() &&
                failedRequestRetries.current < 2
              ) {
                (async () => {
                  failedRequestRetries.current += 1;
                  console.log('localSendRetries', failedRequestRetries.current);

                  await requestSession(() =>
                    setTimeout(
                      () => {
                        handleSendQuery(params);
                      },
                      isLimitReached ? 5000 : 400,
                    ),
                  );
                })();

                return;
              }

              const isLLMError = ['LLM Network error', 'LLM Backend error']
                .map((s) => s.toLowerCase())
                .includes((parsed.msg as string).toLowerCase());
              const isAbnormal = (parsed.msg as string).toLowerCase() === 'LLM Abnormal'.toLowerCase();
              const text = isLLMError
                ? 'Network experiencing extreme load, try again later.'
                : isAbnormal
                  ? 'LLM Network error'
                  : parsed.msg;

              const isBackendError = 'LLM Backend error'
                .toLowerCase()
                .includes((parsed.msg as string).toLowerCase());
              if (isBackendError && failedRequestRetries.current < 3) {
                failedRequestRetries.current += 1;

                setTimeout(() => {
                  requestSession(() =>
                    setTimeout(
                      () => {
                        handleSendQuery(params);
                      },
                      isLimitReached ? 5000 : 400,
                    ),
                  );
                }, 500);
              } else if (parsed.msg.includes('balance insufficient')) {
                setErrorModalOpen({ insufficientBalance: true });
                setTxData({ transactionHash: undefined });
              } else {
                text && toaster.error(text);

                updateTxDataCode(data.code);
              }

              isProd &&
                logError({
                  error: `${parsed.msg}`,
                  modelId: model?._id || '',
                  params: { ...txData, msg: txDataErrorMessage },
                  userId: user?._id || '',
                });

              resetAnimation();
            }
          },
        );

        readableStream.on('end', async () => {
          txTimeoutRef.current && clearTimeout(txTimeoutRef.current);
          if (isSessionReinitiated) {
            readableStream.destroy();
            return;
          }

          setResponseCount((prev) => prev + 1);
          const reviewStorageKey = `${user?._id}-${model?._id}`;

          if (responseCount + 1 === 2 && !modelReviews[reviewStorageKey] && !userReview) {
            setModelReviews((prev) => ({ ...prev, [reviewStorageKey]: true }));

            setTimeout(() => {
              setIsReviewOpen(true);
            }, 500);
          }

          setTxData({ status: 'completed' });

          timeoutRef.current && clearTimeout(timeoutRef.current);

          if (res) {
            const createdHistoryItem = await createQueryHistoryItem({
              api: !isLimitReached,
              executionTime: execTime,
              minerRates,
              modelId: modelId || '',
              pricePerToken: model?.price?.toFixed(3) || '',
              private: params.private || false,
              question: params.private
                ? '***'
                : uploadRes?.cid || ('question' in params && params.question) || 'unknown',
              result: res,
              rewards: totalPayment,
              sessionId,
              status: 'completed',
              transactionHash: transactionHashRef.current,
              walletAddress: account?.bech32Address || '',
            });
            setHistoryId(createdHistoryItem._id);

            const historyKey = queryHistoryKeys.list({
              limit: 10,
              modelId: model._id,
              type: 'user-based',
              userId: user?._id || '',
            });

            const currentHistory = queryClient.getQueryData<{
              pageParams: number[];
              pages: [QueryHistoryItem[]];
            }>(historyKey);

            queryClient.setQueryData(historyKey, {
              ...(currentHistory || {}),
              pageParams: currentHistory?.pageParams || [0],
              pages: [[{ ...createdHistoryItem, model: model }, ...(currentHistory?.pages?.[0] || [])]],
            });
            queryClient.invalidateQueries({ queryKey: historyKey });
          }

          refetchLimits();

          readableStream.destroy();
          // End of transmission
        });
      })
      .catch((error) => {
        console.log('ERRROR', error);

        console.error(error);
        setTxData({ status: error });

        isProd &&
          logError({
            error: `${error}`,
            modelId: model?._id || '',
            params: { ...txData, msg: txDataErrorMessage },
            userId: user?._id || '',
          });
      });
  };

  const handleSendQueryEvent = useEvent(async (params) => {
    const { valid } = await runTokenCheck();

    failedRequestRetries.current = 0;

    if (!txData.transactionHash || !valid) {
      await requestSession(() =>
        setTimeout(
          () => {
            handleSendQuery(params);
          },
          isLimitReached ? 5000 : 400,
        ),
      );

      return;
    }

    await handleSendQuery(params);
  });

  const handleSectionChange = (section: Section) => {
    setActiveSection(section);
    navigate(`/models/${modelId}/${section}`, { replace: true });
  };

  const getExampleModelParamsCb = useEvent(() => ({
    chatRequest: {
      messages: [
        {
          content:
            'file' in queryParams || 'audioBlob' in queryParams
              ? 'file cid'
              : 'question' in queryParams && queryParams.question,
          context: 'context' in queryParams ? queryParams.context : undefined,
          role: 'user',
          // sentence_2: 'sentence2' in queryParams ? queryParams.sentence2 : undefined,
        },
      ],
      model: model?.name || '',
      model_params: queryParams,
      session_id:
        ('apiKey' in queryParams && queryParams.apiKey && JSON.stringify({ api_key: queryParams.apiKey })) ||
        undefined,
    },
    expectedInput: model?.expectedInput,
    model: model?.name || '',
    walletType,
  }));

  return (
    <AnimateRoute className="flex grow flex-col gap-4 px-2 py-4 xs:p-4 lg:overflow-hidden">
      <QueryHeader
        model={model}
        onReviewsClick={() => handleSectionChange('reviews')}
        txDataCode={txDataCode}
        txDataErrorMessage={txDataErrorMessage}
      />

      <div className="relative grid h-full grid-rows-[11rem_1fr] gap-1 overflow-scroll rounded-2xl bg-clay-20 p-2 md:grid-rows-[5.2rem_1fr] lg:grid-rows-[3rem_1fr] lg:overflow-hidden">
        <QueryMenu
          downloads={model.nesa_downloads || 0}
          getModelParams={getExampleModelParamsCb}
          modelId={model._id}
          onChange={handleSectionChange}
          value={activeSection}
        />

        <div className="grid grid-cols-1 gap-2 overflow-hidden md:grid-cols-5">
          {activeSection === 'playground' && (
            <Card
              className={twMerge(
                'relative flex min-h-64 flex-1 flex-col border-2 scrollbar-none md:col-span-3 lg:overflow-y-scroll',
                queryParams.private && 'border-clay-800',
                !txData.transactionHash && 'overflow-hidden',
                model && ['depth-estimation', 'image-generation'].includes(model.type) && 'md:col-span-2',
                model && getIsUnconditionalImageGen(queryParams, model.type) && 'max-h-fit min-h-fit',
              )}
              key="playground"
            >
              <RequestContent
                errors={errors}
                isConnectingToModel={!!txDataCode && [302, 303].includes(txDataCode)}
                isRequestingSession={isRequestingSession}
                model={model}
                onQueryParamsChange={setQueryParams}
                onSendQuery={handleSendQueryEvent}
                queryParams={queryParams}
                txData={txData}
              />
            </Card>
          )}

          {activeSection === 'description' && <ModelDescription model={model} />}

          {activeSection === 'reviews' && model && (
            <Card
              className={twMerge(
                'flex flex-col overflow-hidden md:col-span-3',
                model && ['depth-estimation', 'image-generation'].includes(model.type) && 'md:col-span-2',
              )}
              key="reviews"
            >
              <ModelReviews modelId={model._id} />
            </Card>
          )}

          <motion.div
            className={twMerge(
              'relative flex flex-1 flex-col gap-2 overflow-y-scroll md:col-span-2',
              model && ['depth-estimation', 'image-generation'].includes(model.type) && 'md:col-span-3',
            )}
          >
            <StretchedSkeleton enable={!model} rx={8} ry={8} />

            <AnimatePresence>
              <Card animate={{ opacity: 1 }} className="!pb-1" exit={{ opacity: 0 }} initial={{ opacity: 0 }}>
                <Accordion
                  defaultExpanded
                  isExpanded={blocksState.isParamOpen}
                  onChange={(val) => setBlocksState({ isParamOpen: val })}
                >
                  <Accordion.Trigger className="flex items-center gap-2">
                    <Icon className="size-4 stroke-clay-400" name="penLine" /> Parameters
                  </Accordion.Trigger>
                  <Accordion.Content>
                    <ModelQueryParams modelType={model.type} onChange={setQueryParams} value={queryParams} />
                  </Accordion.Content>
                </Accordion>
              </Card>
            </AnimatePresence>

            <AnimatePresence>
              {model && (
                <Card
                  animate={{ opacity: 1 }}
                  className="!pb-1"
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0 }}
                  ref={responseAccordionRef}
                >
                  <Accordion
                    defaultExpanded={false}
                    isExpanded={blocksState.isResponseOpen}
                    onChange={(val) => setBlocksState({ isResponseOpen: val })}
                  >
                    <Accordion.Trigger className="flex items-center gap-2">
                      <Icon className="size-5 text-clay-400" name="fileLink" />
                      Response
                    </Accordion.Trigger>

                    <Accordion.Content className="relative">
                      <AnimatePresence>
                        {txData.status === 'progress' && (
                          <motion.div
                            animate={{ opacity: 1 }}
                            className="absolute right-2 top-2"
                            exit={{ opacity: 0 }}
                            initial={{ opacity: 0 }}
                          >
                            <Spinner className="size-5" />
                          </motion.div>
                        )}
                      </AnimatePresence>

                      <ResponseContent
                        ipfsLinks={ipfsLinks}
                        model={model}
                        requestData={queryParams}
                        textList={resultArr}
                      />
                    </Accordion.Content>
                  </Accordion>
                </Card>
              )}
            </AnimatePresence>

            <AnimatePresence>
              <Card animate={{ opacity: 1 }} className="!pb-1" exit={{ opacity: 0 }} initial={{ opacity: 0 }}>
                <Payments
                  executionTime={txData.executionTime}
                  historyId={historyId}
                  pricePerToken={getPrice(model.pricing?.output_price) || 0.001}
                  rates={txData.minerRates}
                  totalPayment={txData.paymentProgress?.[txData.paymentProgress.length - 1]?.amount || 0}
                />
              </Card>
            </AnimatePresence>

            <AnimatePresence>
              {model && (
                <QueryHistory
                  isOpen={blocksState.isQueryHistoryOpen}
                  modelId={model._id || ''}
                  onOpenChange={(val) => setBlocksState({ isQueryHistoryOpen: val })}
                  // onClick={({ executionTime, minerRates, question, result, status }) => {
                  //   setResultArr(result ? [result] : []);
                  //   setQueryParams({ question });
                  //   setTxData({ executionTime, minerRates, status });
                  //   setIpfsLinks(result ? [result] : []);
                  // }}
                  userId={user?._id || ''}
                />
              )}
            </AnimatePresence>

            <AnimatePresence>
              {model?.org && (
                <ModelsFromOrg
                  isOpen={blocksState.isMoreFromOrgOpen}
                  modelIdToExclude={model._id}
                  onOpenChange={(val) => setBlocksState({ isMoreFromOrgOpen: val })}
                  orgName={model.org}
                />
              )}
            </AnimatePresence>
          </motion.div>
        </div>
      </div>

      <InsufficientBalanceModal
        isOpen={errorModalOpen.insufficientBalance}
        onOpenChange={(isOpen) => setErrorModalOpen({ insufficientBalance: isOpen })}
      />

      {model && (
        <ReviewModal
          isOpen={isReviewOpen}
          model={model}
          onOpenChange={setIsReviewOpen}
          sessionId={txData.sessionId}
        />
      )}

      <LimitReachedModal
        isOpen={isLimitModalOpen}
        onConnected={() => setTxData({ transactionHash: undefined })}
        onOpenChange={setIsLimitModalOpen}
      />

      <ApiKeyModal
        isOpen={isApiKeyModalOpen}
        onOpenChange={setIsApiKeyModalOpen}
        onSubmit={(key) => {
          setQueryParams({ apiKey: key });
          setIsApiKeyModalOpen(false);
        }}
      />
    </AnimateRoute>
  );
};

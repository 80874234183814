import { twMerge } from 'tailwind-merge';

import { useScrollableRef } from 'shared/hooks/useScrollableRef';

import type { CustomTag } from './Tag';

import { Tag } from './Tag';

type Prop = {
  className?: string;
  tags: CustomTag[];
};

export const TagWrapper = ({ className, tags }: Prop) => {
  const ref = useScrollableRef();

  return (
    <div className={twMerge('flex select-none gap-1 overflow-x-auto scrollbar-none', className)} ref={ref}>
      {tags?.map(({ order, tag, type }, idx) => <Tag key={idx} order={order} tag={tag} type={type} />)}
    </div>
  );
};

import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import type { DAI } from 'shared/api/dai/types';
import type { Model } from 'shared/api/models/types';

import { useUser } from 'app/stores/user';
import { BackButton } from 'app/ui/BackButton';
import { daiKeys } from 'shared/api/dai/queryKeys';
import { useCreateDaiMutation } from 'shared/api/dai/useCreateDaiMutation';
import { useDAIUploadFileMutation } from 'shared/api/dai/useDAIUploadImageMutation';
import { useGetConnectedModelsQuery } from 'shared/api/dai/useGetConnectedModelsQuery';
import { useGetDAIConnectedWalletsQuery } from 'shared/api/dai/useGetDAIConnectedWalletsQuery';
import { useGetUserConnectedWalletsQuery } from 'shared/api/dai/useGetUserConnectedWalletsQuery';
import { useUpdateDaiMutation } from 'shared/api/dai/useUpdateDaiMutation';
import { catchError } from 'shared/helpers/parseAxiosError';
import { uuidv4 } from 'shared/helpers/uuid';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { toaster } from 'shared/ui/Toast';

import type { FormValues, Step, TokenChain } from '../types';

import { getDefaultImageUrl } from '../helpers/getDefaultImageUrl';
import { Description } from './Description';
import { GettingStarted } from './GettingStarted';
import { Initial } from './Initial';
import { ModelUsage } from './ModelUsage';
import { Step as ProgressStep } from './Step';
import { Tokenomics } from './Tokenomics';

const createSteps = [
  { id: 'start', title: 'Getting Started' },
  { id: 'description', title: 'Description' },
  { id: 'tokenomics', title: 'Tokenomics' },
  { id: 'model', title: 'Model' },
];

const defaultValues: Partial<FormValues> = {
  category: 'defi' as const,
  description: '',
  manuallyConnectedModels: [],
  members: [],
  modelUsageDescriptions: {},
  productLink: '',
  projectName: '',
  shortDescription: '',
  socialLinks: [{ href: '', type: 'whitepaper' as const }],
  tagline: '',
  tokenChain: 'base' as const,
  tokenomicsType: 'create' as const,
};

const urlRegex = /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/;

type Props = {
  daiConnectedModels?: Model[];
  daiToEdit?: DAI;
  initialStep?: Step;
};
export const CreateDAIContent = ({ daiConnectedModels = [], daiToEdit, initialStep = 'initial' }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user } = useUser();
  const [step, setStep] = useState<Step>(initialStep);

  const { isPending: isCreating, mutateAsync: createDai } = useCreateDaiMutation();
  const { isPending: isUpdating, mutateAsync: updateDai } = useUpdateDaiMutation();
  const { isPending: isUploadingImage, mutateAsync: uploadImage } = useDAIUploadFileMutation();
  const { data: daiConnectedWallets } = useGetDAIConnectedWalletsQuery(
    { daiId: daiToEdit?._id || '' },
    { enabled: !!daiToEdit?._id },
  );

  const walletsQuery = useGetUserConnectedWalletsQuery({ userId: user?._id || '' }, { enabled: !!user?._id });

  const connectedAddresses = walletsQuery.data?.list.map(({ walletAddress }) => walletAddress) || [];
  const activeConnectedModels = useGetConnectedModelsQuery(
    { walletAddresses: connectedAddresses },
    { enabled: connectedAddresses.length > 0 },
  );

  const methods = useForm<FormValues>({
    defaultValues: {
      ...defaultValues,
      members: [],

      ...(daiToEdit
        ? {
            ...daiToEdit,
            category: daiToEdit.projectCategory,
            coverImage: daiToEdit.cover,
            manuallyConnectedModels: daiConnectedModels,
            modelUsageDescriptions: daiToEdit.modelDescriptions || defaultValues.modelUsageDescriptions || {},
            socialLinks: daiToEdit.socials,
            stakersReserved: daiToEdit.token?.reservedForStakers,
            ticker: daiToEdit.token?.ticker,
            tokenChain: daiToEdit.importedToken?.chain as TokenChain | undefined,
            tokenContractAddress: daiToEdit.importedToken?.contractAddress,
            tokenImage: daiToEdit.token?.image,
            tokenStartDate: daiToEdit.laterToken?.startDate
              ? new Date(daiToEdit.laterToken?.startDate)
              : undefined,
            tokenSupply: daiToEdit.token?.totalSupply,
            tokenomicsType: daiToEdit.tokenType,
          }
        : {}),
    },
    mode: 'onChange',
  });

  const {
    formState: { errors, isValid },
    handleSubmit,
    reset,
    watch,
  } = methods;

  const [coverImage, profileImage, shortDescription, projectName, tagline] = watch([
    'coverImage',
    'profileImage',
    'shortDescription',
    'projectName',
    'tagline',
  ]);

  const [socialLinks, description, productLink] = watch(['socialLinks', 'description', 'productLink']);
  const [
    tokenomicsType,
    tokenImage,
    ticker,
    tokenSupply,
    stakersReserved,
    tokenChain,
    tokenContractAddress,
    tokenStartDate,
  ] = watch([
    'tokenomicsType',
    'tokenImage',
    'ticker',
    'tokenSupply',
    'stakersReserved',
    'tokenChain',
    'tokenContractAddress',
    'tokenStartDate',
  ]);

  const getIsContinueDisabled = () => {
    if (step === 'start') {
      return !coverImage || !profileImage || !shortDescription || !projectName || !tagline;
    }

    if (step === 'description') {
      return (
        !description ||
        !urlRegex.test(productLink) ||
        !socialLinks.length ||
        socialLinks.some((link) => !urlRegex.test(link.href))
      );
    }

    if (step === 'tokenomics') {
      if (tokenomicsType === 'create') {
        console.log('tokenomicsType', tokenImage, tokenSupply, stakersReserved);
        return !tokenImage || !ticker || !tokenSupply || !stakersReserved;
      }
      if (tokenomicsType === 'import') {
        return !tokenChain || !tokenContractAddress;
      }

      if (tokenomicsType === 'later') {
        return !tokenStartDate || !tokenImage || !ticker;
      }
    }

    if (step === 'model') {
      console.log('errors', errors, isValid, walletsQuery.data?.list.length);
      return (
        !isValid ||
        (daiToEdit
          ? (daiConnectedWallets?.list || []).length === 0 && !walletsQuery.data?.list.length
          : !walletsQuery.data?.list.length)
      );
    }
  };

  const isDisabled = getIsContinueDisabled();

  const handleCreate = async (values: FormValues) => {
    try {
      const {
        category,
        coverImage,
        description,
        manuallyConnectedModels,
        members,
        modelUsageDescriptions,
        productLink,
        profileImage,
        projectName,
        shortDescription,
        socialLinks,
        stakersReserved,
        tagline,
        ticker,
        tokenChain,
        tokenContractAddress,
        tokenImage,
        tokenStartDate,
        tokenSupply,
        tokenomicsType,
      } = values;

      let coverImageUrl = getDefaultImageUrl(coverImage);

      if (!coverImageUrl) {
        const { imageUrl } = await uploadImage({ file: coverImage as File });
        coverImageUrl = imageUrl;
      }

      let profileImageUrl = getDefaultImageUrl(profileImage);

      if (!profileImageUrl) {
        const { imageUrl } = await uploadImage({ file: profileImage as File });
        profileImageUrl = imageUrl;
      }

      const daiMembers = members.map((member) => ({
        about: member.about,
        avatar: member.avatar,
        connectedUserId: member.userId,
        id: uuidv4(),
        name: member.name,
        socialLinks: member.socialLinks,
        title: member.title,
      }));

      let createdToken: DAI['token'] = undefined;

      if (tokenomicsType === 'create') {
        let tokenImageUrl = tokenImage && getDefaultImageUrl(tokenImage);
        if (tokenImage && !tokenImageUrl) {
          const { imageUrl } = await uploadImage({ file: tokenImage as File });

          tokenImageUrl = imageUrl;
        }

        createdToken = {
          image: tokenImageUrl!,
          reservedForStakers: stakersReserved!,
          ticker: ticker!,
          totalSupply: tokenSupply!,
        };
      }

      let importedToken: DAI['importedToken'] = undefined;

      if (tokenomicsType === 'import') {
        importedToken = {
          chain: tokenChain!,
          contractAddress: tokenContractAddress!,
        };
      }

      let laterToken: DAI['laterToken'] = undefined;
      let laterTokenData: Pick<Exclude<DAI['token'], undefined>, 'image' | 'ticker'> | undefined = undefined;

      if (tokenomicsType === 'later' && tokenStartDate) {
        let tokenImageUrl = tokenImage && getDefaultImageUrl(tokenImage);
        if (tokenImage && !tokenImageUrl) {
          const { imageUrl } = await uploadImage({ file: tokenImage as File });

          tokenImageUrl = imageUrl;
        }

        laterToken = {
          startDate: tokenStartDate.getTime(),
        };

        laterTokenData = {
          image: tokenImageUrl!,
          ticker: ticker!,
        };
      }

      if (daiToEdit) {
        const res = await updateDai({
          connectedModels: [
            ...(activeConnectedModels.data?.list || []).map(({ model }) => model._id),
            ...(manuallyConnectedModels || []).map((model) => model._id),
          ],
          connectedWalletIds: [
            ...(walletsQuery.data?.list || []).map((w) => w._id),
            ...(daiConnectedWallets?.list || []).map((w) => w._id),
          ],
          cover: coverImageUrl || '',
          description,
          id: daiToEdit._id,
          importedToken: tokenomicsType === 'import' ? importedToken : undefined,
          laterToken,
          members: daiMembers,
          modelDescriptions: modelUsageDescriptions,
          productLink,
          profileImage: profileImageUrl || '',
          projectCategory: category,
          projectName,
          shortDescription,
          socials: socialLinks,
          tagline,
          token:
            tokenomicsType === 'create'
              ? createdToken
              : tokenomicsType === 'later'
                ? laterTokenData
                : undefined,
          tokenType: tokenomicsType,
        });

        toaster.success('Your DAI has been updated');

        navigate(`/dai/${res.data._id}`);

        return;
      }

      const res = await createDai({
        connectedModels: [
          ...(activeConnectedModels.data?.list || []).map(({ model }) => model._id),
          ...(manuallyConnectedModels || []).map((model) => model._id),
        ],
        connectedWalletIds: walletsQuery.data?.list.map((w) => w._id) || [],
        cover: coverImageUrl || '',
        description,
        importedToken: tokenomicsType === 'import' ? importedToken : undefined,
        laterToken,
        members: daiMembers,
        modelDescriptions: modelUsageDescriptions,
        productLink,
        profileImage: profileImageUrl || '',
        projectCategory: category,
        projectName,
        shortDescription,
        socials: socialLinks,
        tagline,
        token:
          tokenomicsType === 'create'
            ? createdToken
            : tokenomicsType === 'later'
              ? laterTokenData
              : undefined,
        tokenType: tokenomicsType,
      });

      toaster.success('Your DAI has been created');

      const userDaisKey = daiKeys.userDAIs({ limit: 100, skip: 0, userId: user?._id || '' });
      queryClient.resetQueries({ queryKey: userDaisKey });

      navigate(`/dai/${res.data._id}`);
    } catch (e) {
      catchError(e);
    }
  };

  const handleDAIEdit = (dai: DAI) => {
    navigate(`/dai/${dai._id}/edit`);
  };

  return (
    <AnimateRoute className="grow p-4">
      <FormProvider {...methods}>
        <BackButton className="mb-3 text-sm font-normal text-clay-350" onClick={() => navigate('/')}>
          Back to DAI
        </BackButton>
        <div className="flex h-full max-h-[calc(100dvh-120px)] flex-1 grow flex-col overflow-hidden rounded-xl border border-clay-20 bg-white py-4">
          <div className="border-b border-clay-20 px-5 pb-3">
            <div className="flex flex-col gap-0.5">
              <div className="text-xl font-medium"> {projectName || 'My DAI'}</div>
              <div className="text-sm text-clay-400">{daiToEdit ? 'Edit Project' : 'Draft Project'}</div>
            </div>
          </div>
          <div className="flex grow flex-col overflow-hidden px-3">
            <header className="flex items-center justify-center gap-3 py-3">
              {createSteps.map((s, idx) => {
                return (
                  <ProgressStep isActive={s.id === step} key={s.id} stepNumber={idx + 1}>
                    {s.title}
                  </ProgressStep>
                );
              })}
            </header>

            <div className="mb-6 flex grow flex-col overflow-scroll rounded-lg bg-clay-10 p-6">
              {step === 'initial' && (
                <Initial
                  onEdit={handleDAIEdit}
                  onStart={() => {
                    reset(defaultValues);
                    setStep('start');
                  }}
                />
              )}
              {step === 'start' && <GettingStarted />}
              {step === 'description' && <Description />}
              {step === 'tokenomics' && <Tokenomics />}
              {step === 'model' && <ModelUsage daiToEdit={daiToEdit} />}
            </div>
            {step !== 'initial' && (
              <div className="flex w-full items-center justify-between">
                <Button
                  color="secondary"
                  onClick={() => {
                    if (step === 'start') {
                      if (daiToEdit) {
                        reset({});
                        navigate('/dai/create');
                        return;
                      }
                      setStep('initial');
                    }

                    if (step === 'description') {
                      setStep('start');
                    }

                    if (step === 'tokenomics') {
                      setStep('description');
                    }

                    if (step === 'model') {
                      setStep('tokenomics');
                    }
                  }}
                >
                  {step === 'start' ? 'Cancel' : 'Back'}
                </Button>
                <Button
                  disabled={isDisabled}
                  isLoading={isCreating || isUploadingImage || isUpdating}
                  onClick={() => {
                    if (step === 'start') {
                      setStep('description');
                    }

                    if (step === 'description') {
                      setStep('tokenomics');
                    }

                    if (step === 'tokenomics') {
                      setStep('model');
                    }

                    if (step === 'model') {
                      handleSubmit(handleCreate)();
                    }
                  }}
                >
                  {step === 'model' ? (daiToEdit ? 'Save' : 'Create') : 'Continue'}
                </Button>
              </div>
            )}
          </div>
        </div>
      </FormProvider>
    </AnimateRoute>
  );
};

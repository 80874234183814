import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { DAI } from './types';

import { daiKeys } from './queryKeys';

type Response = {
  limit: number;
  list: DAI[];
  skip: number;
  totalCount: number;
};

type Params = {
  limit: number;
  skip: number;
  userId: string;
};

export const useGetUserDAIsQuery = (params: Params, options: UseQueryOptions<Response> = {}) => {
  return useQuery({
    queryFn: async () => {
      const { data } = await axiosInstance.get<Response>(`/dai/users/${params.userId}/list`, {
        params: params,
      });

      return data;
    },
    queryKey: daiKeys.userDAIs(params),
    ...options,
  });
};

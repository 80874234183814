import { useEffect, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import { useNavigateToTop } from 'shared/hooks/navigate';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';

import banner1Src from '../assets/banner1.png';
import banner2Src from '../assets/banner2.png';

export const CreateDaiBanner = () => {
  const [bannerName, setBannerName] = useState('banner1');
  const navigate = useNavigateToTop();

  useEffect(() => {
    const interval = setInterval(() => {
      setBannerName((prev) => (prev === 'banner1' ? 'banner2' : 'banner1'));
    }, 7000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div className="relative h-full min-h-64 overflow-hidden">
      <AnimatePresence>
        {bannerName === 'banner1' && (
          <motion.div
            animate={{ opacity: 1 }}
            className="absolute flex size-full flex-col gap-4 rounded-xl bg-cover bg-center bg-no-repeat p-8"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            style={{ backgroundImage: `url(${banner1Src})` }}
          >
            <div className="text-4xl/7 font-medium tracking-tighter text-white md:text-3xl/9">
              Bold AI ideas that can <br /> change the world.
            </div>

            <Button className="mt-auto w-fit pl-2.5" color="dark" onClick={() => navigate('/dai/create')}>
              <Icon className="size-5 text-white" name="customDai" />
              Create a DAI <sup className="-ml-0.5 text-[6.5px] font-light">TM</sup>
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {bannerName === 'banner2' && (
          <motion.div
            animate={{ opacity: 1 }}
            className="absolute flex size-full flex-col gap-4 rounded-xl bg-cover bg-center bg-no-repeat p-4 md:p-8"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            style={{ backgroundImage: `url(${banner2Src})` }}
          >
            <div className="text-2xl/7 font-normal tracking-tighter text-white md:text-[26px]/8">
              When users stake USDC, <br /> Nesa subsidizes that <br /> DAI’s inference and <br /> training
              costs.
            </div>

            <div className="mt-auto flex flex-col">
              <div className="text-sm text-white">Up to</div>
              <div className="text-4xl font-medium text-[#F5FFC7]">6%</div>
              <div className="text-xs text-white">annualized of TVL</div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

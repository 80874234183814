import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { daiKeys } from './queryKeys';

type Response = {
  list: {
    model: { _id: string; modelName: string; name: string };
    sessionCount: number;
  }[];
};

type Params = {
  walletAddresses: string[];
};

export const useGetConnectedModelsQuery = (params: Params, options: UseQueryOptions<Response> = {}) => {
  return useQuery({
    queryFn: async () => {
      const { data } = await axiosInstance.get<Response>('/dai/wallets/detected-models', { params: params });

      return data;
    },
    queryKey: daiKeys.connectedModels(params),
    ...options,
  });
};

import type { ReactNode } from 'react';

import logoSrc from 'app/assets/images/logo.svg';
import { useUser } from 'app/stores/user';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';

type Props = {
  description: ReactNode;
  icon: ReactNode;
  onBackClick: () => void;
  onOpenChange: (open: boolean) => void;
  onUpgradeToProClick: () => void;
  open: boolean;
  subtitle: string;
  title: string;
};

export const PageIsInAlphaModal = ({
  description,
  icon,
  onBackClick,
  onOpenChange,
  onUpgradeToProClick,
  open,
  subtitle,
  title,
}: Props) => {
  const { proVersion } = useUser();

  return (
    <Modal onOpenChange={onOpenChange} open={open}>
      <Modal.Content className="w-full !max-w-md" innerClassName="sm:p-6">
        <div className="mb-5">
          <img alt="" className="w-14" src={logoSrc} />
        </div>
        <h2 className="mb-2.5 text-[1.75rem] font-semibold leading-8 text-clay-900">{title}</h2>
        <p className="text-base text-clay-500">{subtitle}</p>
        <div className="my-6 flex items-start gap-9 border-t border-clay-20 pt-6">
          <div className="relative flex size-12 items-center justify-center rounded-full bg-tusk-100 outline-4 outline-[#C2D48D33]">
            {icon}
          </div>
          <div className="flex-1 text-base/5 font-medium">{description}</div>
        </div>
        <footer className="flex gap-3">
          <Button className="outline-none" color="secondary" onClick={onBackClick} size="small">
            Back
          </Button>
          <Button
            className="flex-1"
            disabled={!!proVersion?.isPro}
            onClick={onUpgradeToProClick}
            size="small"
          >
            {proVersion?.isPro ? 'On early access list' : 'Upgrade to Pro'}
          </Button>
        </footer>
      </Modal.Content>
    </Modal>
  );
};

import type { HTMLProps } from 'react';

import { twMerge } from 'tailwind-merge';

type TagType = 'default' | 'green' | 'purple';

export type CustomTag = {
  order: number;
  tag: string;
  type: TagType;
};

const getStyleOfTagByType: Record<TagType, HTMLProps<HTMLElement>['className']> = {
  default: 'border-blue-50 text-clay-300',
  green: 'border-tusk-200 bg-green-100/40 text-green-500',
  purple: 'border-primary-150 bg-primary-30 text-primary-800',
};

export const Tag = ({ tag, type = 'default' }: CustomTag) => {
  return (
    <div
      className={twMerge(
        'h-6 w-fit text-nowrap rounded border p-1.5 text-xs font-semibold uppercase leading-3',
        getStyleOfTagByType[type],
      )}
    >
      {tag}
    </div>
  );
};

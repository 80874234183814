import type { DAI } from 'shared/api/dai/types';

import { Modal } from 'shared/ui/Modal';

import DiscordIcon from '../assets/discord1.svg?react';
import bgSrc from '../assets/team-bg.png';
import TelegramIcon from '../assets/telegram.svg?react';
import TwitterIcon from '../assets/twitter1.svg?react';

type Props = {
  isOpen: boolean;
  member: DAI['members'][number];
  onOpenChange: (isOpen: boolean) => void;
};

export const TeamMemberModel = ({ isOpen, member, onOpenChange }: Props) => {
  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="!max-w-2xl">
        <Modal.CloseButton className="absolute right-4 top-4 z-20" />
        <div
          className="absolute inset-x-0 top-0 h-24 rounded-xl bg-cover  bg-center"
          style={{ backgroundImage: `url(${bgSrc})` }}
        ></div>

        <div className="relative pt-4">
          <div
            className="mb-4 size-28 rounded-full bg-clay-100 bg-cover bg-center bg-no-repeat"
            style={member.avatar ? { backgroundImage: `url(${member.avatar})` } : {}}
          ></div>

          <div className="mb-4 grid grid-cols-5 gap-4">
            <div className="col-span-2 flex flex-col gap-1">
              <h1 className="text-2xl font-medium">{member.name}</h1>
              <p className="text-base font-light text-clay-400">{member.title}</p>
            </div>
            {(member.socialLinks || []).length > 0 && (
              <div className="col-span-3 flex flex-col gap-1">
                <div className="text-base font-light text-clay-400">Links</div>

                <div className="flex flex-wrap gap-2">
                  {member.socialLinks?.map(({ href, type }) => {
                    return (
                      <div
                        className="flex h-7 cursor-pointer items-center justify-center gap-2 rounded-full bg-clay-10 px-3 text-sm font-medium"
                        key={type}
                        onClick={() =>
                          window.open(href.startsWith('http') ? href : `https://${href}`, '_blank')
                        }
                      >
                        {type === 'discord' ? (
                          <>
                            <DiscordIcon className="size-4" /> Discord
                          </>
                        ) : type === 'twitter' ? (
                          <>
                            <TwitterIcon className="size-4" /> Twitter
                          </>
                        ) : type === 'telegram' ? (
                          <>
                            <TelegramIcon className="size-4" /> Telegram
                          </>
                        ) : type === 'whitepaper' ? (
                          <>Whitepaper</>
                        ) : (
                          <span className="capitalize">{type}</span>
                        )}
                      </div>
                    );
                  })}
                  {/* 
                <div className="flex h-7 items-center justify-center gap-2 rounded-full bg-clay-10 px-3 text-sm font-medium">
                  <TelegramIcon className="size-4" /> Telegram
                </div> */}
                </div>
              </div>
            )}
          </div>

          <div className="whitespace-pre-line pb-3 text-[14px] font-light text-clay-400">
            {member.about || ''}
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Bot, Boxes, ChevronDown, FileBox } from 'lucide-react';
import { twJoin, twMerge } from 'tailwind-merge';

import { useUser } from 'app/stores/user';
import { UploadModelModal } from 'features/UploadModelModal';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Popover } from 'shared/ui/Popover';
import { toaster } from 'shared/ui/Toast';

import { CreationItem } from './ui/CreationItem';

export const MyModel = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [isUploadOpen, setUploadOpen] = useState(false);
  const [isOpenCreationPopover, setIsCreationPopover] = useState(false);

  if (!user) return null;

  return (
    <div className="flex h-9 items-center">
      <div
        className={twMerge(
          'flex h-full w-fit cursor-pointer items-center justify-between rounded-lg bg-clay-20 p-0.5 pl-2.5 transition-colors hover:bg-primary-40 2xl:min-w-fit',
        )}
        onClick={() => navigate('/nodes')}
      >
        <div className="flex size-fit items-center gap-1">
          <div className="text-sm font-semibold">My AI Node</div>
          <div className="size-1 self-start rounded bg-green-600"> </div>
        </div>

        <div className="ml-3 flex gap-px">
          <div className="flex h-8 items-center rounded-l-md bg-white p-2">
            <Icon className="size-3.5 text-clay-400" name="boltStroke" svgProps={{ fill: 'none' }} />
            <span className="ml-0.5 text-xs font-semibold">0.0</span>
          </div>

          <div className="flex h-8 items-center rounded-r-md bg-white p-2">
            <Icon className="size-3 text-primary-800" name="crown" />
            <span className="ml-1 text-xs font-semibold">99</span>
          </div>
        </div>
      </div>

      <Popover onOpenChange={setIsCreationPopover} open={isOpenCreationPopover}>
        <Popover.Trigger asChild>
          <div className="relative ml-3 flex h-9 cursor-pointer items-center gap-1 rounded-lg bg-primary-800 pl-3 pr-4">
            <div className="absolute inset-0 rounded-lg bg-primary-radial opacity-0 transition-opacity duration-500 hover:opacity-100"></div>
            <Icon className="pointer-events-none z-10 size-4 text-white" name="packagePlus" />
            <span className="pointer-events-none z-10 whitespace-nowrap text-sm text-white">Create</span>
            <ChevronDown
              className={twJoin(
                'size-4 text-white duration-200',
                isOpenCreationPopover ? 'rotate-180' : 'rotate-0',
              )}
            />
          </div>
        </Popover.Trigger>
        <Popover.Content align="start" className="p-1">
          <CreationItem
            description="Build a decentralized AI project or protocol"
            icon={<Icon className="size-5 text-transparent" name="boxWithStart" />}
            onClick={() => {
              navigate('/dai/create');
              setIsCreationPopover(false);
            }}
            title={
              <span>
                Create a <span className="font-bold">DAI</span>™
              </span>
            }
          />
          <CreationItem
            description="Earn when your AI model is used in a DAI"
            icon={<Boxes className="size-5 text-clay-350" name="boxWithStart" strokeWidth={1.5} />}
            onClick={() => {
              setIsCreationPopover(false);
              setUploadOpen(true);
            }}
            title={
              <span>
                Upload a <span className="font-bold">Model</span>
              </span>
            }
          />
          <CreationItem
            description="Earn when your Dataset is used in training"
            icon={<FileBox className="size-5 text-clay-350" name="boxWithStart" strokeWidth={1.5} />}
            onClick={() => {
              navigate('/datasets?openCreationModal=1');
              setIsCreationPopover(false);
            }}
            title={
              <span>
                Upload a <span className="font-bold">Dataset</span>
              </span>
            }
          />
          <CreationItem
            description="Earn when your Agent is used in a DAI"
            icon={<Bot className="size-[1.375rem] text-clay-350" name="boxWithStart" strokeWidth={1.5} />}
            onClick={() => {
              toaster.info('Coming Soon');
              setIsCreationPopover(false);
            }}
            title={
              <span>
                Upload an <span className="font-bold">Agent</span>
              </span>
            }
          />
        </Popover.Content>
      </Popover>

      <Button as={Link} className="ml-3 h-9 gap-2" color="secondary" to="/posts">
        <div className="relative size-3.5">
          <Icon className="size-3.5" name="messageSquare" safeArea="0" />
          <div className="absolute -top-1 right-[-2px] size-2 rounded-full border-2 border-clay-20 bg-red-900" />
        </div>
        Posts
      </Button>

      {isUploadOpen && <UploadModelModal isOpen={isUploadOpen} onOpenChange={setUploadOpen} />}
    </div>
  );
};

import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useController, useFormContext } from 'react-hook-form';

import { ImagePlus } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { Label } from 'shared/ui/Label';
import { TextArea } from 'shared/ui/TextArea';

import type { FormValues } from '../types';

import { getFileSrc } from '../helpers/getFileSrc';
import { StepHeader } from './StepHeader';

export const GettingStarted = () => {
  const { control, register } = useFormContext<FormValues>();

  const { field: profileImage } = useController({ control, name: 'profileImage' });
  const { field: coverImage } = useController({ control, name: 'coverImage' });
  const onDrop = useCallback((acceptedFiles: File[]) => {
    console.log('acceptedFiles', acceptedFiles);
    profileImage.onChange(acceptedFiles[0]);
    // setUploadedFiles(acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCoverDrop = useCallback((acceptedFiles: File[]) => {
    console.log('acceptedFiles', acceptedFiles);
    coverImage.onChange(acceptedFiles[0]);
    // setUploadedFiles(acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { getInputProps, getRootProps, isDragActive } = useDropzone({ onDrop });
  const {
    getInputProps: getCoverInputProps,
    getRootProps: getCoverRootProps,
    isDragActive: isCoverDragActive,
  } = useDropzone({ onDrop: onCoverDrop });

  const isCoverImage =
    typeof coverImage.value === 'string'
      ? !coverImage.value.endsWith('.mp4')
      : coverImage.value?.type?.includes('image');

  const isCoverVideo =
    typeof coverImage.value === 'string'
      ? coverImage.value.endsWith('.mp4')
      : coverImage.value?.type?.includes('video');

  return (
    <div className="m-auto flex w-full max-w-3xl flex-col rounded-2xl border border-clay-20  bg-white shadow-lg ">
      <StepHeader stepNumber={1} title="Getting Started" />

      <div className="flex flex-col gap-3 p-4">
        <div>
          <Label className="text-xs">
            Profile Image <span className="ml-1 text-red-900">*</span>
          </Label>

          <div className="flex flex-col gap-4">
            <div
              {...getRootProps()}
              className={twMerge(
                'flex cursor-pointer flex-col rounded-lg border-[1.2px] border-dashed border-clay-40 p-3',
                'font-normal text-clay-400 transition-all duration-200',
                isDragActive && 'border-primary-800',
              )}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    {profileImage.value ? (
                      <img className="size-12 rounded-lg object-cover" src={getFileSrc(profileImage.value)} />
                    ) : (
                      <div className="flex size-12 items-center justify-center rounded-lg bg-clay-10">
                        <ImagePlus className="size-4 text-clay-350" />
                      </div>
                    )}

                    <div className="flex flex-col gap-0.5">
                      <div className="font-medium tracking-tight text-clay-800">
                        Upload DAI profile picture
                      </div>
                      <div className="text-sm font-light text-clay-350">
                        SVG, PNG, JPG or GIF (rec. 500 x 500px)
                      </div>
                    </div>
                  </div>

                  <Button color="white" size="extra-small">
                    Browse
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>

        <Input
          label={
            <span className="text-xs/none font-normal text-clay-500 2xl:text-sm">
              Project Name <span className="text-red-900">*</span>
            </span>
          }
          {...register('projectName', { required: true })}
        />

        <Input
          label={
            <span className="text-xs/none font-normal text-clay-500 2xl:text-sm">
              Tagline <span className="text-red-900">*</span>
            </span>
          }
          {...register('tagline', { required: true })}
        />

        <div className="relative">
          <TextArea
            className="pb-1.5"
            innerClassName="text-sm"
            label={
              <span className="text-xs/none font-normal text-clay-500 2xl:text-sm">
                Short Description <span className="text-red-900">*</span>
              </span>
            }
            maxLength={450}
            {...register('shortDescription', { required: true })}
          />

          <div className="-top-3 text-xs font-light text-clay-380">
            This is a short description for your DAI. Long description can be filled in the next step.
          </div>
        </div>

        <div>
          <Label className="text-xs">
            Upload Cover Image or Video <span className="ml-1 text-red-900">*</span>
          </Label>

          <div className="flex flex-col gap-4">
            <div
              {...getCoverRootProps()}
              className={twMerge(
                'flex cursor-pointer flex-col rounded-lg border-[1.2px] border-dashed border-clay-40 p-3',
                'font-normal text-clay-400 transition-all duration-200',
                isCoverDragActive && 'border-primary-800',
              )}
            >
              <input {...getCoverInputProps()} />
              {isCoverDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    {isCoverImage ? (
                      <img className="size-12 rounded-lg object-cover" src={getFileSrc(coverImage.value)} />
                    ) : isCoverVideo ? (
                      <video
                        autoPlay
                        className="size-12 rounded-lg object-cover"
                        muted
                        playsInline
                        src={getFileSrc(coverImage.value)}
                      />
                    ) : (
                      <div className="flex size-12 items-center justify-center rounded-lg bg-clay-10">
                        <ImagePlus className="size-4 text-clay-350" />
                      </div>
                    )}

                    <div className="flex flex-col gap-0.5">
                      <div className="font-medium tracking-tight text-clay-800">
                        Upload cover image or video
                      </div>
                      <div className="text-sm font-light text-clay-350">
                        SVG, PNG, JPG GIF or MP4 (rec. 1200 x 600px). Max file size 10mb
                      </div>
                    </div>
                  </div>

                  <Button color="white" size="extra-small">
                    Browse
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

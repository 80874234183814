import { useLocation, useNavigate } from 'react-router-dom';

import type { Model } from 'shared/api/models/types';

import { BackButton } from 'app/ui/BackButton';

import { CuriousBlock } from './ui/CuriousBlock';
import { InferenceMap } from './ui/InferenceMap';
import { ModelCard } from './ui/ModelCard';

type Props = {
  isLoading?: boolean;
  model?: Model;
  onReviewsClick?: () => void;
  txDataCode: null | number;
  txDataErrorMessage: null | string;
};

export const QueryHeader = ({ isLoading, model, onReviewsClick, txDataCode, txDataErrorMessage }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isFromSidebar = !!location.state?.sidebar;

  return (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-5">
      <div className="flex flex-col gap-4 lg:col-span-2">
        <BackButton
          className="gap-2 text-base font-normal text-clay-350 hover:text-clay-500"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back to Gallery
        </BackButton>

        <ModelCard isModelLoading={isLoading} model={model} onReviewsClick={onReviewsClick} />
      </div>

      <div className="flex w-full flex-col gap-3 xs:flex-row lg:col-span-3 lg:ml-auto lg:h-36 lg:min-h-32 lg:w-auto lg:grid-cols-5">
        {isFromSidebar && <CuriousBlock />}
        <InferenceMap model={model} txDataCode={txDataCode} txDataErrorMessage={txDataErrorMessage} />
      </div>
    </div>
  );
};

import { memo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { AnimatePresence, motion } from 'framer-motion';
import { useAccount } from 'graz';
import { Info } from 'lucide-react';
import { twMerge } from 'tailwind-merge';
import { v4 as uuid } from 'uuid';

import type { VoiceMessage } from 'features/AudioPanel/AudioPanel';
import type { QueryParams } from 'features/ModelQueryParams/types';
import type { Section } from 'features/QueryMenu/QueryMenu';
import type { TransactionData } from 'pages/QueryPage/types';
import type { Model } from 'shared/api/models/types';

import { useUser } from 'app/stores/user';
import { AudioPanel } from 'features/AudioPanel';
import { LimitReachedModal } from 'features/LimitReachedModal';
import { FilePreview } from 'features/ModelQuery/ui/FilePreview';
import {
  getIsDocumentQA,
  getIsOCR,
  getIsSentenceSimilarity,
  getIsTimeSeriesForecasting,
} from 'features/ModelQueryParams/helpers/modelParamsChecks';
import { QueryLimitOverview } from 'features/QueryLimitOverview';
import { UpgradeToProTriggerModal } from 'features/UpgradeToProModal';
import { getIsAudioModel } from 'pages/QueryPage/helpers/getIsAudioModel';
import { getIsImageModel } from 'pages/QueryPage/helpers/getIsImageModel';
import { getIsQuestionContextModel } from 'pages/QueryPage/helpers/getIsQuestionContextModel';
import { useModelLimitsQuery } from 'shared/api/models/useModelLimitsQuery';
import { getAudioFileDuration } from 'shared/helpers/getAudioFileDuration';
import { getBlobFromFile } from 'shared/helpers/getBlobFromFile';
import { Button } from 'shared/ui/Button';
import { FilePicker } from 'shared/ui/FilePicker';
import { Icon } from 'shared/ui/Icon';
import { Label } from 'shared/ui/Label';
import { Switch } from 'shared/ui/Switch';
import { TextArea } from 'shared/ui/TextArea';
import { toaster } from 'shared/ui/Toast';
import { Tooltip } from 'shared/ui/Tooltip';

import flagSrc from '../../LLMQuery/assets/encrypt-flag.png';

type Props = {
  errors: Record<string, boolean>;
  isConnectingToModel?: boolean;
  isRequestingSession?: boolean;
  model?: Model;
  onQueryParamsChange: (params: Partial<QueryParams>) => void;
  // onRequestSession: () => void;
  onSendQuery: (queryParams?: Partial<QueryParams>) => void;
  queryParams: QueryParams;
  txData: TransactionData;
};

const MB_IN_BYTES = 1048576;

export const RequestContent = memo(
  ({
    errors,
    isConnectingToModel,
    isRequestingSession,
    model,
    onQueryParamsChange,
    onSendQuery,
    queryParams,
    txData,
  }: Props) => {
    const { section } = useParams<{ id: string; section?: Section }>();

    const { proVersion, user } = useUser();
    const { isConnected } = useAccount();

    const { data: limitData } = useModelLimitsQuery(
      { modelId: model?._id || '' },
      { enabled: !!user?._id && !!model },
    );

    const isLimitReached = limitData && (limitData.modelLimitLeft <= 0 || limitData.totalLimitLeft <= 0);
    const showLimitReachedError = isLimitReached && !isConnected;

    const [isLimitOpen, setIsLimitOpen] = useState(false);

    const [isVoiceRecording, setIsVoiceRecording] = useState(false);
    const [voice, setVoice] = useState<VoiceMessage>();

    const isModelExpectImage = getIsImageModel(model);
    const isModelExpectAudio = getIsAudioModel(model);
    const isQuestionNContext = getIsQuestionContextModel(model);
    const isDocumentQA = model && getIsDocumentQA(queryParams, model?.type);
    const isOCR = model && getIsOCR(queryParams, model?.type);
    const isSentenceSimilarity = model && getIsSentenceSimilarity(queryParams, model?.type);
    const isTimeSeriesForecasting = model && getIsTimeSeriesForecasting(queryParams, model?.type);

    const isPrivateAvailable = true;
    // const isPrivateAvailable = model?.type === 'image-generation' && model.org !== 'OpenAI';

    const getIsSendDisabled = () => {
      if (txData.status === 'progress') return true;

      if (isModelExpectImage) return !(queryParams as { file?: unknown }).file;

      if (isModelExpectAudio)
        return isVoiceRecording || ('audioBlob' in queryParams && !queryParams.audioBlob);

      const hasEmptyQuestion = 'question' in queryParams && !queryParams.question;

      if (isQuestionNContext) {
        return hasEmptyQuestion || ('context' in queryParams && !queryParams.context);
      }

      if (isDocumentQA || isOCR) {
        return !queryParams.context || !queryParams.file;
      }

      if (isSentenceSimilarity) {
        return !queryParams.content || !queryParams.context;
      }

      return hasEmptyQuestion;
    };

    const isSendDisabled = getIsSendDisabled();

    const handleUploadFile = async (file: File, availableExt?: string) => {
      if (!file) {
        toaster.error(
          `Make sure you upload the expected file format ${availableExt ? `(${availableExt})` : ''}`,
        );
        return;
      }

      if (file.size > 2 * MB_IN_BYTES) {
        toaster.error('File is too big');

        return;
      }

      onQueryParamsChange({ file });
    };

    return (
      <>
        <AnimatePresence>
          {queryParams.private && (
            <motion.div
              animate={{ opacity: 1 }}
              className="absolute left-1/2 top-0 z-50 flex -translate-x-1/2 items-center justify-center bg-contain bg-top bg-no-repeat px-10 pb-2 pt-1 text-sm text-white"
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              style={{ backgroundImage: `url(${flagSrc})` }}
            >
              <Icon className="mr-2 inline-flex size-4 text-tusk-200" name="lock" />
              <span className="mt-px whitespace-nowrap leading-none">100% Encrypted Request</span>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {model && (
            <QueryLimitOverview
              className={twMerge(
                'absolute left-1/2 top-1.5 z-50 -translate-x-1/2 transition-all',
                queryParams.private && 'top-14',
              )}
              isMinimized={queryParams.private}
              isRequestingSession={isRequestingSession}
              modelId={model?._id}
            />
          )}
        </AnimatePresence>

        <div
          className={twMerge('relative flex min-h-fit flex-1 flex-col scrollbar-none lg:overflow-y-scroll')}
        >
          <div className={twMerge('relative mb-4 flex flex-1 flex-col scrollbar-none lg:overflow-y-scroll')}>
            <div className="mb-3 text-base font-normal text-black">
              <span className="hidden xl:inline">Enter query</span>
              <span className="inline xl:hidden">Query</span>
            </div>

            <div className="relative flex h-full flex-col items-start gap-4">
              {isSentenceSimilarity && (
                <>
                  <div className="relative flex size-full flex-col overflow-hidden rounded-lg">
                    <TextArea
                      className="m-0 size-full p-0"
                      innerClassName="pb-8 resize-none h-full"
                      onChange={(e) => onQueryParamsChange({ content: e.target.value })}
                      placeholder="Enter your content here"
                      rows={undefined}
                      value={queryParams.content}
                    />
                  </div>
                  <div className="relative flex size-full flex-col overflow-hidden rounded-lg">
                    <TextArea
                      className="m-0 size-full p-0"
                      innerClassName="pb-8 resize-none h-full"
                      onChange={(e) => onQueryParamsChange({ context: e.target.value })}
                      placeholder="Enter your sentence here"
                      rows={undefined}
                      value={queryParams.context}
                    />
                  </div>
                </>
              )}
              {!isModelExpectImage && 'question' in queryParams && (
                <div className="relative flex size-full flex-col overflow-hidden rounded-lg">
                  <TextArea
                    className="m-0 size-full p-0"
                    innerClassName="pb-8 resize-none h-full"
                    onChange={(e) => onQueryParamsChange({ question: e.target.value })}
                    placeholder="Enter your query"
                    rows={undefined}
                    value={queryParams.question}
                  />
                </div>
              )}
              {(isQuestionNContext || isDocumentQA || isOCR) && 'context' in queryParams && (
                <TextArea
                  className="size-full p-0"
                  innerClassName="pb-8 resize-none h-full"
                  onChange={(e) => onQueryParamsChange({ context: e.target.value })}
                  placeholder="Enter your context here"
                  value={queryParams.context}
                />
              )}
              {isModelExpectImage && (
                <FilePicker
                  accept=".jpeg,.png,.svg,.jpg"
                  className=" w-full flex-1"
                  onFileChange={([file]) => handleUploadFile(file, '.jpeg,.png,.svg,.jpg')}
                />
              )}
              {isTimeSeriesForecasting && (
                <FilePicker
                  accept=".csv"
                  className=" w-full flex-1"
                  icon="fileLink"
                  onFileChange={([file]) => handleUploadFile(file, '.csv')}
                />
              )}

              {isModelExpectAudio && (
                <div className="flex w-full flex-col gap-3">
                  <AudioPanel
                    isRecording={isVoiceRecording}
                    onChange={(v) => {
                      setVoice(v);
                      onQueryParamsChange({ audioBlob: v.audioBlob });
                    }}
                    onRecordingChange={setIsVoiceRecording}
                    onReset={() => {
                      setVoice(undefined);
                      onQueryParamsChange({ audioBlob: undefined });
                      setIsVoiceRecording(false);
                    }}
                    voice={voice}
                  />
                  {!voice && (
                    <FilePicker
                      accept=".wav"
                      icon="cassette"
                      onFileChange={async ([file]) => {
                        if (!file) {
                          toaster.error(`Make sure you've uploaded .wav audio file`);
                          return;
                        }

                        const promises = [getBlobFromFile(file), getAudioFileDuration(file)] as const;
                        const [blob, duration] = await Promise.all(promises);

                        setVoice({ audioBlob: blob, duration, id: uuid() });
                        onQueryParamsChange({ audioBlob: blob });
                      }}
                    />
                  )}
                </div>
              )}

              {model?.expectedInput && (
                <div
                  className={twMerge(
                    'absolute bottom-1 right-1 flex w-fit items-center gap-0.5 rounded-md bg-clay-20 px-3 py-2 text-xs font-light text-clay-600 transition-colors',
                    errors.inputError && 'bg-primary-800 text-white',
                  )}
                >
                  {model && ['audio', 'data', 'file', 'image', 'text'].includes(model.expectedInput) ? (
                    <>
                      This model requires{' '}
                      <span className="font-semibold text-primary-800">{model.expectedInput}</span> input
                    </>
                  ) : (
                    model?.expectedInput
                  )}
                </div>
              )}
            </div>

            <AnimatePresence>
              {'file' in queryParams && queryParams.file && (
                <FilePreview
                  className="mt-2"
                  file={queryParams.file}
                  onRemove={() => onQueryParamsChange({ file: undefined })}
                />
              )}
            </AnimatePresence>
          </div>

          <div className="mb-4 mt-auto flex flex-wrap items-center justify-between gap-3">
            <Label className="flex max-w-fit cursor-pointer items-center gap-2">
              <Switch
                checked={queryParams.private}
                className=" data-[state=checked]:bg-clay-800 data-[state=checked]:hover:bg-clay-800 data-[state=checked]:focus-visible:bg-clay-800 disabled:bg-clay-20"
                disabled={!isPrivateAvailable}
                onCheckedChange={(isChecked) => onQueryParamsChange({ private: isChecked })}
                onFocus={(e) => e.preventDefault()}
              />
              <span>Private</span>
              <Tooltip
                className="max-w-64"
                content={
                  <>
                    Your data insertion, query, and response is fully encrypted with Nesa’s EE (Equivariant
                    Encryption) for end-to-end&nbsp;privacy.{' '}
                    <Link
                      className="text-primary-800 hover:text-primary-900"
                      target="_blank"
                      to="https://docs.nesa.ai/nesa/technical-designs/security-and-privacy"
                    >
                      Learn more
                    </Link>
                  </>
                }
                header={!isPrivateAvailable && 'EE unavailable for this model'}
              >
                <Info className="size-3" color="#818996" />
              </Tooltip>
            </Label>
            <div className="ml-auto flex items-center gap-4">
              {!proVersion?.isPro && (
                <UpgradeToProTriggerModal
                  redirectPath={`/models/${model?._id}${section ? `/${section}` : ''}`}
                />
              )}
              {user && (
                <Tooltip
                  content={
                    showLimitReachedError
                      ? 'Limit Reached'
                      : isLimitReached && isConnectingToModel
                        ? 'Connecting to a model'
                        : ''
                  }
                  delayDuration={100}
                  open={isRequestingSession || undefined}
                  side="top"
                >
                  <Button
                    className={twMerge(
                      queryParams.private && 'bg-clay-800 py-0 pl-3 hover:bg-clay-900',
                      showLimitReachedError &&
                        'border border-red-1100 bg-red-100 text-red-1100 hover:bg-red-200 disabled:bg-red-100',
                    )}
                    disabled={
                      (!showLimitReachedError && isSendDisabled) || isRequestingSession || isConnectingToModel
                    }
                    onClick={() => {
                      if (showLimitReachedError) {
                        setIsLimitOpen(true);

                        return;
                      }

                      onSendQuery();
                    }}
                  >
                    {queryParams.private && (
                      <div className="mr-2 flex items-center gap-2 border-r border-clay-400/20 py-2 pr-3 text-xs text-clay-300">
                        <Icon className="inline-flex size-4 text-tusk-200" name="lock" />
                        100% Encrypted
                      </div>
                    )}

                    <span className={twMerge(queryParams.private && 'py-2')}>Send query</span>
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
        </div>

        {model && <LimitReachedModal isOpen={isLimitOpen} onOpenChange={setIsLimitOpen} />}
      </>
    );
  },
);

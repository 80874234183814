import type { HTMLAttributeAnchorTarget } from 'react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import { useLocalStorage } from '@uidotdev/usehooks';
import { AxiosError } from 'axios';
import { AnimatePresence } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import type { IconName } from 'shared/ui/Icon';

import { GalleryLogo } from 'app/ui/GalleryLogo';
// import { useUploadModelMutation } from 'shared/api/models/useUploadModelMutation';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Modal } from 'shared/ui/Modal';
import { toaster } from 'shared/ui/Toast';

import uploadSrc from './assets/upload.svg';
import { BuildDockerImage } from './ui/BuildDockerImage';
import { Description } from './ui/Description';
import { Encryption } from './ui/Encryption.tsx';
import { GithubLink } from './ui/GithubLink.tsx';
import { ModelImage } from './ui/ModelImage';
import { Visibility } from './ui/Visibility';

const guides: {
  icon: IconName;
  iconClassName?: string;
  label: string;
  target?: HTMLAttributeAnchorTarget;
  url: string;
}[] = [
  {
    icon: 'bolt',
    iconClassName: 'stroke-clay-400 text-transparent',
    label: 'Getting Started',
    target: '_blank',
    url: 'https://docs.nesa.ai/nesa/using-nesa/getting-started',
  },
  { icon: 'book', iconClassName: '', label: 'Documentation', target: '_blank', url: 'https://docs.nesa.ai' },
];

const steps = [
  { component: ModelImage, name: 'model-image', nextStep: 'description' },
  { component: Description, name: 'description', nextStep: 'build-docker-image', prevStep: 'model-image' },
  {
    component: BuildDockerImage,
    name: 'build-docker-image',
    nextStep: 'github-link',
    prevStep: 'description',
  },
  {
    component: GithubLink,
    name: 'github-link',
    nextStep: 'visibility',
    prevStep: 'build-docker-image',
  },
  { component: Visibility, name: 'visibility', nextStep: 'encryption', prevStep: 'github-link' },
  {
    component: Encryption,
    name: 'encryption',
    prevStep: 'visibility',
  },
];

type Props = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

export type UploadModelValues = {
  buildDockerImage: {
    additionalFiles: File[];
    modelName: string;
    predictFile: File;
    yamlFile: File;
  };
  description: string;
  encryptionEnabled: boolean;
  generatedImage?: string;
  githubLink: string;
  imageDescription: string;
  license: string;
  modelAccessType?: 'private' | 'public';
  modelName: string;
  modelType: string;

  repositoryName: string;
};

export type UploadedModel = {
  _id: string;
  buildDockerImage: {
    additionalFiles?: File[];
    modelName: string;
    predictFile: File;
    yamlFile: File;
  };
  description: string;
  encryptionEnabled?: boolean;
  githubLink?: string;
  image?: string;
  name: string;
  public?: boolean;
  reviewsCount: number;
  type: string;
};

function createObjectId() {
  return hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => hex(Math.random() * 16));
}

function hex(value: number) {
  return Math.floor(value).toString(16);
}

const defaultValues: Partial<UploadModelValues> = {
  description: '',
  encryptionEnabled: false,
  generatedImage: '',
  githubLink: '',
  license: '',
  modelAccessType: undefined,
  modelName: '',
  modelType: '',
  repositoryName: '',
};

export const UploadModelModal = ({ isOpen, onOpenChange }: Props) => {
  const [, setUploadedModels] = useLocalStorage<UploadedModel[]>('uploaded-models', []);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [step, setStep] = useState('model-image');

  const navigate = useNavigate();
  // const { isPending: isUploading, mutateAsync } = useUploadModelMutation();

  const methods = useForm<UploadModelValues>({
    defaultValues,
    mode: 'onChange',
  });

  const { formState, handleSubmit, reset } = methods;
  const { isValid } = formState;

  const handleUploadModel = async () => {
    if (!isValid) {
      toaster.error('Not all required fields are filled');
      return;
    }

    await handleSubmit(async (values) => {
      try {
        setIsUploading(true);

        setTimeout(() => {
          setUploadedModels((prev) => [
            ...prev,
            {
              _id: createObjectId(),
              buildDockerImage: values.buildDockerImage,
              description: values.description,
              encryptionEnabled: values.encryptionEnabled,
              githubLink: values.githubLink,
              image: values.generatedImage,
              modelName: values.modelName,
              name: values.modelName,
              public: values.modelAccessType === 'public',
              reviewsCount: 0,
              type: values.modelType,
            },
          ]);

          setIsUploaded(true);
          setIsUploading(false);
        }, 2000);
        // const { model_id } = await mutateAsync({
        //   description: values.description,
        //   model_name: values.modelName,
        //   model_type: values.modelType,
        //   private: values.modelAccessType === 'private',
        // });

        // if (!model_id) {
        //   toaster.error('No model available');
        //   return;
        // }
      } catch (e) {
        console.log('ERROR', e);
        if (e instanceof AxiosError) {
          const axiosError = e.response?.data?.message;
          toaster.error(axiosError || 'Something went wrong');
        } else {
          toaster.error('Something went wrong');
        }
      }
    })();
  };

  // const mockForModelUploadingStatuses = [
  //   { name: 'gpt-4s', step: 1 },
  //   { name: 'cust-gpt-4o-ri', step: 3 },
  // ];
  const mockForModelUploadingStatuses: { name: string; step: number }[] = [];

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <FormProvider {...methods}>
        <Modal.Content className="w-full md:min-w-fit" innerClassName=" bg-clay-10 p-4 md:p-8">
          <div className="flex flex-col gap-8 md:flex-row">
            <div className="flex w-full flex-col md:w-64">
              <GalleryLogo className="size-12 md:size-14" isBlack />
              <div className="mt-4 md:mt-8">
                <div className="text-2xl font-semibold text-clay-900 md:text-3xl">Upload a Model</div>
                <div className="mt-2 text-sm font-light text-clay-500">
                  Set up your model on Nesa by building a docker image or connecting your Github repository.
                </div>
              </div>
              <div className="mt-6 flex grow flex-col justify-between md:mt-12">
                <div>
                  <div className="mb-3 text-xs font-normal text-clay-500">Guides & Tips</div>

                  <div className="flex flex-col gap-3">
                    {guides.map(({ icon, iconClassName, label, target, url }) => {
                      return (
                        <Link
                          className="group flex items-center gap-1.5"
                          key={label}
                          onClick={() => {
                            if (!url) toaster.info('Coming soon');
                          }}
                          target={target}
                          to={url}
                        >
                          <Icon className={twMerge('size-3.5 text-clay-400', iconClassName)} name={icon} />
                          <span className="text-sm font-normal text-clay-800 transition-colors group-hover:text-primary-800">
                            {label}
                          </span>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
              {mockForModelUploadingStatuses.length > 0 && (
                <div className="my-6">
                  <div className="mb-3 text-xs font-normal text-clay-500">Currently Uploading</div>
                  <div className="flex flex-col gap-0.5">
                    {mockForModelUploadingStatuses.map((upload, index) => (
                      <div
                        className="flex items-center justify-between gap-2 rounded-lg bg-white p-2"
                        key={index}
                      >
                        <div className="min-w-0 text-wrap text-sm font-medium">
                          <span className="block truncate">{upload.name}</span>
                        </div>
                        <div className="flex flex-row items-center gap-2">
                          <div className="flex gap-0.5">
                            {[...Array(5)].map((_, i) => (
                              <div
                                className={twMerge(
                                  'h-1 w-4 rounded bg-green-500 opacity-30',
                                  i < upload.step && 'opacity-100',
                                )}
                                key={i}
                              />
                            ))}
                          </div>
                          <div className="w-4 text-xs font-medium">{upload.step}/5</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {isUploaded && (
                <Button
                  className="mt-14 rounded-lg px-3"
                  isLoading={isUploading}
                  onClick={() => {
                    reset(defaultValues);
                    setIsUploaded(false);
                  }}
                >
                  <Icon className="mr-1 size-4" name="packagePlus" />
                  Upload new model
                </Button>
              )}
            </div>

            <AnimatePresence>
              <div className="mb-6 flex h-auto w-full flex-col justify-start overflow-hidden rounded-xl bg-white shadow-md md:w-[44rem]">
                {isUploaded ? (
                  <div className="my-auto flex flex-col py-6">
                    <img alt="upload" className="size-24 self-center" src={uploadSrc} />

                    <div className="mt-3 w-64 self-center text-center text-2xl/7 font-semibold text-clay-900">
                      Model uploaded successfully!asd
                    </div>
                    <div className="mt-2 w-64 self-center text-center text-sm font-normal text-clay-500">
                      Your model can be accessed now in Nesa Playground. Enjoy!
                    </div>
                    <div
                      className="mt-4 flex h-9 cursor-pointer items-center self-center rounded-lg bg-clay-20 px-3 text-sm text-clay-900 hover:bg-clay-40"
                      onClick={() => {
                        navigate('/');
                        onOpenChange(false);
                      }}
                    >
                      Back to Dashboard
                      <Icon className="ml-2 size-4 p-0" name="arrowUpRight" />
                    </div>
                  </div>
                ) : (
                  steps.map(({ component: Component, name, nextStep, prevStep }) => (
                    <Component
                      isExpanded={step === name}
                      key={name}
                      onNextClick={nextStep ? () => setStep(nextStep) : () => {}}
                      onPrevClick={prevStep ? () => setStep(prevStep) : () => {}}
                      onTitleClick={() => setStep(name)}
                    />
                  ))
                )}
              </div>
            </AnimatePresence>
          </div>

          {!isUploaded && (
            <div className="mt-1 flex items-center justify-between">
              <Button
                className="rounded-lg bg-clay-20 px-3 text-clay-900"
                color="secondary"
                onClick={() => onOpenChange(false)}
                variant="filled-light"
              >
                Cancel
              </Button>

              <Button disabled={!isValid} isLoading={isUploading} onClick={handleUploadModel}>
                Upload Model
              </Button>
            </div>
          )}
        </Modal.Content>
      </FormProvider>
    </Modal>
  );
};

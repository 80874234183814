export const tables: { category: string; description: string; rows: string[][]; title: string }[] = [
  {
    category: 'text-generation',
    description: 'Creating coherent and contextually relevant text using AI models',
    rows: [
      ['Llama-3.2-1B-Instruct', '0.015 / 1M Tokens', '0.125 / 1M Tokens'],
      ['Llama-3.2-3B-Instruct', '0.031 / 1M Tokens', '0.258 / 1M Tokens'],
      ['Llama-3-8B-Lexi-Uncensored', '0.058 / 1M Tokens', '0.489 / 1M Tokens'],
    ],
    title: 'Text generation',
  },

  {
    category: 'image-generation',
    description: 'Creating new images from textual descriptions or latent representations using AI',
    rows: [
      ['FLUX.1-schnell', '0.500 / 1M Tokens', '0.005 / Image (1024 * 1024)'],
      ['LazyMix-Uncensored', '0.188 / 1M Tokens', '0.002 / Image  (1024 * 1024)'],
      ['holodayo-xl-2.1', '0.391 / 1M Tokens', '0.003 / Image  (1024 * 1024)'],
    ],
    title: 'Image Generation',
  },

  {
    category: 'video-generation',
    description: 'Generating video content from textual descriptions using advanced AI models',
    rows: [
      ['Realistic_Vision_V6.0_B1_noVAE_8', '0.500 / 1M Tokens ', '0.025 / Video (1024 x 1024)'],
      ['THUDM/CogVideoX-5b', '0.650 / 1M Tokens ', '0.090 / Video (720 x 480)'],
      ['Animated DreamShaper_8-steps', '0.350 / 1M Tokens ', '0.070 / Video (1024 x 1024)'],
    ],
    title: 'Video Generation',
  },

  {
    category: 'text-to-speech',
    description: 'Converting written text into natural-sounding spoken words using AI',
    rows: [
      ['OuteAI/OuteTTS-0.1-350M', '0.05/ 1M Token', '0.0035 / output (30 sec Audio)'],
      ['suno/bark', '0.060 / 1M Token', '0.0050 / output(30 sec Audio)'],
      ['SWivid/F5-TTS', '0.075 / 1M Token', '0.0055/ output (30 sec Audio)'],
    ],
    title: 'Text to Speech',
  },

  {
    category: 'question-answering',
    description: 'Providing accurate answers to questions posed in natural language',
    rows: [
      [
        'MaRiOrOsSi/t5-base-finetuned-question-answering',
        '0.003 / 1M Tokens',
        '0.0001 / output (answer and indices)',
      ],
      [
        'distilbert/distilbert-base-cased-distilled-squad',
        '0.005/ 1M Tokens',
        '0.003 / output  (answer and indices)',
      ],
      ['tinyroberta-squad2', '0.008/ 1M Tokens', '0.006 / output  (answer and indices)'],
    ],
    title: 'Question Answering',
  },

  {
    category: 'translation',
    description: 'Automatically translating text from one language to another',
    rows: [
      ['google-t5/t5-base', '0.019/ 1M Tokens', '0.039/ 1M Tokens'],
      ['Helsinki-NLP/opus-mt-ar-en', '0.012/ 1M Tokens', '0.035/ 1M Tokens'],
      ['google-t5/t5-large', '0.031/ 1M Tokens', '0.038/ 1M Tokens'],
    ],
    title: 'Machine Translation',
  },

  {
    category: 'image-segmentation',
    description: 'Dividing an image into meaningful regions or objects for detailed analysis',
    rows: [
      ['briaai/RMBG-1.4', '-', '0.0042 / Image'],
      ['facebook/detr-resnet-50-panoptic', '-', '0.0030 / Image'],
      ['apple/deeplabv3-mobilevit-small', '-', '0.0014 / Image'],
    ],
    title: 'Image Segmentation',
  },

  {
    category: 'automatic-speech-recognition',
    description: 'Transcribing spoken language into written text automatically',
    rows: [
      ['openai/whisper-large-v3-turbo', '0.0035 / input (30 sec Audio)', '0.40 / 1M Token'],
      ['facebook/wav2vec2-base-960h', '0.0015 / input (30 sec Audio)', '0.20 / 1M Token'],
      ['openai/whisper-large-v3', '0.0015 / input (30 sec Audio)', '0.20/ 1M Token'],
    ],
    title: 'Automatic Speech Recognition',
  },

  {
    category: 'text-classification',
    description: 'Categorizing text into predefined classes based on its content',
    rows: [
      [
        'SamLowe/roberta-base-go_emotions',
        '0.005 / 1M Token',
        '0.00004 / output (labels and confidence score)',
      ],
      ['ProsusAI/finbert', '0.006 / 1M Token', '0.00005 / output (labels and confidence score)'],
      ['RLHFlow/ArmoRM-Llama3-8B-v0.1', '0.058 / 1M Token', '0.0005 / output (labels and confidence score)'],
    ],
    title: 'Text Classification',
  },

  {
    category: 'speech-to-text',
    description: 'Translating spoken words into text, often in real-time applications',
    rows: [
      ['espnet/xeus', '0.055 / input (30 sec Audio)', '0.250 / 1M Token'],
      ['UsefulSensors/moonshine-base', '0.090 / input (30 sec Audio)', '0.350 / 1M Token'],
      ['eesungkim/stt_kr_conformer_transducer_large', '0.030 / input (30 sec Audio)', '0.200 / 1M Token'],
    ],
    title: 'Speech to Text',
  },

  {
    category: 'audio-classification',
    description: 'Categorizing audio signals into predefined classes or labels',
    rows: [
      [
        'MIT/ast-finetuned-audioset-10-10-0.4593',
        '0.0155/ input (30 sec Audio)',
        '0.00042 / output (labels and confidence scores)',
      ],
      [
        'superb/hubert-base-superb-er',
        '0.045/ input (30 sec Audio)',
        '0.00060 / output (labels and confidence scores)',
      ],
      [
        'm-a-p/MERT-v1-330M',
        '0.050 / input (30 sec Audio)',
        '0.000070/ output (labels and confidence scores)',
      ],
    ],
    title: 'Audio Classification',
  },

  {
    category: 'sentence-similarity',
    description: 'Measuring the similarity between sentences based on semantic content',
    rows: [
      ['sentence-transformers/all-MiniLM-L6-v2', '0.035 / 1M Tokens', '0.002 / output (Tensor)'],
      ['BAAI/bge-m3', '0.0029 / 1M Tokens', '0.0035 / 1M Tokens'],
      ['sentence-transformers/all-mpnet-base-v2', '0.029/ 1M Tokens', '0.001 / output (Tenso'],
    ],
    title: 'Sentence Similarity',
  },

  {
    category: 'summarization',
    description: 'Generating concise summaries from longer text documents',
    rows: [
      ['facebook/bart-large-cnn', '0.0021 / 1M Tokens', '0.031 / 1M Tokens'],
      ['Falconsai/medical_summarization', '0.0029 / 1M Tokens', '0.0035 / 1M Tokens'],
      ['google/pegasus-newsroom', '0.0027 / 1M Tokens', '0.0034 / 1M Tokens'],
    ],
    title: 'Content Summarization',
  },

  {
    category: 'named-entity-recognition',
    description: 'Identifying and classifying key entities like names, organizations, and locations in text',
    rows: [
      ['mdarhri00/named-entity-recognition', '0.045 / 1M Tokens ', '0.070 / 1M Token'],
      ['blaze999/Medical-NER', '0.025 / 1M Tokens', '0.045 / 1M Tokens'],
      ['dslim/bert-base-NER', '0.050 / 1M Tokens', '0.065 / 1M Tokens'],
    ],
    title: 'Named Entity Recognition',
  },

  {
    category: 'object-detection',
    description: 'Identifying and localizing objects within images or videos using AI models',
    rows: [
      ['facebook/detr-resnet-50', '-', '0.00015/ Image'],
      ['microsoft/table-transformer-detection', '-', '0.00015/ Image'],
      ['hustvl/yolos-base', '-', '0.0004/ Image'],
    ],
    title: 'Object Detection',
  },

  {
    category: 'ocr',
    description: 'Digitizing printed or handwritten text from images using optical character recognition',
    rows: [
      ['microsoft/OmniParser', '0.035 / Image', '0.002 / 1M Token'],
      ['yifeihu/TB-OCR-preview-0.1', '0.050 / Image', '0.005 / 1M Token'],
      ['faisalraza/layoutlm-invoices', '0.025 / Image', '0.003 / 1M Token'],
    ],
    title: 'Character Recognition OCR',
  },

  {
    category: 'image-classification',
    description: 'Assigning labels to images based on their visual content',
    rows: [
      ['microsoft/resnet-50', '0.004 / image', '0.00010/ output (labels and confidence scores)'],
      ['AdamCodd/vit-base-nsfw-detector', '0.003 / image', '0.00025 / output (labels and confidence scores)'],
      ['trpakov/vit-face-expression', '0.005 / image', '0.00050 / output (labels and confidence scores)'],
    ],
    title: 'Image Classification',
  },

  {
    category: 'metabolic-modeling',
    description: 'Simulating metabolic pathways to predict biochemical behaviors in cells or organisms',
    rows: [
      [
        'single cell flux estimation analysis (scFEA)',
        '0.014 / input (Expression Matrix)',
        '0.190 / output (Fluxes Matrix)',
      ],
      [
        'single-cell Flux Balance Analysis (scFBA)',
        '0.015/ input (Expression Matrix)',
        '0.130 /output (Fluxes Matrix)',
      ],
      ['FLUXestimator', '0.015/ input (Expression Matrix)', '0.150 /output (Fluxes Matrix)'],
    ],
    title: 'Metabolic Modeling',
  },
  {
    category: 'system-biology',
    description:
      'Integrating complex biological data to model and understand systemic interactions within biological systems',
    rows: [
      [
        'Compositional Perturbation Autoencoder',
        '0.030 /input (Expression Matrix)',
        '0.145/ output (Expression Matrix)',
      ],
      ['ChemCPA', '0.035/ input (Expression Matrix)', '0.145/ output (Expression Matrix)'],
      [
        'Graph Variational Causal Inference (GraphVCI)',
        '0.015 / input (Expression Matrix)',
        '0.100 / output (Expression Matrix)',
      ],
    ],
    title: 'System Biology',
  },

  {
    category: 'spatial-transcriptomics',
    description:
      'Analyzing gene expression within the spatial context of tissues to understand cellular organization and function',
    rows: [
      ['GraphST', '0.030 / input (AnnData)', '0.315 / output (Clusters)'],
      ['TISSUE', '0.050 / input (AnnData)', '0.500 / output (Regions)'],
      ['CCST', '0.033 / input (AnnData)', '0.300 / output (Clusters)'],
    ],
    title: 'Spatial Transcriptomics',
  },

  {
    category: 'structural-bioinformatics',
    description:
      'Computationally studying biological macromolecular structures to elucidate their function and interactions',
    rows: [
      ['Alpha fold', '0.032 / input (Sequence)', '0.125 / output (Structure)'],
      ['RoseTTAFold', '0.035 / input (Sequence)', '0.150 / output (Structure)'],
      ['OpenFold', '0.028 / input (Sequence)', '0.120 / output (Structure)'],
    ],
    title: 'Structural Bioinformatics',
  },

  {
    category: 'Unconditional Gen',
    description: 'Generating new images from scratch without any conditional input using generative models',
    rows: [
      ['google/ddpm-celebahq-256', '-', '0.00025/ Image'],
      ['deepsynthbody/deepfake_ecg', '-', '0.00035/ Image'],
      ['commaai/commavq-gpt2m', '-', '0.00035/ Image'],
    ],
    title: 'Unconditional Image Generation',
  },

  {
    category: 'depth-estimation',
    description: 'Predicting depth information from images to reconstruct 3D representations of scenes',
    rows: [
      ['depth-anything/Depth-Anything-V2-Large', '-', '0.015 / Image'],
      ['Intel/dpt-large', '-', '0.011 / Image'],
      ['apple/DepthPro', '-', '0.009 / Image'],
    ],
    title: 'Depth Estimation',
  },

  {
    category: 'feature-extractor',
    description: 'Deriving meaningful features from data for use in analysis or downstream tasks',
    rows: [
      ['facebook/dinov2-base', '0.005 / image', '0.012 / output (Tensor)'],
      ['nomic-ai/nomic-embed-vision-v1.5', '0.007 / image', '0.020 / output (Tensor)'],
      ['microsoft/rad-dino-maira-2', '0.006 / image', '0.018 / output (Tensor)'],
    ],
    title: 'Feature Extraction',
  },

  {
    category: 'time-series-forecasting',
    description: 'Predicting future data points in a sequence based on historical time-dependent data',
    rows: [
      ['Transformers', '0.004 / input (tabular)', '0.009 / output (tabular)'],
      ['FbProphet', '0.003 / input (tabular)', '0.003 / output (tabular)'],
      ['Sarimax', '0.003 / input(tabular)', '0.002 / output (tabular)'],
    ],
    title: 'Time Series Forecasting',
  },

  {
    category: 'super-resolution',
    description:
      'Enhancing the resolution of images using AI to produce higher-quality visuals from low-resolution inputs',
    rows: [
      ['FaceUp Sharp', '-', '0.003 / Image (2x)'],
      ['Universal Upscaler', '-', '0.002 / Image (4x)'],
      ['Nomos ESR-GANs', '-', '0.0015 / Image (4x)'],
    ],
    title: 'Image Super Resolution',
  },

  {
    category: 'sentiment-analysis',
    description: 'Large-scale AI models trained on extensive datasets to perform a variety of tasks',
    rows: [
      ['gpt-4-turbo-preview', '9.211 / 1M Tokens ', '29.260 / 1M Tokens'],
      ['gpt-4o', '1.156 / 1M Tokens', '9.885 / 1M Tokens'],
      ['dall-e-3', '-', '0.078 / Image  (1024 * 1024)'],
    ],
    title: 'Centralized Models',
  },

  {
    category: 'sentiment-analysis',
    description: 'Determining the emotional tone or polarity expressed in text',
    rows: [
      [
        'cardiffnlp/twitter-roberta-base-sentiment-latest',
        '0.009 / 1M Token',
        '0.00004 / output (labels and confidence score)',
      ],
      [
        'mrm8488/distilroberta-finetuned-financial-news',
        '0.005 / 1M Token',
        '0.00005 / output (labels and confidence score)',
      ],
      ['unitary/toxic-bert', '0.008 / 1M Token', '0.00005 / output (labels and confidence score)'],
    ],
    title: 'Sentiment Analysis',
  },
];

export const accordions = [
  {
    children:
      "Nesa offers pricing structures centered around your preferred method of use - whether that's on Nesa Playground (Web) or On-Demand (Web & API/SDK).",
    title: "How does Nesa's Pricing work?",
  },

  {
    children:
      'Yes! Up to a limited number of requests, basic account holders can access and interact with models available in Nesa Playground.',
    title: 'Is Nesa free to use?',
  },

  {
    children:
      "Nesa's Enterprise Plan offerings enable your business to bring on multiple seats under the same account, allowing for an flexible and easy-to-use account management experience.",
    title: 'How many users are supported on each subscription plan?',
  },

  {
    children:
      'Through our trusted payment providers, you have the ability to pay via bank account, debit, credit, and crypto transfers.',
    title: 'What are my payment options?',
  },

  {
    children:
      'Nesa is the most secure AI platform today, with its patent-pending secure HE and DP privacy solutions.',
    title: 'How secure is Nesa?',
  },

  {
    children:
      'Nesa places privacy first and uses your data strictly in accordance with its stated privacy policy.',
    title: 'How does Nesa use my data?',
  },
];

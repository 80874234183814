import { useState } from 'react';

import type { IconName } from 'shared/ui/Icon';

import { filterItems } from 'shared/const';
import { getCategoryLabel } from 'shared/helpers/getCategoryLabel';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Select } from 'shared/ui/Select';

import type { FilterState, SortFilter } from '../types';

import { Filter } from './ui/Filter';

type Props = {
  filters: FilterState;
  onFilterChange: (filters: FilterState) => void;
};
const getIconByValue = (value: string): IconName => {
  const item = filterItems.find((item) => item.value === value);
  return item?.icon ?? 'box';
};
export const Filters = ({ filters, onFilterChange }: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const lg = useMinWidthMediaQuery('lg');

  const onSortChange = (value: SortFilter) => {
    onFilterChange({ sort: value });
  };

  const handleReset = () => {
    setSearchValue('');
    onFilterChange({ globalSearch: '' });
  };

  const selectedTypes = filters?.type || [];
  return (
    <>
      {!lg && filters && (
        <div className="mb-2 flex items-center justify-between">
          <span className="ml-2 text-base font-bold text-blue-900">Filter</span>
          <div className="right-4 box-border flex h-11 items-center gap-1 rounded-xl bg-blue-50 p-1">
            <Select
              className="h-9 w-fit rounded-lg bg-gray-100 p-3 text-sm inner-border-0"
              iconClassName="mr-1"
              iconName="arrowDownUp"
              onValueChange={onSortChange}
              value={filters.sort ? filters.sort : undefined}
            >
              <Select.Content className="z-10 p-1">
                <div className="mb-2 w-48 border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-medium text-blue-800">
                  Sort by
                </div>
                <Select.Item
                  className="text-sm text-clay-700 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="newest"
                >
                  Newest
                </Select.Item>
                <Select.Item
                  className="border-b border-blue-50 text-sm text-clay-700 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="oldest"
                >
                  Oldest
                </Select.Item>
                <Select.Item
                  className="text-sm text-clay-700 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="name-lowest"
                >
                  Name Ascending
                </Select.Item>
                <Select.Item
                  className="border-b border-blue-50 text-sm text-clay-700 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="name-highest"
                >
                  Name Decending
                </Select.Item>
                <Select.Item
                  className="text-sm text-clay-700 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="ranking-highest"
                >
                  Most Rated
                </Select.Item>
                <Select.Item
                  className="text-sm text-clay-700 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="likes-highest"
                >
                  Most Likes
                </Select.Item>
                <Select.Item
                  className="border-b border-blue-50 text-sm text-clay-700 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="downloads-highest"
                >
                  Most Downloaded
                </Select.Item>
                <Select.Item
                  className="text-sm text-clay-700 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="price-highest"
                >
                  Price Highest
                </Select.Item>
                <Select.Item
                  className="text-sm text-clay-700 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="price-lowest"
                >
                  Price Lowest
                </Select.Item>
              </Select.Content>
            </Select>

            <Filter onSortChange={onSortChange} />
          </div>
        </div>
      )}

      {lg && filters && (selectedTypes.length > 0 || searchValue.length > 0) && (
        <div className="mb-2 flex shrink-0 pl-2">
          <span className="mr-4 text-sm/9 font-semibold text-clay-900">Results</span>
          <div className="flex w-full flex-wrap items-center gap-1">
            {selectedTypes.map((item) => (
              <div
                className="flex h-9 shrink-0 items-center justify-between rounded-lg bg-white p-3 text-clay-300"
                key={item}
              >
                <div className="flex gap-2">
                  <Icon className="size-3 text-blue-800" name={getIconByValue(item)} />
                  <span className="text-xs">{getCategoryLabel(item)}</span>
                </div>

                <Icon
                  className="ml-3 size-2 cursor-pointer rounded p-1 text-blue-800 hover:bg-clay-20"
                  name="x"
                  onClick={() => {
                    if (!filters.type) return;

                    const typesToSet = filters.type.filter((typeItem) => typeItem !== item);
                    onFilterChange({
                      ...filters,
                      globalSearch: typesToSet.length === 0 ? undefined : filters.globalSearch,
                      type: typesToSet,
                    });

                    if (typesToSet.length === 0) {
                      setSearchValue('');
                    }
                  }}
                />
              </div>
            ))}
          </div>

          <Input
            className="group h-9 max-w-80 justify-items-center"
            classNameInputWrapper="rounded-xl px-2 shadow-sm"
            endSlot={
              searchValue && (
                <Icon
                  className="cursor-pointer text-clay-500 transition-colors hover:text-primary-900"
                  name="close"
                  onClick={handleReset}
                />
              )
            }
            onChange={(e) => {
              const value = e.target.value;

              if (!value) {
                handleReset();
                return;
              }

              setSearchValue(value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onFilterChange({ globalSearch: searchValue });
              }
            }}
            placeholder="Filtered search"
            startSlot={
              <Icon
                className="size-3.5 text-clay-900 transition-colors group-focus-within:text-clay-600 sm:size-4 sm:text-clay-300"
                name="search"
              />
            }
            value={searchValue}
          />
        </div>
      )}
    </>
  );
};

import { useState } from 'react';

import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';
import { TextArea } from 'shared/ui/TextArea';

type Props = {
  initialValue?: string;
  isOpen: boolean;
  modelName: string;
  onOpenChange: (value: boolean) => void;
  onSave: (text: string) => void;
};
export const ModelDescriptionModal = ({
  initialValue = '',
  isOpen,
  modelName,
  onOpenChange,
  onSave,
}: Props) => {
  const [value, setValue] = useState(initialValue);
  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content>
        <Modal.CloseButton />

        <h1 className="mb-3 text-lg/6 tracking-tight">
          Description how <b>{modelName}</b> model is being used
        </h1>

        <TextArea
          maxLength={300}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Your description..."
          value={value}
        />

        <div className="mt-4 flex items-center justify-between gap-3">
          <Button
            color="secondary"
            onClick={() => {
              onOpenChange(false);
              setValue('');
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!value}
            onClick={() => {
              onSave(value);

              setValue('');
            }}
          >
            Save
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AnimatePresence } from 'framer-motion';
import { ArrowUpRight } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

import type { DAI } from 'shared/api/dai/types';

import { Icon } from 'shared/ui/Icon';
import { Modal } from 'shared/ui/Modal';

import { LeftSidebar } from './ui/LeftSidebar';
import { MyStakedAssets } from './ui/MyStakedAssets';
import { Stake } from './ui/Stake';
import { Unstake } from './ui/Unstake';

type Props = { dai: DAI; isOpen: boolean; onOpenChange: (isOpen: boolean) => void };

export const StakingModal = ({ dai, isOpen, onOpenChange }: Props) => {
  const [activeSection, setActiveSection] = useState<'my-staked-assets' | 'stake' | 'unstake'>('stake');
  const navigate = useNavigate();

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content
        className="w-full !max-w-[95vw] lg:!max-w-5xl"
        innerClassName="backdrop-blur-md bg-white/70 grid grid-cols-1 md:grid-cols-4 p-6 sm:p-8 gap-5"
      >
        <Modal.CloseButton />
        <LeftSidebar
          onMenuClick={(menu) => {
            if (menu === 'my-staked-assets') {
              setActiveSection('my-staked-assets');
              return;
            }
          }}
        />

        <div className="col-span-1 flex flex-col gap-3 md:col-span-3">
          {activeSection === 'my-staked-assets' ? (
            <MyStakedAssets onClose={() => setActiveSection('stake')} />
          ) : (
            <div className="rounded-2xl bg-white p-4 md:p-6">
              <h1 className="mb-0 text-xl font-semibold tracking-tight md:mb-4 md:text-3xl">
                <span className="text-clay-500">Stake $NES</span>
                {dai.token ? ` for $${dai.token.ticker.toUpperCase()}` : ''}
              </h1>

              <div className="mb-6 flex items-center gap-5 border-b border-clay-20 py-3">
                {(['stake', 'unstake'] as const).map((section) => {
                  return (
                    <div
                      className={twMerge(
                        'cursor-pointer text-base font-medium capitalize tracking-tight text-clay-800/40 transition-colors hover:text-clay-800/60 md:text-xl',
                        section === activeSection && 'text-clay-800 hover:text-clay-800',
                      )}
                      key={section}
                      onClick={() => setActiveSection(section)}
                    >
                      {section}
                    </div>
                  );
                })}
              </div>

              <AnimatePresence>
                {activeSection === 'stake' && <Stake dai={dai} />}
                {activeSection === 'unstake' && <Unstake dai={dai} />}
              </AnimatePresence>
            </div>
          )}

          <div
            className="group flex cursor-pointer items-center justify-between rounded-2xl bg-white p-6"
            onClick={() => navigate('/privilege/faucet')}
          >
            <div className="flex items-center gap-4">
              <div className="flex size-10 min-w-10 items-center justify-center rounded-full bg-tusk-100">
                <Icon className="size-5" name="logo" />
              </div>

              <div className="flex flex-col gap-2">
                <div className="text-lg/none font-medium tracking-tight">Get $NES from the Faucet</div>
                <div className="text-sm/none font-light tracking-tight text-clay-400">
                  Go to the Testnet Faucet to get more $NES
                </div>
              </div>
            </div>
            <ArrowUpRight className="size-5 transition-colors group-hover:text-primary-800" />
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

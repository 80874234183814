import { useFormContext, useWatch } from 'react-hook-form';

import { AnimatePresence, motion } from 'framer-motion';

import { useUploadModelMutation } from 'shared/api/models/useUploadModelMutation';
import { Button } from 'shared/ui/Button';

import type { UploadModelValues } from '../UploadModelModal.tsx';

import { Input } from '../../../shared/ui/Input';
import { StepHeader } from './StepHeader';

type Props = {
  isExpanded: boolean;
  onNextClick: () => void;
  onPrevClick: () => void;
  onTitleClick: () => void;
};

export const githubRegexp = /^(https?:\/\/)?(www\.)?github\.com\/[a-zA-Z0-9_-]+\/[a-zA-Z0-9_-]+$/i;

export const GithubLink = ({ isExpanded, onNextClick, onPrevClick, onTitleClick }: Props) => {
  const { isPending: isUploading } = useUploadModelMutation();

  const { control, formState, register } = useFormContext<UploadModelValues>();

  const githubLink = useWatch({ control, name: 'githubLink' });

  return (
    <>
      <StepHeader
        icon="github"
        isActive={isExpanded}
        isCompleted={!!githubLink}
        onClick={onTitleClick}
        title="Github Link"
      />

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            animate={{ height: 'auto' }}
            className="flex shrink-0 flex-col gap-3 overflow-hidden"
            exit={{ height: 0, opacity: 0 }}
            initial={{ height: 0 }}
            layout
            transition={{ duration: 0.5, ease: 'easeInOut' }}
          >
            <Input
              {...register('githubLink', {
                validate: (val) => (!githubRegexp.test(val) && val ? 'Invalid link' : true),
              })}
              className="mt-4 h-full px-4"
              classNameLabel="text-xs font-light text-clay-500"
              error={formState.errors?.['githubLink']?.message?.toString() || undefined}
              errorSpacing
              label="Github Repository Link (Optional)"
              placeholder="ie. https://github.com/meta/meta-lama"
            />

            <div className="mx-4 flex justify-between">
              <Button
                className="rounded-lg bg-clay-20 px-3 text-clay-900"
                color="secondary"
                onClick={onPrevClick}
                variant="filled-light"
              >
                Back
              </Button>

              <Button className="rounded-lg px-3" isLoading={isUploading} onClick={onNextClick}>
                Next
              </Button>
            </div>

            <div className="mt-1 border-b border-clay-20"></div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

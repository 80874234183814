import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Modal } from 'shared/ui/Modal';
import { TradingView } from 'widgets/TradingView';

type Props = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};
export const TradeModal = ({ isOpen, onOpenChange }: Props) => {
  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="!min-h-full !max-w-full" innerClassName="flex flex-col">
        <div className="mb-6 flex max-h-fit w-full flex-1 items-center justify-between">
          <div className="flex items-center gap-3 text-xl font-medium">
            <Icon className="size-5" name="chartColumns" />
            Trading view
          </div>

          <Button color="dark" onClick={() => onOpenChange(false)} size="extra-small">
            <Icon className="size-4" name="shrink" />
            Exit Tradeview
          </Button>
        </div>

        <div className="grid grow grid-cols-4">
          <div className="col-span-3">
            {' '}
            <TradingView allowSymbolChange={false} hideVolume />
          </div>
          <div className="col-span-1"></div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

import { useForm } from 'react-hook-form';

import { AxiosError } from 'axios';

import { useUser } from 'app/stores/user';
import { useNodeConnectMutation } from 'shared/api/user/useNodeConnectMutation';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { Modal } from 'shared/ui/Modal';
import { toaster } from 'shared/ui/Toast';

type Props = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

const PUBLIC_KEY_REGEX = /^[0-9a-fA-F]{66}$/;

type FormValues = {
  publicKey: string;
};

export const ConnectNodeModal = ({ isOpen, onOpenChange }: Props) => {
  const { updateUser } = useUser();
  const { isPending, mutateAsync: connectNode } = useNodeConnectMutation();

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    defaultValues: { publicKey: '' },
    mode: 'onChange',
  });

  const handleNodeSubmit = async (values: FormValues) => {
    try {
      const { user } = await connectNode({ public_key: values.publicKey });

      updateUser(user);

      toaster.success('Public Key is connected successfully');

      onOpenChange(false);
    } catch (e) {
      if (e instanceof AxiosError) {
        const axiosError = e.response?.data?.message;
        toaster.error(axiosError || 'Something went wrong');
      } else {
        toaster.error('Something went wrong');
      }
    }
  };

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="!max-w-md">
        <Modal.CloseButton />

        <div className="mb-6 flex flex-col items-center justify-center gap-2 pt-2">
          <h1 className="text-center text-2xl/none">Connect a node</h1>
          <div className="text-center text-base font-light text-clay-500">
            All of your nodes connect through one public key
          </div>
        </div>

        <form className="flex flex-col gap-3" onSubmit={handleSubmit(handleNodeSubmit)}>
          <div className="flex flex-col">
            <Input
              {...register('publicKey', {
                required: 'Public Key is required',
                validate: (val) => (PUBLIC_KEY_REGEX.test(val.trim()) ? undefined : 'Public Key is invalid'),
              })}
              error={errors.publicKey?.message}
              errorSpacing
              placeholder="Your Public Key"
            />{' '}
          </div>
          <Button disabled={!isValid} isLoading={isPending} type="submit">
            Connect
          </Button>
        </form>
      </Modal.Content>
    </Modal>
  );
};

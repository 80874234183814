import { useController, useFormContext, useWatch } from 'react-hook-form';

import { AnimatePresence, motion } from 'framer-motion';
import { Upload } from 'lucide-react';

import { useUploadModelMutation } from 'shared/api/models/useUploadModelMutation';
import { Button } from 'shared/ui/Button';
import { ChipFilePreview, FileUploader } from 'shared/ui/FileUploader';
import { Input } from 'shared/ui/Input';
import { Label } from 'shared/ui/Label';

import type { UploadModelValues } from '../UploadModelModal';

import { StepHeader } from './StepHeader';

type Props = {
  isExpanded: boolean;
  onNextClick: () => void;
  onPrevClick: () => void;
  onTitleClick: () => void;
};

export const BuildDockerImage = ({ isExpanded, onNextClick, onPrevClick, onTitleClick }: Props) => {
  const { isPending: isUploading } = useUploadModelMutation();

  const { control, formState, register } = useFormContext<UploadModelValues>();
  const modelName = useWatch({ control, name: 'buildDockerImage.modelName' });
  const { field: yamlFileField } = useController({
    control,
    defaultValue: undefined,
    name: 'buildDockerImage.yamlFile',
    rules: { required: true },
  });
  const { field: predictFileField } = useController({
    control,
    defaultValue: undefined,
    name: 'buildDockerImage.predictFile',
    rules: { required: true },
  });
  const { field: additionalFilesField } = useController({
    control,
    defaultValue: undefined,
    name: 'buildDockerImage.additionalFiles',
    rules: { required: false },
  });
  console.log(yamlFileField, predictFileField, additionalFilesField);
  return (
    <>
      <StepHeader
        icon="docker"
        isActive={isExpanded}
        isCompleted={!!yamlFileField?.value && !!predictFileField?.value && !!modelName}
        onClick={onTitleClick}
        title="Build Docker Image"
      />

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            animate={{ height: 'auto' }}
            className="flex shrink-0 flex-col gap-3 overflow-hidden"
            exit={{ height: 0, opacity: 0 }}
            initial={{ height: 0 }}
            layout
            transition={{ duration: 0.5, ease: 'easeInOut' }}
          >
            <Input
              {...register('buildDockerImage.modelName')}
              className="mt-4 h-full px-4"
              classNameLabel="text-xs font-light text-clay-500"
              error={formState.errors?.['buildDockerImage']?.message?.toString() || undefined}
              label={
                <>
                  Model name <span className="ml-1 text-red-900">*</span>
                </>
              }
              placeholder="Name your model"
            />

            <div className="mt-4 h-full px-4">
              <Label className="text-xs">
                Upload Yaml File<span className="ml-1 text-red-900">*</span>
              </Label>
              <FileUploader
                dropzoneProps={{
                  accept: {
                    ['text/html']: ['.yaml', '.yml'],
                  },
                  maxFiles: 1,
                  maxSize: 1000 * 1000 * 10, // 10 MB
                }}
                filePreview={
                  <ChipFilePreview
                    files={[yamlFileField?.value]}
                    onDelete={() => yamlFileField.onChange(undefined)}
                  />
                }
                icon={<Upload className="size-4 text-clay-350" />}
                onChange={(files) => yamlFileField.onChange(files[0])}
                title="Upload YAML"
                values={yamlFileField?.value ? [yamlFileField?.value] : []}
              />
            </div>

            <div className="mt-4 h-full px-4">
              <Label className="text-xs">
                Upload predict.py <span className="ml-1 text-red-900">*</span>
              </Label>
              <FileUploader
                dropzoneProps={{
                  accept: {
                    ['text/html']: ['.py'],
                  },
                  maxFiles: 1,
                  maxSize: 1000 * 1000 * 10,
                }}
                filePreview={
                  <ChipFilePreview
                    files={[predictFileField?.value]}
                    onDelete={() => predictFileField.onChange(undefined)}
                  />
                }
                icon={<Upload className="size-4 text-clay-350" />}
                onChange={(files) => predictFileField.onChange(files[0])}
                title="Upload predict.py"
                values={predictFileField?.value ? [predictFileField?.value] : []}
              />
            </div>

            <div className="mt-4 h-full px-4">
              <Label className="text-xs">Upload Additional Files (Optional)</Label>
              <FileUploader
                dropzoneProps={{
                  maxFiles: 10,
                  maxSize: 1000 * 1000 * 10, // 10 MB EACH
                  multiple: true,
                }}
                filePreview={
                  <ChipFilePreview
                    files={additionalFilesField?.value}
                    onDelete={(deletedFile) => {
                      additionalFilesField.onChange(
                        additionalFilesField?.value?.filter((file) => file.name !== deletedFile.name),
                      );
                    }}
                  />
                }
                icon={<Upload className="size-4 text-clay-350" />}
                onChange={(files) =>
                  additionalFilesField.onChange([...(additionalFilesField?.value || []), ...files])
                }
                title="Some additional files"
                values={additionalFilesField?.value}
              />
            </div>

            <div className="mx-4 flex justify-between">
              <Button
                className="rounded-lg bg-clay-20 px-3 text-clay-900"
                color="secondary"
                onClick={onPrevClick}
                variant="filled-light"
              >
                Back
              </Button>

              <Button className="rounded-lg px-3" isLoading={isUploading} onClick={onNextClick}>
                Next
              </Button>
            </div>

            <div className="mt-1 border-b border-clay-20"></div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

import { Link } from 'react-router-dom';

import { AnimatePresence } from 'framer-motion';

import { useGetQueryHistoryByIdQuery } from 'shared/api/queryHistory/useGetQueryHistoryByIdQuery';
import { Accordion } from 'shared/ui/Accordion';
import { Icon } from 'shared/ui/Icon';
import { Spinner } from 'shared/ui/Spinner';
import { TruncateMiddle } from 'shared/ui/TruncateMiddle';

export type MinerRate = {
  account: string;
  rate: number;
};

type Props = {
  executionTime?: number;
  historyId?: string;
  isOpen?: boolean;
  onOpenChange?: (val: boolean) => void;
  pricePerToken: number | string;
  rates: MinerRate[];
  totalPayment?: number;
};

const labelClassName = 'text-clay-500 text-base';

export const Payments = ({ executionTime, historyId, isOpen, onOpenChange, pricePerToken, rates }: Props) => {
  const { data } = useGetQueryHistoryByIdQuery(
    { id: historyId ?? '' },
    {
      enabled: !!historyId,
      refetchInterval: (query) => {
        return historyId && !query.state.data?.mirrorTxHash ? 3000 : undefined;
      },
    },
  );
  const mirrorTxHash = data?.mirrorTxHash;

  return (
    <AnimatePresence>
      <Accordion isExpanded={isOpen} onChange={onOpenChange}>
        <Accordion.Trigger className="flex gap-2">
          <Icon className="size-5 text-clay-400" name="globeDigital" />
          Transactions
        </Accordion.Trigger>
        <Accordion.Content animate={{ maxHeight: undefined }} className="grid-cols-1 pb-3">
          <div className="grid grid-cols-2 gap-x-4 gap-y-2">
            <div>
              <div className={labelClassName}>Tx Hash</div>
              <Link
                className="group flex cursor-pointer items-center gap-0.5 transition-colors hover:text-primary-900"
                to={`https://explorer.nesa.ai/nesa/transactions/${mirrorTxHash}`}
              >
                {mirrorTxHash ? (
                  <>
                    <TruncateMiddle largeString={mirrorTxHash} />
                    <Icon
                      className="text-clay-500 transition-colors group-hover:text-primary-900 hover:text-primary-900"
                      name="arrowUpRightLong"
                    />
                  </>
                ) : historyId ? (
                  <Spinner className="size-4" />
                ) : (
                  '-'
                )}
              </Link>
            </div>
            <div className="flex w-full flex-col">
              <div className={labelClassName}>Inference nodes</div>
              {rates.map((item) => {
                return (
                  <div className="mt-1 flex flex-1 grow items-center" key={item.account}>
                    <div className="mr-1 truncate">{item.account}</div>
                    <span className="whitespace-nowrap">
                      {' '}
                      - <span className="font-medium">{item.rate}</span>
                    </span>
                    <div className="ml-2 whitespace-nowrap rounded border border-primary-900 bg-primary-50 p-1 text-sm/none text-primary-1000">
                      Equipped with TEE
                    </div>
                  </div>
                );
              })}
            </div>
            <div>
              <div className={labelClassName}>Execution time</div>
              {executionTime ? Math.abs(executionTime) : ' - '}s
            </div>
            <div className="flex flex-col gap-0.5">
              <div className={labelClassName}>Price per token</div>
              <span>${pricePerToken}</span>
            </div>
          </div>
        </Accordion.Content>
      </Accordion>
    </AnimatePresence>
  );
};

import { memo } from 'react';

import { DateTime } from 'luxon';
import { Bar, ComposedChart, ResponsiveContainer, Tooltip } from 'recharts';

import { useGetStakeAmountChartQuery } from 'shared/api/dai/useGetStakeAmountChartQuery';
import { Spinner } from 'shared/ui/Spinner';

type Props = {
  daiId: string;
};
export const StakeChart = memo(({ daiId }: Props) => {
  const { data: chartData, isPending } = useGetStakeAmountChartQuery({ daiId }, { enabled: !!daiId });

  if (isPending) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner className="size-6" />
      </div>
    );
  }

  return (
    <ResponsiveContainer height="100%" width="100%">
      <ComposedChart
        data={chartData}
        height={300}
        margin={{
          bottom: 5,
          left: 8,
          right: 8,
          top: 10,
        }}
        width={500}
      >
        <defs>
          <linearGradient id="colorPrimary" x1="0" x2="0" y1="0" y2="1">
            <stop offset="5%" stopColor="#8356E2" stopOpacity={0.6} />
            <stop offset="95%" stopColor="#8356E2" stopOpacity={0} />
          </linearGradient>
        </defs>

        <Tooltip
          content={({ active, payload }) => {
            if (!active) return null;

            return (
              <div className="flex max-w-56 flex-col rounded-lg bg-clay-800 p-3 text-white">
                <span className="text-lg">
                  ${payload?.[0]?.value?.toLocaleString(undefined, { maximumFractionDigits: 2 })} (
                  {payload?.[0]?.payload.stakesCount || 0} stakes)
                </span>

                <span className="text-sm text-[#B7C0CE]">
                  Staked on {DateTime.fromISO(payload?.[0]?.payload?.date).toLocaleString(DateTime.DATE_MED)}
                </span>
              </div>
            );
          }}
          cursor={false}
          wrapperStyle={{ outline: 'none' }}
        />
        <Bar
          className="transition-colors hover:fill-primary-300"
          dataKey="count"
          fill="#ECEDF1"
          radius={[10, 10, 10, 10]}
        />

        {/* <Area
          dataKey="stakesCount"
          fill="url(#colorPrimary)"
          fillOpacity={1}
          stroke="#8884d8"
          strokeWidth={1}
          type="linear"
        /> */}
        {/* <Line dataKey="stakesCount" dot={false} stroke="#7A4AE1" strokeWidth={1} type="linear" /> */}
      </ComposedChart>
    </ResponsiveContainer>
  );
});

import type { NavigateOptions, To } from 'react-router-dom';

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const useNavigateToTop = () => {
  const navigate = useNavigate();

  const navigateAndReset = useCallback(
    (to: To, options?: NavigateOptions) => {
      navigate(to, options);
      window.scrollTo(0, 0);
    },
    [navigate],
  );

  return navigateAndReset;
};

import type { ComponentProps, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

type Props = {
  description: string;
  icon: ReactNode;
  title: ReactNode;
} & Omit<ComponentProps<'div'>, 'title'>;

export const CreationItem = ({ className, description, icon, title, ...props }: Props) => {
  return (
    <div
      {...props}
      className={twMerge(
        'flex cursor-pointer gap-4 rounded-xl bg-white p-3.5 duration-200 hover:bg-clay-20',
        className,
      )}
    >
      <div className="flex size-6 items-center justify-center">{icon}</div>
      <div className="flex flex-col gap-1">
        <div className="text-lg/5 font-medium text-clay-700">{title}</div>
        <div className="text-sm text-clay-380">{description}</div>
      </div>
    </div>
  );
};

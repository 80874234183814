import { useScrollableRef } from 'shared/hooks/useScrollableRef';
import { TagWrapper } from 'shared/ui/Tag';

import type { UploadingModel } from './UserModelList';

export const UploadingModelCardWrapper = ({ models }: { models: UploadingModel[] }) => {
  const ref = useScrollableRef();

  return (
    <div className={`flex gap-2 overflow-x-scroll py-2 scrollbar-none`} ref={ref}>
      {models.map((model, index) => (
        <div
          className="min-w-[318px] cursor-grab select-none justify-between
                rounded-lg border border-transparent bg-white
                p-3 transition-all duration-300 hover:border-[#ADB0B6] hover:drop-shadow-mdAll active:cursor-grabbing"
          key={index}
        >
          <p className="text-lg font-semibold">{model.name}</p>
          <TagWrapper
            tags={model.tags.map((tag, index) => {
              return {
                order: index,
                tag,
                type: 'green',
              };
            })}
          />
          <div className="mt-3 h-px w-full bg-clay-20" />
          <div className="mt-3 flex flex-row items-center justify-between gap-2">
            <div className="flex flex-row items-center gap-4">
              <div className="flex gap-1">
                {[...Array(5)].map((_, i) => (
                  <div
                    className={`h-0.5 w-8 rounded bg-green-500 ${i < model.step ? 'opacity-100' : 'opacity-30'}`}
                    key={i}
                  />
                ))}
              </div>
              <div className="w-4 text-xs font-medium">{model.step}/5</div>
            </div>

            <div className="self-end text-xs text-green-500">{model.status}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

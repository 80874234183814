import { useNavigate } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { Icon } from 'shared/ui/Icon';

import { ContentLoader } from '../ContentLoader';
import { Image } from '../Image';

type Props = {
  coinImageSrc?: string;
  coinName?: string;
  description?: string;
  id?: string;
  isLoading?: boolean;
  manageButton?: boolean;
  mediaSrc?: string;
  onClick?: () => void;
  participants?: { avatar?: string; username: string }[];
  priceChangePrc?: number;
  showMediaNearTitle?: boolean;
  tags?: string[];
  title?: string;
  tvl?: string;
  userImageSrc?: string;
  userName?: string;
} & ClassName;

export const HotDaiCard = ({
  className,
  coinImageSrc,
  coinName,
  description,
  id,
  isLoading,
  manageButton,
  mediaSrc,
  onClick,
  participants = [],
  priceChangePrc = 0,
  showMediaNearTitle,
  tags = [],
  title,
  tvl,
  userImageSrc,
  userName,
}: Props) => {
  const navigate = useNavigate();
  return (
    <div
      className={twMerge(
        'flex h-fit min-h-fit flex-col overflow-hidden rounded-lg border border-clay-20 bg-white  transition-colors ',
        !!onClick && 'cursor-pointer hover:border-clay-100',
        className,
      )}
      onClick={onClick}
    >
      {mediaSrc &&
        !showMediaNearTitle &&
        (mediaSrc.endsWith('.mp4') ? (
          <div className="h-40 w-full bg-clay-10">
            <video autoPlay className="size-full object-cover" loop muted playsInline src={mediaSrc} />
          </div>
        ) : (
          <div className="h-40 w-full bg-clay-10">
            <img alt="avatar" className="size-full object-cover" src={mediaSrc} />
          </div>
        ))}

      <div className="flex grow flex-col  gap-4 p-4">
        <div className="flex items-center gap-2">
          <div
            className={twMerge(
              'relative flex size-6 items-center justify-center overflow-hidden rounded-full bg-clay-900 p-1',
              coinImageSrc && 'p-0',
            )}
          >
            {coinImageSrc ? (
              <Image className="size-full object-cover" src={coinImageSrc} />
            ) : (
              <Icon className="size-full text-tusk-200" name="logo" />
            )}

            {isLoading && (
              <ContentLoader className="absolute left-0 top-0" height="100%" width="100%">
                <rect height="100%" width="100%" x="0" y="0" />
              </ContentLoader>
            )}
          </div>

          <div className="relative min-h-5 flex-1 text-sm font-semibold uppercase">
            {isLoading ? (
              <ContentLoader className="absolute left-0 top-1/2 -translate-y-1/2" height="12" width="100%">
                <rect height="12" rx={8} ry={8} width="100%" x="0" y="0" />
              </ContentLoader>
            ) : (
              `$${coinName || '--'}`
            )}
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-3 text-xl font-medium tracking-tight md:text-2xl/7">
            <div className="relative size-12 min-w-12 overflow-hidden rounded-lg bg-clay-10">
              {showMediaNearTitle &&
                (mediaSrc?.endsWith('.mp4') ? (
                  <video autoPlay className="size-full object-cover" loop muted playsInline src={mediaSrc} />
                ) : (
                  <Image className="size-full object-cover" src={mediaSrc || ''} />
                ))}

              {isLoading && (
                <ContentLoader className="absolute left-0 top-0" height="100%" width="100%">
                  <rect height="100%" width="100%" x="0" y="0" />
                </ContentLoader>
              )}
            </div>

            <div className="relative line-clamp-2 min-h-7 flex-1">
              {isLoading ? (
                <ContentLoader className="absolute left-0 top-1/2 -translate-y-1/2" height="20" width="100%">
                  <rect height="20" rx={8} ry={8} width="100%" x="0" y="0" />
                </ContentLoader>
              ) : (
                title
              )}
            </div>
          </div>
          <div className="relative line-clamp-2 min-h-5 text-sm font-light text-clay-500 md:text-base/5">
            {isLoading ? (
              <ContentLoader className="absolute left-0 top-1/2 -translate-y-1/2" height="12" width="100%">
                <rect height="12" rx={8} ry={8} width="100%" x="0" y="0" />
              </ContentLoader>
            ) : (
              description
            )}
          </div>
        </div>
        {(userImageSrc || userName) && (
          <div className="mt-auto flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div
                className="relative size-5 overflow-hidden rounded-full bg-clay-20 bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: `url(${userImageSrc})` }}
              >
                {isLoading && (
                  <ContentLoader className="absolute left-0 top-0" height="100%" width="100%">
                    <rect height="100%" width="100%" x="0" y="0" />
                  </ContentLoader>
                )}
              </div>

              <div className="relative min-h-5 text-sm font-light">
                {isLoading ? (
                  <ContentLoader
                    className="absolute left-0 top-1/2 -translate-y-1/2"
                    height="12"
                    width="100%"
                  >
                    <rect height="12" rx={8} ry={8} width="100%" x="0" y="0" />
                  </ContentLoader>
                ) : (
                  userName
                )}
              </div>
            </div>

            {tags.map((tag) => (
              <div className="rounded-md bg-clay-10 px-2 py-1 text-xs font-semibold uppercase" key={tag}>
                {tag}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="flex items-center justify-between border-t border-clay-20 p-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-3">
            <div className="relative min-h-14 min-w-11 text-2xl font-medium md:min-h-9 md:text-3xl">
              {isLoading ? (
                <ContentLoader className="absolute left-0 top-1/2 -translate-y-1/2" height="24" width="100%">
                  <rect height="24" rx={8} ry={8} width="100%" x="0" y="0" />
                </ContentLoader>
              ) : (
                `$${tvl}`
              )}
            </div>
            {!!priceChangePrc && (
              <div className="flex items-center gap-1.5">
                <div
                  className={twMerge(
                    'flex size-4 items-center justify-center rounded-full bg-green-600/20 text-green-600',
                    priceChangePrc < 0 && 'bg-red-900/20 text-red-900',
                  )}
                >
                  <Icon className={twMerge('size-3', priceChangePrc > 0 && 'rotate-180')} name="arrowDown" />
                </div>

                <div
                  className={twMerge(
                    'text-sm/none font-medium text-green-600',
                    priceChangePrc < 0 && ' text-red-900',
                  )}
                >
                  {priceChangePrc > 0 ? '+' : ''}
                  {priceChangePrc}%
                </div>
              </div>
            )}
          </div>
          <div className="font-light text-clay-500">TVL</div>
        </div>

        <div className="flex -space-x-4">
          {participants.map((p, idx) => {
            return (
              <div
                className={twMerge(
                  'relative size-10 rounded-full border-[3px] border-white bg-clay-20 bg-cover bg-center bg-no-repeat',
                )}
                key={p.username}
                style={{ backgroundImage: `url(${p.avatar})`, zIndex: participants.length - idx }}
              ></div>
            );
          })}
        </div>
      </div>
      {manageButton && id && (
        <div className="mb-4 mt-2 flex items-center px-4 ">
          <div
            className="w-full cursor-pointer rounded-lg border border-solid border-[#D9DCE4] p-2 text-center text-sm font-medium shadow"
            onClick={() => navigate(`/dai/${id}/edit`)}
          >
            Manage
          </div>
        </div>
      )}
    </div>
  );
};

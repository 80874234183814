import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowUpRightIcon, Bot, BotIcon, Grid2X2Icon, ListIcon } from 'lucide-react';

import { BackButton } from 'app/ui/BackButton';
import { PageIsInAlphaModal } from 'features/PageIsInAlphaModal';
import { UpgradeToProModal } from 'features/UpgradeToProModal';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Card } from 'shared/ui/Card';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Select } from 'shared/ui/Select';

import { agentsCards, mainCards, topGrayCards } from './config';

export const Agents = () => {
  const navigate = useNavigate();

  const [isShowBlocker, setIsShowBlocker] = useState(false);
  const [openUpgradeToPro, setOpenUpgradeToPro] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsShowBlocker(true);
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <AnimateRoute className="relative rounded-xl bg-clay-20 p-2">
      <div className="rounded-xl bg-white">
        <header className="border-b border-clay-20 px-4 py-3.5">
          <BackButton className="text-sm font-normal text-clay-400" onClick={() => navigate('/')}>
            Back to Dashboard
          </BackButton>
        </header>
        <div className="px-4 py-7">
          <div className="mb-6 flex flex-col justify-between gap-3 md:flex-row md:items-center">
            <div className="flex flex-1 flex-col gap-1">
              <h1 className="text-3xl font-black text-clay-900 md:text-4xl">Explore Nesa</h1>
              <div className="text-base font-light text-clay-500 md:text-lg">
                Explore the <span className="font-semibold">decentralized AI</span> projects and protocols on
                Nesa Chain.
              </div>
            </div>
            <Button className="pl-3" color="dark">
              <BotIcon className="size-5 text-white" />
              Create an Agent
            </Button>
          </div>

          <div className="mb-2.5 grid grid-cols-1 gap-2.5 lg:grid-cols-2">
            {topGrayCards.map((card, i) => (
              <div className="flex items-center rounded-lg bg-clay-20 px-6 py-4" key={i}>
                <div className="flex flex-1 flex-col gap-1">
                  <h4 className="text-xl font-semibold text-clay-900">{card.title}</h4>
                  <p className="text-base text-clay-500">{card.description}</p>
                </div>
                <Button className="rounded-full border-none" color="white">
                  {card.button}
                </Button>
              </div>
            ))}
          </div>

          <div className="mb-8 grid grid-cols-1 gap-2.5 lg:grid-cols-3">
            {mainCards.map((card, i) => (
              <div
                className="flex items-center gap-3 rounded-lg p-2.5 pl-7"
                key={i}
                style={{ background: card.bg }}
              >
                <div className="flex-1">
                  <h6 className="mb-1.5 text-base/5 font-medium text-tusk-100">{card.title}</h6>
                  <p className="mb-7 whitespace-pre-line text-3xl font-semibold text-white lg:text-2xl xl:text-3xl 2xl:text-4xl">
                    {card.description}
                  </p>
                  <Button color="dark">Explore</Button>
                </div>
                <div className="grid grid-cols-1 gap-1.5">
                  {card.buttons.map((el, i) => (
                    <div
                      className="flex min-w-48 cursor-pointer justify-between gap-4 rounded-lg bg-clay-900/10 p-3 duration-200 hover:bg-clay-900/15"
                      key={i}
                    >
                      <span className="text-base font-medium text-white">{el}</span>
                      <ArrowUpRightIcon className="size-5 text-white opacity-30" />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="mb-5 flex justify-between">
            <Input
              className="max-w-2xl"
              classNameInputWrapper="bg-clay-10"
              placeholder="Search for an Agent"
              size="medium"
              startSlot={
                <Icon
                  className="size-4 text-clay-900 transition-colors group-focus-within:text-clay-600 sm:size-4 sm:text-clay-300"
                  name="search"
                />
              }
            />
            <div className="right-4 box-border flex h-11 items-center gap-1 rounded-xl bg-blue-50 p-1">
              <Select
                className="h-9 w-fit rounded-lg bg-gray-100 p-3 text-sm inner-border-0"
                iconName="arrowDownUp"
                onValueChange={() => null}
                placeholder="Sort by"
                placeholderClassName="font-medium text-clay-600"
                value={undefined}
              >
                <Select.Content className="z-10 rounded-lg p-1 shadow-sm">
                  <div className="mb-2 w-48 border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-medium text-blue-800">
                    Sort by
                  </div>
                  <Select.Item
                    className="text-sm text-clay-700 hover:bg-blue-50"
                    indicatorClassName="size-3 text-clay-700"
                    value="newest"
                  >
                    Newest
                  </Select.Item>
                </Select.Content>
              </Select>

              <Button asIcon className="size-9" color="white">
                <Grid2X2Icon className="size-4" />
              </Button>

              <Button asIcon className="size-9" color="secondary">
                <ListIcon className="size-4" />
              </Button>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-2.5 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
            {agentsCards.map((card, i) => (
              <Card key={i}>
                <div className="mb-3.5 flex items-start justify-between">
                  <img alt="" className="size-12" src={card.img} />
                  <div className="flex items-center gap-1">
                    <Icon className="size-4 text-gold-600" name="starSolid" />
                    <div className="pt-0.5 text-sm/4">
                      <span className="fill-gold-600 font-semibold text-gold-600">{card.rating}</span>
                      <span className=" text-clay-20"> / </span>
                      <span className="text-clay-900">{card.reviewCount}</span>
                    </div>
                  </div>
                </div>
                <h3 className="mb-2 text-xl/7 font-semibold text-clay-900">{card.title}</h3>
                <p className="text-base font-light text-clay-500">{card.description}</p>
                <div className="mt-5 text-center text-xs font-semibold text-clay-300">{card.type}</div>
              </Card>
            ))}
          </div>
        </div>
      </div>

      <div className="absolute inset-0 z-30" onClick={() => setIsShowBlocker(true)} />

      <PageIsInAlphaModal
        description={
          <>
            <span className="text-clay-900">
              Access to <span className="font-semibold">Agents</span> is being rolled out in phases to users
              on the early access list.
            </span>
            <p className="mt-2.5 text-sm text-clay-500">To join the access list, upgrade to Nesa Pro.</p>
          </>
        }
        icon={<Bot className="size-5 text-clay-900" />}
        onBackClick={() => {
          setIsShowBlocker(false);
          navigate(-1);
        }}
        onOpenChange={() => {
          setIsShowBlocker(false);
          navigate(-1);
        }}
        onUpgradeToProClick={() => {
          setOpenUpgradeToPro(true);
        }}
        open={isShowBlocker}
        subtitle="Nesa Agents can execute tasks on-chain and are functionally autonomous. Earn money when your own agents are used, and integrate the best ones into DAI."
        title="Nesa Agents is in Alpha"
      />

      <UpgradeToProModal
        onOpenChange={() => {
          setOpenUpgradeToPro(false);
          navigate(-1);
        }}
        open={openUpgradeToPro}
        redirectPath="/"
      />
    </AnimateRoute>
  );
};

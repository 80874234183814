import { twMerge } from 'tailwind-merge';

import type { NesaNode } from 'shared/api/nodes/types';
import type { ClassName } from 'shared/types';

import { Card } from 'shared/ui/Card';
import { Icon } from 'shared/ui/Icon';
import { NodeSpecChip } from 'shared/ui/NodeSpecChip/NodeSpecChip';

import { NodeStatus } from './NodeStatus';

type Props = {
  node: NesaNode;
  onClick?: () => void;
} & ClassName;

export const getWorkerType = (node: NesaNode) => {
  if (node.specs.gpu.length > 0 && !node.specs.gpu.includes('NA')) {
    return 'gpu-worker';
  }

  if (node.specs.cpu.length > 0 && !node.specs.cpu.includes('NA')) {
    return 'cpu-worker';
  }
};

export const NodeCard = ({ className, node, onClick }: Props) => {
  const workerType = getWorkerType(node);
  const specs =
    workerType === 'cpu-worker'
      ? node.specs.cpu.join(',')
      : workerType === 'gpu-worker'
        ? node.specs.gpu.join(',')
        : 'Unknown';

  return (
    <Card
      className={twMerge(
        'flex flex-col !px-6 pt-4',
        !!onClick && 'cursor-pointer transition-colors hover:border-clay-100',
        className,
      )}
      onClick={onClick}
    >
      <header className="flex flex-wrap items-center justify-between gap-1.5 border-b border-clay-20 pb-4 pt-2">
        <div className="flex flex-wrap items-center">
          <div className="mr-3 border-r border-clay-20 pr-3 text-base/none md:text-xl/none">
            {node.moniker || 'Node'}
          </div>

          <div className="text-sm md:text-base">
            ID: <span className="font-light text-clay-500">{node._id}</span>
          </div>
        </div>

        <NodeStatus status={node.status} />
      </header>

      <section className="flex flex-col gap-4 py-3 md:flex-row">
        <div className="size-28 rounded-lg bg-clay-20"></div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col items-start gap-3 md:flex-row md:items-center">
            <div className="flex items-center gap-2 rounded-lg bg-clay-10 px-3 py-2 text-sm font-normal">
              <Icon className="size-4" name="cpu" />
              {workerType ? `${workerType === 'gpu-worker' ? 'GPU' : 'CPU'} worker` : 'Unknown'}
            </div>
            <NodeSpecChip specs={specs} />
          </div>

          <p className="font-light text-clay-500">
            This node is a top performing network resource, rated in the top 1% of performance on the
            inference network.
          </p>
        </div>
      </section>
    </Card>
  );
};

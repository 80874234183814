import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';

import { useUser } from 'app/stores/user';
import { useNodesByPublicKeyInfiniteQuery } from 'shared/api/nodes/useNodesByPublicKeyInfiniteQuery';
import { getShortenedAddress } from 'shared/helpers/getShortenedAddress';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Spinner } from 'shared/ui/Spinner';
import { toaster } from 'shared/ui/Toast';

import emptySrc from './assets/emptyNodes.svg';
import { ConnectNodeModal } from './ui/ConnectNodeModal';
import { DisconnectPublicKeyModal } from './ui/DisconnectPublicKeyModal';
import { NodeCard } from './ui/NodeCard';

export const UserNodeList = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  const [isConnectOpen, setIsConnectOpen] = useState(false);
  const [isDisconnectOpen, setIsDisconnectOpen] = useState(false);

  const { data, fetchNextPage, hasNextPage, isFetching, isPending } = useNodesByPublicKeyInfiniteQuery(
    { limit: 5, publicKey: user?.connected_nodes?.[0]?.public_key || '' },
    { enabled: !!user?.connected_nodes?.[0]?.public_key },
  );

  const hasConnectedNodes = !!user?.connected_nodes?.length;
  const nodes = data?.pages.flat() || [];

  const publicKey = user?.connected_nodes?.[0]?.public_key || '';

  if (!hasConnectedNodes) {
    return (
      <>
        <div className="my-auto flex flex-col items-center justify-center gap-1.5 py-10">
          <div className="mb-2 flex h-20 items-end justify-center">
            <img className="h-auto w-20" src={emptySrc} />
          </div>

          <h1 className="text-center text-2xl ">No nodes provisioned yet</h1>
          <div className="text-center text-base font-light text-clay-500">
            Your nodes that you have provisioned on <br /> the Nesa network will appear here.
          </div>

          <Button className="mt-4" onClick={() => setIsConnectOpen(true)}>
            Connect a node
          </Button>
        </div>
        <ConnectNodeModal isOpen={isConnectOpen} onOpenChange={setIsConnectOpen} />
      </>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="mb-6 flex flex-col gap-0.5">
        <div className="flex items-center justify-between">
          <h2 className="text-xl/none">My Public Key</h2>
          <Button color="white" onClick={() => setIsDisconnectOpen(true)} size="extra-small">
            Disconnect
          </Button>
        </div>

        <CopyToClipboard onCopy={() => toaster.success('Copied')} text={publicKey}>
          <div className="group flex w-fit cursor-pointer select-none items-center gap-2 text-base font-light text-clay-400 transition-colors hover:text-primary-900">
            {getShortenedAddress(publicKey)}{' '}
            <Icon
              className="size-4 cursor-pointer text-clay-300 transition-colors group-hover:text-primary-900"
              name="copy"
            />
          </div>
        </CopyToClipboard>
      </div>

      <div className="flex flex-col gap-3">
        {isPending ? (
          <div className="flex items-center justify-center py-7">
            <Spinner className="size-6" />
          </div>
        ) : (
          nodes.map((node) => {
            return (
              <NodeCard
                key={node._id}
                node={node}
                onClick={() => {
                  navigate(`/nodes/${node.node_id}`);
                }}
              />
            );
          })
        )}

        {hasNextPage && (
          <div className="mx-auto flex w-full items-center justify-center">
            <Button
              isLoading={isFetching}
              onClick={() => {
                fetchNextPage();
              }}
            >
              Load more
            </Button>
          </div>
        )}
      </div>

      {publicKey && (
        <DisconnectPublicKeyModal
          isOpen={isDisconnectOpen}
          onOpenChange={setIsDisconnectOpen}
          publicKey={publicKey}
        />
      )}
    </div>
  );
};

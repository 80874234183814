import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { ConnectedUserWallet } from './types';

import { daiKeys } from './queryKeys';

type Response = {
  list: ConnectedUserWallet[];
};

type Params = {
  daiId?: string;
  userId: string;
};

export const useGetUserConnectedWalletsQuery = (params: Params, options: UseQueryOptions<Response> = {}) => {
  return useQuery({
    queryFn: async () => {
      const { data } = await axiosInstance.get<Response>('/dai/wallets', { params: params });

      return data;
    },
    queryKey: daiKeys.userWallets(params),
    ...options,
  });
};

import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { DAI } from './types';

type Params = {
  address: string;
  pub_key: { type: string; value: string };
  signature: string;
};

type Response = { data: DAI };

export const useDAIVerifyWalletMutation = (options: UseMutationOptions<Response, Error, Params> = {}) => {
  return useMutation({
    mutationFn: async (params: Params) => {
      const { data } = await axiosInstance.post<Response>(`/dai/sign/verify`, params);

      return data;
    },
    ...options,
  });
};

import type { HTMLMotionProps } from 'framer-motion';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useIntersectionObserver } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';

import type { Model } from 'shared/api/models/types';
import type { ClassName } from 'shared/types';

import { cleanObject } from 'shared/helpers/cleanObject';
import { useEvent } from 'shared/hooks/useEvent';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { GalleryCard } from 'shared/ui/GalleryCard';

import type { FilterState } from './types';

import { Filters } from './Filters/Filters';
import { PreviewBanner } from './PreviewBanner/PreviewBanner';

type Props = {
  fetchNextPage: () => void;
  filters?: FilterState;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  isInContainer?: boolean;
  isPending?: boolean;
  modelList: Model[] | undefined;
  onFilterChange?: (filters: FilterState) => void;
  scrollContainerRef: React.MutableRefObject<HTMLDivElement | null>;
} & ClassName &
  HTMLMotionProps<'div'>;

export const Gallery = ({
  fetchNextPage,
  filters,
  hasNextPage,
  isFetchingNextPage,
  isInContainer = true,
  isPending,
  modelList,
  onFilterChange,
  scrollContainerRef,
}: Props) => {
  const [ref, intersection] = useIntersectionObserver<HTMLDivElement>();

  const navigate = useNavigate();
  const itemsCount = modelList?.length ?? 0;
  const sm = useMinWidthMediaQuery('sm');
  const lg = useMinWidthMediaQuery('lg');
  const xl = useMinWidthMediaQuery('xl');

  useEffect(() => {
    if (intersection?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, intersection?.isIntersecting]);

  const getYPosition = useEvent((index: number) => {
    const baseY = 50;

    if (xl) {
      return ((index % 4) + 1) * baseY;
    } else if (lg) {
      return ((index % 3) + 1) * baseY;
    } else if (sm) {
      return ((index % 2) + 1) * baseY;
    } else {
      return ((index % 1) + 1) * baseY;
    }
  });

  return (
    <div
      className={`flex size-full flex-col overflow-hidden ${isInContainer ? 'mt-3 rounded-2xl bg-blue-50 p-2' : ''}`}
    >
      {filters && onFilterChange && <Filters filters={filters} onFilterChange={onFilterChange} />}
      <div className="grow overflow-y-auto scrollbar-none" ref={(el) => (scrollContainerRef.current = el)}>
        <div className="grid w-full grid-flow-dense grid-cols-1 justify-between gap-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {filters && filters.type?.length === 1 && (
            <PreviewBanner className="col-span-1 sm:col-span-2" type={filters.type?.[0] || ''} />
          )}
          {isPending ? (
            new Array(20).fill(null).map((_, i) => <GalleryCard isLoading key={i} />)
          ) : itemsCount > 0 ? (
            modelList?.map((model, i) => {
              return (
                <AnimatePresence key={model._id}>
                  <motion.div
                    animate={{ opacity: 1, y: 0 }}
                    className="group/card cursor-pointer"
                    initial={{ opacity: 0, y: getYPosition(i) }}
                    key={model._id}
                    onClick={() => {
                      const cleanFilters = filters && cleanObject(filters, { removeNull: true });

                      navigate(
                        `/models/${model._id}`,
                        filters ? { state: { homeState: cleanFilters } } : undefined,
                      );
                    }}
                    ref={i === modelList.length - 2 ? ref : undefined}
                    transition={{ duration: 0.8 }}
                  >
                    <GalleryCard
                      isDescriptionPreview
                      isTrustEnabled={filters?.trustEnabled}
                      model={model}
                      // tags={MOCK_NESAS_PICK.includes(model.name) ? ['nesas-pick'] : []}
                    />
                  </motion.div>
                </AnimatePresence>
              );
            })
          ) : (
            <div className="py-5 text-center text-clay-380 sm:col-span-4">No items found</div>
          )}
          {isFetchingNextPage && (
            <>
              <GalleryCard isLoading />
              <GalleryCard isLoading />
              <GalleryCard isLoading />
              <GalleryCard isLoading />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

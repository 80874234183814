import type { InfiniteData } from '@tanstack/react-query';

import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useLocalStorage } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useAccount } from 'graz';
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import type { LLMQueryParams } from 'features/LLMQuery/ui/LLMParams';
import type { Section } from 'features/QueryMenu/QueryMenu';
import type { TransactionData } from 'pages/QueryPage/types';
import type { LlmMessage } from 'shared/api/messages/types';
import type { Model, ModelListResponse } from 'shared/api/models/types';
import type { QueryHistory as QueryHistoryItem } from 'shared/api/queryHistory/types';

import { queryClient } from 'app/App';
import { useUser } from 'app/stores/user';
import { ApiKeyModal } from 'features/ApiKeyModal';
import { addLLMMessage } from 'features/LLMQuery/helpers/addLLMMessage';
import { LLMChatContent } from 'features/LLMQuery/ui/LLMChatContent';
import { LLMParams } from 'features/LLMQuery/ui/LLMParams';
import { LimitReachedModal } from 'features/LimitReachedModal';
import { QueryHistory } from 'features/ModelQueryHistory';
import { ModelReviews } from 'features/ModelReviews';
import { ModelsFromOrg } from 'features/ModelsFromOrg';
import { QueryHeader } from 'features/QueryHeader';
import { IPFS_REGEX } from 'features/QueryHistory';
import { QueryLimitOverview } from 'features/QueryLimitOverview';
import { QueryMenu } from 'features/QueryMenu';
import { chatClient, createChatClient } from 'pages/QueryPage/chatClientStore';
import { ERROR_CODES } from 'pages/QueryPage/config';
import { getErrorMessage } from 'pages/QueryPage/helpers/getErrorMessage';
import { getIsApiModel } from 'pages/QueryPage/helpers/getIsApiModel';
import { getIsZkAvailable } from 'pages/QueryPage/helpers/getIsZkAvailable';
import { useChatStatus } from 'pages/QueryPage/hooks/useChatStatus';
import { InsufficientBalanceModal } from 'pages/QueryPage/ui/InsufficientBalanceModal';
import { Payments } from 'pages/QueryPage/ui/Payments';
import { ReviewModal } from 'pages/QueryPage/ui/ReviewModal';
import { useErrorSubmitMutation } from 'shared/api/errors/useErrorSubmitMutation';
import { getLlmSessionMessageQueryKey } from 'shared/api/messages/useGetLlmSessionMessagesQuery';
import { queryHistoryKeys } from 'shared/api/queryHistory/queryKeys';
import { useCreateQueryHistoryMutation } from 'shared/api/queryHistory/useCreateQueryHistoryMutation';
import { useGetUserReviewByModelQuery } from 'shared/api/reviews/useGetUserReviewByModelQuery';
import { useTokenCheckMutation } from 'shared/api/user/useTokenCheckMutation';
import { cleanObject } from 'shared/helpers/cleanObject';
import { getPrice } from 'shared/helpers/getPrice';
import { sleep } from 'shared/helpers/sleep';
import { uuidv4 } from 'shared/helpers/uuid';
import { useEvent } from 'shared/hooks/useEvent';
import { useLimitReached } from 'shared/hooks/useLimitReached';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { useStateX } from 'shared/hooks/useStateX';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Card } from 'shared/ui/Card';
import { Icon } from 'shared/ui/Icon';
import { ModelDescription } from 'shared/ui/ModelDescription';
import { Spinner } from 'shared/ui/Spinner';
import { StretchedSkeleton } from 'shared/ui/StretchedSkeleton';
import { toaster } from 'shared/ui/Toast';

import flagSrc from './assets/encrypt-flag.png';
import { addMessageTags } from './helpers/addMessageTags';
import { getDefaultLLMParams } from './helpers/getDefaultLLMParams';
import { getLLMModelParams } from './helpers/getLLMModelParams';
import { tokenizer } from './helpers/hotoke';
import { ExpandedViewChat } from './ui/ExpandedViewChat';
import { LLMParamsAccordion } from './ui/LLMParamsAccordion';
// import { LLMSessionHistory } from './ui/LLMSessionHistory';

type Stream = { destroy: () => void; on: (event: string, fn: unknown) => void } & unknown;

const isProd = import.meta.env.MODE === 'production';

type Props = {
  model: Model;
};

export const LLMQuery = ({ model }: Props) => {
  const { id: modelId, section } = useParams<{ id: string; section?: Section }>();

  const navigate = useNavigate();

  const { isLimitReached, refetch: refetchLimits } = useLimitReached(modelId);
  const { mutateAsync: runTokenCheck } = useTokenCheckMutation();

  const md = useMinWidthMediaQuery('md');

  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [isExpandedViewOpen, setIsExpandedViewOpen] = useState(false);
  // const [isHistoryExpanded, setIsHistoryExpanded] = useState(false);
  const [isPaymentsExpanded, setIsPaymentsExpanded] = useState(false);
  const [historyId, setHistoryId] = useState('');
  const [isParamsExpanded, setIsParamsExpanded] = useLocalStorage('llmParamOpen', true);
  const [activeSection, setActiveSection] = useState<Section>(section || 'playground');

  const [modelReviews, setModelReviews] = useLocalStorage<Record<string, boolean>>('nesaModelReviews', {});

  const { mutateAsync: logError } = useErrorSubmitMutation();

  const { data: account, walletType } = useAccount();
  const { user } = useUser();

  const { data: reviewData } = useGetUserReviewByModelQuery(
    { modelId: model?._id || '', userId: user?._id || '' },
    { enabled: !!model?._id && !!user?._id },
  );
  const { mutateAsync: createQueryHistoryItem } = useCreateQueryHistoryMutation();

  const userReview = reviewData?.data;

  useEffect(() => {
    createChatClient({
      address: account?.bech32Address,
      isByPass: !isLimitReached,
      modelName: model?.name.toLowerCase(),
      walletType,
    });

    requestChatStatus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.bech32Address, isLimitReached, model?.name, walletType]);

  const isApiModel = getIsApiModel(model);

  const { requestChatStatus, txDataCode, updateTxDataCode } = useChatStatus();

  const [responseCount, setResponseCount] = useState(0);
  const [queryParams, setQueryParams] = useStateX<LLMQueryParams>(getDefaultLLMParams(isApiModel));
  const [txData, setTxData] = useStateX<TransactionData>({
    minerRates: [],
    paymentProgress: [],
    status: 'idle',
  });

  const [readableStream, setReadableStream] = useState<Stream>();
  const [txDataErrorMessage, setTxDataErrorMessage] = useState<null | string>(null);
  const [errorModalOpen, setErrorModalOpen] = useStateX({ insufficientBalance: false });
  const [isApiKeyModalOpen, setIsApiKeyModalOpen] = useState(false);

  const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);

  const transactionHashRef = useRef('');

  const timeoutRef = useRef<NodeJS.Timeout | null | number>(null);
  const txTimeoutRef = useRef<NodeJS.Timeout | null | number>(null);
  const failedRequestRetries = useRef(0);

  useEffect(() => {
    isLimitReached && setIsLimitModalOpen(true);
  }, [isLimitReached]);

  useEffect(() => {
    return () => {
      const historyQueryKey = getLlmSessionMessageQueryKey({
        modelName: model?.name,
        sessionId: 'default',
        userId: user?._id || '',
      });

      queryClient.setQueryData(historyQueryKey, {
        pageParams: [],
        pages: [],
      });
    };
  }, [model?.name, user?._id]);

  const resetAnimation = () => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    setTxData({ status: 'idle' });
  };

  const requestSession = async (cb?: () => void) => {
    try {
      if (!chatClient) {
        toaster.error('No client defined');
        return;
      }

      const sessionStream = await chatClient.requestSession();

      sessionStream.on('data', (data: { code: number; message: string }) => {
        //  Processing transmission data
        console.log('sessionStream', data);

        setTxDataErrorMessage(null);

        const { code } = data;
        const message = data.message || '';

        if (code === 200) {
          // Streaming data return for TransactionHash

          setTxData({ transactionHash: message || '' });
          transactionHashRef.current = message || '';

          if (cb && typeof cb === 'function') {
            cb();
          }
        } else if (code === 302) {
          console.log('code');
        } else if (ERROR_CODES.includes(code)) {
          console.log('ERROR', { code, message });
          const { text: error, type } = getErrorMessage(message.toLowerCase());
          transactionHashRef.current = '';

          if (type === 'insufficientBalance') {
            setErrorModalOpen({ insufficientBalance: true });
          }

          toaster.error(error);
          setTxDataErrorMessage(error);
          updateTxDataCode(code);

          isProd &&
            logError({
              error: getErrorMessage(message.toLowerCase()).text,
              modelId: model?._id || '',
              params: {
                ...txData,
                code,
                full: message,
                msg: error,
              },
              userId: user?._id || '',
            });

          setTxData({ transactionHash: '' });
        }
      });
      sessionStream.on('end', async () => {
        // End of transmission
      });
      // });
    } catch (e) {
      console.log('ERROR SESSION', e);
      console.error(e);

      const errorMessage = getErrorMessage(`${e}`).text;

      isProd &&
        logError({
          error: `${e}`,
          modelId: model?._id || '',
          params: { ...txData, msg: txDataErrorMessage },
          userId: user?._id || '',
        });

      toaster.error(errorMessage);

      resetAnimation();
    }
  };

  const startTxTimeout = (stream: { destroy: () => void } & unknown) => {
    txTimeoutRef.current && clearTimeout(txTimeoutRef.current);

    txTimeoutRef.current = setTimeout(
      () => {
        console.log('timeout');
        resetAnimation();
        setTxData({ status: 'error' });
        logError({
          error: `model timeout`,
          modelId: model?._id || '',
          params: { ...txData },
          userId: user?._id || '',
        });

        stream?.destroy();

        txTimeoutRef.current && clearTimeout(txTimeoutRef.current);
      },
      5 * 60 * 1000,
    );
  };

  const handleLLmError = async (qParams: Partial<LLMQueryParams> = {}, skipMsg?: boolean) => {
    readableStream?.destroy();
    chatClient?.requestCloseHeartbeat();

    await requestSession(() => setTimeout(() => handleSendQuery(qParams, skipMsg), 5000));
  };

  const handleSendQuery = async (qParams: Partial<LLMQueryParams> = {}, skipMsg?: boolean) => {
    const params: LLMQueryParams = { ...queryParams, ...qParams };

    if (params.question && !skipMsg) {
      const messageId = uuidv4();
      addLLMMessage({
        _id: messageId,
        messageText: params.question,
        modelName: model?.name || '',
        params,
        role: 'user',
        sessionId: txData.sessionId,
        userId: user?._id || '',
      });
    }

    setTxData({ status: 'progress' });

    const startTime = DateTime.now();

    if (!chatClient) {
      console.error('No client defined!');

      return;
    }

    const modelParams = getLLMModelParams({
      isApiModel,
      isZkAvailable: getIsZkAvailable(model) || false,
      queryParams: params,
    });

    const hasEESupported =
      model.name?.toLowerCase() === 'meta-llama/Llama-3.2-1B-Instruct'.toLowerCase() && queryParams.private;

    const listQueryKey = getLlmSessionMessageQueryKey({
      modelName: model.name,
      sessionId: txData.sessionId || '',
      userId: user?._id || '',
    });

    const messages: InfiniteData<ModelListResponse, unknown> | undefined = hasEESupported
      ? queryClient.getQueryData(listQueryKey)
      : undefined;

    const allMessagesToInclude = messages?.pages
      ?.flatMap((msg) => msg)
      .slice(1, 6)
      ?.reverse() as LlmMessage[] | undefined;

    const allMessages = allMessagesToInclude?.map((message) => {
      return `<|start_header_id|>${message.history.role || ''}<|end_header_id|>${message.history.content || ''}<|eot_id|>`;
    });

    const question = hasEESupported
      ? JSON.stringify(
          tokenizer.encode(
            addMessageTags({
              history: allMessages?.join(''),
              systemText: 'systemPrompt' in params && params.systemPrompt ? params.systemPrompt : undefined,
              text: params.question,
            }),
            { bos: true, eos: false },
          ) || [],
        )
      : params.question;

    const chatRequest = {
      messages: [{ content: question, role: 'user' }],
      model: hasEESupported ? `${model?.name.toLowerCase() || ''}-he` : model?.name.toLowerCase() || '',
      model_params: modelParams,
      session_id: JSON.stringify(
        cleanObject({
          api_key: 'apiKey' in params ? params.apiKey : undefined,
          // ee: hasEESupported ? true : undefined,
          // privacy_strategy:
          //   params.private && (model.privacy_strategies || []).length > 0
          //     ? model.privacy_strategies[0]
          privacy_strategy: undefined,
          session_id: txData.sessionId || null,
          user_id: user?._id,
        }),
      ),
    };

    chatClient
      ?.requestChat(chatRequest)
      ?.then((readableStream1) => {
        const readableStream = readableStream1 as Stream;

        let execTime: number | undefined = undefined;
        let response = '';
        let sessionId = '';
        let proofCid: string | undefined = undefined;

        const isSessionReinitiated = false;

        startTxTimeout(readableStream);
        setReadableStream(readableStream);

        readableStream.on(
          'data',
          (data: {
            code: number;
            content?: string;
            message: string;
            session_id?: string;
            total_payment: { amount: number; denom: string };
          }) => {
            console.log('readable stream', data);

            startTxTimeout(readableStream);

            // Processing transmission data
            if (data.code === 200) {
              failedRequestRetries.current = 0;

              if (!execTime) {
                execTime = Math.abs(startTime.diffNow('seconds').seconds);
              }

              response += data.message;

              // responseArray.push(Number(data.message));

              const isIpfsCid = hasEESupported && !!data.message && IPFS_REGEX.test(data.message);

              if (isIpfsCid) {
                proofCid = data.message;
              }

              if (data.message === '[DONE]' || data.message === '##') {
                // console.log('responseArray', responseArray);
                return;
              }

              let parsedSessionField;
              try {
                parsedSessionField =
                  typeof data.session_id === 'string' && data.session_id.trim().startsWith('{')
                    ? JSON.parse(data.session_id)
                    : { session_id: data.session_id };
              } catch (e) {
                parsedSessionField = { session_id: data.session_id };
              }

              console.log('parsedSessionField', parsedSessionField);

              sessionId = parsedSessionField?.session_id;

              try {
                setTxData((prev) => ({
                  ...prev,
                  executionTime: execTime,
                  paymentProgress: [
                    ...(prev.paymentProgress || []),
                    { ...data.total_payment, date: DateTime.now().toISO() },
                  ],
                  sessionId: parsedSessionField.session_id,
                }));

                const messageId = uuidv4();
                addLLMMessage({
                  _id: messageId,
                  isEncoded: hasEESupported || false,
                  messageText: data.message,
                  modelName: model?.name || '',
                  params,
                  role: 'assistant',
                  sessionId: parsedSessionField.session_id ?? 'default',
                  userId: user?._id || '',
                });

                setResponseCount((prev) => prev + 1);
                const reviewStorageKey = `${user?._id}-${model?._id}`;
                setTimeout(() => {
                  const messageElement = document.getElementById(messageId);
                  if (messageElement) {
                    messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                  }
                }, 100);
                if (responseCount + 1 === 2 && !modelReviews[reviewStorageKey] && !userReview) {
                  setModelReviews((prev) => ({ ...prev, [reviewStorageKey]: true }));

                  setTimeout(() => {
                    setIsReviewOpen(true);
                  }, 500);
                }
              } catch (e) {
                console.error('json parse error', e, data.session_id);
              }
            }

            if (
              data.code === 204 &&
              data.message === 'Error: Connection failed' &&
              failedRequestRetries.current <= 3
            ) {
              failedRequestRetries.current += 1;
              setTimeout(
                () => {
                  handleSendQuery(qParams, true);
                },
                500 * (failedRequestRetries.current + 1),
              );

              return;
            }

            if (data.code === 205) {
              const parsed = data.message ? JSON.parse(data.message) : {};

              if (parsed.msg === 'session status mismatch') {
                setTimeout(() => {
                  handleLLmError(qParams, true);
                }, 0);

                return;
              }

              if (
                parsed.detail?.toLowerCase() === 'Token is invalid'.toLowerCase() &&
                failedRequestRetries.current < 2
              ) {
                (async () => {
                  failedRequestRetries.current += 1;
                  const accessTokenDefault = localStorage.getItem('user') || undefined;

                  createChatClient({
                    address: account?.bech32Address,
                    authToken: accessTokenDefault,
                    isByPass: !isLimitReached,
                    modelName: model.name,
                    walletType,
                  });

                  requestChatStatus();

                  await sleep(1000);

                  await requestSession(() =>
                    setTimeout(
                      () => {
                        handleSendQuery(params, true);
                      },
                      isLimitReached ? 5000 : 400,
                    ),
                  );
                })();

                return;
              }

              if (
                parsed.detail?.toLowerCase() === 'session id is required'.toLowerCase() &&
                failedRequestRetries.current < 2
              ) {
                (async () => {
                  failedRequestRetries.current += 1;

                  await requestSession(() =>
                    setTimeout(
                      () => {
                        handleSendQuery(params, true);
                      },
                      isLimitReached ? 5000 : 400,
                    ),
                  );
                })();

                return;
              }

              const isBackendError = 'LLM Backend error'
                .toLowerCase()
                .includes((parsed.msg as string).toLowerCase());

              const isLLMError = ['LLM Network error', 'LLM Backend error']
                .map((s) => s.toLowerCase())
                .includes((parsed.msg as string).toLowerCase());
              const isAbnormal = (parsed.msg as string).toLowerCase() === 'LLM Abnormal'.toLowerCase();
              const text = isLLMError
                ? 'Network experiencing extreme load, try again later.'
                : isAbnormal
                  ? 'LLM Network error'
                  : parsed.msg;

              if (isBackendError && failedRequestRetries.current <= 3) {
                failedRequestRetries.current += 1;

                setTimeout(() => {
                  requestSession(() =>
                    setTimeout(
                      () => {
                        handleSendQuery(params, true);
                      },
                      isLimitReached ? 5000 : 400,
                    ),
                  );
                }, 500);
              } else if (parsed.msg.includes('balance insufficient')) {
                setErrorModalOpen({ insufficientBalance: true });
                setTxData({ transactionHash: undefined });
              } else {
                text && toaster.error(text);
              }

              resetAnimation();
            }
          },
        );

        readableStream.on('end', async () => {
          txTimeoutRef.current && clearTimeout(txTimeoutRef.current);
          console.log('sessionId', sessionId);
          console.log('response', response);
          // console.log('responseArray', responseArray);

          if (response) {
            const createdHistoryItem = await createQueryHistoryItem({
              api: !isLimitReached,
              executionTime: execTime,
              // executionTime: execTime,
              minerRates: [],
              modelId: modelId || '',
              pricePerToken: model?.price?.toFixed(3) || '',
              private: queryParams.private || false,
              proofCid: proofCid,
              question: queryParams.private ? '***' : chatRequest.messages[0].content?.toString(),
              result: queryParams.private ? '***' : response,
              sessionId,
              status: 'completed',
              transactionHash: transactionHashRef.current,
              walletAddress: account?.bech32Address || '',
            });
            setHistoryId(createdHistoryItem._id);
            const historyKey = queryHistoryKeys.list({
              limit: 10,
              modelId: model._id,
              type: 'user-based',
              userId: user?._id || '',
            });

            const currentHistory = queryClient.getQueryData<{
              pageParams: number[];
              pages: [QueryHistoryItem[]];
            }>(historyKey);

            queryClient.setQueryData(historyKey, {
              ...(currentHistory || {}),
              pageParams: currentHistory?.pageParams || [0],
              pages: [[{ ...createdHistoryItem, model: model }, ...(currentHistory?.pages?.[0] || [])]],
            });
            queryClient.invalidateQueries({ queryKey: historyKey });
          }

          refetchLimits();

          if (isSessionReinitiated) {
            readableStream.destroy();
            return;
          }

          setTxData({ status: 'completed' });

          timeoutRef.current && clearTimeout(timeoutRef.current);

          readableStream.destroy();
          // End of transmission
        });
      })
      .catch((error) => {
        console.log('ERRROR', error);

        console.error(error);
        setTxData({ status: error });

        isProd &&
          logError({
            error: `${error}`,
            modelId: model?._id || '',
            params: { ...txData, msg: txDataErrorMessage },
            userId: user?._id || '',
          });
      });
  };

  const handleSectionChange = (section: Section) => {
    setActiveSection(section);
    navigate(`/models/${modelId}/${section}`, { replace: true });
  };

  const getExampleModelParamsCb = useEvent(() => ({
    chatRequest: {
      messages: [{ content: queryParams.question, role: 'user' }],
      model: model?.name || '',
      model_params: getLLMModelParams({
        isApiModel,
        isZkAvailable: getIsZkAvailable(model) || false,
        queryParams,
      }),
      session_id: JSON.stringify(
        cleanObject({
          api_key: 'apiKey' in queryParams ? queryParams.apiKey : undefined,
          session_id: txData.sessionId || null,
          user_id: user?._id,
        }),
      ),
    },
    expectedInput: model?.expectedInput,
    model: model?.name || '',
    walletType,
  }));

  const onMessageSend = async (message: string) => {
    if ('apiKey' in queryParams && !queryParams.apiKey && model.org && getIsApiModel(model)) {
      setIsApiKeyModalOpen(true);

      return;
    }

    const { valid } = await runTokenCheck();

    failedRequestRetries.current = 0;

    if (!txData.transactionHash || !valid) {
      await requestSession(() =>
        setTimeout(
          () => {
            setQueryParams({ question: message });
            handleSendQuery({ question: message });
          },
          isLimitReached ? 5000 : 400,
        ),
      );

      return;
    }

    setQueryParams({ question: message });
    handleSendQuery({ question: message });
  };

  return (
    <AnimateRoute className="flex grow flex-col gap-4 px-2 py-4 xs:p-4 lg:overflow-hidden">
      <QueryHeader
        model={model}
        onReviewsClick={() => handleSectionChange('reviews')}
        txDataCode={txDataCode}
        txDataErrorMessage={txDataErrorMessage}
      />

      <div className="relative grid h-full grid-rows-[11rem_1fr] gap-1 overflow-scroll rounded-2xl bg-clay-20 p-2 md:grid-rows-[5.2rem_1fr] lg:grid-rows-[3rem_1fr] lg:overflow-hidden ">
        <QueryMenu
          downloads={model.nesa_downloads || 0}
          getModelParams={getExampleModelParamsCb}
          modelId={model._id}
          onChange={handleSectionChange}
          value={activeSection}
        />

        <div className="grid grid-cols-1 gap-2 overflow-hidden md:grid-cols-5">
          <AnimatePresence>
            {activeSection === 'description' && <ModelDescription model={model} />}
            {activeSection === 'reviews' && model && (
              <Card
                animate={{ opacity: 1 }}
                className="flex flex-col overflow-hidden  md:col-span-3"
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
              >
                <ModelReviews modelId={model._id} />
              </Card>
            )}

            {activeSection === 'playground' && (
              <motion.div
                animate={{ opacity: 1 }}
                className={twMerge(
                  'relative flex min-h-[32rem] flex-1 flex-col overflow-y-scroll rounded-lg border-2 border-clay-20 bg-white transition-colors scrollbar-none md:col-span-3 md:min-h-fit',
                  queryParams.private && 'border-clay-800',
                )}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
              >
                <AnimatePresence>
                  {queryParams.private && (
                    <motion.div
                      animate={{ opacity: 1 }}
                      className="absolute left-1/2 top-0 z-50 flex -translate-x-1/2 items-center justify-center"
                      exit={{ opacity: 0 }}
                      initial={{ opacity: 0 }}
                    >
                      <div
                        className="inset-x-0 top-0 flex min-w-72 items-center justify-center bg-contain bg-top bg-no-repeat px-10 pb-2 pt-1 text-sm text-white"
                        style={{ backgroundImage: `url(${flagSrc})` }}
                      >
                        <Icon className="mr-2 inline-flex size-4 text-tusk-200" name="lock" />
                        <span className="mt-px whitespace-nowrap leading-none">100% Encrypted Request</span>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
                {md && (
                  <>
                    <motion.button
                      animate="rest"
                      className="absolute right-1.5 top-1.5 z-20 flex h-7 min-w-7 items-center justify-center rounded-md bg-clay-20 px-1 text-clay-400 outline-none"
                      initial="rest"
                      onClick={() => setIsExpandedViewOpen(true)}
                      whileHover="hover"
                    >
                      <Icon className="size-4 min-w-4" name="expand" />
                      <motion.div
                        className="overflow-hidden"
                        variants={{ hover: { opacity: 1, width: 'auto' }, rest: { opacity: 0, width: 0 } }}
                      >
                        <div className="pl-2 pr-0.5 text-sm font-semibold">Fullscreen</div>
                      </motion.div>
                    </motion.button>
                    <ExpandedViewChat
                      isApiModel={isApiModel}
                      isOpen={isExpandedViewOpen}
                      isRequestingSession={!!txDataCode && [302, 303].includes(txDataCode) && isLimitReached}
                      isTyping={txData.status === 'progress'}
                      model={model}
                      onMessageSend={onMessageSend}
                      onOpenChange={setIsExpandedViewOpen}
                      onQueryParamsChange={(params) => setQueryParams((prev) => ({ ...prev, ...params }))}
                      queryParams={queryParams}
                      sessionId={txData.sessionId}
                    />
                  </>
                )}
                <AnimatePresence>
                  {modelId && (
                    <QueryLimitOverview
                      className={twMerge(
                        'absolute left-1/2 top-2.5 z-50 -translate-x-1/2 transition-all',
                        queryParams.private && 'top-9',
                      )}
                      modelId={modelId}
                    />
                  )}
                </AnimatePresence>
                {!model ? (
                  <div>
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <LLMChatContent
                      isRequestingSession={!!txDataCode && [302, 303].includes(txDataCode) && isLimitReached}
                      isTyping={txData.status === 'progress'}
                      model={model}
                      onMessageSend={onMessageSend}
                      onQueryParamsChange={(params) => setQueryParams((prev) => ({ ...prev, ...params }))}
                      queryParams={queryParams}
                      sessionId={txData.sessionId}
                    />
                  </>
                )}
              </motion.div>
            )}
          </AnimatePresence>

          <motion.div className="relative flex flex-1 flex-col gap-2 overflow-y-scroll md:col-span-2">
            <StretchedSkeleton enable={!model} rx={8} ry={8} />
            <AnimatePresence>
              {model && (
                <LLMParamsAccordion
                  isApiModel={isApiModel}
                  isExpanded={isParamsExpanded}
                  onExpandedChange={setIsParamsExpanded}
                  queryParams={queryParams}
                >
                  <LLMParams isApiModel={isApiModel} onChange={setQueryParams} queryParams={queryParams} />
                </LLMParamsAccordion>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {model && (
                <QueryHistory contentClassName="max-h-72" modelId={model._id} userId={user?._id || ''} />
              )}
            </AnimatePresence>
            <AnimatePresence>
              <Card animate={{ opacity: 1 }} className="!pb-1" initial={{ opacity: 0 }}>
                <Payments
                  executionTime={txData.executionTime}
                  historyId={historyId}
                  isOpen={isPaymentsExpanded}
                  onOpenChange={setIsPaymentsExpanded}
                  pricePerToken={getPrice(model.pricing?.output_price) || 0.001}
                  rates={txData.minerRates}
                  totalPayment={
                    txData.paymentProgress
                      ? txData.paymentProgress[txData.paymentProgress.length - 1]?.amount
                      : 0
                  }
                />
              </Card>
            </AnimatePresence>
            {/* {model && (
              <LLMSessionHistory
                activeSessionId={txData.sessionId}
                isExpanded={isHistoryExpanded}
                modelId={model._id}
                onClick={(item) => {
                  setTxData({ sessionId: item._id === txData.sessionId ? undefined : item._id });
                }}
                onExpandedChange={setIsHistoryExpanded}
                userId={user?._id || ''}
              />
            )} */}
            <AnimatePresence>
              {model?.org && <ModelsFromOrg modelIdToExclude={model._id} orgName={model.org} />}
            </AnimatePresence>
          </motion.div>
        </div>
      </div>

      <InsufficientBalanceModal
        isOpen={errorModalOpen.insufficientBalance}
        onOpenChange={(isOpen) => setErrorModalOpen({ insufficientBalance: isOpen })}
      />

      {model && (
        <ReviewModal
          isOpen={isReviewOpen}
          model={model}
          onOpenChange={setIsReviewOpen}
          sessionId={txData.sessionId}
        />
      )}

      <LimitReachedModal
        isOpen={isLimitModalOpen}
        onConnected={() => setTxData({ transactionHash: undefined })}
        onOpenChange={setIsLimitModalOpen}
      />

      <ApiKeyModal
        isOpen={isApiKeyModalOpen}
        onOpenChange={setIsApiKeyModalOpen}
        onSubmit={(key) => {
          setQueryParams({ apiKey: key });
          setIsApiKeyModalOpen(false);
        }}
      />
    </AnimateRoute>
  );
};

import { Icon } from 'shared/ui/Icon';

type Props = {
  status: number;
};
export const NodeStatus = ({ status }: Props) => {
  if (status === 1) {
    return (
      <div className="flex select-none items-center gap-2 text-xs text-green-800 md:text-sm">
        <Icon className="size-3 text-green-800" name="check" /> Online
      </div>
    );
  }
  if (status === 2) {
    return (
      <div className="flex select-none items-center gap-2 text-xs text-red-800 md:text-sm">
        <Icon className="size-3 text-red-800" name="close" /> Down
      </div>
    );
  }
  return (
    <div className="flex select-none items-center gap-2 text-xs md:text-sm">
      <div className="size-3 rounded-full bg-clay-500 "></div> Unknown
    </div>
  );
};

import type { PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

type Props = {
  onPageChange: (page: number) => void;
  page: number;
  perPage: number;
  totalItemsCount: number;
};

const pageOffset = 2;

const Page = ({
  children,
  className,
  isActive,
  onClick,
}: PropsWithChildren<{ isActive?: boolean; onClick: () => void } & ClassName>) => {
  return (
    <div
      className={twMerge(
        'flex cursor-pointer select-none items-center justify-center rounded-lg border border-clay-20 px-3 py-2 text-sm font-medium text-clay-400 transition-colors hover:bg-clay-10',
        isActive && 'border-clay-40 bg-clay-20 hover:bg-clay-20',
        className,
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export const Pagination = ({ onPageChange, page, perPage, totalItemsCount }: Props) => {
  const calculatedPageCount = Math.ceil(totalItemsCount / perPage);
  const pageCount = calculatedPageCount > 99 ? 99 : calculatedPageCount;

  const startPage = page - pageOffset < 0 ? 0 : page - pageOffset;
  const endPage = page + pageOffset < pageCount ? page + pageOffset : pageCount - 1;

  if (pageCount < 11) {
    return (
      <div className="mx-auto flex w-full items-center justify-center gap-2 pt-6">
        {Array.from({ length: pageCount })
          .fill('')
          .map((_, idx) => {
            return (
              <Page isActive={idx === page} key={idx} onClick={() => onPageChange(idx)}>
                {idx + 1}
              </Page>
            );
          })}
      </div>
    );
  }

  return (
    <div className="mx-auto mt-auto flex w-full items-center justify-center gap-2 pt-6">
      <div
        className={twMerge(
          'cursor-pointer select-none transition-colors hover:text-clay-700',
          page === 0 && 'pointer-events-none opacity-50',
        )}
        onClick={() => {
          if (page === 0) return;

          onPageChange(page - 1);
        }}
      >
        Previous
      </div>
      {startPage !== 0 && (
        <>
          <Page onClick={() => onPageChange(0)}>1</Page>
          <div>...</div>
        </>
      )}
      {Array.from({ length: endPage - startPage + 1 })
        .fill('')
        .map((_, idx) => {
          return (
            <Page
              isActive={startPage + idx === page}
              key={startPage + idx}
              onClick={() => onPageChange(startPage + idx)}
            >
              {startPage + idx + 1}
            </Page>
          );
        })}

      {endPage < pageCount - 1 && (
        <>
          <div className="select-none">...</div>
          <Page onClick={() => onPageChange(pageCount - 1)}>{pageCount}</Page>
        </>
      )}
      <div
        className={twMerge(
          'cursor-pointer select-none transition-colors hover:text-clay-700',
          page === pageCount - 1 && 'pointer-events-none opacity-50',
        )}
        onClick={() => {
          if (page === pageCount - 1) return;

          onPageChange(page + 1);
        }}
      >
        Next
      </div>
    </div>
  );
};

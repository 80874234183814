import type { ComponentProps } from 'react';
import { useSearchParams } from 'react-router-dom';

import { XIcon } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

import { Icon, type IconName } from 'shared/ui/Icon';
import { Slider } from 'shared/ui/Slider';

import type { FiltersProps } from './MainFilters';

import { TasksFilters } from './TasksFilters';

export const filterSections = [
  { id: 'main', label: 'Main' },
  // { id: 'tasks', label: 'Tasks' },
  { id: 'libraries', label: 'Libraries' },
  { id: 'languages', label: 'Languages' },
  { id: 'licenses', label: 'Licenses' },
  { id: 'other', label: 'Other' },
] as const;

export const modalities: { icon: IconName; id: string; isStroke?: boolean; label: string }[] = [
  { icon: 'rotate', id: '3d', isStroke: true, label: '3D' },
  { icon: 'audioLines', id: 'audio', isStroke: true, label: 'Audio' },
  { icon: 'database', id: 'geospatial', label: 'Geospatial' },
  { icon: 'image', id: 'image', label: 'Image' },
  { icon: 'letterText', id: 'text', isStroke: true, label: 'Text' },
  { icon: 'table', id: 'tabular', isStroke: true, label: 'Tabular' },
  { icon: 'clock', id: 'timeseries', label: 'Time-Series' },
  { icon: 'play', id: 'video', isStroke: true, label: 'Video' },
] as const;

export const formats: { id: string; label: string }[] = [
  { id: 'json', label: 'json' },
  { id: 'csv', label: 'CSV' },
  { id: 'parquet', label: 'parquet' },
  { id: 'imagefolder', label: 'imagefolder' },
  { id: 'audiofolder', label: 'soundfolder' },
  { id: 'webdataset', label: 'webdataset' },
  { id: 'text', label: 'text' },
  { id: 'arrow', label: 'arrow' },
] as const;

type Props = {
  filters: FiltersProps['filters'];
  onChange: FiltersProps['onChange'];
};

const sizeNumberToString = (val: number) => {
  if (val === 0) return '<1K';
  if (val === 1) return '1K';
  if (val === 2) return '10K';
  if (val === 3) return '100K';
  if (val === 4) return '1M';
  if (val === 5) return '10M';
  if (val === 6) return '100M';
  if (val === 7) return '1B';
  if (val === 8) return '10B';
  if (val === 9) return '100B';
  if (val === 10) return '1T';
  if (val === 11) return '>1T';
};

const sizeStringToNumber = (val: string) => {
  if (val === '<1K') return 0;
  if (val === '1K') return 1;
  if (val === '10K') return 2;
  if (val === '100K') return 3;
  if (val === '1M') return 4;
  if (val === '10M') return 5;
  if (val === '100M') return 6;
  if (val === '1B') return 7;
  if (val === '10B') return 8;
  if (val === '100B') return 9;
  if (val === '1T') return 10;
  if (val === '>1T') return 11;
};

export const MainFiltersSection = ({ filters, onChange }: Props) => {
  const [, setSearchParams] = useSearchParams();

  return (
    <>
      <div className="grid grid-cols-4 grid-rows-2 overflow-hidden rounded-xl  bg-white inner-border inner-border-clay-20">
        {modalities.map(({ icon, id, isStroke, label }) => {
          const isSelected = filters.modality?.includes(id);
          return (
            <div
              className={twMerge(
                'group flex border-collapse cursor-pointer flex-col items-center justify-center gap-2 border-[0.5px] border-clay-20 py-4 transition-colors hover:bg-primary-40 hover:text-primary-800',
                isSelected && 'bg-primary-40',
              )}
              key={id}
              onClick={() => {
                onChange({
                  modality: isSelected
                    ? filters.modality?.filter((m) => m !== id)
                    : [...(filters.modality || []), id],
                });
              }}
            >
              <Icon
                className={twMerge(
                  'size-5 text-clay-400 transition-colors group-hover:text-primary-800',
                  isStroke &&
                    'stroke-clay-400 stroke-[1.6px] !text-transparent group-hover:stroke-primary-800',
                  icon === 'play' && 'stroke-2',
                  isSelected && (isStroke ? 'stroke-primary-800' : 'text-primary-800'),
                )}
                name={icon}
              />
              <span
                className={twMerge(
                  'text-sm text-clay-800 transition-colors group-hover:text-primary-800',
                  isSelected && 'text-primary-800',
                )}
              >
                {label}
              </span>
            </div>
          );
        })}
      </div>

      <div className="flex flex-col gap-4 border-b border-clay-20 pb-6 pt-4">
        <div className="text-sm font-medium text-clay-700">Size(rows)</div>

        <div className="flex w-full flex-col gap-0.5">
          <Slider
            max={11}
            min={0}
            onValueChange={([val]) =>
              onChange({ size: sizeNumberToString(val) ? [sizeNumberToString(val)!] : [] })
            }
            renderTooltipContent={([val] = []) => {
              return val !== undefined ? sizeNumberToString(val) : '';
            }}
            step={1}
            value={[sizeStringToNumber(filters.size[0]) || 0]}
          />

          <div className="flex items-start justify-between text-xs font-medium">
            <span>{`<1K`}</span>
            <span>{`>1T`}</span>
          </div>
        </div>
      </div>

      {!!filters.keywords.length && (
        <div className="flex flex-col gap-4">
          {/* <div className="text-sm font-medium text-clay-700">Active Keywords</div> */}
          <div className="flex flex-wrap items-center gap-2">
            {filters.keywords.map((keyword) => {
              const isSelected = true;
              return (
                <Chip
                  aria-selected={isSelected}
                  className="flex items-center gap-1"
                  key={keyword}
                  onClick={() => {
                    onChange({
                      keywords: [],
                    });
                    setSearchParams((prev) => {
                      const newS = new URLSearchParams(prev);
                      newS.delete('keywords');
                      return newS;
                    });
                  }}
                >
                  {keyword}
                  <XIcon className="size-4" strokeWidth={2} />
                </Chip>
              );
            })}
          </div>
        </div>
      )}

      <div className="flex flex-col gap-4">
        <div className="text-sm font-medium text-clay-700">Format</div>

        <div className="flex flex-wrap items-center gap-2">
          {formats.map(({ id, label }) => {
            const isSelected = filters.format?.includes(id) || filters.keywords?.includes(id);
            return (
              <Chip
                aria-selected={isSelected}
                key={id}
                onClick={() =>
                  onChange({
                    format: isSelected
                      ? filters.format?.filter((i) => i !== id)
                      : [...(filters.format || []), id],
                  })
                }
              >
                {label}
              </Chip>
            );
          })}
        </div>
      </div>
      <TasksFilters filters={filters} onChange={onChange} />
    </>
  );
};

const Chip = (props: ComponentProps<'div'>) => {
  return (
    <div
      {...props}
      className={twMerge(
        'cursor-pointer rounded-lg border border-clay-20 bg-transparent px-2 py-1.5 text-sm/none text-clay-500 transition-colors hover:bg-primary-40',
        'aria-selected:border-primary-800 aria-selected:bg-primary-800 aria-selected:text-white aria-selected:hover:bg-primary-900',
        props.className,
      )}
    />
  );
};

export const daiKeys = {
  base: ['dai'],
  byId: (params: { id: string }) => [...daiKeys.base, 'byId', params],
  connectedModels: (params: { walletAddresses: string[] }) => [...daiKeys.base, 'connectedModels', params],
  daiSummary: (params: { daiId: string; walletAddresses: string[] }) => [
    ...daiKeys.base,
    'daiSummary',
    params,
  ],
  daiWallets: (params: { daiId: string }) => [...daiKeys.base, 'daiWallets', params],
  infiniteList: (params: {
    category?: string;
    limit: number;
    sort?: 'newest' | 'oldest';
    tags?: string[];
  }) => [...daiKeys.base, 'infiniteList', params],
  list: (params: {
    category?: string;
    limit: number;
    skip: number;
    sort?: 'newest' | 'oldest';
    tags?: string[];
  }) => [...daiKeys.base, 'list', params],
  listByUserId: (params?: { userId: string }) => [...daiKeys.base, 'list-by-user-id', params],
  requestChart: (params: { walletAddresses: string[] }) => [...daiKeys.base, 'requestChart', params],
  sessionOverview: (params: { walletAddresses: string[] }) => [...daiKeys.base, 'sessionOverview', params],
  stakeAmountChart: (params: { daiId: string }) => [...daiKeys.base, 'stakeAmountChart', params],
  stakeSummary: (params: { daiId?: string } = {}) => [...daiKeys.base, 'stake-summary', params],
  summary: (params: { ids: string[] }) => [...daiKeys.base, 'summary', params],
  userDAIs: (params: { limit: number; skip: number; userId: string }) => [
    ...daiKeys.base,
    'user-dais',
    params,
  ],
  userWallets: (params: { daiId?: string; userId: string }) => [...daiKeys.base, 'userWallets', params],
};

import { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { useQueryClient } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import { WalletType, useAccount, useSuggestChainAndConnect } from 'graz';
import { Box, Search, X } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

import type { ConnectedUserWallet, DAI } from 'shared/api/dai/types';

import { useUser } from 'app/stores/user';
import { checkInstalledWallet } from 'features/WalletProvider/WalletProvider';
import { daiKeys } from 'shared/api/dai/queryKeys';
import { useDAIVerifyWalletMutation } from 'shared/api/dai/useDAIVerifyWalletMutation';
import { useDeleteVerifiedWalletMutation } from 'shared/api/dai/useDeleteVerifiedWalletMutation';
import { useGetDAIConnectedWalletsQuery } from 'shared/api/dai/useGetDAIConnectedWalletsQuery';
import { useGetUserConnectedWalletsQuery } from 'shared/api/dai/useGetUserConnectedWalletsQuery';
import { useGetWalletsSessionsOverviewQuery } from 'shared/api/dai/useGetWalletsSessionsOverviewQuery';
import { nesaTestnet } from 'shared/config/networks/nesaTestnet';
import { catchError } from 'shared/helpers/parseAxiosError';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { Spinner } from 'shared/ui/Spinner';
import { toaster } from 'shared/ui/Toast';
import { Tooltip } from 'shared/ui/Tooltip';

import type { FormValues } from '../types';

import { AddModelModal } from './AddModelModal';
import { DetectedModels } from './DetectedModels';
import { StepHeader } from './StepHeader';

type Props = {
  daiToEdit?: DAI;
};
export const ModelUsage = ({ daiToEdit }: Props) => {
  const queryClient = useQueryClient();

  const [isAddModelOpen, setIsAddModelOpen] = useState(false);

  const { control } = useFormContext<FormValues>();
  const { user } = useUser();
  const { data: account, walletType } = useAccount();
  const { suggestAndConnectAsync } = useSuggestChainAndConnect();
  const { isPending: isVeryfying, mutateAsync: verifyWallet } = useDAIVerifyWalletMutation();
  const { isPending: isDeleting, mutateAsync: deleteWallet } = useDeleteVerifiedWalletMutation();

  const walletsQuery = useGetUserConnectedWalletsQuery({ userId: user?._id || '' }, { enabled: !!user?._id });
  const daiWalletsQuery = useGetDAIConnectedWalletsQuery(
    { daiId: daiToEdit?._id || '' },
    { enabled: !!daiToEdit?._id },
  );

  const connectedWallets = [...(daiWalletsQuery.data?.list || []), ...(walletsQuery.data?.list || [])];

  const connectedAddresses = connectedWallets.map(({ walletAddress }) => walletAddress);

  const walletsSessionOverview = useGetWalletsSessionsOverviewQuery(
    { walletAddresses: connectedAddresses },
    { enabled: connectedAddresses.length > 0 },
  );

  const { field: manuallyConnectedModelsField } = useController({ control, name: 'manuallyConnectedModels' });

  const handleConnectWallet = async () => {
    const isInstalled = checkInstalledWallet(WalletType.KEPLR);

    if (!isInstalled) {
      toaster.error(`You don't have Keplr wallet installed or enabled`);
      return;
    }

    await suggestAndConnectAsync({
      chainInfo: nesaTestnet,
      walletType: WalletType.KEPLR,
    });
  };

  const handleSignMessage = async () => {
    try {
      if (!account?.bech32Address || walletType !== WalletType.KEPLR) {
        toaster.error('You should connect Kelplr wallet');

        return;
      }

      const signed = await window.keplr?.signArbitrary(nesaTestnet.chainId, account?.bech32Address, 'test');

      if (!signed || !signed.pub_key) {
        toaster.error('Something went wrong...');

        return;
      }
      console.log('signed', signed);

      const result = await verifyWallet({
        address: account.bech32Address,
        pub_key: signed.pub_key,
        signature: signed?.signature || '',
      });

      walletsQuery.refetch({});
      walletsSessionOverview.refetch();

      console.log({ result });

      return signed;
    } catch (e) {
      catchError(e);
    }
  };

  const handleDeleteWallet = async (wallet: ConnectedUserWallet) => {
    try {
      await deleteWallet({ walletId: wallet._id });

      toaster.success('Your wallet address has been removed');

      if (!user?._id) return;

      const queryKey = daiKeys.userWallets({ userId: user?._id });

      const currentData = queryClient.getQueryData<{ list: ConnectedUserWallet[] }>(queryKey);

      queryClient.setQueryData(queryKey, { list: currentData?.list.filter((w) => w._id !== wallet._id) });
      // daiWalletsQuery

      const daiWalletsQueryKey = daiKeys.daiWallets({ daiId: daiToEdit?._id || '' });

      const currentDaiWalletsData = queryClient.getQueryData<{ list: ConnectedUserWallet[] }>(
        daiWalletsQueryKey,
      );
      queryClient.setQueryData(daiWalletsQueryKey, {
        list: currentDaiWalletsData?.list.filter((w) => w._id !== wallet._id),
      });
    } catch (e) {
      catchError(e);
    }
  };

  const isCurrentAddressSigned = walletsQuery.data?.list.some(({ walletAddress }) =>
    account?.bech32Address ? walletAddress === account?.bech32Address : false,
  );

  return (
    <div className="mx-auto flex max-h-fit w-full max-w-3xl grow flex-col gap-4">
      <div className="flex max-h-fit w-full flex-1 flex-col overflow-hidden rounded-2xl border border-clay-20  bg-white shadow-lg ">
        <StepHeader
          description="Connect the wallets you use for inference on Nesa Chain, so visitors can track your DAI’s model usage"
          stepNumber={4}
          title="Link Model Usage"
        />

        <div className="mb-4 flex flex-col gap-3 overflow-hidden p-4">
          <Input
            classNameInputWrapper="!h-14 pl-3 pr-2 rounded-xl bg-clay-10 border-none"
            disabled
            endSlot={
              account && walletType === WalletType.KEPLR ? (
                <Tooltip
                  content={isCurrentAddressSigned ? `You've already verified this address` : ''}
                  side="top"
                >
                  <Button
                    className="!pointer-events-auto"
                    disabled={isCurrentAddressSigned}
                    isLoading={isVeryfying}
                    onClick={handleSignMessage}
                  >
                    Sign message
                  </Button>
                </Tooltip>
              ) : (
                <Button className="!pointer-events-auto" onClick={handleConnectWallet}>
                  Connect wallet
                </Button>
              )
            }
            startSlot={<Search className="size-4 text-clay-350" />}
            value={account?.bech32Address || ''}
          />

          <div className="flex flex-col gap-3 overflow-scroll pt-2">
            <div className="text-start text-xs text-clay-380">Wallets</div>
            {walletsQuery.isPending && (
              <div className="flex items-center justify-center py-6">
                <Spinner className="size-5" />
              </div>
            )}

            {walletsQuery.isSuccess && connectedWallets.length === 0 && (
              <div className="flex items-center justify-center py-6 text-sm text-clay-500">
                {`You don't have any connected wallets`}
              </div>
            )}

            {walletsQuery.isSuccess &&
              connectedWallets.map((wallet) => {
                return (
                  <div className="flex items-center gap-2 text-sm text-clay-600" key={wallet.walletAddress}>
                    <Box className="size-4 text-clay-350" /> {wallet.walletAddress}{' '}
                    <AnimatePresence>
                      {walletsSessionOverview.data?.data[wallet.walletAddress] !== undefined && (
                        <motion.div
                          animate={{ opacity: 1 }}
                          className="select-none rounded-md bg-clay-20 px-2 py-0.5 text-xs font-semibold uppercase text-clay-600"
                          exit={{ opacity: 0 }}
                          initial={{ opacity: 0 }}
                        >
                          initiated {walletsSessionOverview.data?.data[wallet.walletAddress]} sessions
                        </motion.div>
                      )}
                    </AnimatePresence>
                    <X
                      className={twMerge(
                        'ml-auto size-4 cursor-pointer text-clay-350 hover:text-red-900',
                        isDeleting && 'pointer-events-none opacity-50',
                      )}
                      onClick={() => handleDeleteWallet(wallet)}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      {walletsQuery.isSuccess && (
        <div className="max-h-fit w-full flex-1 rounded-2xl bg-white p-6 pt-4 shadow-lg">
          <DetectedModels connectedAddresses={connectedAddresses} />
        </div>
      )}

      <AddModelModal
        isOpen={isAddModelOpen}
        onAddModels={(models) => {
          manuallyConnectedModelsField.onChange(models);
        }}
        onOpenChange={setIsAddModelOpen}
      />
    </div>
  );
};

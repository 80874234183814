import { useState } from 'react';
import { Link } from 'react-router-dom';

import { SearchIcon } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

import type { ProjectCategory } from 'pages/CreateDAI/types';

import { useNavigateToTop } from 'shared/hooks/navigate';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';

import { CreateDaiBanner } from './ui/CreateDaiBanner';
import { DoxxedTeamList } from './ui/DoxxedTeamList';
import { ExploreDAI } from './ui/ExploreDAI';
import { HottestDAIs } from './ui/HottestDAIs';
import { TrendingDAIs } from './ui/TrendingDAIs';
import { LC_SHOWED_WELCOME_MODAL, WelcomeDAIModal } from './ui/WelcomeDAIModal';

export const categories: { label: string; value: ProjectCategory }[] = [
  { label: 'DeSci', value: 'desci' },
  { label: 'DeFi', value: 'defi' },
  { label: 'Entertainment', value: 'entertainment' },
  { label: 'Gaming', value: 'gaming' },
  { label: 'Infrastructure', value: 'infrastructure' },
  { label: 'Productivity', value: 'productivity' },
  { label: 'Social', value: 'social' },
];

export const DAIOverview = () => {
  const navigate = useNavigateToTop();

  const [showWelcomeModal, setShowWelcomeModal] = useState(() => {
    const showed = localStorage.getItem(LC_SHOWED_WELCOME_MODAL);
    return !showed;
  });

  return (
    <AnimateRoute className="rounded-xl bg-clay-20 p-2">
      <div className="rounded-xl bg-white">
        <div className="grid grid-cols-1 lg:grid-cols-4">
          {/* main column */}
          <div className="col-span-1 border-r border-clay-20 px-3 py-4 md:col-span-3 md:px-6 md:py-8">
            <div className="mb-8">
              <div className="mb-8 flex flex-col justify-between gap-3 md:flex-row md:items-center">
                <div className="flex flex-col gap-1">
                  <h1 className="text-3xl font-black md:text-4xl">Explore Nesa</h1>
                  <div className="text-base font-light text-clay-500 md:text-lg">
                    Explore the <span className="font-semibold">decentralized AI</span> projects and protocols
                    on Nesa Chain.
                  </div>
                </div>

                <div className="flex gap-2">
                  <Button asIcon color="secondary" onClick={() => navigate('/dai/explore')}>
                    <SearchIcon className="size-4 text-clay-300" strokeWidth={1.5} />
                  </Button>
                  <Button className="pl-3" color="dark" onClick={() => navigate('/dai/create')}>
                    <Icon className="size-5 text-white" name="customDai" />
                    Create a DAI <sup className="-ml-0.5 text-[6.5px] font-light">TM</sup>
                  </Button>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
                <CreateDaiBanner />

                <DoxxedTeamList />
              </div>
            </div>

            <div className="mb-10">
              <HottestDAIs />
            </div>

            <ExploreDAI />
          </div>

          {/* right section */}
          <div className="flex flex-col gap-6 p-3 md:p-6">
            <TrendingDAIs />
            <div className="flex flex-col gap-3">
              <div className="text-lg font-medium">DAI Categories</div>

              <div className="flex flex-col gap-1.5">
                {categories.map((category) => {
                  return (
                    <Link
                      className={twMerge(
                        'cursor-pointer rounded-lg px-3 py-2 text-sm font-medium text-clay-500 transition-colors hover:bg-clay-10',
                      )}
                      key={category.value}
                      to={`/dai/explore?category=${category.value}`}
                    >
                      {category.label}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <WelcomeDAIModal onOpenChange={setShowWelcomeModal} open={showWelcomeModal} />
    </AnimateRoute>
  );
};

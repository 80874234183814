import agentImg1 from './assets/1.png';
import agentImg2 from './assets/2.png';
import agentImg3 from './assets/3.png';
import agentImg4 from './assets/4.png';
import agentImg5 from './assets/5.png';
import agentImg6 from './assets/6.png';
import agentImg7 from './assets/7.png';
import agentImg8 from './assets/8.png';
import agentImg9 from './assets/9.png';
import agentImg10 from './assets/10.png';
import agentImg11 from './assets/11.png';
import agentImg12 from './assets/12.png';

export const topGrayCards = [
  {
    button: 'Private keys hidden',
    description: 'Nesa EE gives AI agents total blanket privacy',
    title: 'Nesa EE',
  },
  {
    button: 'Suitablility fingerprinting',
    description: 'Orchestrator identifies the task-agents to initialize',
    title: 'Nesa Orchestrator',
  },
];

export const mainCards = [
  {
    bg: '#A783F4',
    buttons: ['PriceStar', 'DAODecisions', 'AuditGPT', 'WebSmith'],
    description: 'Goal based\nAgents',
    title: 'Most Used',
  },
  {
    bg: '#179E6C',
    buttons: ['GamePixar', 'Librarian', 'Einstein', 'WikiWEB3'],
    description: 'Learning\nbased Agents',
    title: 'Favorites',
  },
  {
    bg: '#5496FF',
    buttons: ['TeamX', 'CodeShield', 'IoTSwarm', 'Surveillar'],
    description: 'Multi-agent\nSystems',
    title: 'New Noteworthy',
  },
];

export const agentsCards = [
  {
    description: 'Empower your DAO with intelligent governance solutions to streamline voting...',
    img: agentImg1,
    rating: 4.9,
    reviewCount: 124,
    title: 'DAODecisions',
    type: 'GOAL BASED',
  },
  {
    description: 'Harness unparalleled analytical prowess with Einstein, your AI-driven strategist for...',
    img: agentImg2,
    rating: 4.8,
    reviewCount: 68,
    title: 'Einstein',
    type: 'LEARNING BASED',
  },
  {
    description: 'Craft the future of decentralized web with WebSmith’s AI-enhanced development tools...',
    img: agentImg3,
    rating: 4.6,
    reviewCount: 82,
    title: 'WebSmith',
    type: 'GOAL BASED',
  },
  {
    description: 'Ensure the integrity of your blockchain with Surveillar’s advanced monitoring system...',
    img: agentImg5,
    rating: 4.8,
    reviewCount: 132,
    title: 'Surveillar',
    type: 'MULTI-AGENT',
  },
  {
    description: 'Revolutionize gaming with GamePixar’s AI blockchain integration for immersive, secure...',
    img: agentImg4,
    rating: 4.6,
    reviewCount: 193,
    title: 'GamePixar',
    type: 'LEARNING BASED',
  },
  {
    description: 'Boost your crypto project’s productivity with TeamX’s AI-driven teamwork collaboration... ',
    img: agentImg6,
    rating: 4.4,
    reviewCount: 104,
    title: 'TeamX',
    type: 'MULTI-AGENT',
  },
  {
    description: 'Stay ahead of the market with Price Agent AI-based cryptocurrency price predictions...',
    img: agentImg7,
    rating: 4.7,
    reviewCount: 376,
    title: 'Price Agent',
    type: 'GOAL BASED',
  },
  {
    description: 'Connect and secure your IoT devices with IoTSwarm’s blockchain-based interoperable...',
    img: agentImg8,
    rating: 4.4,
    reviewCount: 21,
    title: 'IoTSwarm',
    type: 'MULTI-AGENT',
  },
  {
    description:
      'Manage your decentralized data effortlessly with Librarian’s search retrieval system for... ',
    img: agentImg9,
    rating: 4.2,
    reviewCount: 52,
    title: 'Librarian',
    type: 'LEARNING BASED',
  },
  {
    description: 'Protect your smart contracts with CodeShield’s security auditing, vulnerability...',
    img: agentImg10,
    rating: 3.8,
    reviewCount: 231,
    title: 'CodeShield',
    type: 'MULTI-AGENT',
  },
  {
    description: 'Build a comprehensive decentralized knowledge base with WikiWEB3’s curation...',
    img: agentImg11,
    rating: 4.1,
    reviewCount: 212,
    title: 'WikiWEB3',
    type: 'LEARNING BASED',
  },
  {
    description: 'Streamline your blockchain audits with AuditGPT’s intelligent assessment tools for...',
    img: agentImg12,
    rating: 4.2,
    reviewCount: 74,
    title: 'AuditGPT',
    type: 'GOAL BASED',
  },
];

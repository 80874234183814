import { getSizeCategories } from 'shared/api/datasets/useGetDatasetsQuery';
import { useGetMatchingDatasetsTags } from 'shared/api/datasets/useGetMatchingDatasetsTags';
import { Spinner } from 'shared/ui/Spinner';

import type { FiltersProps } from './MainFilters';

import { Chip } from './Chip';

type Props = {
  filters: FiltersProps['filters'];
  onChange: FiltersProps['onChange'];
};

export const LibraryFilters = ({ filters, onChange }: Props) => {
  const { data: availableLibraries, isPending } = useGetMatchingDatasetsTags({
    format:
      filters.format && filters.format?.length > 0
        ? filters.format.map((val) => `format:${val}`).join(',')
        : undefined,
    language: filters.language?.join(','),
    license: filters.license?.join(','),
    modality:
      filters.modality && filters.modality?.length > 0
        ? filters.modality.map((val) => `modality:${val}`).join(',')
        : undefined,
    other: filters.other?.join(','),
    size_categories: filters.size?.[0] && getSizeCategories(filters.size[0]),

    sort: filters.sort,
    task_categories: filters.tasks?.join(',') || undefined,
    type: 'library',
  });

  const { data: defaultData, isPending: isDefaultPending } = useGetMatchingDatasetsTags(
    { sort: filters.sort, type: 'library' },
    { staleTime: Infinity },
  );

  const remainingLibraries = defaultData?.matchingTags.filter(
    (value) => !availableLibraries?.matchingTags.includes(value),
  );

  return (
    <div>
      {isPending || isDefaultPending ? (
        <div className="flex flex-col items-center justify-center py-10">
          <Spinner className="size-4" />
        </div>
      ) : (
        <div className="flex flex-wrap items-center gap-2">
          {availableLibraries?.matchingTags.map((value) => (
            <Chip
              isActive={filters.library?.includes(value)}
              key={value}
              onClick={() => {
                console.log('filters.library', filters.library);
                onChange({
                  library: filters.library?.includes(value)
                    ? filters.library?.filter((val) => val !== value)
                    : [...(filters.library || []), value],
                });
              }}
            >
              {value.replace('library:', '')}
            </Chip>
          ))}
          {remainingLibraries?.map((value) => (
            <Chip isActive={filters.library?.includes(value)} isDisabled key={value}>
              {value.replace('library:', '')}
            </Chip>
          ))}
        </div>
      )}
    </div>
  );
};

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { queryClient } from 'app/App';
import { trainingQueryKeys, useCreateTrainingMutation } from 'shared/api/training';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { Modal } from 'shared/ui/Modal';
import { toaster } from 'shared/ui/Toast';

type Props = {
  modelId: string;
  onClose: () => void;
  open: boolean;
  type: 'code' | 'lora' | 'no-code';
};

type FormValues = {
  projectName: string;
};

export const CreateTrainingModal = ({ modelId, onClose, open, type }: Props) => {
  const navigate = useNavigate();

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    defaultValues: { projectName: '' },
    mode: 'onChange',
  });

  const { isPending, mutateAsync } = useCreateTrainingMutation();

  const onSend = async (values: FormValues) => {
    try {
      const training = await mutateAsync({ dataSource: 'local', modelId, name: values.projectName, type });
      navigate(`/training/${type}/${training._id}`);

      queryClient.invalidateQueries({ queryKey: trainingQueryKeys.byUser() });
    } catch {
      toaster.error('Something went wrong');
    }
  };

  return (
    <Modal onOpenChange={onClose} open={open}>
      <Modal.Content className="!max-w-xl">
        <Modal.CloseButton />

        <h2 className="mb-5 text-2xl font-semibold text-clay-900">Create a Training Project</h2>

        <Input
          classNameInputWrapper="h-11"
          classNameLabel="text-sm mb-2"
          error={errors.projectName?.message}
          label="Project Name"
          {...register('projectName', {
            required: true,
            validate: (val) => !!val.trim(),
          })}
          placeholder="Project Name"
        />

        <footer className="mt-5 flex justify-end gap-6">
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={!isValid} isLoading={isPending} onClick={handleSubmit(onSend)}>
            Create
          </Button>
        </footer>
      </Modal.Content>
    </Modal>
  );
};

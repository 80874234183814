import { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { motion } from 'framer-motion';
import { Box, CircleCheck, TriangleAlert, X } from 'lucide-react';

import type { Model } from 'shared/api/models/types';

import { useGetConnectedModelsQuery } from 'shared/api/dai/useGetConnectedModelsQuery';
import { cleanObject } from 'shared/helpers/cleanObject';
import { Button } from 'shared/ui/Button';
import { Spinner } from 'shared/ui/Spinner';
import { toaster } from 'shared/ui/Toast';
import { Tooltip } from 'shared/ui/Tooltip';

import type { FormValues } from '../types';

import { AddModelModal } from './AddModelModal';
import { ModelDescriptionModal } from './ModelDescriptionModal';

type Props = {
  connectedAddresses: string[];
};
export const DetectedModels = ({ connectedAddresses }: Props) => {
  const [isAddModelOpen, setIsAddModelOpen] = useState(false);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);

  const [modelToChange, setModelToChange] = useState<Model>();

  const { control } = useFormContext<FormValues>();

  const activeConnectedModels = useGetConnectedModelsQuery(
    { walletAddresses: connectedAddresses },
    { enabled: connectedAddresses.length > 0 },
  );

  const { field: manuallyConnectedModelsField } = useController({ control, name: 'manuallyConnectedModels' });
  const { field: modelUsageDescriptionsField } = useController({ control, name: 'modelUsageDescriptions' });

  const handleSaveModelDescription = (text: string) => {
    if (!modelToChange) {
      toaster.error('No model is selected');
      return;
    }

    modelUsageDescriptionsField.onChange({
      ...modelUsageDescriptionsField.value,
      [modelToChange._id]: text,
    });

    setIsDescriptionOpen(false);
    setModelToChange(undefined);
  };

  const hasNoModels =
    (activeConnectedModels.isSuccess &&
      (activeConnectedModels.data?.list || []).length === 0 &&
      (manuallyConnectedModelsField.value || [])?.length === 0) ||
    (connectedAddresses.length == 0 && (manuallyConnectedModelsField.value || [])?.length === 0);

  return (
    <>
      <h4 className="mb-3 text-sm font-light text-clay-380">Detected Model Usage</h4>

      <div className="flex min-w-72 flex-col gap-1">
        {activeConnectedModels.isPending && connectedAddresses.length > 0 && (
          <div className="flex items-center justify-center py-2.5">
            <Spinner className="size-3.5" />
          </div>
        )}
        {activeConnectedModels.isSuccess &&
          (activeConnectedModels.data?.list || []).map((data) => {
            return (
              <div
                className="flex items-center gap-2 overflow-hidden px-1.5 py-1 text-sm text-clay-700"
                key={data.model._id}
              >
                <Box className="size-4 min-w-4 text-clay-350" />
                <span className="truncate">{data.model.modelName}</span>

                <motion.div
                  animate={{ opacity: 1 }}
                  className="min-w-fit select-none rounded-md bg-clay-20 px-2 py-0.5 text-xs font-semibold uppercase text-clay-600"
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0 }}
                >
                  {data.sessionCount} sessions
                </motion.div>
              </div>
            );
          })}

        {hasNoModels && <div className="pt-2.5 text-center text-sm text-clay-500">No models found</div>}

        {manuallyConnectedModelsField.value?.map((model) => {
          const hasDescription = !!modelUsageDescriptionsField.value[model._id];
          return (
            <div
              className="flex cursor-pointer items-center justify-between rounded-lg px-1.5 py-1 transition-colors hover:bg-clay-10"
              key={model._id}
              onClick={() => {
                setModelToChange(model);
                setIsDescriptionOpen(true);
              }}
            >
              <div className="flex items-center gap-2 overflow-hidden text-sm text-clay-700">
                {!hasDescription ? (
                  <Tooltip content="Please add description" side="top">
                    <TriangleAlert className="size-4 text-yellow-500" />
                  </Tooltip>
                ) : (
                  <CircleCheck className="size-4 min-w-4 text-green-600" />
                )}
                <span className="truncate">{model.modelName}</span>
              </div>

              <X
                className="size-4 cursor-pointer text-clay-350 transition-colors hover:text-red-900"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  manuallyConnectedModelsField.onChange(
                    (manuallyConnectedModelsField.value || [])?.filter(({ _id }) => _id !== model._id),
                  );
                  modelUsageDescriptionsField.onChange(
                    cleanObject({
                      ...modelUsageDescriptionsField.value,
                      [model._id]: undefined,
                    }),
                  );
                }}
              />
            </div>
          );
        })}
        <Button className="mx-auto mt-4 max-w-fit font-light" onClick={() => setIsAddModelOpen(true)}>
          Add Model Manually
        </Button>
      </div>

      <AddModelModal
        isOpen={isAddModelOpen}
        onAddModels={(models) => {
          const manualModelIds = (manuallyConnectedModelsField.value || []).map((model) => model._id);
          const connectedModelIds = (activeConnectedModels.data?.list || []).map(({ model }) => model._id);
          const currentModelIds = [...manualModelIds, ...connectedModelIds];
          manuallyConnectedModelsField.onChange([
            ...(manuallyConnectedModelsField.value || []),
            ...models.filter((model) => !currentModelIds.includes(model._id)),
          ]);
        }}
        onOpenChange={setIsAddModelOpen}
      />

      {modelToChange && isDescriptionOpen && (
        <ModelDescriptionModal
          initialValue={modelUsageDescriptionsField.value[modelToChange._id]}
          isOpen={isDescriptionOpen}
          modelName={modelToChange?.modelName}
          onOpenChange={setIsDescriptionOpen}
          onSave={handleSaveModelDescription}
        />
      )}
    </>
  );
};

import type { PropsWithChildren, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

type Props = {
  isActive?: boolean;
  stepNumber: ReactNode;
} & ClassName;

export const Step = ({ children, className, isActive, stepNumber }: PropsWithChildren<Props>) => {
  return (
    <div
      className={twMerge(
        'flex cursor-pointer items-center gap-1.5 rounded-lg border border-clay-20 p-0.5 pr-3 text-sm font-normal text-clay-400 transition-colors hover:border-clay-40',
        isActive && 'border-transparent bg-green-600/10 text-green-600',
        className,
      )}
    >
      <div
        className={twMerge(
          'flex size-6 min-w-6 items-center justify-center rounded-md bg-clay-10 text-center text-xs font-semibold text-clay-900 transition-colors',
          isActive && 'bg-white',
        )}
      >
        {stepNumber}
      </div>

      {children}
    </div>
  );
};

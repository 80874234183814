import { twMerge } from 'tailwind-merge';

import type { FilterState, SortFilter } from 'shared/ui/Gallery/types';

import { useUser } from 'app/stores/user';
import { CategoryModelsDropdown } from 'features/CategoryModelsDropdown/CategoryModelsDropdown';
import { parseFiltersFromParams } from 'features/FiltersProvider/FilterProvider';
import { UpgradeToProTriggerModal } from 'features/UpgradeToProModal';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Filter } from 'shared/ui/Gallery/Filters/ui/Filter';
import { Icon } from 'shared/ui/Icon';
import { Select } from 'shared/ui/Select';

import { NesaTrust } from '../NesaTrust';

type Props = {
  filters: FilterState;
  onFilterChange: (filters: FilterState) => void;
};

export const GalleryFilters = ({ filters, onFilterChange }: Props) => {
  const { proVersion } = useUser();

  const lg = useMinWidthMediaQuery('lg');

  const onSortChange = (value: SortFilter) => {
    onFilterChange({ sort: value });
  };

  return (
    <div className="mt-5 flex h-fit items-center gap-4 px-4">
      <div className={twMerge('flex flex-1 items-center gap-4', !lg && 'flex-col items-start gap-2')}>
        <div className="flex w-fit items-center gap-8">
          <span className="text-2xl font-bold text-blue-900">Models</span>
          {!lg && !proVersion?.isPro && <UpgradeToProTriggerModal redirectPath="" />}
        </div>
        <div className="flex h-11 items-center gap-1 rounded-xl bg-blue-50 p-1">
          <CategoryModelsDropdown
            currentTypes={filters.type || []}
            onApply={(values) => {
              onFilterChange({
                featuredEnabled: false,
                globalSearch: '',
                tag: values.includes('all') ? undefined : filters.tag,
                type: values,
              });
            }}
          />

          <div
            className={twMerge(
              'flex h-9 cursor-pointer items-center gap-2 rounded-lg bg-clay-10 px-4 transition-colors hover:bg-white',
              filters.tag === 'nesas-pick' && 'bg-white',
            )}
            onClick={() => {
              onFilterChange({
                ...(filters.tag === 'nesas-pick' ? {} : { ...parseFiltersFromParams(new URLSearchParams()) }),
                globalSearch: !filters.tag ? '' : filters.globalSearch,
                tag: filters.tag === 'nesas-pick' ? undefined : 'nesas-pick',
              });
            }}
          >
            <Icon className="-mt-px size-4 text-primary-800" name="logo" />
            <span className="text-sm font-medium text-clay-600">Nesa&apos;s Pick</span>
          </div>

          <div
            className={twMerge(
              'flex h-9 cursor-pointer items-center gap-2 rounded-lg bg-clay-10 px-4 transition-colors hover:bg-white',
              filters.featuredEnabled && 'bg-white',
            )}
            onClick={() => {
              const featuredEnabledToSet = !filters.featuredEnabled;
              onFilterChange({
                featuredEnabled: featuredEnabledToSet,
                tag: featuredEnabledToSet ? undefined : filters.tag,
              });
            }}
          >
            <Icon className="-mt-px size-4 text-green-500" name="star" />
            <span className="text-sm font-medium text-clay-600">Featured</span>
          </div>
        </div>
      </div>

      {lg && !proVersion?.isPro && (
        <div className="ml-auto flex justify-end">
          <UpgradeToProTriggerModal redirectPath="" />
        </div>
      )}

      {lg && (
        <div className="right-4 box-border flex h-11 items-center gap-1 rounded-xl bg-blue-50 p-1">
          <NesaTrust
            isEnabled={filters.trustEnabled}
            onEnabledChange={(value) => onFilterChange({ trustEnabled: value })}
          />
          <Select
            className="h-9 w-fit rounded-lg bg-gray-100 p-3 text-sm inner-border-0"
            iconName="arrowDownUp"
            onValueChange={onSortChange}
            placeholder="Sort by"
            placeholderClassName="font-medium text-clay-600"
            value={filters.sort ? filters.sort : undefined}
          >
            <Select.Content className="z-10 rounded-lg p-1 shadow-sm">
              <div className="mb-2 w-48 border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-medium text-blue-800">
                Sort by
              </div>
              <Select.Item
                className="text-sm text-clay-700 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="newest"
              >
                Newest
              </Select.Item>
              <Select.Item
                className="border-b border-blue-50 text-sm text-clay-700 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="oldest"
              >
                Oldest
              </Select.Item>
              <Select.Item
                className="text-sm text-clay-700 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="name-lowest"
              >
                Name Ascending
              </Select.Item>
              <Select.Item
                className="border-b border-blue-50 text-sm text-clay-700 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="name-highest"
              >
                Name Decending
              </Select.Item>
              <Select.Item
                className="text-sm text-clay-700 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="ranking-highest"
              >
                Most Rated
              </Select.Item>
              <Select.Item
                className="text-sm text-clay-700 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="likes-highest"
              >
                Most Likes
              </Select.Item>
              <Select.Item
                className="border-b border-blue-50 text-sm text-clay-700 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="downloads-highest"
              >
                Most Downloaded
              </Select.Item>
              <Select.Item
                className="text-sm text-clay-700 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="price-highest"
              >
                Price Highest
              </Select.Item>
              <Select.Item
                className="text-sm text-clay-700 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="price-lowest"
              >
                Price Lowest
              </Select.Item>
            </Select.Content>
          </Select>

          <Filter onSortChange={onSortChange} />
        </div>
      )}
    </div>
  );
};

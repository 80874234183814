import { useInfiniteQuery } from '@tanstack/react-query';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { NesaNode, NodeListByPublicKeyResponse } from './types';

import { nodesKeys } from './queryKeys';

export const useNodesByPublicKeyInfiniteQuery = (
  { limit = 20, publicKey }: { limit: number; publicKey: string },
  options: { enabled?: boolean } = {},
) => {
  return useInfiniteQuery({
    getNextPageParam: (last: NesaNode[] = [], all: NesaNode[][]) =>
      last.length === limit ? all.length : null,
    initialPageParam: 0,
    queryFn: async ({ pageParam = 0, signal }) => {
      const res = await axiosInstance.get<NodeListByPublicKeyResponse>(`/nodes/${publicKey}/list`, {
        params: { limit, skip: pageParam * limit },
        signal,
      });

      return res.data.list;
      // const res = await axiosInstance.get<NodeListByPublicKeyResponse>(`/nodes/${publicKey}/list`, {
      //   params: { limit, skip: pageParam * limit },
      //   signal,
      // });

      // return [
      //   {
      //     _id: 'string;',

      //     ip: 'string;',
      //     ip_history: ['string'],

      //     moniker: 'string;',

      //     node_id: 'string;',
      //     public_key: 'string;',

      //     specs: {
      //       cpu: ['string'],
      //       gpu: ['string'],
      //       ram: 'string;',
      //       vram: ['string'],
      //     },
      //     status: 1,
      //   },
      // ];
    },
    queryKey: nodesKeys.nodesByPublicKey({ limit, publicKey }),
    ...(options || {}),
  });
};

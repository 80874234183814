import { useNavigate } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { getTvl } from 'pages/DAI/helpers/getTvl';
import { useDAIListQuery } from 'shared/api/dai/useDAIListQuery';
import { HotDaiCard } from 'shared/ui/HotDaiCard/HotDaiCard';
import { Icon } from 'shared/ui/Icon';

type Props = ClassName;

export const TrendingDAIs = ({ className }: Props) => {
  const navigate = useNavigate();

  const { data: daiList, isPending: isListLoading } = useDAIListQuery({ limit: 5, skip: 0, sort: 'newest' });

  return (
    <div className={twMerge('flex flex-col gap-4', className)}>
      <header className="flex items-center gap-3">
        <div className="flex size-8 items-center justify-center rounded-lg bg-yellow-500/10">
          <Icon className="size-4 stroke-yellow-500 text-transparent" name="flame" />
        </div>

        <div className="text-2xl font-medium tracking-tighter">Trending DAI</div>
      </header>

      <div className="flex flex-col gap-3">
        {isListLoading
          ? Array.from({ length: 4 })
              .fill('')
              .map((_, idx) => {
                return <HotDaiCard isLoading key={idx} />;
              })
          : daiList?.list.map((dai) => {
              const founder = dai.members[0];

              return (
                <HotDaiCard
                  className="border-transparent bg-clay-10"
                  coinImageSrc={dai.token?.image}
                  coinName={dai.token?.ticker}
                  description={dai.shortDescription}
                  key={dai._id}
                  mediaSrc={dai.profileImage}
                  onClick={() => navigate(`/dai/${dai._id}`)}
                  showMediaNearTitle
                  title={dai.projectName}
                  tvl={getTvl(dai.tvl)}
                  userImageSrc={founder?.avatar}
                  userName={founder?.name || ''}
                />
              );
            })}
      </div>
    </div>
  );
};

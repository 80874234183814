import type { HTMLAttributeAnchorTarget } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { LayoutGrid } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';
import type { IconName } from 'shared/ui/Icon';

import { Icon } from 'shared/ui/Icon';
import { toaster } from 'shared/ui/Toast';

const links: {
  icon: IconName;
  iconClassName?: string;
  label: string;
  target?: HTMLAttributeAnchorTarget;
  url?: string;
}[] = [
  {
    icon: 'bolt',
    iconClassName: 'stroke-clay-400 text-transparent',
    label: 'Getting Started',
    target: '_blank',
    url: 'https://docs.nesa.ai/nesa/using-nesa/getting-started',
  },
  { icon: 'book', iconClassName: '', label: 'F.A.Q.', target: '_blank', url: 'https://docs.nesa.ai' },
];

type Props = {
  onMenuClick: (menu: 'my-staked-assets') => void;
} & ClassName;

export const LeftSidebar = ({ className, onMenuClick }: Props) => {
  const navigate = useNavigate();
  return (
    <div className={twMerge('flex flex-col', className)}>
      <Icon className="mb-6 size-12 sm:mb-8 sm:size-14" name="logo" />
      <div className="mb-2 text-3xl font-semibold tracking-tight">Nesa Staking</div>
      <div className="mb-6 font-light text-clay-400">
        {' '}
        Start staking your assets on Nesa and get daily rewards.
      </div>

      <div className="mb-8 flex flex-col gap-3">
        <div
          className="flex cursor-pointer items-center gap-2 text-sm font-normal text-clay-800 transition hover:text-primary-800"
          onClick={() => onMenuClick('my-staked-assets')}
        >
          <LayoutGrid className="size-4 text-clay-380" /> My Staked Assets
        </div>
        <div
          className="flex cursor-pointer items-center gap-1 text-sm font-normal text-clay-800 transition hover:text-primary-800"
          onClick={() => navigate('/privilege/faucet')}
        >
          <Icon className="size-4 text-clay-380" name="logo" /> Get $NES from the Faucet
        </div>
      </div>

      <div className="mt-auto">
        <div className="mb-3 text-xs font-normal text-clay-500">Guides & Tips</div>

        <div className="flex flex-col gap-3">
          {links.map(({ icon, iconClassName, label, target, url }) => {
            return (
              <Link
                className="group flex items-center gap-1.5"
                key={label}
                onClick={() => {
                  if (!url) toaster.info('Coming soon');
                }}
                target={target}
                to={url || ''}
              >
                <Icon className={twMerge('size-3.5 text-clay-400', iconClassName)} name={icon} />
                <span className="text-sm font-normal text-clay-800 transition-colors group-hover:text-primary-800">
                  {label}
                </span>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

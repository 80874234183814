import type { GetModelFilesParams } from './types';

export const modelsKeys = {
  base: ['models'],
  beingUsed: () => [...modelsKeys.base, 'being-used-rn'],
  featured: () => [...modelsKeys.base, 'featured'],
  ids: (params: { ids: string[] }) => [...modelsKeys.base, 'ids', params],
  limits: (params: { modelId: string }) => [...modelsKeys.base, 'limits', params],
  list: (params: Record<string, boolean | null | number | string | string[]>) => [
    ...modelsKeys.base,
    'types',
    params,
  ],
  modelFiles: (params: GetModelFilesParams) => [...modelsKeys.base, 'files', params],
  modelsByOrg: (params: { limit: number; org: string }) => [...modelsKeys.base, 'org', params],
  names: (params: { names: string[] }) => [...modelsKeys.base, 'names', params],
  previewList: (params: Record<string, null | number | string>) => [
    ...modelsKeys.base,
    'types',
    'preview',
    params,
  ],
  random: () => [...modelsKeys.base, 'random-pick'],
  status: (id: string) => [...modelsKeys.base, id, 'status'],
  types: () => [...modelsKeys.base, 'types'],
};

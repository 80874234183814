import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { DAI } from './types';

import { daiKeys } from './queryKeys';

type Response = {
  data: DAI;
};

type Params = {
  id: string;
};

export const useDAIByIdQuery = (params: Params, options: UseQueryOptions<Response> = {}) => {
  return useQuery({
    queryFn: async () => {
      const { data } = await axiosInstance.get<Response>(`/dai/${params.id}`, {
        params: params,
      });

      return data;
    },
    queryKey: daiKeys.byId(params),
    ...options,
  });
};

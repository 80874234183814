import { useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { getFormattedNumber } from 'pages/Datasets/helpers/getFormattedNumber';
import { useGetDAISummaryQuery } from 'shared/api/dai/useGetDAISummaryQuery';
import { useGetRequestsChartQuery } from 'shared/api/dai/useGetRequestsChartQuery';
import { Tabs } from 'shared/ui/Tabs';

import { ChainChart } from './ChainChart';
import { StakeChart } from './StakeChart';

type Props = { daiId: string; walletAddresses: string[] } & ClassName;

export const DAIChart = ({ className, daiId, walletAddresses }: Props) => {
  const [viewType, setViewType] = useState<'chain' | 'stake'>('chain');

  const { data: requestsChartData, isSuccess } = useGetRequestsChartQuery(
    { walletAddresses },
    { enabled: walletAddresses.length > 0 },
  );

  const { data: daiOverview } = useGetDAISummaryQuery(
    { daiId, walletAddresses },
    { enabled: walletAddresses.length > 0 && !!daiId },
  );

  const hasRequests = isSuccess && requestsChartData.list.some((item) => item.requests > 0);

  return (
    <div className={twMerge('relative col-span-4 h-48 py-0', className)}>
      <Tabs.Root
        className="relative mb-4 w-full min-w-fit"
        onValueChange={setViewType}
        tabs={
          <Tabs.List className="h-fit flex-row overflow-hidden rounded-full bg-clay-20">
            <Tabs.Trigger className="whitespace-nowrap" value="chain">
              Chain View
            </Tabs.Trigger>
            <Tabs.Trigger value="stake">Stake View</Tabs.Trigger>
          </Tabs.List>
        }
        value={viewType}
        variant="rounded-white"
      />
      {daiOverview && daiOverview?.allTimeTxCount > 0 && (
        <div className="absolute bottom-6 right-0 z-10 flex translate-y-full flex-col gap-2">
          <div className="mx-auto text-[10px] font-medium uppercase text-clay-350 md:text-sm">
            AI Transactions
          </div>
          <div className="flex rounded-lg border border-clay-20 bg-white py-1.5">
            <div className="flex flex-1 flex-col items-center justify-center border-r border-clay-20 px-3">
              <div className="text-lg font-medium md:text-xl">
                {getFormattedNumber(daiOverview.allTimeTxCount)}
              </div>
              <div className="text-center text-xs font-light text-clay-350">Lifetime</div>
            </div>
            <div className="flex min-w-fit flex-1 flex-col items-center justify-center px-3">
              <div className="text-lg font-medium md:text-xl">
                {getFormattedNumber(daiOverview.yesterdayTxCount)}
              </div>
              <div className="text-center text-xs font-light text-clay-350">Past Day</div>
            </div>
          </div>
        </div>
      )}

      <AnimatePresence>
        {viewType === 'chain' && (
          <motion.div
            animate={{ opacity: 1 }}
            className="absolute inset-x-0 size-full"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            <ChainChart isLaunched={hasRequests} walletAddresses={walletAddresses} />
          </motion.div>
        )}
        {viewType === 'stake' && (
          <motion.div
            animate={{ opacity: 1 }}
            className="absolute -inset-x-10 h-full w-[calc(100%+4rem)]"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            <StakeChart daiId={daiId} />
          </motion.div>
        )}
      </AnimatePresence>

      {viewType === 'chain' && isSuccess && !hasRequests && (
        <div className="absolute  -inset-x-10 flex h-full items-center justify-center bg-white/20 backdrop-blur-[2px]">
          <div className="flex flex-col items-center justify-center gap-1 rounded-xl bg-clay-10 px-5 py-4 text-center">
            <h1 className="text-center text-lg/none font-medium">This DAI has not launched yet</h1>
          </div>
        </div>
      )}
    </div>
  );
};

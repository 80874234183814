export const getFormattedNumber = (balance: number) => {
  if (balance >= 1000000000) {
    return `${(balance / 1000000000).toLocaleString('en', { maximumFractionDigits: 1, minimumFractionDigits: 0 })}B`;
  }
  if (balance >= 1000000) {
    return `${(balance / 1000000).toLocaleString('en', { maximumFractionDigits: 1, minimumFractionDigits: 0 })}M`;
  }
  if (balance >= 1000) {
    return `${(balance / 1000).toLocaleString('en', { maximumFractionDigits: 1, minimumFractionDigits: 0 })}k`;
  }

  return balance.toLocaleString('en');
};

import { useEffect, useState } from 'react';

import { motion } from 'framer-motion';
import { ArrowLeftIcon, ArrowRightIcon } from 'lucide-react';
import { twJoin, twMerge } from 'tailwind-merge';

import logoSrc from 'app/assets/images/logo.svg';
import { TERMS_LINK } from 'shared/const/links';
import { Button } from 'shared/ui/Button';
import { Checkbox } from 'shared/ui/Checkbox';
import { Modal } from 'shared/ui/Modal';

import imgSlide1 from './assets/1.png';
import imgSlide2 from './assets/2.png';
import imgSlide3 from './assets/3.png';

const slides = [
  {
    bg: '#DBC9FF',
    img: imgSlide1,
    imgClassName: 'top-2.5 left-0 h-full',
    title: "Stake to get airdropped both $NES and that DAI's token.",
  },
  {
    bg: '#FFDDCF',
    img: imgSlide2,
    imgClassName: 'top-0 left-0 h-full',
    title: 'Nesa subsidizes the inference costs for each DAI that gets stakes.',
  },
  {
    bg: '#F1FFDE',
    img: imgSlide3,
    imgClassName: 'top-0 left-0 h-full',
    title: 'DAI are the building blocks of web3 AI. Discover the next big thing.',
  },
];
export const LC_SHOWED_WELCOME_MODAL = 'dai-showed-welcome-modal';

type Props = {
  onOpenChange: (open: boolean) => void;
  open: boolean;
};

export const WelcomeDAIModal = ({ onOpenChange, open }: Props) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const [checked, setChecked] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentSlide((prev) => {
        const count = prev + 1;
        if (count >= slides.length) {
          return 0;
        }
        return count;
      });
    }, 7000);
    return () => {
      clearTimeout(timeout);
    };
  }, [currentSlide]);

  return (
    <Modal onOpenChange={() => null} open={open}>
      <Modal.Content className="w-full !max-w-xl" innerClassName="sm:p-6">
        <div className="mb-5">
          <img alt="" className="w-14" src={logoSrc} />
        </div>
        <h2 className="mb-2 mt-6 text-3xl font-semibold text-clay-900 md:text-4xl">
          Welcome to the world of DAI
        </h2>
        <p className="text-lg text-clay-500">
          DAI are decentralized AI projects and protocols built on Nesa Chain, powered by Nesa models,
          datasets and agents.
        </p>
        <div className="my-6 overflow-hidden rounded-lg">
          <div className="relative">
            <motion.div
              animate={{ translateX: `-${currentSlide * 100}%` }}
              className={twMerge('relative flex h-56')}
            >
              {slides.map((el, i) => (
                <div className="relative min-w-full" key={i} style={{ background: el.bg }}>
                  <img alt="" className={twMerge('absolute', el.imgClassName)} src={el.img} />
                  <div className="absolute right-5 top-1/2 w-60 -translate-y-1/2 text-balance text-2xl font-semibold text-clay-900">
                    {el.title}
                  </div>
                </div>
              ))}
            </motion.div>
            <div className="absolute bottom-4 left-4 flex h-[22px] w-[48px] justify-between rounded-full bg-[#000]/20">
              <Button
                asIcon
                className="size-[22px] rounded-full disabled:!bg-transparent"
                disabled={currentSlide === 0}
                onClick={() => setCurrentSlide(currentSlide - 1)}
              >
                <ArrowLeftIcon className="size-[14px] text-white" />
              </Button>
              <Button
                asIcon
                className="size-[22px] rounded-full disabled:!bg-transparent"
                disabled={currentSlide === slides.length - 1}
                onClick={() => setCurrentSlide(currentSlide + 1)}
              >
                <ArrowRightIcon className="size-[14px] text-white" />
              </Button>
            </div>
          </div>
        </div>
        <p className="text-sm italic text-clay-400">
          Nesa does not endorse any DAI listed on the platform, does not control any DAI token rewards or
          airdrops, and does not vet any DAI claims or information.
        </p>

        <Checkbox.CheckboxWrapper className="my-6">
          <Checkbox.Control
            checked={checked}
            className="mt-0.5"
            isError={isError}
            onChange={(e) => {
              setChecked(e.target.checked);
              setIsError(!e.target.checked);
            }}
          />
          <Checkbox.CheckboxLabel className={twJoin('text-clay-900', isError && 'text-red-800')}>
            By continuing you agree to Nesa’s{' '}
            <a
              className="underline duration-200 hover:text-primary-800"
              href={TERMS_LINK}
              rel="noreferrer"
              target="_blank"
            >
              Terms of Service
            </a>{' '}
            including Nesa’s user residency restrictions. You agree to do your own research before trading.
          </Checkbox.CheckboxLabel>
        </Checkbox.CheckboxWrapper>

        <div>
          <Button
            className="w-full"
            onClick={() => {
              if (checked) {
                onOpenChange(false);
                localStorage.setItem(LC_SHOWED_WELCOME_MODAL, 'true');
              } else {
                setIsError(true);
              }
            }}
          >
            Let’s Go!
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { getTvl } from 'pages/DAI/helpers/getTvl';
import { Icon } from 'shared/ui/Icon';

import { Image } from '../Image';

type Props = {
  coinImageSrc?: string;
  coinName?: string;
  description: string;
  mediaSrc?: string;
  onClick?: () => void;
  participants?: { avatar?: string; username: string }[];
  priceChangePrc?: number;
  tags?: string[];
  title: string;
  tvl: string;
  userImageSrc?: string;
  userName: string;
} & ClassName;

export const DaiRow = ({
  className,
  coinImageSrc,
  coinName,
  mediaSrc,
  onClick,
  participants = [],
  priceChangePrc,
  title,
  tvl,
  userImageSrc,
  userName,
}: Props) => {
  return (
    <div className={twMerge('grid grid-cols-[4fr_2fr_2fr_1fr_2fr] gap-2', className)} onClick={onClick}>
      <div className="flex items-center gap-3">
        {mediaSrc?.endsWith('.mp4') ? (
          <video
            autoPlay
            className="size-10 min-w-10 rounded-lg object-cover"
            loop
            muted
            playsInline
            src={mediaSrc}
          />
        ) : (
          <Image className="size-10 min-w-10 overflow-hidden rounded-lg object-cover" src={mediaSrc || ''} />
        )}

        <div className="line-clamp-2 text-base/5 text-clay-900">{title}</div>
      </div>

      <div className="flex items-center gap-2">
        <div
          className={twMerge(
            'flex size-6 items-center justify-center overflow-hidden rounded-full bg-clay-900 p-1',
            coinImageSrc && 'p-0',
          )}
        >
          {coinImageSrc ? (
            <Image className="size-full object-cover" src={coinImageSrc} />
          ) : (
            <Icon className="size-full text-tusk-200" name="logo" />
          )}
        </div>

        <div className="text-sm font-semibold uppercase">${coinName || '--'}</div>
      </div>

      <div className="flex items-center gap-2">
        {!!userImageSrc && !!userName && (
          <>
            <div
              className="size-5 rounded-full bg-clay-20 bg-cover bg-center bg-no-repeat"
              style={{ backgroundImage: `url(${userImageSrc})` }}
            ></div>

            <div className="text-sm font-light">{userName}</div>
          </>
        )}
      </div>

      <div className="flex items-center">
        {participants.map((p, idx) => {
          return (
            <div
              className={twMerge(
                'relative size-10 rounded-full border-2 border-white bg-clay-20 bg-cover bg-center bg-no-repeat',
                idx < participants.length - 1 && 'translate-x-1/2',
              )}
              key={p.username}
              style={{ backgroundImage: `url(${p.avatar})`, zIndex: participants.length - idx }}
            ></div>
          );
        })}
      </div>

      <div className="flex flex-col gap-0.5">
        <div className="flex items-center gap-3">
          <div className="text-lg font-medium">${getTvl(tvl)}</div>
          {priceChangePrc && (
            <div className="flex items-center gap-1.5">
              <div
                className={twMerge(
                  'flex size-4 items-center justify-center rounded-full bg-green-600/20 text-green-600',
                  priceChangePrc < 0 && 'bg-red-900/20 text-red-900',
                )}
              >
                <Icon className={twMerge('size-3', priceChangePrc > 0 && 'rotate-180')} name="arrowDown" />
              </div>

              <div
                className={twMerge(
                  'text-sm/none font-light text-green-600',
                  priceChangePrc < 0 && ' text-red-900',
                )}
              >
                {priceChangePrc > 0 ? '+' : ''}
                {priceChangePrc}%
              </div>
            </div>
          )}
        </div>
        <div className="text-sm/none font-light text-clay-500">Total value locked</div>
      </div>
    </div>
  );
};

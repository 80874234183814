import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { NodeDisconnectParams, User } from './types';

type Response = { user: User };

export const useNodeDisconnectMutation = (
  options: UseMutationOptions<Response, Error, NodeDisconnectParams> = {},
) => {
  return useMutation({
    mutationFn: async (params: NodeDisconnectParams) => {
      const { data } = await axiosInstance.post<Response>(`/users/nodes/${params.public_key}/disconnect`);

      return data;
    },
    ...options,
  });
};

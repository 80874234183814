import { useState } from 'react';

import { useAccount, useCosmWasmSigningClient, useExecuteContract, useQuerySmart } from 'graz';
import { X } from 'lucide-react';
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import { useGetDAISummaryListQuery } from 'shared/api/dai/useGetDAISummaryListQuery';
import { fromUnesToNes } from 'shared/helpers/fromUnesToNes';
import { catchError } from 'shared/helpers/parseAxiosError';
import { timeAgo } from 'shared/helpers/timeAgo';
import { Button } from 'shared/ui/Button';
import { Image } from 'shared/ui/Image';
import { Spinner } from 'shared/ui/Spinner';
import { toaster } from 'shared/ui/Toast';
import { Tooltip } from 'shared/ui/Tooltip';

import type { Stake, StakesResponse } from '../types';

import { stakingContractAddress } from '../consts';

type Props = {
  onClose: () => void;
};
export const MyStakedAssets = ({ onClose }: Props) => {
  const { data: account } = useAccount();

  const [selectedStake, setSelectedStake] = useState<Stake>();

  const { data: signingClient } = useCosmWasmSigningClient();
  const { executeContractAsync, isLoading: isUnstaking } = useExecuteContract({
    contractAddress: stakingContractAddress,
  });

  const {
    data,
    isLoading: isLoadingStakes,
    isSuccess,
    refetch: refetchStakes,
  } = useQuerySmart<StakesResponse, Error>({
    address: stakingContractAddress,
    queryMsg: {
      get_stakes: {
        owner: account?.bech32Address,
      },
    },
  });

  const daiList = (data?.stakes || []).reduce<string[]>((acc, stake) => {
    if (acc.includes(stake.company_id)) {
      return acc;
    }

    acc.push(stake.company_id);

    return acc;
  }, []);

  const { data: daiSummary } = useGetDAISummaryListQuery({ ids: daiList }, { enabled: daiList.length > 0 });

  const nowSeconds = DateTime.now().toSeconds();

  const handleUnstake = async (itemToUnstake: Stake) => {
    try {
      if (!itemToUnstake) return;

      await executeContractAsync({
        fee: {
          amount: [{ amount: '1', denom: 'unes' }],
          gas: '200000',
        },
        funds: [{ amount: itemToUnstake.amount, denom: 'unes' }],
        msg: {
          unstake: { stake_ids: [itemToUnstake.id] },
        },

        signingClient,
      });

      refetchStakes();

      toaster.success(`Success`);
    } catch (e) {
      catchError(e);
    }
  };

  return (
    <div className="relative rounded-2xl bg-white p-4 md:p-6">
      <X
        className="absolute right-4 top-4 size-5 cursor-pointer text-clay-350 transition-colors hover:text-red-900"
        onClick={onClose}
      />
      <h1 className="mb-3 text-xl font-semibold tracking-tight md:mb-4 md:text-3xl">My Staked Assets</h1>

      <div>
        <div className="grid grid-cols-5 gap-3 border-b border-clay-20 px-3 pb-3">
          <div className="text-sm font-light text-clay-380">DAI</div>
          <div className="text-sm font-light text-clay-380">Amount Staked</div>
          <div className="text-sm font-light text-clay-380">Staked for</div>
          <div className="text-sm font-light text-clay-380">Period</div>
          <div className="text-sm font-light text-clay-380"></div>
        </div>

        {isSuccess && !(data?.stakes || [])?.length && (
          <div className="py-8 text-center text-sm font-light text-clay-350">
            You don&apos;t have any stakes
          </div>
        )}

        {isLoadingStakes && (
          <div className="flex items-center justify-center py-10">
            <Spinner className="size-5" />
          </div>
        )}

        <div className="flex max-h-96 flex-col overflow-y-auto">
          {isSuccess &&
            (data?.stakes || [])?.length > 0 &&
            data?.stakes.map((stake, idx) => {
              if (stake.claimed) return null;

              const timeLeft = stake.start_time + stake.lockup_seconds - nowSeconds;
              const summary = daiSummary?.list.find((dai) => dai._id === stake.company_id);

              const leftDays = DateTime.fromSeconds(stake.start_time + stake.lockup_seconds)
                .diffNow()
                .as('days');
              const leftHours = DateTime.fromSeconds(stake.start_time + stake.lockup_seconds)
                .diffNow()
                .as('hours');

              const leftText = leftDays > 0 ? `${Math.ceil(leftDays)} Days` : `${Math.ceil(leftHours)} Hours`;

              return (
                <div
                  className={twMerge(
                    'grid grid-cols-5 gap-3 rounded-xl px-3 py-4',
                    idx % 2 !== 0 && 'bg-clay-10',
                  )}
                  key={stake.id}
                >
                  <div className="flex items-center gap-3">
                    {summary?.cover && (
                      <Image className="size-7 min-w-7 overflow-hidden rounded-full" src={summary?.cover} />
                    )}
                    <span className="truncate">{summary?.projectName || ''}</span>
                  </div>
                  <div className="flex items-center">{fromUnesToNes(stake.amount).toString()} $NES</div>
                  <div className="flex items-center text-clay-400">
                    {timeAgo(DateTime.fromSeconds(stake.start_time)).replace('ago', '')}
                  </div>
                  <div className="flex items-center text-clay-400">
                    {stake.lockup_seconds / (60 * 60 * 24)} Days
                  </div>
                  <div className="flex items-center justify-end">
                    <Tooltip
                      content={timeLeft > 0 ? `You will be able to claim in ${leftText}` : ''}
                      delayDuration={100}
                      side="top"
                    >
                      <div>
                        <Button
                          color="white"
                          disabled={timeLeft > 0}
                          isLoading={selectedStake?.id === stake.id && isUnstaking}
                          onClick={() => {
                            setSelectedStake(stake);
                            handleUnstake(stake);
                          }}
                          size="extra-small"
                        >
                          Claim
                        </Button>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

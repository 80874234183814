import { ChatClient } from 'nesa.js';

import { nesaTestnet } from 'shared/config/networks/nesaTestnet';

export let chatClient: ChatClient | undefined = undefined;

type Props = {
  address: string | undefined;
  authToken?: string;
  isByPass?: boolean;
  modelName: string | undefined;
  walletType: string | undefined;
};

export const createChatClient = ({ address, authToken, isByPass, modelName, walletType }: Props) => {
  if (!modelName) return;
  if (!isByPass && (!walletType || !address)) return;

  const accessTokenDefault = localStorage.getItem('user');
  const accessToken = authToken || accessTokenDefault;

  chatClient && chatClient?.requestCloseHeartbeat();

  chatClient = new ChatClient({
    ...(isByPass
      ? {
          agentUrl: 'https://api-agent.dev.nesa.ai',
          authToken:
            import.meta.env.MODE === 'development'
              ? 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NzM2NjdkM2MxYjRhYTUxMmYwZTkzODYiLCJlbWFpbCI6ImdyaXNoYW5pYTE5OThAZ21haWwuY29tIiwiaWF0IjoxNzM4MTYxNDA1LCJleHAiOjE3Mzk4ODk0MDV9.fBGv-nJPjr0XBoZHMlhAzT2c5au1jsDmniok8z9sLLI'
              : accessToken || '',
        }
      : {
          walletName: walletType === 'metamask_snap_leap' ? 'npm:@leapwallet/metamask-cosmos-snap' : 'keplr',
        }),
    chainInfo: nesaTestnet,
    isByPass,
    modelName: modelName,
  });

  return () => chatClient?.requestCloseHeartbeat();
};

import { twMerge } from 'tailwind-merge';

import type { DatasetSort } from 'shared/api/datasets/types';
import type { ClassName } from 'shared/types';

import type { formats, modalities } from './MainFiltersSection';

import { CountChip } from './CountChip';
import { LanguageFilters } from './LanguageFilters';
import { LibraryFilters } from './LibraryFilters';
import { LicenseFilters } from './LicenseFilters';
import { MainFiltersSection, filterSections } from './MainFiltersSection';
import { OtherFilters } from './OtherFilters';

export type MainFilters = {
  filterSection: (typeof filterSections)[number]['id'];
  format?: (typeof formats)[number]['id'][];
  keywords: string[];
  language?: string[];
  library?: string[];
  license?: string[];
  modality: (typeof modalities)[number]['id'][];
  other?: string[];
  search?: string;
  size: string[];
  sort: DatasetSort;
  tasks?: string[];
};

export type FiltersProps = {
  filters: MainFilters;
  onChange: (value: Partial<MainFilters>) => void;
} & ClassName;

export const MainFilters = ({ className, filters, onChange }: FiltersProps) => {
  return (
    <div className={twMerge('flex w-full flex-col gap-6', className)}>
      <div className="flex flex-wrap items-center gap-2">
        {filterSections.map(({ id, label }) => {
          return (
            <div
              className={twMerge(
                'flex h-7 cursor-pointer items-center rounded-lg border border-clay-20 bg-transparent px-2 py-1.5 text-sm/none text-clay-500 transition-colors hover:bg-primary-40',
                filters.filterSection === id &&
                  'border-primary-800 bg-primary-800 text-white hover:bg-primary-900',
              )}
              key={id}
              onClick={() => onChange({ filterSection: id })}
            >
              {label}{' '}
              {/* {id === 'tasks' && filters.tasks && filters.tasks.length > 0 && (
                <CountChip className="ml-2" isActive={filters.filterSection === id}>
                  {' '}
                  {filters.tasks.length}
                </CountChip>
              )} */}
              {id === 'libraries' && filters.library && filters.library.length > 0 && (
                <CountChip className="ml-2" isActive={filters.filterSection === id}>
                  {filters.library.length}
                </CountChip>
              )}
              {id === 'languages' && filters.language && filters.language.length > 0 && (
                <CountChip className="ml-2" isActive={filters.filterSection === id}>
                  {filters.language.length}
                </CountChip>
              )}
            </div>
          );
        })}
      </div>

      {filters.filterSection === 'main' && <MainFiltersSection filters={filters} onChange={onChange} />}
      {/* {filters.filterSection === 'tasks' && <TasksFilters filters={filters} onChange={onChange} />} */}
      {filters.filterSection === 'libraries' && <LibraryFilters filters={filters} onChange={onChange} />}
      {filters.filterSection === 'languages' && <LanguageFilters filters={filters} onChange={onChange} />}
      {filters.filterSection === 'licenses' && <LicenseFilters filters={filters} onChange={onChange} />}
      {filters.filterSection === 'other' && <OtherFilters filters={filters} onChange={onChange} />}
    </div>
  );
};

import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

type Params = {
  datasetId: string;
  license: string;
  ownerName: string;
  private: boolean;
};

export type NesaDataset = {
  _id: string;
  bucketPath?: string;
  creatorName: string;
  datasetId: string;
  license: string;
  likes?: number;
  ownerUserId: string;
  private: boolean;
  timestamp: number;
};

type Response = {
  data: NesaDataset;
};

export const useCreateNesaDatasetMutation = (options: UseMutationOptions<Response, Error, Params> = {}) => {
  return useMutation({
    mutationFn: async (params: Params) => {
      const { data } = await axiosInstance.post<Response>(`/datasets/create`, params);

      return data;
    },

    ...options,
  });
};

import { Icon } from '../Icon';

type TProps = {
  files: File[];
  onDelete: (file: File) => void;
};

export const ChipFilePreview = ({ files, onDelete }: TProps) => {
  return (
    <div className="flex flex-wrap gap-1">
      {files.map((file) => (
        <div
          className="flex w-fit items-center gap-1 rounded-lg bg-clay-10 px-2 py-1 text-sm text-clay-500"
          key={file?.name}
        >
          <Icon className="size-[16px] stroke-clay-350 text-transparent" name="fileBox" />
          {file?.name}

          <Icon
            className="size-4 cursor-pointer transition-colors hover:text-red-1000"
            name="close"
            onClick={(event) => {
              event.stopPropagation();
              onDelete(file);
            }}
          />
        </div>
      ))}
    </div>
  );
};

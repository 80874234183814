import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { userKeys } from './queryKeys';

type Props = {
  limit: number;
  name: string;
  skip: number;
};

type Response = {
  list: {
    _id: string;
    avatar_cid?: string;
    email: string;
    first_name: string;
    fullName: string;
    last_name: string;
  }[];
};

export const useUsersByNameQuery = (params: Props, options: UseQueryOptions<Response>) => {
  return useQuery({
    queryFn: async () => {
      const { data } = await axiosInstance.get<Response>(`/users/name/${params.name}`, {
        params: {
          limit: params.limit,
          skip: params.skip,
        },
      });

      return data;
    },
    queryKey: userKeys.usersByNames(params),
    ...options,
  });
};

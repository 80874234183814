import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { daiKeys } from './queryKeys';

type Response = {
  count: number;
  date: string;
  day: number;
  stakesCount: number;
}[];

type Params = {
  daiId: string;
};

export const useGetStakeAmountChartQuery = (params: Params, options: UseQueryOptions<Response> = {}) => {
  return useQuery({
    queryFn: async () => {
      const { data } = await axiosInstance.get<Response>(`/dai/${params.daiId}/stake/chart`);

      return data;
    },
    queryKey: daiKeys.stakeAmountChart(params),
    ...options,
  });
};

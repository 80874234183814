import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { FilterState } from 'shared/ui/Gallery/types';

import { cleanObject } from 'shared/helpers/cleanObject';

export const buildParams = (data: Record<string, boolean | string | string[]>) => {
  const params = new URLSearchParams();

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((value) => params.append(key, value.toString()));
    } else {
      params.append(key, value.toString());
    }
  });

  return params;
};

export const parseFiltersFromParams = (searchParams: URLSearchParams) => {
  return {
    featuredEnabled: searchParams.get('featuredEnabled') === 'true' || false,
    globalSearch: searchParams.get('globalSearch') || undefined,
    sort: searchParams.get('sort') as FilterState['sort'],
    tag: searchParams.get('tag') as FilterState['tag'],
    trustEnabled: searchParams.get('trustEnabled') === 'true' || false,
    type: searchParams.getAll('type') || [],
  };
};

type FiltersContextType = {
  filters: FilterState;
  onFiltersChange: (filters: FilterState) => void;
};

const FiltersContext = createContext<FiltersContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const FilterProvider: React.FC<{ children: ReactNode }> = ({ children }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onFiltersChange = useCallback(
    (newFilters: FilterState) => {
      const cleanFilters = cleanObject(newFilters, { removeNull: true });
      setSearchParams(buildParams(cleanFilters));
    },
    [setSearchParams],
  );

  const value = useMemo(
    () => ({ filters: parseFiltersFromParams(searchParams), onFiltersChange }),
    [searchParams, onFiltersChange],
  );

  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
};

export const useFilters = (): FiltersContextType => {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error('useFilters must be used within a FiltersProvider');
  }
  return context;
};

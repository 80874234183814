import { useState } from 'react';

import { motion } from 'framer-motion';
import { useAccount, useBalance, useCosmWasmSigningClient, useExecuteContract } from 'graz';
import { twMerge } from 'tailwind-merge';

import type { DAI } from 'shared/api/dai/types';
import type { ClassName } from 'shared/types';

import { useCreateDAIStakeMutation } from 'shared/api/dai/useCreateDAIStakeMutation';
import { useDAIByIdQuery } from 'shared/api/dai/useDAIByIdQuery';
import { useGetDAIStakeSummaryQuery } from 'shared/api/dai/useGetDAIStakeSummaryQuery';
import { nesaTestnet } from 'shared/config/networks/nesaTestnet';
import { TERMS_LINK } from 'shared/const/links';
import { fromNesToUnes } from 'shared/helpers/fromNesToUnes';
import { fromUnesToNes } from 'shared/helpers/fromUnesToNes';
import { catchError } from 'shared/helpers/parseAxiosError';
import { Button } from 'shared/ui/Button';
import { Checkbox } from 'shared/ui/Checkbox';
import { Input } from 'shared/ui/Input';
import { Spinner } from 'shared/ui/Spinner';
import { toaster } from 'shared/ui/Toast';

type Props = { dai: DAI } & ClassName;

export const Stake = ({ className, dai }: Props) => {
  const { data: account } = useAccount();

  const {
    data: uBalance,
    isFetching,
    refetch: refetchBalance,
  } = useBalance({
    bech32Address: account?.bech32Address,
    chainId: nesaTestnet.chainId,
    denom: nesaTestnet.stakeCurrency.coinMinimalDenom,
  });

  const { data: signingClient } = useCosmWasmSigningClient();
  const { executeContractAsync, isLoading: isStaking } = useExecuteContract({
    contractAddress: 'nesa1pryug3pp92fhn5qavdt2uxu32j3gv0v7vueuzs3ep8xelqd6exls97lw6v',
  });

  const { data: stakeSummary, isPending, refetch: refetchSummary } = useGetDAIStakeSummaryQuery();

  const { refetch: refetchDAI } = useDAIByIdQuery({ id: dai._id || '' }, { enabled: !!dai._id });

  const { mutateAsync: createDAIStake } = useCreateDAIStakeMutation();

  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [amount, setAmount] = useState('0');
  const [selectedLockPeriod, setSelectedLockPeriod] = useState<'days30' | 'days90'>('days30');

  const balance = uBalance?.amount ? fromUnesToNes(uBalance.amount).toNumber() : undefined;

  const handleStake = async () => {
    try {
      const res = await executeContractAsync({
        fee: {
          amount: [{ amount: '1', denom: 'unes' }],
          gas: '200000',
        },
        funds: [{ amount: fromNesToUnes(amount).toString(), denom: 'unes' }],
        msg: {
          stake: {
            amount: fromNesToUnes(amount).toString(),
            company_id: dai._id,
            // lockup_period: 'seconds1',
            lockup_period: selectedLockPeriod,
          },
        },

        signingClient,
      });

      console.log('res', res);

      refetchBalance();

      await createDAIStake({
        account: account?.bech32Address || '',
        amount: fromNesToUnes(amount).toString(),
        company_id: dai._id,
        lockup_seconds:
          selectedLockPeriod === 'days30'
            ? 30 * 24 * 60 * 60
            : selectedLockPeriod === 'days90'
              ? 90 * 24 * 60 * 60
              : 0,
      });

      refetchDAI();

      refetchSummary();
      toaster.success(`Your stake was successful`);
    } catch (e) {
      catchError(e);
    }
  };

  return (
    <motion.div
      animate={{ opacity: 1 }}
      className={twMerge('flex flex-col', className)}
      initial={{ opacity: 0 }}
    >
      <div className="mb-2 flex items-end justify-between text-sm">
        <div className="font-medium">Amount</div>
        <div className="flex items-center gap-2 font-medium">
          Balance: {isFetching ? <Spinner className="size-3" /> : balance || 0} NES
        </div>
      </div>
      <Input
        classNameInputWrapper="border-none bg-clay-10 font-medium"
        disabled={isStaking}
        endSlot={
          <div
            className="cursor-pointer rounded-lg border border-primary-800 px-2 py-0.5 text-xs text-primary-800 transition hover:brightness-90"
            onClick={() => {
              uBalance?.amount && setAmount(fromUnesToNes(uBalance.amount).toString());
            }}
          >
            MAX
          </div>
        }
        error={Number.isNaN(Number(amount)) ? 'Should be a valid number' : ''}
        errorSpacing
        onChange={(e) => setAmount(e.target.value)}
        size="medium"
        value={amount}
      />

      <div
        className={twMerge(
          'mt-3 flex cursor-pointer items-center justify-between rounded-lg border-b border-clay-20 px-3 py-4 transition-colors hover:bg-primary-30',
          selectedLockPeriod === 'days30' && 'bg-primary-40',
          isStaking && 'pointer-events-none',
        )}
        onClick={() => setSelectedLockPeriod('days30')}
      >
        <div className="text-clay-400">30 days</div>
        <div className="font-medium">1x Rewards</div>
      </div>
      <div
        className={twMerge(
          'mb-3 flex cursor-pointer items-center justify-between rounded-lg border-b border-clay-20 px-3 py-4 transition-colors hover:bg-primary-30',
          selectedLockPeriod === 'days90' && 'bg-primary-40',
          isStaking && 'pointer-events-none',
        )}
        onClick={() => setSelectedLockPeriod('days90')}
      >
        <div className="text-clay-400">90 days</div>
        <div className="font-medium">5x Rewards</div>
      </div>

      <Button
        className="mb-5 text-base font-normal"
        disabled={!isTermsChecked || !amount || Number.isNaN(Number(amount)) || !Number(amount) || !balance}
        isLoading={isStaking}
        onClick={handleStake}
        size="medium"
      >
        {!balance && !isFetching ? '$NES balance low. Get more from the Faucet.' : 'Stake $NES'}
      </Button>

      <div className="mb-5 flex items-center justify-center">
        <Checkbox.CheckboxWrapper>
          <Checkbox.Control checked={isTermsChecked} onChange={(e) => setIsTermsChecked(e.target.checked)} />
          <Checkbox.CheckboxLabel className="font-light">
            By staking, you confirm you have read, understand, and agree with the{' '}
            <a
              className="inline-flex cursor-pointer font-bold underline"
              href={TERMS_LINK}
              rel="noreferrer"
              target="_blank"
            >
              Terms of Service
            </a>
            .
          </Checkbox.CheckboxLabel>
        </Checkbox.CheckboxWrapper>
      </div>

      <div className={twMerge('grid grid-cols-3 gap-3', !dai.token && 'grid-cols-2')}>
        <div className="flex flex-col items-center justify-center gap-0">
          <div className="text-center text-sm font-light text-clay-400">Total $NES Staked</div>
          <div className="text-center text-lg font-medium tracking-tight">
            {isPending ? (
              <div className="flex items-center justify-center py-1">
                <Spinner className="size-4" />
              </div>
            ) : stakeSummary ? (
              fromUnesToNes(stakeSummary.totalStaked).toString()
            ) : (
              ''
            )}
          </div>
        </div>
        {dai.token && dai.token.reservedForStakers && dai.token.totalSupply && (
          <div className="flex flex-col items-center justify-center gap-0">
            <div className="text-center text-sm font-light text-clay-400">
              ${dai.token.ticker} Airdrop for Stakers
            </div>
            <div className="text-center text-lg font-medium tracking-tight">
              {((dai.token.reservedForStakers * dai.token.totalSupply) / 100).toLocaleString()}
            </div>
          </div>
        )}
        <div className="flex flex-col items-center justify-center gap-0">
          <div className="text-center text-sm font-light text-clay-400">Stakers</div>
          <div className="text-center text-lg font-medium tracking-tight">
            {isPending ? (
              <div className="flex items-center justify-center py-1">
                <Spinner className="size-4" />
              </div>
            ) : (
              stakeSummary?.stakersCount
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

import { useNavigate } from 'react-router-dom';

import { BackButton } from 'app/ui/BackButton';
import { AnimateRoute } from 'shared/ui/AnimateRoute';

import { FindModel } from './ui/FindModel';
import { TrainedModels } from './ui/TrainedModels';

export const Training = () => {
  const navigate = useNavigate();

  return (
    <AnimateRoute className="mx-auto w-full max-w-7xl px-4 pb-10 pt-4">
      <div className="mb-4 flex">
        <BackButton
          className="gap-4 text-2xl font-semibold text-clay-900 hover:text-clay-900"
          onClick={() => navigate('/')}
        >
          Training
        </BackButton>
      </div>
      <div className="flex flex-col gap-5">
        <FindModel />
        <TrainedModels />
      </div>
    </AnimateRoute>
  );
};

import type { ComponentProps } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import { useProductPrices } from 'app/stores/prices';
import { useUser } from 'app/stores/user';
import { BackButton } from 'app/ui/BackButton';
import { UpgradeToProModal, upgradeToProBulletList } from 'features/UpgradeToProModal';
import { NESA_PRO_PRICE_BY_MONTH } from 'shared/const';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';

import bgSrc from './assets/bg.png';
import { accordions, tables } from './const';
import { Accordion, AccordionProvider } from './ui/accordion';
import * as C from './ui/card-ui';
import { Table } from './ui/table-ui';

const items = [
  'Access to all Nesa Playground models',
  'Ability to upload custom models',
  'Testnet faucet access',
];

// const itemsPro = [
//   'Earn 5x the Nesa Playground points',
//   'Unlimited* inference credits',
//   'Unrestricted custom model pricing',
//   'Spotlight your custom models',
//   'Early access to new models',
//   'Nesa Pro Badge',
// ];

// const itemsEnterprise = [
//   'SSO and SAML support',
//   'Hybrid + intranet deployment strategies',
//   'Custom inference geolocation',
//   'Advanced logging dashboard',
//   'Granular access control',
//   'Priority Slack/Email support',
// ];

export const PricingPage = () => {
  // const [loadingStripe, setLoadingStripe] = useState<ProductStripeType | null>(null);

  const { data: prices } = useProductPrices();
  const priceByMonth = prices?.['nesa.pro.month'];

  // const paymentSubscription = useProductStripePayment('/pricing');

  // const upgradeToProByStripe = async (productType: ProductStripeType) => {
  //   setLoadingStripe(productType);

  //   try {
  //     await paymentSubscription(productType);
  //   } catch {
  //     setLoadingStripe(null);
  //   }
  // };

  const [isUpgradeOpen, setIsUpgradeOpen] = useState(false);
  const navigate = useNavigate();

  const { proVersion } = useUser();

  return (
    <AnimateRoute className="mx-auto w-full max-w-7xl px-4 pb-28 pt-6">
      <div
        className="absolute inset-0 -top-4 -z-50 bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${bgSrc})` }}
      ></div>

      <div className="mb-4">
        <BackButton
          className="gap-5 text-2xl font-semibold text-clay-900 hover:text-clay-900"
          onClick={() => navigate('/')}
        >
          Pricing
        </BackButton>
      </div>

      <div className="mb-10 flex flex-col items-center gap-4 text-center">
        <div className="rounded-full bg-white px-3.5 py-2 text-sm text-clay-900 shadow-checkbox">Pricing</div>
        <TitlePage>Empowering scalable and secure AI solutions.</TitlePage>
        <div className="max-w-xl text-lg/6 text-clay-500">
          Elevate your AI workloads with secure, streamlined execution and access a multitude of models, all
          in one place.
        </div>
      </div>

      <div className="mx-auto grid w-full max-w-[52rem] grid-cols-1 gap-4 md:grid-cols-2">
        <C.Card>
          <C.CardHeader>
            <C.CardTitle>Basic</C.CardTitle>
            <C.CardDescription>Limited Access</C.CardDescription>
          </C.CardHeader>
          <C.CardItemWrapper>
            {items.map((el, i) => (
              <C.CardItem key={i}>{el}</C.CardItem>
            ))}
          </C.CardItemWrapper>
          <C.CardFooter>
            <div>
              <C.CardPriceDescription>Forever</C.CardPriceDescription>
              <C.CardPrice>Free</C.CardPrice>
            </div>
            <Button color="secondary" disabled>
              {proVersion?.isPro ? 'Upgraded' : 'Currently Active'}
            </Button>
          </C.CardFooter>
        </C.Card>

        <C.Card className="shadow-card-large">
          <C.CardHeader>
            <div className="flex items-center gap-2">
              <C.CardTitle>Nesa</C.CardTitle>
              <div className=" rounded-lg bg-blue-600 px-3 py-2 text-xl/none font-bold text-white">PRO</div>
            </div>
            <C.CardDescription>Unlimited Access</C.CardDescription>
          </C.CardHeader>
          <C.CardItemWrapper>
            {upgradeToProBulletList.map((el, i) => (
              <C.CardItem className={twMerge(el.bold && 'font-bold')} key={i}>
                {el.text}
              </C.CardItem>
            ))}
          </C.CardItemWrapper>
          <C.CardFooter>
            <div>
              <C.CardPriceDescription>Subscribe for</C.CardPriceDescription>
              <C.CardPrice>
                ${priceByMonth?.unit_amount ? `${priceByMonth.unit_amount / 100}` : NESA_PRO_PRICE_BY_MONTH}{' '}
                <span className=" text-base text-clay-500">/Month</span>
              </C.CardPrice>
            </div>
            <Button color="blue" disabled={proVersion?.isPro} onClick={() => setIsUpgradeOpen(true)}>
              {proVersion?.isPro ? 'Currently Active' : 'Subscribe'}
            </Button>
          </C.CardFooter>
        </C.Card>

        {/* <C.Card>
          <C.CardHeader>
            <C.CardTitle>Nesa for Enterprise</C.CardTitle>
            <C.CardDescription>Accelerate your AI Roadmap</C.CardDescription>
          </C.CardHeader>
          <C.CardItemWrapper>
            {itemsEnterprise.map((el, i) => (
              <C.CardItem key={i}>{el}</C.CardItem>
            ))}
          </C.CardItemWrapper>
          <C.CardFooter>
            <div>
              <C.CardPriceDescription>Starting at</C.CardPriceDescription>
              <C.CardPrice>
                ${NESA_ENTERPRISE_PRICE_BY_MONTH} <span className=" text-base text-clay-500">/Month</span>
              </C.CardPrice>
            </div>
            <Button
              isLoading={loadingStripe === 'nesa.enterprise.year'}
              onClick={() => upgradeToProByStripe('nesa.enterprise.year')}
            >
              Start Now
            </Button>
          </C.CardFooter>
        </C.Card> */}
      </div>

      <div className="my-14 flex flex-col items-center gap-6 text-center">
        <div className="rounded-full bg-white px-3.5 py-2 text-sm text-clay-900 shadow-checkbox">
          API Pricing
        </div>
        <TitlePage>Simple and flexible. Only pay for what you use</TitlePage>
        <Button as="a" href="mailto:hi@nesa.ai" size="medium">
          Contact Sales
        </Button>
      </div>

      <div className="grid grid-cols-3 gap-4">
        {tables.map((el, i) => (
          <Table
            category={el.category}
            description={el.description}
            key={i}
            rows={el.rows}
            title={el.title}
          />
        ))}
      </div>

      <div className="mt-28">
        <AccordionProvider>
          {accordions.map(({ children, title }, i) => (
            <Accordion key={i} title={title}>
              <p className="w-full overflow-hidden text-lg font-light text-clay-900">{children}</p>
            </Accordion>
          ))}
        </AccordionProvider>
      </div>

      <UpgradeToProModal onOpenChange={setIsUpgradeOpen} open={isUpgradeOpen} redirectPath="/pricing" />
    </AnimateRoute>
  );
};

const TitlePage = (props: ComponentProps<'div'>) => {
  return (
    <div
      {...props}
      className={twMerge(
        'w-full max-w-2xl text-5xl font-semibold leading-none tracking-tight text-clay-900 2xl:text-[3.25rem]',
        props.className,
      )}
    />
  );
};

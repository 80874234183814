import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { daiKeys } from './queryKeys';

type Response = {
  list: {
    date: string;
    requests: number;
  }[];
};

type Params = {
  walletAddresses: string[];
};

export const useGetRequestsChartQuery = (params: Params, options: UseQueryOptions<Response> = {}) => {
  return useQuery({
    queryFn: async () => {
      const { data } = await axiosInstance.get<Response>(`/dai/requests/chart`, { params });

      return data;
    },
    queryKey: daiKeys.requestChart(params),
    ...options,
  });
};

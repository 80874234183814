import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useAccount } from 'graz';
import { ArrowUpRight } from 'lucide-react';

import type { DAI } from 'shared/api/dai/types';

import { useUser } from 'app/stores/user';
import { WalletProviderModal } from 'features/WalletProviderModal';
import { categories } from 'pages/DAIOverview/DAIOverview';
import { StakingModal } from 'pages/DAIOverview/ui/StakingModal/StakingModal';
import { useGetDAIConnectedWalletsQuery } from 'shared/api/dai/useGetDAIConnectedWalletsQuery';
import { useGetDAISummaryQuery } from 'shared/api/dai/useGetDAISummaryQuery';
// import { useTransactionsWalletActivityQuery } from 'shared/api/transactions';
import { getShortenedAddress } from 'shared/helpers/getShortenedAddress';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Image } from 'shared/ui/Image';
import { Spinner } from 'shared/ui/Spinner';
import { toaster } from 'shared/ui/Toast';
import { Tooltip } from 'shared/ui/Tooltip';

import { getTvl } from '../helpers/getTvl';
import { ConnectedModels } from './ConnectedModels';
import { DAIChart } from './DAIChart';
import { TeamMember } from './TeamMember';
import { TeamMemberModel } from './TeamMemberModel';

type Props = {
  dai: DAI;
};
export const DAIContent = ({ dai }: Props) => {
  const { isConnected } = useAccount();
  const { user } = useUser();
  const [isWalletProviderOpen, setIsWalletProviderOpen] = useState(false);
  const [isStakingOpen, setIsStakingOpen] = useState(false);
  const [isTeamMemberOpen, setIsTeamMemberOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState<DAI['members'][number]>();

  const { data } = useGetDAIConnectedWalletsQuery({ daiId: dai._id });
  const wallets = data?.list;
  const walletAddresses = (wallets || [])?.map((w) => w.walletAddress);

  // const { data: txResponse } = useTransactionsWalletActivityQuery(
  //   { limit: 100, skip: 0, walletAddresses: walletAddresses },
  //   { enabled: !!walletAddresses.length },
  // );

  const { data: daiSummary, isPending: isSummaryPending } = useGetDAISummaryQuery(
    { daiId: dai._id, userId: user?._id, walletAddresses },
    { enabled: !!dai._id },
  );

  return (
    <>
      {dai.cover.endsWith('.mp4') ? (
        <video autoPlay className="h-96 w-full object-cover" loop muted playsInline src={dai.cover} />
      ) : (
        <img className="h-96 w-full object-cover" src={dai.cover} />
      )}

      <div className="relative -top-12 m-4 grid grid-cols-1 gap-3 rounded-xl bg-white bg-gradient-to-t from-transparent to-clay-20 p-6 md:gap-x-8 lg:grid-cols-5 2xl:grid-cols-4">
        <div className="col-span-1 flex flex-col gap-4 lg:col-span-3 2xl:col-span-3">
          <div className="flex flex-col gap-2 md:flex-row md:gap-4">
            {dai.profileImage && (
              <Image
                className="size-20  overflow-hidden rounded-full object-cover sm:size-24 lg:size-32"
                src={dai.profileImage}
              />
            )}

            <div className="flex flex-col gap-2 md:gap-4">
              <div className="flex items-center gap-4">
                <div className="size-6 overflow-hidden rounded-full bg-clay-800">
                  {dai.token?.image && <Image className="size-full object-cover" src={dai.token?.image} />}
                </div>
                {/* <UsdcIcon className="size-6" /> */}
                <span className="font-light">${dai.token?.ticker || '--'}</span>
                {/* <div className="rounded-full bg-clay-40 px-2 py-0.5 text-sm text-clay-350">#54</div>
                <div className="flex items-center gap-1.5 rounded-full bg-blue-600/10 px-2 py-1 text-xs text-blue-600">
                  <Icon className="size-2.5 text-blue-600" name="check" />
                  Verified
                </div> */}
              </div>

              <div className="text-2xl font-semibold tracking-tighter lg:text-4xl">{dai.projectName}</div>

              <div className="flex flex-wrap items-center gap-1.5 md:gap-3">
                {[dai.projectCategory].map((tag, idx) => {
                  const category = categories.find((cat) => cat.value === tag);
                  return (
                    <div
                      className="rounded-lg bg-clay-20 px-2 py-1 text-sm capitalize text-clay-350"
                      key={idx}
                    >
                      {category?.label || ''}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="text-base font-light text-clay-350 md:text-lg">{dai.shortDescription}</div>

          {!!user && (
            <div className="flex items-start gap-3 py-4 ">
              <div className="flex flex-col gap-1">
                <div className="text-sm text-clay-400">Staked amount</div>
                <div className="text-3xl font-semibold tracking-tighter">
                  {isSummaryPending ? (
                    <Spinner className="my-1 size-5" />
                  ) : daiSummary?.stakedByUser ? (
                    `$${getTvl(daiSummary.stakedByUser)}`
                  ) : (
                    `$0`
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-span-1 flex flex-col gap-10 lg:col-span-2 2xl:col-span-1">
          {dai.productLink && (
            <Button
              className=" flex justify-between pr-2 text-base"
              color="white"
              onClick={() =>
                window.open(
                  dai.productLink.startsWith('https://') ? dai.productLink : `https://${dai.productLink}`,
                )
              }
            >
              Try {dai.projectName || ''} now
              <ArrowUpRight className="size-4" />
            </Button>
          )}

          <div className="flex flex-col gap-5">
            <div className="text-sm text-clay-400 md:text-base">Total Value Locked</div>
            <div className="flex items-center gap-3 text-2xl font-semibold tracking-tighter sm:text-3xl lg:text-5xl">
              ${getTvl(dai.tvl)}{' '}
              {/* <div className="flex items-center gap-1.5">
                <div
                  className={twMerge(
                    'flex size-4 items-center justify-center rounded-full bg-green-600/20 text-green-600',
                    priceChangePrc < 0 && 'bg-red-900/20 text-red-900',
                  )}
                >
                  <Icon className={twMerge('size-3', priceChangePrc > 0 && 'rotate-180')} name="arrowDown" />
                </div>

                <div
                  className={twMerge(
                    'text-base/none font-light tracking-normal text-green-600',
                    priceChangePrc < 0 && ' text-red-900',
                  )}
                >
                  {priceChangePrc > 0 ? '+' : ''}
                  {priceChangePrc}%
                </div>
              </div> */}
            </div>
            <Button
              className="flex h-auto w-full items-center justify-start gap-4 px-3 py-2 md:px-6 md:py-4"
              onClick={() => {
                if (!isConnected) {
                  setIsWalletProviderOpen(true);
                  return;
                }
                setIsStakingOpen(true);
              }}
            >
              <Icon className="size-8 text-white" name="logo" />

              {isConnected ? (
                <div className=" flex flex-col items-start font-light">
                  <div className="text-base text-white md:text-xl">
                    Stake $NES {dai.token?.ticker ? ` for $${dai.token.ticker.toUpperCase()}` : ''}
                  </div>
                  <div className="text-sm text-white/50 md:text-base">
                    Earn $NES {dai.token?.ticker ? ` and $${dai.token.ticker.toUpperCase()}` : ''}
                  </div>
                </div>
              ) : (
                <div className="text-start text-lg font-light">Connect Wallet to Stake</div>
              )}
            </Button>
          </div>
        </div>

        <DAIChart
          className="col-span-1 mb-6 lg:col-span-5 2xl:col-span-4"
          daiId={dai._id}
          walletAddresses={(wallets || [])?.map((w) => w.walletAddress)}
        />
      </div>
      <div className="relative col-span-1 grid grid-cols-1 md:grid-cols-2 lg:col-span-4">
        <div className="flex flex-col gap-1.5 border-b border-r border-clay-20 px-5 py-3">
          <div className="text-base font-normal text-clay-350">Address</div>
          <div className="flex flex-col">
            {wallets?.map((wallet) => {
              return (
                <div className="flex items-center gap-2 text-base font-medium" key={wallet._id}>
                  {getShortenedAddress(wallet.walletAddress)}{' '}
                  <CopyToClipboard onCopy={() => toaster.success('Copied')} text={wallet.walletAddress || ''}>
                    <Icon
                      className="size-4 cursor-pointer text-clay-350 transition-colors hover:text-clay-800"
                      name="copy"
                    />
                  </CopyToClipboard>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col gap-1.5 border-b border-clay-20 px-5 py-3">
          <div className="text-base font-normal text-clay-350">Stakers</div>
          <div className="flex items-center gap-2 text-base font-medium">
            {isSummaryPending ? <Spinner className="size-4" /> : daiSummary?.stakers ?? '--'}
          </div>
        </div>{' '}
        <div className="flex flex-col gap-1.5 border-b border-r border-clay-20 px-5 py-3">
          <div className="text-base font-normal text-clay-350">Total Supply</div>
          <div className="flex items-center gap-2 text-base font-medium">
            {(dai.token?.totalSupply && Number(dai.token?.totalSupply)?.toLocaleString()) || '--'}
          </div>
        </div>{' '}
        <div className="flex flex-col gap-1.5 border-b border-clay-20 px-5 py-3 ">
          <div className="flex items-center gap-1 text-base font-normal text-clay-350">
            Airdrop to Stakers{' '}
            <Tooltip
              content={
                <div className="max-w-52">
                  Nesa does not control any DAI token rewards or airdrops and does not vet DAI claims or
                  information. Before staking and trading, always do your own research.
                </div>
              }
              delayDuration={100}
              side="top"
            >
              <Icon className="size-4 text-clay-350" name="infoCircle" />
            </Tooltip>
          </div>
          <div className="flex items-center gap-2 text-base font-medium">
            {dai.token?.totalSupply && dai.token?.reservedForStakers
              ? `${((dai.token?.totalSupply * dai.token.reservedForStakers) / 100).toLocaleString()} (${dai.token.reservedForStakers}%)`
              : '--'}
          </div>
        </div>
      </div>
      <ConnectedModels
        connectedModelIds={dai.connectedModels || []}
        modelDescriptions={dai.modelDescriptions}
      />

      <div className="grid grid-cols-1 gap-4 p-6 md:grid-cols-2 md:gap-x-12">
        <div className="flex flex-col gap-4">
          <h2 className="text-xl md:text-2xl">{dai.tagline}</h2>
          <p className="whitespace-pre-line text-base font-light text-clay-350">{dai.description}</p>
        </div>
        <div className="flex flex-col">
          <h2 className="mb-3 text-2xl font-semibold md:text-3xl">{dai.projectName} Team</h2>
          <div className="mb-10 flex w-fit items-center gap-1.5 rounded-full bg-tusk-100/80 px-2 py-1 text-xs font-medium">
            <Icon className="size-2.5" name="check" /> Doxxed
          </div>

          <div className="grid grid-cols-2 gap-x-3 gap-y-6 md:grid-cols-3">
            {dai.members.map((member) => {
              return (
                <TeamMember
                  avatar={member.avatar}
                  key={member.id}
                  name={member.name}
                  onClick={() => {
                    setSelectedMember(member);
                    setIsTeamMemberOpen(true);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className="col-span-3 flex flex-col gap-2 border-b border-clay-20 p-6 pb-8 md:pb-12">
        <div className="text-base text-clay-350">Official Links</div>
        <div className="flex flex-wrap items-center gap-1.5 md:gap-3">
          {dai.socials.map(({ href, type }) => {
            return (
              <div
                className="cursor-pointer rounded-full bg-clay-10 px-2.5 py-1 text-sm font-medium capitalize transition-colors hover:bg-clay-20"
                key={href}
                onClick={() => window.open(href.startsWith('https://') ? href : `https://${href}`, '_blank')}
              >
                {type}
              </div>
            );
          })}
          {/* {['website', 'Docs', 'Whitepaper', 'X/Twitter', 'Discord'].map((name) => {
            return (
              <div className="rounded-full bg-clay-10 px-2.5 py-1 text-sm font-medium" key={name}>
                {name}
              </div>
            );
          })} */}
        </div>
      </div>

      <div className="flex max-w-full flex-col gap-4 overflow-hidden px-6  pt-8">
        <div className="text-xl font-medium md:text-2xl">Interaction Logs</div>

        <div className="flex flex-col gap-2 overflow-scroll pb-10">
          {[
            { id: 1, text: 'interacted with Luna', url: 'intsdcds.com' },
            { id: 2, text: 'interacted with Luna', url: 'intsdcds.com' },
          ].map((item) => {
            return (
              <div
                className="grid min-w-[600px] grid-cols-5 rounded-lg bg-clay-10 p-2 pr-4 md:h-12 md:grid-cols-5"
                key={item.id}
              >
                <div className="col-span-2 flex items-center justify-start truncate font-light text-clay-350 md:col-span-1">
                  <div className="mr-3 flex size-8 min-w-8 items-center justify-center rounded-lg bg-clay-30/70">
                    {' '}
                    <Icon className="size-4 text-clay-300" name="box" />
                  </div>

                  {item.url}
                </div>
                <div className="col-span-2 flex items-center justify-between font-light text-clay-350 md:col-span-3">
                  {item.text}
                  <Icon name="arrowUpRight" />
                </div>
                <div className="flex items-center justify-end text-end text-sm font-light text-clay-350">
                  41 minutes ago
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <StakingModal dai={dai} isOpen={isStakingOpen} onOpenChange={setIsStakingOpen} />

      {selectedMember && (
        <TeamMemberModel
          isOpen={isTeamMemberOpen}
          member={selectedMember}
          onOpenChange={setIsTeamMemberOpen}
        />
      )}

      <WalletProviderModal onOpenChange={setIsWalletProviderOpen} open={isWalletProviderOpen} />
    </>
  );
};

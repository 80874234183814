import { useEffect, useRef } from 'react';

export const useScrollableRef = () => {
  const elRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const el = elRef.current;
    let isDragging = false;
    let startX = 0;
    let scrollLeft = 0;

    if (el) {
      const start = (e: MouseEvent) => {
        if (e.currentTarget instanceof Element && e.currentTarget.clientWidth < e.currentTarget.scrollWidth) {
          e.stopPropagation();
        }
        isDragging = true;
        startX = e.pageX - el.offsetLeft;
        scrollLeft = el.scrollLeft;
      };

      const move = (e: MouseEvent) => {
        if (e.currentTarget instanceof Element && e.currentTarget.clientWidth < e.currentTarget.scrollWidth) {
          e.stopPropagation();
        }
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - el.offsetLeft;
        const walk = (x - startX) * 1.2; // scroll speed
        el.scrollLeft = scrollLeft - walk;
      };

      const stop = (e: MouseEvent) => {
        if (e.currentTarget instanceof Element && e.currentTarget.clientWidth < e.currentTarget.scrollWidth) {
          e.stopPropagation();
        }
        isDragging = false;
      };

      const onWheel = (e: WheelEvent) => {
        if (e.currentTarget instanceof Element && e.currentTarget.clientWidth < e.currentTarget.scrollWidth) {
          e.stopPropagation();
        }
        if (e.deltaY === 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
        });
      };

      el.addEventListener('wheel', onWheel);
      el.addEventListener('mousemove', move);
      el.addEventListener('mousedown', start);
      el.addEventListener('mouseup', stop);
      el.addEventListener('mouseleave', stop);
      el.addEventListener('click', (e: MouseEvent) => {
        if (
          e.currentTarget instanceof Element &&
          e.currentTarget.clientWidth >= e.currentTarget.scrollWidth
        ) {
          return;
        }
        e.stopPropagation();
      });
      return () => {
        el.removeEventListener('wheel', onWheel);
        el.removeEventListener('mousemove', move);
        el.removeEventListener('mousedown', start);
        el.removeEventListener('mouseup', stop);
        el.removeEventListener('mouseleave', stop);
        el.removeEventListener('click', (e: MouseEvent) => {
          if (
            e.currentTarget instanceof Element &&
            e.currentTarget.clientWidth >= e.currentTarget.scrollWidth
          ) {
            return;
          }
          e.stopPropagation();
        });
      };
    }
  }, []);
  return elRef;
};

import { useState } from 'react';

import { useLocalStorage } from '@uidotdev/usehooks';
import { Plus } from 'lucide-react';

import { Button } from 'shared/ui/Button';
import { Checkbox } from 'shared/ui/Checkbox';
import { Modal } from 'shared/ui/Modal';

import type { Member } from '../types';

type Props = {
  initialMemberIds: string[];
  isOpen: boolean;
  onCreate?: () => void;
  onOpenChange: (isOpen: boolean) => void;
  onSelect: (members: Member[]) => void;
};

export const ExistingMembersModal = ({
  initialMemberIds = [],
  isOpen,
  onCreate,
  onOpenChange,
  onSelect,
}: Props) => {
  const [members] = useLocalStorage<Member[]>('dai-members', []);

  const [selected, setSelected] = useState<Member[]>([]);
  const membersToShow = members.filter(({ id }) => !initialMemberIds.includes(id));

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content>
        <Modal.CloseButton />

        <h1 className="mb-1 text-xl font-semibold">Add Existing Member</h1>

        <p className="mb-4 text-sm font-light text-clay-500">Select multiple members if needed</p>

        <div className="flex flex-col gap-3">
          {membersToShow.length > 0 ? (
            membersToShow.map((member) => {
              const isSelected = !!selected.find((item) => item.id === member.id);
              return (
                <div key={member.id}>
                  <Checkbox.CheckboxWrapper className="flex items-center">
                    <Checkbox.Control
                      checked={isSelected || false}
                      onChange={() =>
                        setSelected(
                          isSelected
                            ? selected.filter((item) => item.id !== member.id)
                            : [...selected, member],
                        )
                      }
                    />
                    <Checkbox.CheckboxLabel className="flex items-center">
                      {' '}
                      <div className="mr-4 size-8 overflow-hidden rounded-full bg-clay-20">
                        {!!member.avatar && member.avatar.startsWith('https://') && (
                          <img className="size-full object-cover" src={member.avatar} />
                        )}
                      </div>
                      {member.name}{' '}
                      <span className="ml-3 font-light italic text-clay-400">{member.title}</span>
                    </Checkbox.CheckboxLabel>
                  </Checkbox.CheckboxWrapper>
                </div>
              );
            })
          ) : (
            <div className="flex items-center justify-center py-6 text-center text-sm font-light text-clay-400">
              You have no members created yet
            </div>
          )}
        </div>

        <div className="mt-6 flex items-center gap-3">
          <Button color="secondary" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button disabled={!selected.length} onClick={() => onSelect(selected)}>
            Add
          </Button>

          <Button className="ml-auto" color="blue" onClick={() => onCreate?.()} variant="filled-light">
            <Plus className="size-4" /> Create New{' '}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CodesandboxIcon, Settings, Trash2 } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

import type { DAI } from 'shared/api/dai/types';
import type { ClassName } from 'shared/types';

import { useUser } from 'app/stores/user';
import { useGetUserDAIsQuery } from 'shared/api/dai/useGetUserDAIsQuery';
import { Button } from 'shared/ui/Button';
import { Spinner } from 'shared/ui/Spinner';

import { DeleteConfirmModal } from './DeleteConfirmModal';

type Props = {
  onEdit: (dai: DAI) => void;
  onStart: () => void;
} & ClassName;

export const Initial = ({ className, onEdit, onStart }: Props) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const userDais = useGetUserDAIsQuery(
    { limit: 100, skip: 0, userId: user?._id || '' },
    { enabled: !!user?._id },
  );

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [daiToDelete, setDaiToDelete] = useState<DAI>();
  const daiToEdit = useRef('');

  return (
    <>
      <div
        className={twMerge(
          'mx-auto mt-8 flex w-full max-w-lg flex-col rounded-2xl border border-clay-20 bg-white p-10 shadow-lg',
          className,
        )}
      >
        <div className="mx-auto mb-6 flex size-16 items-center justify-center rounded-full border border-clay-20 bg-white shadow-md">
          <CodesandboxIcon className="size-6" />
        </div>

        <div className="mb-8 flex w-full min-w-full max-w-2xl flex-col items-center gap-0.5">
          <div className="text-center text-3xl font-semibold">Create a New DAI</div>
          <div className="text-center text-base font-light text-clay-400">
            A decentralized AI project or protocol.
          </div>
        </div>

        <Button className="w-full text-base font-medium" onClick={onStart} size="medium">
          Start
        </Button>
      </div>

      <div className="mx-auto mt-8 flex w-full max-w-lg flex-col gap-0.5">
        <div className="text-sm font-normal text-clay-400">My Recent DAI</div>
        {userDais.isPending && (
          <div className="flex items-center justify-center py-5">
            <Spinner className="size-5" />
          </div>
        )}
        {userDais.isSuccess && !userDais.data?.list.length && (
          <div className="py-5 text-center text-sm font-light text-clay-350">No recent DAIs</div>
        )}
        {userDais.isSuccess && (userDais.data.list || []).length > 0 && (
          <div className="mt-3 flex max-h-96 flex-col gap-1 overflow-scroll">
            {userDais.data.list.map((dai) => {
              return (
                <div
                  className=" flex cursor-pointer items-center justify-between rounded-lg border border-transparent bg-white px-3 py-4 transition-colors hover:border-clay-20"
                  key={dai._id}
                  onClick={() => navigate(`/dai/${dai._id}`)}
                >
                  {dai.projectName}

                  <div className="flex items-center justify-end gap-2">
                    <div className="rounded-lg bg-green-10 px-2 py-1 text-[10px] font-medium uppercase text-green-600">
                      created
                    </div>

                    <Button
                      className="px-2"
                      color="white"
                      onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        daiToEdit.current = dai._id;

                        onEdit(dai);

                        daiToEdit.current = '';
                      }}
                      size="extra-small"
                    >
                      <Settings className="size-4" />
                    </Button>

                    <Button
                      className="px-2 "
                      color="red"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        setDaiToDelete(dai);

                        setIsDeleteOpen(true);
                      }}
                      size="extra-small"
                    >
                      <Trash2 className="size-4 text-red-900" />
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {daiToDelete && (
        <DeleteConfirmModal dai={daiToDelete} isOpen={isDeleteOpen} onOpenChange={setIsDeleteOpen} />
      )}
    </>
  );
};

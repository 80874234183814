import { DateTime } from 'luxon';
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts';

import { useGetRequestsChartQuery } from 'shared/api/dai/useGetRequestsChartQuery';
import { Spinner } from 'shared/ui/Spinner';

type Props = {
  isLaunched?: boolean;
  walletAddresses: string[];
};

const mockData = [
  {
    amt: 2400,
    name: 'Page A',
    requests: 1000,
    uv: 1000,
  },
  {
    amt: 2210,
    name: 'Page B',
    requests: 1398,
    uv: 3000,
  },
  {
    amt: 2290,
    name: 'Page C',
    requests: 1200,
    uv: 2000,
  },
  {
    amt: 2000,
    name: 'Page D',
    requests: 3908,
    uv: 2780,
  },
  {
    amt: 2181,
    name: 'Page E',
    requests: 4800,
    uv: 1890,
  },
  {
    amt: 2500,
    name: 'Page F',
    requests: 3800,
    uv: 2390,
  },
  {
    amt: 2100,
    name: 'Page G',
    requests: 4300,
    uv: 3490,
  },
  {
    amt: 2000,
    name: 'Page D',
    requests: 3908,
    uv: 2780,
  },
  {
    amt: 2181,
    name: 'Page E',
    requests: 4800,
    uv: 1890,
  },
  {
    amt: 2500,
    name: 'Page F',
    requests: 3800,
    uv: 2390,
  },
  {
    amt: 2100,
    name: 'Page G',
    requests: 5000,
    uv: 3490,
  },
  {
    amt: 2181,
    name: 'Page E',
    requests: 5300,
    uv: 1890,
  },
  {
    amt: 2500,
    name: 'Page F',
    requests: 5400,
    uv: 2590,
  },
  {
    amt: 2100,
    name: 'Page G',
    requests: 8000,
    uv: 3490,
  },
];
export const ChainChart = ({ isLaunched, walletAddresses = [] }: Props) => {
  const { data: chartData, isPending } = useGetRequestsChartQuery(
    { walletAddresses },
    { enabled: walletAddresses.length > 0 },
  );

  if (isPending) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner className="size-6" />
      </div>
    );
  }

  const data = isLaunched === false ? mockData : chartData?.list || [];

  return (
    <ResponsiveContainer height="100%" width="100%">
      <AreaChart
        data={data}
        height={300}
        margin={{
          bottom: 5,
          left: 5,
          right: 5,
          top: 5,
        }}
        width={500}
      >
        <Tooltip
          content={({ active, payload }) => {
            if (!active) return null;

            return (
              <div className="flex max-w-56 flex-col rounded-lg bg-clay-800 p-3 text-white">
                <span className="text-lg">
                  {payload?.[0]?.value?.toLocaleString(undefined, { maximumFractionDigits: 2 })} requests
                </span>

                <span className="text-sm text-[#B7C0CE]">
                  {DateTime.fromISO(payload?.[0]?.payload?.date).toLocaleString(DateTime.DATE_MED)}
                </span>
              </div>
            );
          }}
          cursor={false}
          wrapperStyle={{ outline: 'none' }}
        />
        <defs>
          <linearGradient id="colorPrimary" x1="0" x2="0" y1="0" y2="1">
            <stop offset="5%" stopColor="#8356E2" stopOpacity={0.6} />
            <stop offset="95%" stopColor="#8356E2" stopOpacity={0} />
          </linearGradient>
        </defs>

        <Area
          dataKey="requests"
          dot={false}
          fill="url(#colorPrimary)"
          fillOpacity={1}
          stroke="#7A4AE1"
          strokeWidth={1}
          type="linear"
        />

        {/*
        <Line dataKey="requests" dot={false} stroke="#7A4AE1" strokeWidth={1} type="linear" /> */}
      </AreaChart>
    </ResponsiveContainer>
  );
};

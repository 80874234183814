import { FileBox } from 'lucide-react';

import type { DatasetSort } from 'shared/api/datasets/types';

import { Button } from 'shared/ui/Button';
import { Select } from 'shared/ui/Select';

const sortOptions: { label: string; value: DatasetSort }[] = [
  { label: 'Trending', value: 'trending' },
  { label: 'Most likes', value: 'likes' },
  // { label: 'Most downloads', value: 'downloads' },
  { label: 'Recently created', value: 'created' },
  { label: 'Recently updated', value: 'modified' },
  { label: 'Most rows', value: 'most_rows' },
  { label: 'Least rows', value: 'least_rows' },
];

type Props = {
  onCreateDataset: () => void;
  onSortChange: (sort: DatasetSort) => void;
  sort: DatasetSort;
};

export const DatasetFilters = ({ onCreateDataset, onSortChange, sort }: Props) => {
  return (
    <div className="ml-auto flex w-fit items-center gap-1 rounded-xl bg-clay-20 p-1">
      <Select
        className="h-9 w-fit rounded-lg bg-gray-100 p-3 text-sm inner-border-0"
        iconClassName="mr-1"
        iconName="arrowDownUp"
        onValueChange={(val) => onSortChange(val as DatasetSort)}
        placeholder="Sort by"
        placeholderClassName="font-medium text-clay-600"
        value={sort}
      >
        <Select.Content className="z-10 p-1">
          <div className="mb-2 w-48 border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-medium text-blue-800">
            Sort by
          </div>

          {sortOptions.map((item) => {
            return (
              <Select.Item
                className="text-sm text-clay-700 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                key={item.value}
                value={item.value}
              >
                {item.label}
              </Select.Item>
            );
          })}
        </Select.Content>
      </Select>

      <Button className="h-9 w-fit pl-2.5" color="dark" onClick={onCreateDataset}>
        {/* <Icon className="mr-1 size-3 text-white" name="" /> */}
        <FileBox className="mr-0.5 size-4 text-white" />
        Upload Dataset
      </Button>
    </div>
  );
};

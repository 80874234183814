import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { motion } from 'framer-motion';

import { BackButton } from 'app/ui/BackButton';
import { useDAIByIdQuery } from 'shared/api/dai/useDAIByIdQuery';
import { useNavigateToTop } from 'shared/hooks/navigate';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Spinner } from 'shared/ui/Spinner';
import { TextArea } from 'shared/ui/TextArea';
import { TradingView } from 'widgets/TradingView';

import { DAIContent } from './ui/DAIContent';
import { TradeModal } from './ui/TradeModal';

export const DAI = () => {
  const navigate = useNavigateToTop();

  const { id = '' } = useParams<{ id: string }>();

  const { data, isPending, isSuccess } = useDAIByIdQuery({ id }, { enabled: !!id });

  const dai = data?.data;

  const [isTradeExpanded, setIsTradeExpanded] = useState(false);
  // const [isStakingOpen, setIsStakingOpen] = useState(false);
  // const [isTeamMemberOpen, setIsTeamMemberOpen] = useState(false);

  return (
    <AnimateRoute className="rounded-xl bg-clay-20 p-2">
      <div className="overflow-hidden rounded-xl bg-white">
        <div className="grid gap-4 xl:grid-cols-4">
          <div className="relative col-span-1 flex max-w-full flex-col overflow-hidden border-r border-clay-20 xl:col-span-3">
            <BackButton
              className="absolute left-4 top-4 z-30 text-sm font-normal text-clay-400"
              onClick={() => navigate('/')}
            >
              Back to DAI
            </BackButton>

            {isPending && (
              <div className="flex size-full grow items-center justify-center">
                <Spinner className="size-7" />
              </div>
            )}

            {isSuccess && dai && <DAIContent dai={dai} />}
          </div>

          {/* right side */}
          <div className="col-span-1 px-4 py-6">
            <div className="relative flex flex-col">
              <header className="flex items-center justify-between">
                <div className="text-2xl font-medium">
                  Trade {dai?.token?.ticker ? `$${dai?.token?.ticker}` : ''}
                </div>

                <motion.button
                  animate="rest"
                  className=" flex h-7 min-w-7 items-center justify-center gap-1.5 rounded-md bg-clay-20 pl-1 pr-3 text-sm text-clay-400 outline-none"
                  initial="rest"
                  onClick={() => setIsTradeExpanded(true)}
                  whileHover="hover"
                >
                  <Icon className="size-4 min-w-4" name="expand" />
                  Trade view
                </motion.button>
              </header>

              <div className="mb-8 mt-4 h-80 overflow-hidden">
                <TradingView allowSymbolChange={false} hideLegend hideTopToolbar hideVolume />
              </div>

              <div className="absolute inset-0 flex items-center justify-center bg-white/20 backdrop-blur-[1.2px]">
                <div className="flex flex-col items-center justify-center gap-1 rounded-xl bg-clay-10 px-3 py-3.5 text-center">
                  <h1 className="text-center text-lg/none font-medium">Swap disabled</h1>
                  <p className="text-center text-sm/none font-light text-clay-400">
                    This project has no coin to trade yet
                  </p>
                </div>
              </div>
            </div>

            <div className="relative">
              <TextArea disabled placeholder="Write Message here" rows={4} />
              <Button className="absolute bottom-6 right-2 px-2.5" disabled>
                <Icon name="send" />
              </Button>
            </div>

            {/* <div className="flex flex-col">
              <CommentCard
                author="test"
                className="border-b border-clay-20 py-4"
                commentsCount={10}
                reactions={{}}
                text="As you may have probably heard, in the past weeks three Tech Giants (Microsoft, Amazon and Google) announced that they would bet on nuclear reactors to feed the surging energy demand of data centers."
              />
            </div> */}
          </div>
        </div>
      </div>
      <TradeModal isOpen={isTradeExpanded} onOpenChange={setIsTradeExpanded} />

      {/* <StakingModal isOpen={isStakingOpen} onOpenChange={setIsStakingOpen} />

      <TeamMemberModel isOpen={isTeamMemberOpen} onOpenChange={setIsTeamMemberOpen} /> */}
    </AnimateRoute>
  );
};

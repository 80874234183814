import type { ComponentPropsWithoutRef, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

type Props = {
  description: string;
  disabled?: boolean;
  icon: ReactNode;
  title: string;
} & ComponentPropsWithoutRef<'div'>;

export const TrainCard = ({ className, description, disabled, icon, title, ...props }: Props) => {
  return (
    <div
      {...props}
      className={twMerge(
        'rounded-xl border border-clay-20 bg-white p-5 shadow-checkbox duration-200',
        disabled && 'border-clay-10 bg-clay-10',
        !disabled && 'cursor-pointer hover:bg-clay-20',
        className,
      )}
    >
      <div className="mb-4">{icon}</div>
      <h6 className="mb-2 text-xl font-semibold text-clay-900">{title}</h6>
      <p className="text-base text-clay-500">{description}</p>
    </div>
  );
};

import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { ContentLoader } from 'shared/ui/ContentLoader';
import { Icon } from 'shared/ui/Icon';

type Props = {
  coinName?: string;
  imageSrc?: string;
  isLoading?: boolean;
  price?: string;
  title?: string;
  userImageSrc?: string;
  userName?: string;
} & ClassName;
export const DoxxedTeamCard = ({
  className,
  coinName,
  imageSrc,
  isLoading,
  price,
  title,
  userImageSrc,
  userName,
}: Props) => {
  return (
    <div
      className={twMerge(
        'flex flex-col gap-0 rounded-xl border border-clay-20 bg-white pb-2 pt-4',
        className,
      )}
    >
      <div className="flex flex-col gap-3 border-b border-clay-20 px-4">
        <div className="flex items-center gap-2">
          <div className="relative flex size-6 items-center justify-center overflow-hidden rounded-full bg-clay-900 p-1">
            {isLoading ? (
              <ContentLoader className="absolute left-0 top-0" height="100%" width="100%">
                <rect height="100%" width="100%" x="0" y="0" />
              </ContentLoader>
            ) : (
              <Icon className="size-full text-tusk-200" name="logo" />
            )}
          </div>

          <div className="relative min-h-5 flex-1 text-sm font-semibold uppercase">
            {isLoading ? (
              <ContentLoader className="absolute left-0 top-1/2 -translate-y-1/2" height="100%" width="100%">
                <rect height="14" rx={8} ry={8} width="100%" x="0" y="0" />
              </ContentLoader>
            ) : (
              coinName
            )}
          </div>
        </div>

        <div className="flex items-start gap-3">
          <div
            className="relative size-9 min-w-9 overflow-hidden rounded-lg bg-clay-20 bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: imageSrc ? `url(${imageSrc})` : undefined }}
          >
            {isLoading && (
              <ContentLoader className="absolute left-0 top-0" height="100%" width="100%">
                <rect height="100%" width="100%" x="0" y="0" />
              </ContentLoader>
            )}
          </div>

          <div className="relative min-h-6 flex-1 text-xl/6 font-semibold">
            {isLoading ? (
              <ContentLoader className="absolute left-0 top-1/2 -translate-y-1/2" height="100%" width="100%">
                <rect height="20" rx={8} ry={8} width="100%" x="0" y="0" />
              </ContentLoader>
            ) : (
              title
            )}
          </div>
        </div>

        <div className="flex items-center gap-3 pb-4">
          <div
            className="relative size-5 overflow-hidden rounded-full bg-clay-20 bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: userImageSrc ? `url(${userImageSrc})` : undefined }}
          >
            {isLoading && (
              <ContentLoader className="absolute left-0 top-0" height="100%" width="100%">
                <rect height="100%" width="100%" x="0" y="0" />
              </ContentLoader>
            )}
          </div>
          <div className="relative min-h-5 flex-1 text-sm font-light">
            {isLoading ? (
              <ContentLoader className="absolute left-0 top-1/2 -translate-y-1/2" height="12" width="100%">
                <rect height="12" rx={8} ry={8} width="100%" x="0" y="0" />
              </ContentLoader>
            ) : (
              userName
            )}
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between px-4 pt-1">
        <div className="text-xs font-light">TVL</div>
        <div className="relative min-h-7 min-w-7 text-xl font-semibold tracking-tight">
          {isLoading ? (
            <ContentLoader className="absolute left-0 top-1/2 -translate-y-1/2" height="16" width="100%">
              <rect height="16" rx={8} ry={8} width="100%" x="0" y="0" />
            </ContentLoader>
          ) : (
            `$${price}`
          )}
        </div>
      </div>
    </div>
  );
};

import { useNavigate } from 'react-router-dom';

import { BackButton } from 'app/ui/BackButton';
import { StretchedSkeleton } from 'shared/ui/StretchedSkeleton';

export const PageSkeleton = () => {
  const navigate = useNavigate();

  return (
    <div className="relative flex size-full flex-col gap-4 px-2 py-4 xs:p-4 ">
      {/* header start */}
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-5">
        <div className="relative flex min-h-20 w-full max-w-[400px] flex-col gap-4 lg:col-span-3">
          <BackButton
            className="gap-2 text-base font-normal text-clay-350 hover:text-clay-500"
            onClick={() => navigate('/models')}
          >
            Back to Gallery
          </BackButton>

          {/* left card */}
          <div className="grid grid-cols-[3.5rem_1fr] gap-4">
            {/* image */}
            <div className="relative size-14">
              <StretchedSkeleton enable rx={8} ry={8} />
            </div>
            <div className="grid grid-cols-1 gap-0.5">
              <StretchedSkeleton className="relative mb-2 h-6" enable rx={4} ry={4} />
              <StretchedSkeleton className="relative mb-3 h-4" enable rx={4} ry={4} />

              <div className="flex w-full flex-wrap items-center gap-3 lg:gap-4">
                <StretchedSkeleton className="relative h-5 max-w-16" enable rx={4} ry={4} />
                <StretchedSkeleton className="relative h-5 max-w-16" enable rx={4} ry={4} />
                <StretchedSkeleton className="relative h-5 max-w-20" enable rx={4} ry={4} />
                <StretchedSkeleton className="relative h-5 max-w-20" enable rx={4} ry={4} />
              </div>
            </div>
          </div>
        </div>
        <div className="relative grid h-32 grid-cols-1 gap-1 rounded-2xl bg-white p-1.5 lg:col-span-2 lg:h-36 lg:grid-cols-5 lg:gap-2 lg:p-1.5">
          <div className="col-span-3 flex flex-col gap-3 p-1 lg:p-3">
            <div className="flex items-center gap-2 lg:gap-3">
              <StretchedSkeleton
                className="relative size-3 max-w-3 rounded-full lg:size-5 lg:max-w-5"
                enable
                rx={12}
                ry={12}
              />
              <StretchedSkeleton className="relative h-3 w-full lg:h-5" enable />
            </div>

            <div className="mt-auto flex flex-col gap-1 lg:gap-3">
              <div className="flex items-center gap-2">
                <StretchedSkeleton className="relative size-2 max-w-2" enable />
                <StretchedSkeleton className="relative max-h-2 max-w-16" enable />
              </div>
              <StretchedSkeleton className="relative max-h-5 w-full lg:max-h-10" enable />
            </div>
          </div>
          <div className="col-span-2 flex flex-col gap-1 rounded-md bg-clay-10 p-2 lg:gap-2 lg:rounded-lg lg:p-4">
            <StretchedSkeleton
              className="relative mt-0 hidden max-h-2 max-w-14 lg:mt-auto lg:flex lg:max-h-3"
              enable
            />
            <StretchedSkeleton className="relative max-h-4 w-full" enable />
          </div>
        </div>
      </div>
      {/* header end */}

      {/* content */}
      <div className="relative flex flex-1 flex-col rounded-2xl bg-clay-20 px-4 py-2">
        {/* navigation buttons */}
        <div className="relative mb-3 flex h-32 w-full flex-col-reverse items-center justify-between md:h-10 md:flex-row">
          <div className="flex size-full flex-col items-center gap-2 md:flex-row md:gap-4">
            <StretchedSkeleton className="relative max-h-6 w-full md:max-w-40" enable rx={8} ry={8} />
            <StretchedSkeleton className="relative max-h-6 w-full md:max-w-40" enable rx={8} ry={8} />
            <StretchedSkeleton className="relative max-h-6 w-full md:max-w-40" enable rx={8} ry={8} />
          </div>
          <div className="flex h-full items-center gap-4">
            <StretchedSkeleton className="relative h-6 max-w-36" enable rx={8} ry={8} />
            <StretchedSkeleton className="relative h-6 max-w-36" enable rx={8} ry={8} />
            <StretchedSkeleton className="relative h-6 max-w-36" enable rx={8} ry={8} />
            <StretchedSkeleton className="relative h-6 max-w-36" enable rx={8} ry={8} />
          </div>
        </div>

        <div className="grid w-full flex-1 grid-cols-1 gap-4 rounded-lg md:grid-cols-2">
          <div className="flex w-full flex-col rounded-lg bg-white p-4">
            <StretchedSkeleton className=" relative mt-auto max-h-4" enable rx={8} ry={8} />

            <div className="flex flex-1 flex-col items-center justify-center gap-6">
              <StretchedSkeleton className="relative mt-auto max-h-12 max-w-12" enable rx={8} ry={8} />
              <StretchedSkeleton className="relative mb-auto max-h-5 max-w-40" enable rx={8} ry={8} />
            </div>
          </div>
          <div className="flex w-full flex-col gap-4">
            <div className="rounded-lg bg-white p-4 pb-20">
              <StretchedSkeleton className="relative mt-auto max-h-4 max-w-20" enable rx={8} ry={8} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

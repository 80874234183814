import type { Dispatch, PropsWithChildren, ReactNode, SetStateAction } from 'react';
import { createContext, useContext, useId, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { twJoin } from 'tailwind-merge';

type Props = {
  children: ReactNode;
  title: string;
};

const AccordionContext = createContext<[null | string, Dispatch<SetStateAction<null | string>>] | null>(null);

export const AccordionProvider = ({ children }: PropsWithChildren) => {
  const state = useState<null | string>(null);

  return <AccordionContext.Provider value={state}>{children}</AccordionContext.Provider>;
};

const useAccordionContext = () => {
  const state = useContext(AccordionContext);

  if (!state) throw Error('use useAccordionContext without provider');

  return state;
};

export const Accordion = ({ children, title }: Props) => {
  const [openId, setOpenId] = useAccordionContext();

  const id = useId();

  const isOpen = id === openId;

  return (
    <div className="w-full border-b border-clay-30 bg-transparent px-10">
      <header
        className="flex cursor-pointer items-center gap-6 py-6 text-clay-500 duration-200 hover:text-clay-900"
        onClick={() => setOpenId(isOpen ? null : id)}
      >
        <h4 className="flex-1 text-xl/6 font-medium">{title}</h4>
        <div className="relative size-5">
          <div
            className={twJoin(
              'absolute left-0 top-1/2 h-px w-5 -translate-y-1/2 -rotate-90 rounded-full bg-clay-900 duration-200',
              isOpen && 'rotate-0',
            )}
          />
          <div
            className={twJoin(
              'absolute left-0 top-1/2 h-px w-5 -translate-y-1/2 rounded-full bg-clay-900 duration-200',
              isOpen && 'rotate-180',
            )}
          />
        </div>
      </header>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            animate={{
              height: 'auto',
              opacity: 1,
            }}
            exit={{ height: 0, opacity: 0 }}
            initial={{ height: 0, opacity: 0 }}
          >
            <div className="pb-6">{children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

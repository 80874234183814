import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { daiKeys } from './queryKeys';

type Response = {
  list: {
    _id: string;
    cover: string;
    projectName: string;
  }[];
};

type Params = {
  ids: string[];
};

export const useGetDAISummaryListQuery = (params: Params, options: UseQueryOptions<Response> = {}) => {
  return useQuery({
    queryFn: async () => {
      const { data } = await axiosInstance.get<Response>('/dai/summary', { params: params });

      return data;
    },
    queryKey: daiKeys.summary(params),
    ...options,
  });
};

import { twMerge } from 'tailwind-merge';

import type { TaskCategory } from 'shared/api/datasets/types';

import { useGetDatasetsTagsByType } from 'shared/api/datasets/useGetDatasetsTagsByType';
import { Spinner } from 'shared/ui/Spinner';

import type { FiltersProps } from './MainFilters';

type Props = {
  filters: FiltersProps['filters'];
  onChange: FiltersProps['onChange'];
};

const getTitleByKey = (key: string) => {
  if (key === 'nlp') return 'Natural Language Processing';
  if (key === 'audio') return 'Audio';
  if (key === 'tabular') return 'Tabular';
  if (key === 'rl') return 'Reinforcement Learning';
  if (key === 'multimodal') return 'Multimodal';
  if (key === 'cv') return 'Computer Vision';
  if (key === 'other') return 'Other';

  return key;
};

export const TasksFilters = ({ filters, onChange }: Props) => {
  // const [search, setSearch] = useState('');
  const { data, isPending } = useGetDatasetsTagsByType({ type: 'task_categories' });

  const combinedBySubType = data?.task_categories?.reduce<Record<string, TaskCategory[]>>(
    (acc, taskCategory) => {
      acc[taskCategory.subType] = [...(acc[taskCategory.subType] || []), taskCategory];

      return acc;
    },
    {},
  );

  const hasData = data?.task_categories && data?.task_categories?.length > 0;

  return (
    <div>
      {isPending && (
        <div className="flex flex-col items-center justify-center pb-10">
          <Spinner className="size-4" />
        </div>
      )}

      {hasData && (
        <>
          {/* <div className="mb-6 flex flex-col">
            <Input
              className="w-full"
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Filter Tasks by Type"
              startSlot={<Icon className="size-3 text-clay-600" name="search" />}
              value={search}
            />
          </div> */}
          <div className="flex flex-col gap-5">
            {combinedBySubType &&
              Object.entries(combinedBySubType).map(([key, items]) => {
                const filtered = items;

                if (filtered.length === 0) return null;

                return (
                  <div className="flex flex-col gap-3" key={key}>
                    <div className="text-sm font-medium text-clay-700">{getTitleByKey(key)}</div>

                    <div className="flex flex-wrap items-center gap-2">
                      {filtered.map((item) => {
                        const isSelected = filters.tasks?.includes(item.id);
                        return (
                          <div
                            className={twMerge(
                              'cursor-pointer select-none rounded-lg border border-clay-20 bg-transparent px-2 py-1.5 text-sm/none text-clay-500 transition-colors hover:bg-primary-40',
                              isSelected &&
                                'border-primary-800 bg-primary-800 text-white hover:bg-primary-900',
                            )}
                            key={item.id}
                            onClick={() => onChange({ tasks: isSelected ? [] : [item.id] })}
                          >
                            {item.label}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useDebounce, useIntersectionObserver } from '@uidotdev/usehooks';
import { twMerge } from 'tailwind-merge';

import { getTvl } from 'pages/DAI/helpers/getTvl';
import { useDAIListInfiniteQuery } from 'shared/api/dai/useDAIListInfiniteQuery';
import { DaiRow } from 'shared/ui/DaiRow/DaiRow';
import { HotDaiCard } from 'shared/ui/HotDaiCard/HotDaiCard';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Select } from 'shared/ui/Select';
import { Spinner } from 'shared/ui/Spinner';

type ViewType = 'grid' | 'row';

type Props = {
  defaultViewType?: ViewType;
  withSearchInput?: boolean;
};

type DAISort = 'newest' | 'oldest';

export const ExploreDAI = ({ defaultViewType = 'grid', withSearchInput }: Props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [viewType, setViewType] = useState<ViewType>(defaultViewType);

  const sort = (searchParams.get('sort') || 'newest') as DAISort;
  const searchValue = searchParams.get('search') || '';
  const category = searchParams.get('category') || undefined;

  const debounceValue = useDebounce(searchValue, 200);

  const onSearchParamsChange = (name: string, value: string) => {
    setSearchParams((prev) => {
      const newSearch = new URLSearchParams(prev);
      if (value) {
        newSearch.set(name, value);
      } else {
        newSearch.delete(name);
      }
      return newSearch;
    });
  };

  const {
    data: daiList,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPending: isListLoading,
    isSuccess,
  } = useDAIListInfiniteQuery({ category: category, limit: 12, search: debounceValue, sort });

  const [ref, intersection] = useIntersectionObserver<HTMLDivElement>();

  const list = daiList?.pages.flat() || [];

  useEffect(() => {
    if (intersection?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, intersection?.isIntersecting]);

  return (
    <div className="flex flex-col gap-6 overflow-hidden">
      <header className="flex flex-col justify-between gap-2 sm:flex-row sm:items-center">
        {withSearchInput ? (
          <Input
            className="max-w-2xl"
            classNameInputWrapper="bg-clay-10"
            onChange={(e) => onSearchParamsChange('search', e.target.value)}
            placeholder="Search for an Agent"
            size="medium"
            startSlot={
              <Icon
                className="size-4 text-clay-900 transition-colors group-focus-within:text-clay-600 sm:size-4 sm:text-clay-300"
                name="search"
              />
            }
            value={searchValue}
          />
        ) : (
          <div className="text-3xl font-medium tracking-tighter">More DAI</div>
        )}

        <div className="right-4 box-border flex h-12 max-w-fit items-center gap-1 rounded-xl bg-blue-50 p-1">
          <Select
            className="h-10 w-fit rounded-lg bg-gray-100 p-3 text-sm inner-border-0"
            iconName="arrowDownUp"
            onValueChange={(val) => onSearchParamsChange('sort', val)}
            placeholder="Sort by"
            placeholderClassName="font-medium text-clay-600"
            value={sort}
          >
            <Select.Content className="z-10 rounded-lg p-1 shadow-sm">
              <div className="mb-2 w-48 border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-medium text-blue-800">
                Sort by
              </div>

              <Select.Item className="text-sm text-clay-900 hover:bg-clay-10" value="newest">
                Newest
              </Select.Item>

              <Select.Item className="text-sm text-clay-900 hover:bg-clay-10" value="oldest">
                Oldest
              </Select.Item>
            </Select.Content>
          </Select>

          <div
            className={twMerge(
              'flex size-10 cursor-pointer items-center justify-center rounded-lg text-clay-500 transition-colors hover:bg-white',
              viewType === 'grid' && 'bg-white text-clay-800',
            )}
            onClick={() => setViewType('grid')}
          >
            <Icon className="size-4" name="grid" />
          </div>
          <div
            className={twMerge(
              'flex size-10 cursor-pointer items-center justify-center rounded-lg text-clay-500 transition-colors hover:bg-white',
              viewType === 'row' && 'bg-white text-clay-800',
            )}
            onClick={() => setViewType('row')}
          >
            <Icon className="size-4" name="list" />
          </div>
        </div>
      </header>

      {isSuccess && list.length === 0 && (
        <div className="flex items-center justify-center pt-8 text-center text-sm text-clay-500">No data</div>
      )}

      {isSuccess && list.length > 0 && (
        <>
          <div
            className={twMerge(
              'relative grid grid-cols-1 gap-0',
              viewType === 'grid' && 'grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3',
              viewType === 'row' && 'w-full overflow-x-scroll',
            )}
          >
            {list.map((dai) => {
              const founder = dai.members[0];
              if (viewType === 'row') {
                return (
                  <DaiRow
                    className="min-w-[512px] cursor-pointer border-b border-clay-20 py-4 transition-colors last:border-none hover:bg-clay-10"
                    coinImageSrc={dai.token?.image}
                    coinName={dai.token?.ticker}
                    description={dai.shortDescription}
                    key={`row-${dai._id}`}
                    mediaSrc={dai.profileImage}
                    // mediaSrc={dai.cover}
                    onClick={() => navigate(`/dai/${dai._id}`)}
                    participants={dai.members.map((member) => ({
                      avatar: member.avatar,
                      username: member.name,
                    }))}
                    tags={[dai.projectCategory]}
                    title={dai.projectName}
                    tvl={getTvl(dai.tvl)}
                    userImageSrc={founder?.avatar}
                    userName={founder?.name}
                  />
                );
              }
              return (
                <HotDaiCard
                  className="h-full"
                  coinImageSrc={dai.token?.image}
                  coinName={dai.token?.ticker}
                  description={dai.shortDescription}
                  key={dai._id}
                  mediaSrc={dai.profileImage}
                  onClick={() => navigate(`/dai/${dai._id}`)}
                  showMediaNearTitle
                  tags={[dai.projectCategory]}
                  title={dai.projectName}
                  tvl={getTvl(dai.tvl)}
                  userImageSrc={founder?.avatar}
                  userName={founder?.name || ''}
                ></HotDaiCard>
              );
            })}
          </div>

          <div ref={ref} />
        </>
      )}

      {(isListLoading || isFetchingNextPage) && (
        <div className="flex items-center justify-center py-8">
          <Spinner className="size-5" />
        </div>
      )}
    </div>
  );
};

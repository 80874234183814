import { useEffect, useRef, useState } from 'react';

export const TruncateMiddle = ({ largeString }: { largeString: string }) => {
  const [truncatedLargeString, setTruncatedLargeString] = useState(largeString);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateTruncatedLargeString = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const approximateCharWidth = 8;
        const maxChars = Math.floor(containerWidth / approximateCharWidth);

        if (largeString.length > maxChars) {
          const charsToKeep =
            Math.floor(maxChars / 2) > 6 ? Math.floor(maxChars / 2) - 4 : Math.floor(maxChars / 2) - 1;
          setTruncatedLargeString(
            `${largeString.slice(0, charsToKeep)}....${largeString.slice(largeString.length - charsToKeep)}`,
          );
        } else {
          setTruncatedLargeString(largeString);
        }
      }
    };

    updateTruncatedLargeString();
    window.addEventListener('resize', updateTruncatedLargeString);

    return () => {
      window.removeEventListener('resize', updateTruncatedLargeString);
    };
  }, [largeString]);

  return (
    <div className="w-full" ref={containerRef}>
      {truncatedLargeString}
    </div>
  );
};

import type { NodeChatPeriod } from './types';

export const nodesKeys = {
  base: ['nodes'],
  chart: (nodeId: string, type: NodeChatPeriod) => [...nodesKeys.base, 'node-chart', nodeId, 'period', type],
  node: (nodeId: string) => [...nodesKeys.base, 'node', nodeId],
  nodeStat: (nodeId: string) => [...nodesKeys.base, 'node', 'stat-general', nodeId],
  nodesByPublicKey: (params: { limit: number; publicKey: string; skip?: number }) => [
    ...nodesKeys.base,
    'node-list',
    'public-key',
    params,
  ],
};

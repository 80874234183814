import type { DropzoneOptions } from 'react-dropzone';

import { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { twMerge } from 'tailwind-merge';

import { Button } from '../Button';

type TProps = {
  dropzoneProps: DropzoneOptions;
  filePreview: React.ReactNode;
  icon?: React.ReactNode;
  onChange: (acceptedFiles: File[]) => void;
  subtitle?: React.ReactNode;
  title: React.ReactNode;
  values: File[] | undefined;
};

export const FileUploader = ({
  dropzoneProps,
  filePreview,
  icon,
  onChange,
  subtitle,
  title = <div className="font-medium tracking-tight text-clay-800">Upload File</div>,
  values,
}: TProps) => {
  const { accept, maxFiles, maxSize } = dropzoneProps;
  const [triedToAddManyFiles, setTriedToAddManyFiles] = useState(false);
  const onDrop = (acceptedFiles: File[]) => {
    if (dropzoneProps.maxFiles && values && values.length + acceptedFiles.length > dropzoneProps.maxFiles) {
      setTriedToAddManyFiles(true);
      return;
    }
    setTriedToAddManyFiles(false);
    onChange(acceptedFiles);
  };

  const { fileRejections, getInputProps, getRootProps, isDragActive } = useDropzone({
    ...dropzoneProps,
    onDrop,
    validator: (file) => duplicateValidator(values, file),
  });

  const filesUploaded = values && values.length > 0;

  const acceptableTypes = accept
    ? Object.values(accept)
        .flatMap((ext) => ext)
        .map((ext) => `*${ext}`)
        .join(', ')
    : '';

  const getErrorMessage = () => {
    if (triedToAddManyFiles) {
      return 'Too many files';
    }
    if (!fileRejections || fileRejections.length === 0) return;
    if (fileRejections[0]?.errors?.[0].code === 'file-invalid-type') {
      return `File type must be one of ${acceptableTypes}`;
    }
    if (fileRejections[0]?.errors?.[0].code === 'duplicated-file') {
      return `Files are not unique: ${fileRejections.map((reject) => reject.file.name).join(', ')}`;
    }
    return fileRejections[0]?.errors?.[0].message;
  };

  return (
    <div className="flex flex-col gap-4">
      <div
        {...getRootProps()}
        className={twMerge(
          'flex cursor-pointer flex-col rounded-lg border-[1.2px] border-dashed border-clay-40 p-3',
          'font-normal text-clay-400 transition-all duration-200',
          isDragActive && 'border-primary-800',
        )}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <>
            <p>Drop the files here ...</p>
            {accept && <em>(Only {acceptableTypes} files will be accepted)</em>}
            {maxFiles && maxFiles > 1 && <em>(Only {maxFiles} files will be accepted)</em>}
            {maxSize && (
              <em>
                (Only files of size {`<`} {maxSize / 1000 / 1000}MB will be accepted)
              </em>
            )}
          </>
        ) : (
          <div>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                {filesUploaded ? (
                  filePreview
                ) : subtitle ? (
                  <div className="flex items-center gap-4">
                    <div className="flex size-12 items-center justify-center rounded-lg bg-clay-10">
                      {icon && icon}
                    </div>
                    <div className="flex flex-col gap-0.5">
                      <div className="font-medium tracking-tight text-clay-800">{title}</div>
                      <div className="text-sm font-light text-clay-350">{subtitle}</div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="flex size-12 items-center justify-center rounded-lg bg-clay-10">
                      {icon && icon}
                    </div>
                    <div className="font-medium tracking-tight text-clay-800">{title}</div>
                  </>
                )}
                {filesUploaded && <div></div>}
              </div>

              <Button color="white" size="extra-small">
                Browse
              </Button>
            </div>
            <div className="mt-1 font-normal text-red-800">{getErrorMessage()}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const duplicateValidator = (
  uploadedFiles: File[] | undefined,
  file: File,
): { code: string; message: string } | null => {
  if (
    uploadedFiles &&
    uploadedFiles.some((f) => f.name === file.name && f.size === file.size && f.type === file.type)
  ) {
    return {
      code: 'duplicated-file',
      message: 'File must be unique',
    };
  }
  return null;
};

import { useQueryClient } from '@tanstack/react-query';

import type { DAI } from 'shared/api/dai/types';

import { useUser } from 'app/stores/user';
import { daiKeys } from 'shared/api/dai/queryKeys';
import { useDeleteDaiMutation } from 'shared/api/dai/useDeleteDaiMutation';
import { catchError } from 'shared/helpers/parseAxiosError';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';
import { toaster } from 'shared/ui/Toast';

type Props = {
  dai: DAI;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};
export const DeleteConfirmModal = ({ dai, isOpen, onOpenChange }: Props) => {
  const { user } = useUser();
  const { isPending, mutateAsync: deleteDAI } = useDeleteDaiMutation({
    onSuccess: () => {
      const queryKey = daiKeys.userDAIs({ limit: 100, skip: 0, userId: user?._id || '' });

      queryClient.refetchQueries({
        queryKey,
      });
    },
  });

  const queryClient = useQueryClient();

  const handleDelete = async () => {
    try {
      await deleteDAI({ id: dai._id });

      toaster.success('DAI was deleted successfully');

      onOpenChange(false);
    } catch (e) {
      catchError(e);
    }
  };
  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="!max-w-md">
        <Modal.CloseButton />

        <h2 className="mb-5 text-center">
          Are you sure you want to delete <b>{dai.projectName || ''}</b> DAI?
        </h2>

        <div className="flex w-full items-center gap-2">
          <Button className="w-full" color="secondary" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button className="w-full" color="red" isLoading={isPending} onClick={handleDelete}>
            Delete
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

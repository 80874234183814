import type { WalletActivityRequest } from './types';

export const transactionKeys = {
  base: ['transaction'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: (params: Record<string, any>) => [...transactionKeys.base, 'item', params],
  modelList: (params: { limit?: number; skip?: number }) => [...transactionKeys.base, 'modelList', params],

  walletActivity: (params: WalletActivityRequest) => [...transactionKeys.base, 'wallet-activity', params],
};

import { getSizeCategories } from 'shared/api/datasets/useGetDatasetsQuery';
import { useGetMatchingDatasetsTags } from 'shared/api/datasets/useGetMatchingDatasetsTags';
import { Spinner } from 'shared/ui/Spinner';

import type { FiltersProps } from './MainFilters';

import { Chip } from './Chip';

type Props = {
  filters: FiltersProps['filters'];
  onChange: FiltersProps['onChange'];
};

export const LicenseFilters = ({ filters, onChange }: Props) => {
  const { data: availableLicense, isPending } = useGetMatchingDatasetsTags({
    format:
      filters.format && filters.format?.length > 0
        ? filters.format.map((val) => `format:${val}`).join(',')
        : undefined,
    language: filters.language?.join(','),
    library: filters.library?.join(','),
    modality:
      filters.modality && filters.modality?.length > 0
        ? filters.modality.map((val) => `modality:${val}`).join(',')
        : undefined,
    other: filters.other?.join(','),
    size_categories: filters.size?.[0] && getSizeCategories(filters.size[0]),

    sort: filters.sort,

    task_categories: filters.tasks?.join(',') || undefined,
    type: 'license',
  });

  const { data: defaultData, isPending: isDefaultPending } = useGetMatchingDatasetsTags(
    { sort: filters.sort, type: 'license' },
    { staleTime: Infinity },
  );

  const remainingLicense = defaultData?.matchingTags.filter(
    (value) => !availableLicense?.matchingTags.includes(value),
  );

  return (
    <div>
      {isPending || isDefaultPending ? (
        <div className="flex flex-col items-center justify-center py-10">
          <Spinner className="size-4" />
        </div>
      ) : (
        <div className="flex flex-wrap items-center gap-2">
          {availableLicense?.matchingTags.map((value) => (
            <Chip
              isActive={filters.license?.includes(value)}
              key={value}
              onClick={() => {
                console.log('filters.library', filters.license);
                onChange({
                  license: filters.license?.includes(value)
                    ? filters.license?.filter((val) => val !== value)
                    : [...(filters.license || []), value],
                });
              }}
            >
              {value.replace('license:', '')}
            </Chip>
          ))}
          {remainingLicense?.map((value) => (
            <Chip isActive={filters.license?.includes(value)} isDisabled key={value}>
              {value.replace('license:', '')}
            </Chip>
          ))}
        </div>
      )}
    </div>
  );
};

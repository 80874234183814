import type { PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

type Props = { isActive?: boolean } & ClassName;

export const CountChip = ({ children, className, isActive }: PropsWithChildren<Props>) => {
  return (
    <span
      className={twMerge(
        'ml-2 flex size-[14px] items-center justify-center rounded-full bg-primary-40 text-[8px]/none text-primary-800',
        isActive && 'bg-primary-30/40 text-white',
        className,
      )}
    >
      {children}
    </span>
  );
};

import type { WillChangeEvent } from '@egjs/react-flicking';

import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Flicking from '@egjs/react-flicking';
import { twMerge } from 'tailwind-merge';

import { getTvl } from 'pages/DAI/helpers/getTvl';
import { useDAIListQuery } from 'shared/api/dai/useDAIListQuery';
import { HotDaiCard } from 'shared/ui/HotDaiCard/HotDaiCard';
import { Icon } from 'shared/ui/Icon';
import { StretchedSkeleton } from 'shared/ui/StretchedSkeleton';

import mockSrc from '../assets/placeholder-image.png';

const list = [
  {
    coinName: '$LYN',
    description: 'Explore the features of your AI model. Build it from the ground up to meet your...',
    id: 1,
    mediaSrc: mockSrc,
    participants: [
      { image: mockSrc, username: 'Test' },
      { image: mockSrc, username: 'Test2' },
    ],
    priceChangePrc: 14,
    tags: ['video'],
    title: 'Everlyn: Your dreams as video',
    tvl: 1364500,
    userName: 'TokenWizard',
  },
  {
    coinName: '$LYN',
    description: 'Explore the features of your AI model. Build it from the ground up to meet your...',
    id: 2,
    mediaSrc: mockSrc,
    participants: [
      { image: mockSrc, username: 'Test' },
      { image: mockSrc, username: 'Test2' },
    ],
    priceChangePrc: 14,
    tags: ['video'],
    title: 'Everlyn: Your dreams as video',
    tvl: 1364500,
    userName: 'TokenWizard',
  },
  {
    coinName: '$LYN',
    description: 'Explore the features of your AI model. Build it from the ground up to meet your...',
    id: 3,
    mediaSrc: mockSrc,
    participants: [
      { image: mockSrc, username: 'Test' },
      { image: mockSrc, username: 'Test2' },
    ],
    priceChangePrc: -14,
    tags: ['video'],
    title: 'Everlyn: Your dreams as video',
    tvl: 1364500,
    userName: 'TokenWizard',
  },
  {
    coinName: '$LYN',
    description: 'Explore the features of your AI model. Build it from the ground up to meet your...',
    id: 4,
    mediaSrc: mockSrc,
    participants: [
      { image: mockSrc, username: 'Test' },
      { image: mockSrc, username: 'Test2' },
    ],
    priceChangePrc: 14,
    tags: ['video'],
    title: 'Everlyn: Your dreams as video',
    tvl: 1364500,
    userName: 'TokenWizard',
  },
];
export const HottestDAIs = () => {
  const ref = useRef<Flicking>(null);

  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);

  const { data: daiList, isPending: isListLoading } = useDAIListQuery({ limit: 5, skip: 0, sort: 'newest' });

  return (
    <div className="flex flex-col overflow-hidden rounded-xl border border-clay-20 bg-gradient-to-tr from-transparent via-transparent via-60% to-primary-800/20  px-4 py-3 shadow-md">
      <header className="mb-4 flex items-center justify-between">
        <div className="text-lg font-medium tracking-tight">Hottest DAI</div>

        <div
          className="flex cursor-pointer items-center gap-1.5 text-primary-800 hover:brightness-90"
          onClick={() => navigate(`/dai/explore`)}
        >
          Explore more <Icon name="arrowUpRight" />
        </div>
      </header>

      <Flicking
        align="prev"
        bound
        cameraClass="flex"
        cameraTag="div"
        circular={false}
        defaultIndex={0}
        horizontal
        noPanelStyleOverride
        onWillChange={(e: WillChangeEvent) => {
          setActiveIndex(e.index);
        }}
        ref={ref}
        renderOnSameKey={false}
        viewportTag="div"
      >
        {isListLoading
          ? Array.from({ length: 4 })
              .fill('')
              .map((_, idx) => {
                return (
                  <div
                    className="mr-2 min-h-64 w-80 min-w-80 max-w-80 overflow-hidden md:w-96 md:min-w-96 md:max-w-96"
                    key={idx}
                  >
                    <StretchedSkeleton className="relative" enable key={idx} rx={12} ry={12} />
                  </div>
                );
              })
          : daiList?.list.map((dai, idx) => {
              const founder = dai.members[0];

              return (
                <div className="mr-2" key={idx}>
                  <HotDaiCard
                    className="h-full w-80 max-w-80 md:w-96 md:max-w-96"
                    coinImageSrc={dai.token?.image}
                    coinName={dai.token?.ticker}
                    description={dai.shortDescription}
                    key={dai._id}
                    mediaSrc={dai.cover}
                    onClick={() => navigate(`/dai/${dai._id}`)}
                    tags={[dai.projectCategory]}
                    title={dai.projectName}
                    tvl={getTvl(dai.tvl)}
                    userImageSrc={founder?.avatar}
                    userName={founder?.name || ''}
                  />
                </div>
              );
            })}
      </Flicking>

      <div className="ml-auto mt-4 flex items-center gap-2 pb-3">
        <Icon
          className={twMerge(
            'size-4 cursor-pointer rounded-md bg-clay-10 p-1 text-clay-500 transition-colors hover:bg-clay-20',
            (activeIndex === undefined || activeIndex <= 0) && 'cursor-not-allowed opacity-50',
          )}
          name="arrowLeftLong"
          onClick={() => {
            if (activeIndex > 0) {
              ref.current?.prev();
            }
          }}
        />
        <Icon
          className={twMerge(
            'size-4 rotate-180 cursor-pointer rounded-md bg-clay-10 p-1 text-clay-500 transition-colors hover:bg-clay-20',
            activeIndex >= list.length - 1 && 'cursor-not-allowed opacity-50',
          )}
          name="arrowLeftLong"
          onClick={() => {
            if (activeIndex < list.length - 1) {
              ref.current?.next();
            }
          }}
        />
      </div>
    </div>
  );
};

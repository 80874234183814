import type { WillChangeEvent } from '@egjs/react-flicking';

import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Flicking from '@egjs/react-flicking';
import { ArrowUpRight, Lock } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

import { Logo } from 'app/ui/Logo';
import { useGetModelsByIdsQuery } from 'shared/api/models/useGetModelsByIdsQuery';
import { getCategoryLabel } from 'shared/helpers/getCategoryLabel';
import { Button } from 'shared/ui/Button';
import { GalleryCard } from 'shared/ui/GalleryCard';
import { Icon } from 'shared/ui/Icon';
import { StretchedSkeleton } from 'shared/ui/StretchedSkeleton';

type Props = {
  connectedModelIds: string[];
  modelDescriptions: Record<string, string>;
};
export const ConnectedModels = ({ connectedModelIds, modelDescriptions }: Props) => {
  const navigate = useNavigate();
  const ref = useRef<Flicking>(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const { data: connectedModels = [], isFetching } = useGetModelsByIdsQuery(
    { ids: connectedModelIds },
    { enabled: connectedModelIds.length > 0 },
  );

  if (!isFetching && connectedModels.length === 0) return null;

  if (isFetching) {
    return (
      <div className="relative m-6 min-h-80 max-w-full">
        <StretchedSkeleton enable rx={12} ry={12} />
      </div>
    );
  }

  const items = connectedModels.slice(0, 10);

  return (
    <div className="bg-clay-10">
      <Flicking
        align="prev"
        bound
        cameraClass="flex min-h-80"
        cameraTag="div"
        circular={false}
        defaultIndex={0}
        horizontal
        noPanelStyleOverride
        onWillChange={(e: WillChangeEvent) => {
          setActiveIndex(e.index);
        }}
        ref={ref}
        renderOnSameKey={false}
        viewportTag="div"
      >
        {items.map((model, idx) => {
          return (
            <div className="flex min-w-full flex-col gap-2 p-6" key={idx}>
              <div className="mb-6 flex flex-col justify-between gap-2 md:flex-row md:items-center">
                <div className="flex w-fit items-center justify-center gap-2 rounded-full bg-white px-6 py-3 text-sm font-medium text-clay-1000 shadow-sm md:text-base">
                  <div className="size-2.5 rounded-full bg-[#00C465]"></div>
                  <Logo className="max-w-12" isBlack />
                  Models Connected
                </div>

                <div
                  className="flex items-center gap-1.5 rounded-lg bg-black px-3.5 py-2 text-[12px]/none text-white"
                  style={{
                    background:
                      'conic-gradient(from 180deg at 48.5% 50%, #000000 -28.12deg, #5A5A5A 26.25deg, #000000 88.12deg, #3A3A3A 146.25deg, #000000 204.37deg, #5E5E5E 268.13deg, #000000 331.88deg, #5A5A5A 386.25deg)',
                  }}
                >
                  <Lock className="size-3 text-tusk-100" />
                  Equivariant Encryption <span className="font-bold">ON</span>
                </div>
                {/* <Button className="!text-sm md:!text-base" color="white">
                  {' '}
                  Fully Blind AI Inference
                </Button> */}
              </div>

              <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                <GalleryCard
                  className="pointer-events-none relative col-span-1 shadow-lg"
                  isTrustEnabled
                  model={model}
                />

                <div className="col-span-1 md:col-span-2">
                  <div className="col-span-1 mb-6 grid gap-3 md:grid-cols-3">
                    <div className="flex flex-col gap-0.5 overflow-hidden rounded-lg bg-white px-5 py-3">
                      <div className="text-sm text-clay-350">Model</div>
                      <div className="truncate text-base font-medium">{model.modelName}</div>
                    </div>
                    <div className="flex flex-col gap-0.5 rounded-lg bg-white px-5 py-3">
                      <div className="text-sm text-clay-350">Type</div>
                      <div className="text-base font-medium">{getCategoryLabel(model.type)}</div>
                    </div>
                    <div className="flex flex-col gap-0.5 rounded-lg bg-white px-5 py-3">
                      <div className="text-sm text-clay-350">Training</div>
                      <div className="text-base font-medium">--</div>
                    </div>
                  </div>
                  {modelDescriptions?.[model._id] && (
                    <div className="mb-4 text-base font-light text-clay-350 md:text-lg">
                      {modelDescriptions[model._id]}
                    </div>
                  )}

                  <Button
                    className="pr-2 text-sm md:!text-base"
                    color="white"
                    onClick={() => navigate(`/models/${model._id}`)}
                  >
                    Try Now
                    <ArrowUpRight className="size-4 md:size-5" />
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </Flicking>

      <div className="flex items-center justify-end gap-2 px-6 pb-3">
        <Icon
          className={twMerge(
            'size-4 cursor-pointer rounded-md bg-clay-10 p-1 text-clay-500 transition-colors hover:bg-clay-20',
            (activeIndex === undefined || activeIndex <= 0) && 'cursor-not-allowed opacity-50',
          )}
          name="arrowLeftLong"
          onClick={() => {
            if (activeIndex > 0) {
              ref.current?.prev();
            }
          }}
        />
        <Icon
          className={twMerge(
            'size-4 rotate-180 cursor-pointer rounded-md bg-clay-10 p-1 text-clay-500 transition-colors hover:bg-clay-20',
            activeIndex >= connectedModels.length - 1 && 'cursor-not-allowed opacity-50',
          )}
          name="arrowLeftLong"
          onClick={() => {
            if (activeIndex < connectedModels.length - 1) {
              ref.current?.next();
            }
          }}
        />
      </div>
    </div>
  );
};

import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useDebounce } from '@uidotdev/usehooks';

import { BackButton } from 'app/ui/BackButton';
import { CreateDatasetModal } from 'features/CreateDatasetModal';
import { sizesCheckMapRevert, useGetDatasetsQuery } from 'shared/api/datasets/useGetDatasetsQuery';
import { useStateX } from 'shared/hooks/useStateX';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Card } from 'shared/ui/Card';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';

import { CreateDatasetBanner } from './ui/CreateDatasetBanner';
import { DatasetCard } from './ui/DatasetCard';
import { DatasetFilters } from './ui/DatasetFilters';
import { MainFilters } from './ui/MainFilters';
import { Pagination } from './ui/Pagination';

export const Datasets = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [leftFilters, setLeftFilters] = useStateX<MainFilters>({
    filterSection: 'main',
    format: [],
    keywords: [],
    modality: [],
    search: undefined,
    size: ['>1T'],
    sort: 'trending',
    tasks: [],
  });

  const debouncedSearch = useDebounce(leftFilters.search, 300);
  const [page, setPage] = useState(0);
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const { data, isPending } = useGetDatasetsQuery({
    format: leftFilters.format,
    keywords: leftFilters.keywords,
    library: leftFilters.library,
    modality: leftFilters.modality,
    p: page,
    search: debouncedSearch || undefined,
    size: leftFilters.size,
    sort: leftFilters.sort,
    tasks: leftFilters.tasks,
    withCount: true,
  });

  const datasetList = data?.datasets;

  const openCreationModal = searchParams.get('openCreationModal');
  useEffect(() => {
    if (openCreationModal) {
      setSearchParams((prev) => {
        const newS = new URLSearchParams(prev);
        newS.delete('openCreationModal');
        return newS;
      });
      setIsCreateOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCreationModal]);

  useEffect(() => {
    const keyword = searchParams.get('keywords');

    if (!keyword) return;

    if (keyword in sizesCheckMapRevert) {
      setLeftFilters({ size: [sizesCheckMapRevert[keyword as keyof typeof sizesCheckMapRevert]] });
    } else {
      setLeftFilters({ keywords: [keyword] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnimateRoute className="max-w-[1300px]  pt-4" container>
      <div className="mb-2.5 flex flex-col justify-between gap-2 sm:flex-row sm:items-center">
        <BackButton onClick={() => navigate(-1)}>
          Datasets
          <div className="flex items-center justify-center rounded-md border border-clay-20 bg-white px-1.5 py-1 text-xs/none font-normal uppercase text-primary-800">
            Alpha
          </div>
        </BackButton>

        <DatasetFilters
          onCreateDataset={() => setIsCreateOpen(true)}
          onSortChange={(sort) => setLeftFilters({ sort })}
          sort={leftFilters.sort}
        />
      </div>

      <div className="grid grid-cols-1 gap-6 pb-10 lg:grid-cols-3">
        <div className="col-span-1 flex flex-col gap-6">
          <CreateDatasetBanner onClick={() => setIsCreateOpen(true)} />

          <MainFilters filters={leftFilters} onChange={setLeftFilters} />
        </div>
        <Card className="col-span-1 flex flex-col rounded-xl !px-6 !pb-10 lg:col-span-2">
          <div className="mb-5 flex flex-col items-center justify-between gap-1.5 sm:flex-row">
            <div className="w-full text-xl font-medium">
              Datasets{' '}
              {data?.numTotalItems !== undefined && (
                <span className="ml-2 text-lg font-light text-clay-500">
                  {data?.numTotalItems?.toLocaleString('en')} on-chain
                </span>
              )}
            </div>

            <div className="w-full">
              <Input
                className=""
                classNameInputWrapper="bg-clay-10 border-clay-10 !text-sm text-clay-300"
                onChange={(e) => setLeftFilters({ search: e.target.value || '' })}
                placeholder="Filter by name"
                startSlot={<Icon className="size-3 text-clay-300" name="search" />}
                value={leftFilters.search || ''}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
            {isPending ? (
              <>
                {Array.from({ length: 30 })
                  .fill('')
                  .map((_, idx) => (
                    <DatasetCard isLoading key={idx} />
                  ))}
              </>
            ) : (
              datasetList?.map((dataset) => {
                return (
                  <DatasetCard
                    key={dataset.datasetId}
                    likes={dataset.likes || 0}
                    // modalities={dataset.datasetsServerInfo?.modalities}
                    onClick={() => navigate(`/datasets/${dataset.datasetId.replace('/', '---')}`)}
                    rows={dataset?.nRows}
                    timestamp={dataset.timestamp}
                    title={dataset.datasetId}
                  />
                );
              })
            )}

            {data?.datasets !== undefined && data?.datasets.length === 0 && (
              <div className="col-span-2 my-auto flex size-full flex-1 flex-col items-center justify-center py-14 text-center text-sm text-clay-300">
                No data found for given filters
              </div>
            )}
          </div>

          {data && data?.numTotalItems !== undefined && data?.numTotalItems > 0 && (
            <Pagination
              onPageChange={setPage}
              page={page}
              perPage={30}
              totalItemsCount={data?.numTotalItems}
            />
          )}
        </Card>
      </div>

      <CreateDatasetModal isOpen={isCreateOpen} onOpenChange={setIsCreateOpen} />
    </AnimateRoute>
  );
};

import type { ComponentProps } from 'react';

import { twMerge } from 'tailwind-merge';

import { Icon } from 'shared/ui/Icon';

export const Card = ({ className, ...props }: ComponentProps<'div'>) => {
  return (
    <div
      {...props}
      className={twMerge('row-span-3 grid grid-rows-subgrid gap-7 rounded-xl bg-white p-6', className)}
    />
  );
};

export const CardHeader = ({ className, ...props }: ComponentProps<'div'>) => {
  return <div {...props} className={twMerge('border-b border-clay-20 pb-6', className)} />;
};

export const CardTitle = ({ className, ...props }: ComponentProps<'div'>) => {
  return <div {...props} className={twMerge('text-3xl font-semibold text-clay-900', className)} />;
};

export const CardDescription = ({ className, ...props }: ComponentProps<'div'>) => {
  return <div {...props} className={twMerge('mt-1.5 text-lg/none text-clay-500', className)} />;
};

export const CardItem = ({ children, className, ...props }: ComponentProps<'div'>) => {
  return (
    <div {...props} className={twMerge('flex items-start gap-2.5', className)}>
      <div className="size-3.5 min-w-3.5">
        <Icon className="size-3.5 text-green-600" name="check" safeArea="0" />
      </div>
      <div className="flex-1 text-sm/none text-clay-500">{children}</div>
    </div>
  );
};

export const CardItemWrapper = ({ className, ...props }: ComponentProps<'div'>) => {
  return <div {...props} className={twMerge('flex flex-col gap-3.5', className)} />;
};

export const CardPriceDescription = ({ className, ...props }: ComponentProps<'div'>) => {
  return <div {...props} className={twMerge('text-sm/4 text-clay-500', className)} />;
};

export const CardPrice = ({ className, ...props }: ComponentProps<'div'>) => {
  return (
    <div {...props} className={twMerge('text-[2rem] font-semibold leading-9 text-clay-900', className)} />
  );
};

export const CardFooter = ({ className, ...props }: ComponentProps<'footer'>) => {
  return <footer {...props} className={twMerge('flex flex-col gap-2.5', className)} />;
};

import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import amdSrc from './assets/AMD.svg';
import appleSrc from './assets/apple.svg';
import intelSrc from './assets/intel.svg';
import nvidiaSrc from './assets/nvidia.svg';

type Props = {
  specs: string;
} & ClassName;

export const NodeSpecChip = ({ className, specs }: Props) => {
  return (
    <div
      className={twMerge(
        'flex items-center gap-2 rounded-lg bg-clay-10 px-3 py-2 text-sm font-normal',
        className,
      )}
    >
      {specs.includes('NVIDIA Corporation') ? (
        <div className="flex items-center gap-2 text-sm/none">
          <img className="max-w-20" src={nvidiaSrc} /> {specs.replace('NVIDIA Corporation', '')}
        </div>
      ) : specs.includes('Intel') ? (
        <div className="flex items-center gap-2 text-sm/none">
          <img className="w-full max-w-8" src={intelSrc} /> {specs.replace('Intel', '')}
        </div>
      ) : specs.includes('AMD') ? (
        <div className="flex items-center gap-2 text-sm/none">
          <img className="w-full max-w-10" src={amdSrc} /> {specs.replace('AMD', '')}
        </div>
      ) : specs.includes('Apple') ? (
        <div className="flex items-center gap-2 text-sm/none">
          <img className="w-full max-w-12" src={appleSrc} /> {specs.replace('Apple', '')}
        </div>
      ) : (
        specs
      )}
    </div>
  );
};
